import { useWindowSize } from './useWindowsSizeHooks';
import { Responsive } from "semantic-ui-react";
/* eslint-disable */
export const isMobileScreen = () => {
    const currentScreenSize = useWindowSize();
    const defaultMobileScreen = Responsive.onlyMobile.maxWidth;

    return currentScreenSize <= defaultMobileScreen;
}
/* eslint-enable */

