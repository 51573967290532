import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Image } from "semantic-ui-react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { isMobileScreen } from "../../helpers/isMobileScreen";

//Assets
import SteerLogoWhite from "../../assets/images/stear-logo-white.svg";

//Components
import ProfileMenu from "./profile-menu/profileMenu";
import NotificationMenu from "./notification-menu/notificationMenu";
import HeaderMenuTop from "./header-menu-top";
import MobileBackButton from "../mobile-responsive-components/mobile-back-button/mobileBackButton";
import MobileSideButton from "../mobile-responsive-components/mobile-side-button/mobileSideButton";
import ModalD3ScheduleStatus from "../../containers/views/optimise-page/components/modal/modal-d3-schedule-status";

// Hooks
import { useStateWithPaths, useActions } from "../../hooks/useConnect";
import useListenSocketEvent from "../../hooks/useListenSocketEvent";

// Redux
import { GENERAL_REDUCER } from "../../reducers/common/generalReducer";
import { HEADER_TITLE_REDUCER } from "../../reducers/common/headerTitleReducer";
import { UAC_REDUCER } from "../../reducers/common/uacReducer";
import { OPTIMISE_PAGE_REDUCER } from "../../reducers/optimisePageReducer";
import { PAGES_WITH_D3_SCHEDULE_STATUS, FEATURE_CODE_ACCESS_SCHEDULE_STATUS } from "../../constants/applicationInsights";

// Actions
import * as GeneralActions from "../../actions/common/actionGeneral";
import * as HeaderTitleActions from "../../actions/common/actionHeaderTitle";
import * as OptimisePageActions from "../../actions/actionOptimise";


// Constants
import { CATEGORY_SCHEDULE_D3_VOYAGE_STATUS, SOCKET_EVENTS_NAME } from "../../constants/optimise";
import { DIVISION_CODE } from "../../constants/userGroups";

const TopHeader = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [generalData] = useStateWithPaths([`${GENERAL_REDUCER}`]);
  const [headerTitleData] = useStateWithPaths([`${HEADER_TITLE_REDUCER}`]);
  const [{ optimisePage }] = useStateWithPaths([`${OPTIMISE_PAGE_REDUCER}`]);
  const [uacData] = useStateWithPaths([`${UAC_REDUCER}`]);

  const { 
    setRedirectLocation,
    getVesselScheduleSidebar,
    setStateOptimisePage,
    getScheduleStatusDMinus3Header
  } = useActions({
    setRedirectLocation: GeneralActions.setRedirectLocation,
    getVesselScheduleSidebar: OptimisePageActions.getVesselScheduleSidebar,
    setStateOptimisePage: OptimisePageActions.setStateOptimisePage,
    getScheduleStatusDMinus3Header: HeaderTitleActions.getScheduleStatusDMinus3Header
  });

  const [openD3ScheduleStatusModal, setOpenD3ScheduleStatusModal] = useState(false);
  const [checkScheduleD3Status, setCheckScheduleD3Status] = useState({});

  const scheduleD3VoyageStatus = headerTitleData?.scheduleD3VoyageStatus;
  const voyagesDMinus3Status = optimisePage?.voyagesDMinus3Status;
  const divisionId = uacData?.uac?.data?.division_id;
  const divisionCode = uacData?.uac?.data?.division_code;
  const allFeatures = uacData?.uac?.data?.all_features;

  const executeMenu = [
    "/track",
    "/explore",
    "/planning",
    "/dashboard",
    "/kpi",
    "/delete-vessel-schedule",
    "/speed",
  ];

  const permissionForD3Status = (PAGES_WITH_D3_SCHEDULE_STATUS.includes(pathname) && allFeatures?.some(feature => FEATURE_CODE_ACCESS_SCHEDULE_STATUS.includes(feature.feature_code)));
  const isMobile = isMobileScreen();

  // Socket
  const socketEvents = useMemo(() => [
    {
      eventName: SOCKET_EVENTS_NAME.connect,
      handlerFn: (param) => {
        console.log(`%cConnection successful`, `background: #a8a8fa; padding: 1px;`);
      }
    },
    {
      eventName: SOCKET_EVENTS_NAME.connect_failed,
      handlerFn: (param) => {
        console.log('%cConnection Failed', `background: #000; padding: 1px;`);
      }
    },
    {
      eventName: SOCKET_EVENTS_NAME.d3Optimisation,
      handlerFn: (param) => {
        const trip_id = param?.trip_id;
        const d3Status = {
          is_valid: true,
          message: "Successfully retrieved data.",
          data: param
        }
        const currentDivisionId = divisionId;
        if (param?.division_id == currentDivisionId) {
          setStateOptimisePage("voyagesDMinus3Status", { ...voyagesDMinus3Status, [trip_id]: d3Status })
          getScheduleStatusDMinus3Header({ category: CATEGORY_SCHEDULE_D3_VOYAGE_STATUS.schedulePendingVerification })
        }
      }
    },
    {
      eventName: SOCKET_EVENTS_NAME.updateStatusD3Voyage,
      handlerFn: (param) => {
        getScheduleStatusDMinus3Header({ category: CATEGORY_SCHEDULE_D3_VOYAGE_STATUS.schedulePendingVerification })
      }
    }
  ], [divisionId, getScheduleStatusDMinus3Header, voyagesDMinus3Status])

  useListenSocketEvent(socketEvents, permissionForD3Status);

  useEffect(() => {
    if (executeMenu.includes(location?.pathname)) {
      setRedirectLocation("track");
    } else if (_.isEqual(location?.pathname, "/")) {
      setRedirectLocation("home");
    } else if (_.isEqual(location?.pathname, "/main-explore")) {
      setRedirectLocation("track");
    } else {
      setRedirectLocation("demand");
    }
    /* eslint-disable */
  }, [history])
  /* eslint-enable */

  const getTitle = () => {
    const length = headerTitleData?.titleAndCallBackStack?.length || 0
    if(length > 0) {
      return headerTitleData?.titleAndCallBackStack[length-1].title
    } 
    return headerTitleData?.data
  }

  const openSidebar = (voyageId, dsStatus)  => {
    getVesselScheduleSidebar(voyageId);
    navigate('/vessel-schedule');
    setStateOptimisePage("openVesselScheduleSidebar", {
      isOpen: true,
      dsStatus
    });
    setOpenD3ScheduleStatusModal(false);
  }

  useEffect(() => {
    const isProcessing = scheduleD3VoyageStatus?.data?.find(item => item.ds_status === "Processing")
    const object = {
      color: "#26A671",
      title: "D-3 Optimisation Finished",
      isProcessing: false
    }
    if (isProcessing) {
      object.color = "#E29E20";
      object.title = "D-3 Optimisation is Running"
      object.isProcessing = true
    }
    
    setCheckScheduleD3Status(object)
  }, [scheduleD3VoyageStatus])

  useEffect(() => {
    if (permissionForD3Status) {
      getScheduleStatusDMinus3Header({category: CATEGORY_SCHEDULE_D3_VOYAGE_STATUS.schedulePendingVerification})
      setStateOptimisePage("isPLTIntegration", _.isEqual(DIVISION_CODE.PCSB, divisionCode));
    }
  }, [permissionForD3Status])

  return (
    isMobile ? 
      (_.isEqual(location?.pathname, "/") ? (
          <div className={`flex mobile-top-header-container ${generalData?.general.redirect_location}`}>
            <div className={`flex space-between width-100`}>
              <div className={`flex column mobile-secondary-title-container mobile-width-33`}>
                {headerTitleData?.titleAndCallBackStack?.length > 0 ? 
                  <div className={`flex`} style={{ width: '250px' }}>
                      <div><MobileBackButton headerTitleData={headerTitleData}/></div>
                      <div className={`mobile-header-title`}>{getTitle()}</div>
                  </div>
                  : <Link
                    to={"/"}
                    onClick={() => {
                      setRedirectLocation("home");
                    }}
                  >
                    <div className={`flex`}>
                      <Image src={SteerLogoWhite} />
                    </div>
                  </Link>
                }
              </div>
              <div className={`flex end mobile-width-33`}>
                <div className={`flex`}>
                  <NotificationMenu />
                </div>
                <div className={`flex mobile-side-button-container`}>
                  <MobileSideButton type={props.type} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`flex mobile-top-header-container ${generalData?.general.redirect_location}`}>
            <div className={`flex space-between width-100`}>
              <div className={`flex column mobile-secondary-title-container mobile-width-33`}>
                <div className={`flex`} style={{ width: '250px' }}>
                  <div><MobileBackButton headerTitleData={headerTitleData}/></div>
                  <div className={`mobile-header-title`}>{getTitle()}</div>
                </div>
              </div>
              <div className={`flex end mobile-width-33`}>
                <div className={`flex`}>
                </div>
                <div className={`flex mobile-side-button-container`}>
                  <MobileSideButton type={props.type} />
                </div>
              </div>
            </div>
          </div>
        )
      ) :
      (
        <div className={`flex top-header-container ${generalData?.general.redirect_location}`}>
        <div className={`flex width-100 space-between`}>
          <div className={`flex column secondary-title-container width-33`}>
            <Link
              to={"/"}
              onClick={() => {
                setRedirectLocation("home");
              }}
            >
              <div className={`flex`}>
                <Image src={SteerLogoWhite} style={{ height: "42px", width: "135px" }} />
              </div>
            </Link>
          </div>
          <div className="header-menu-wrapper flex justify-center  width-100">
            <HeaderMenuTop type={props.type} />
          </div>
          <div className={`header-menu-right flex end width-33 center`} style={{gap: 40}}>
            <NotificationMenu />
            {permissionForD3Status && scheduleD3VoyageStatus?.is_valid && (
              <ModalD3ScheduleStatus
                openD3ScheduleStatusModal={openD3ScheduleStatusModal}
                setOpenD3ScheduleStatusModal={setOpenD3ScheduleStatusModal}
                scheduleD3VoyageStatus={scheduleD3VoyageStatus}
                openSidebar={openSidebar}
                checkScheduleD3Status={checkScheduleD3Status}
              />
            )}
            <ProfileMenu type={props.type} />          
          </div>
        </div>
      </div>
      )
  );

  
};

export default TopHeader;
