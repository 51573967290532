import React, { useState, useEffect } from 'react'
import HeaderMenuTop from '../../header/header-menu-top';

const MobileSideButton = (props) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleSetIsSidebarOpen = (value) => {
        setIsSidebarOpen(value);
    }

    return (
        <div>
            <div className={"mobile-side-button"} onClick={() => {handleSetIsSidebarOpen(true)}}>
                <div className={'mobile-side-button-line'}></div>
                <div className={'mobile-side-button-line'}></div>
                <div className={'mobile-side-button-line'}></div>
            </div>
            <HeaderMenuTop type={props.type} isSidebarOpen={isSidebarOpen} handleSetIsSidebarOpen={handleSetIsSidebarOpen} />
        </div>
    );
}

export default MobileSideButton