import { useEffect } from "react";
import SupportVirtusIcon from "../../assets/images/support-call-virtus.svg";
import SupportVirtusActiveIcon from "../../assets/images/support-call-virtus-active.svg";
import CloseIcon from "../../assets/images/close-white.svg";
import { Image } from "semantic-ui-react";

const VirtusSupport = ({ isOpen, setIsOpen }) => {
  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window?.hbspt) {
          window?.hbspt.forms.create({
            region: "na1",
            portalId: "43629946",
            formId: "48476e17-e7ac-4f5a-b903-a31a6c54dae3",
            target: "#hubspotForm",
          });
        }
      });
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]);

  return (
    <div>
      <div
        className={`virtus-support virtus-support-btn ${
          isOpen && "virtus-support-btn-active"
        } flex`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Image src={!isOpen ? SupportVirtusIcon : SupportVirtusActiveIcon} />
      </div>
      {isOpen && (
        <div className={`virtus-support-popup-container`}>
          <div className="content-header pad-1">
            <p className="content-header-text">Support Team</p>
            <Image
              src={CloseIcon}
              onClick={() => setIsOpen(false)}
              className="content-header-icon"
            />
          </div>
          <div className="content-form pad-1">
            <p className="contact-sub-text">
              Let us know your concerns by filling in the information below.
            </p>
            <br />
            <p className="contact-sub-text">Alternatively, you may visit us at</p>
            <a
              className="contact-sub-text-link"
              href="https://helpdesk.virtus-ip.com/"
              target="_blank"
              rel="noreferrer"
            >
              Virtus Helpdesk
              <br />
              <br />
            </a>
            <div id="hubspotForm"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtusSupport;
