import _ from 'lodash'

import * as actions from '../../actions/common/actionMasterData'

const defaultState = {}

const REDUCERS = {
  [actions.GET_MASTER_DATA_MERGE_WITH_OPTIONS]: (state, { payload }) => {
    return _.merge({}, state, _.set({}, payload.path, payload.value))
  },

  [actions.GET_MASTER_DATA_WITH_OPTIONS]: (state, { payload }) => {
    return _.set(state, payload.path, payload.value)
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const MASTER_DATA_REDUCER = 'commonMasterData'
