import _ from 'lodash'
import { LAMBDA_API_INSTANCE } from '../serverInstances/lambdaServer'
import jwt from 'jsonwebtoken'

const token = localStorage.getItem('idToken')
const session = jwt.decode(token)

const CONTROLLER_NAME = '/notification'

export const postVoyageCompleted = (data) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/voyageCompleted`, data))
}

export const postSaveVoyageCompleted = (ratingDetail, userRating, userComment) => {
  const bodyParams = {
    berthing_date: ratingDetail.berthing_date,
    vessel_id: ratingDetail.vessel_id,
    vessel_name: ratingDetail.vessel_name,
    trip_id: ratingDetail.trip_id,
    user_rating: userRating,
    user_comment: userComment,
    updated_date: new Date(),
    updated_by: session.name,
    location: ratingDetail.location,
    seq: ratingDetail.seq,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/saveVoyageCompleted`, bodyParams))
}

export const postVesselTrackingStatusReason = (data) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselTrackingStatusReason`, data))
}

export const postShipToShip = () => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/shipToShip`))
}

export const postIncidentReasons = (data) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/incidentReasons`, data))
}

// export const postSaveVesselTrackingStatusReason = (item, reasonList, reasonComment) => {
//   const bodyParams = {
//     trip_id: item.trip_id,
//     seq: item.seq,
//     location: item.location,
//     user_added_reasons: reasonList,
//     user_comment: reasonComment,
//     updated_by: session.name,
//   }

//   return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/saveVesselTrackingStatusReason`, bodyParams))
// }

export const postSaveUserNotificationStatus = (checkedNotificationList) => {
  const bodyParams = {
    user_notification_statuses: _.map(checkedNotificationList, (item) => ({
      notification_type_id: item.notification_type_id,
      trip_id: item.trip_id,
      seq: item.seq,
      location: item.location,
      notification: item.notification,
      is_read: item.is_read,
      is_flagged: item.is_flagged,
      is_removed: item.is_removed,
    })),
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/saveUserNotificationStatus`, bodyParams))
}

export const postSaveVesselTrackingStatusReason = (activeLog) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/saveVesselTrackingStatusReason`, activeLog))
}

export const postVesselTrackingIncidentUserComment = (selectedNotification) => {
  const bodyParams = {
    trip_id: selectedNotification.trip_id,
    seq: selectedNotification.seq,
    location: selectedNotification.location,
    notification: selectedNotification.notification,
    notification_type_id: selectedNotification.notification_type_id
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/incidentUserComment`, bodyParams))
}

export const postDeleteVesselTrackingIncidentUserComment = (id) => {
  const bodyParams = {
    data: { user_comment_id: +id },
  }
  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_NAME}/incidentUserComment`, bodyParams))
}

export const postUpdateVesselTrackingStatusReason = (data) => {
  const bodyParams = {
    user_comment_id: data.user_comment_id,
    user_added_reason: data.user_added_reason,
    user_added_reason_description: data.user_added_reason_description,
    reason_is_avoidable: data.reason_is_avoidable,
    user_comment: data.user_comment,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/updateVesselTrackingStatusReason`,
    bodyParams
  ))
}


export const postRatingUserComment = (payload, method) => {
  let bodyParams = {}

  if (method == "DELETE") {
    bodyParams = {
      data: { voyage_rating_user_comment_id: payload?.voyage_rating_user_comment_id }
    }
    return LAMBDA_API_INSTANCE().then(response => response.delete(
      `${CONTROLLER_NAME}/voyageRatingUserComment`,
      bodyParams
    ))
  }

  bodyParams = {
    trip_id: payload?.trip_id,
    sequence: payload?.sequence,
    actual_sequence: payload?.actual_sequence,
    location: payload?.location,
    notification: payload?.notification,
    notification_type_id: payload?.notification_type_id,
    user_rating: payload?.user_rating,
    user_comment: payload?.user_comment,
  }

  if (method == "POST") {
    return LAMBDA_API_INSTANCE().then(response => response.post(
      `${CONTROLLER_NAME}/voyageRatingUserComment`,
      bodyParams
    ))
  }

  if (method == "PUT") {
    bodyParams = {
      ...bodyParams,
      voyage_rating_user_comment_id: payload?.voyage_rating_user_comment_id
    }
    return LAMBDA_API_INSTANCE().then(response => response.put(
      `${CONTROLLER_NAME}/voyageRatingUserComment`,
      bodyParams
    ))
  }

}

export const getNotificationListingApi = (query, offset, limit, is_flagged, is_read) => {
    var isflagged = (is_flagged !== null ? `&is_flagged=${is_flagged}` : ``)
    var isread = (is_read !== null ? `&is_read=${is_read}` : ``)

  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/myNotifications?search_keyword=${query}&offset=${offset}&limit=${limit}` + isflagged + isread
    )
  );
};

export const getNotificationDetailsApi = (notification_type_id, trip_id, seq, location, notification) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/myNotificationDetails?notification_type_id=${notification_type_id}&trip_id=${trip_id}&seq=${seq}&location=${location}&notification=${notification}`
    )
  );
};