import * as services from '../services/serviceBooking'

import { fetchError, fetchStart, fetchSuccess } from './common/actionFetch'

export const SET_PAGE = 'SET_PAGE'
export const setPage = (page) => {
  return (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: page,
    })
  }
}

export const SET_MODULE = 'SET_MODULE'
export const setModule = (module) => {
  return (dispatch) => {
    dispatch({
      type: SET_MODULE,
      payload: module,
    })
  }
}

export const SET_BOOKING_TYPE = 'SET_BOOKING_TYPE'
export const setBookingType = (type) => {
  return (dispatch) => {
    dispatch({
      type: SET_BOOKING_TYPE,
      payload: type,
    })
  }
}

export const SET_EDIT_BUTTON = 'SET_EDIT_BUTTON'
export const setEditButton = (confirmEditClicked) => {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_BUTTON,
      payload: confirmEditClicked,
    })
  }
}

export const SET_IS_EDIT = 'SET_IS_EDIT'
export const setIsEdit = (isEdit) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDIT,
      payload: isEdit,
    })
  }
}

export const SET_MATERIAL_SPECIAL_REQUEST_DATA = 'SET_MATERIAL_SPECIAL_REQUEST_DATA'
export const setMaterialSpecialRequestData = (materialSpecialRequestData) => {
  return (dispatch) => {
    dispatch({
      type: SET_MATERIAL_SPECIAL_REQUEST_DATA,
      payload: materialSpecialRequestData,
    })
  }
}

export const POST_VIEW_MATERIAL_MATCHING_VOYAGES = 'POST_VIEW_MATERIAL_MATCHING_VOYAGES'
export const postViewMaterialMatchingVoyages = (
  type,
  projectName,
  discipline,
  startLocation,
  endLocation,
  departureDate,
  arrivalDate
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_MATERIAL_MATCHING_VOYAGES))
    services
      .postViewMatchingVoyages(
        type,
        projectName,
        discipline,
        startLocation,
        endLocation,
        departureDate,
        arrivalDate
      )
      .then((response) =>
        dispatch({
          type: POST_VIEW_MATERIAL_MATCHING_VOYAGES,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_MATERIAL_MATCHING_VOYAGES)))
      .catch(() => dispatch(fetchError(POST_VIEW_MATERIAL_MATCHING_VOYAGES)))
  }
}

export const POST_VIEW_PASSENGER_MATCHING_VOYAGES = 'POST_VIEW_PASSENGER_MATCHING_VOYAGES'
export const postViewPassengerMatchingVoyages = (
  type,
  projectName,
  discipline,
  startLocation,
  endLocation,
  departureDate,
  arrivalDate
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_PASSENGER_MATCHING_VOYAGES))
    services
      .postViewMatchingVoyages(
        type,
        projectName,
        discipline,
        startLocation,
        endLocation,
        departureDate,
        arrivalDate
      )
      .then((response) =>
        dispatch({
          type: POST_VIEW_PASSENGER_MATCHING_VOYAGES,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_PASSENGER_MATCHING_VOYAGES)))
      .catch(() => dispatch(fetchError(POST_VIEW_PASSENGER_MATCHING_VOYAGES)))
  }
}

export const POST_SAVE_RESERVATION = 'POST_SAVE_RESERVATION'
export const postSaveReservation = (confirmReservationData) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_RESERVATION))
    services
      .postSaveReservation(confirmReservationData)
      .then((response) =>
        dispatch({
          type: POST_SAVE_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_SAVE_RESERVATION)))
  }
}

export const POST_VIEW_RESERVATION_LIST = 'POST_VIEW_RESERVATION_LIST'
export const postViewReservationList = (type, userEmail, status, pagination) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_RESERVATION_LIST))
    services
      .postViewReservationList(type, userEmail, status, pagination)
      .then((response) =>
        dispatch({
          type: POST_VIEW_RESERVATION_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_RESERVATION_LIST)))
      .catch(() => dispatch(fetchError(POST_VIEW_RESERVATION_LIST)))
  }
}

export const POST_EDIT_PASSENGER_RESERVATION = 'POST_EDIT_PASSENGER_RESERVATION'
export const postEditPassengerReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EDIT_PASSENGER_RESERVATION))
    services
      .postEditReservation(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_EDIT_PASSENGER_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EDIT_PASSENGER_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_EDIT_PASSENGER_RESERVATION)))
  }
}

export const POST_EDIT_MATERIAL_RESERVATION = 'POST_EDIT_MATERIAL_RESERVATION'
export const postEditMaterialReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EDIT_MATERIAL_RESERVATION))
    services
      .postEditReservation(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_EDIT_MATERIAL_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EDIT_MATERIAL_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_EDIT_MATERIAL_RESERVATION)))
  }
}

export const POST_VIEW_PASSENGER_FINALIZED_ITINERARY = 'POST_VIEW_PASSENGER_FINALIZED_ITINERARY'
export const postViewPassengerFinalizedItinerary = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_PASSENGER_FINALIZED_ITINERARY))
    services
      .postViewFinalizedItinerary(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_VIEW_PASSENGER_FINALIZED_ITINERARY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_PASSENGER_FINALIZED_ITINERARY)))
      .catch(() => dispatch(fetchError(POST_VIEW_PASSENGER_FINALIZED_ITINERARY)))
  }
}

export const POST_VIEW_MATERIAL_FINALIZED_ITINERARY = 'POST_VIEW_MATERIAL_FINALIZED_ITINERARY'
export const postViewMaterialFinalizedItinerary = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_MATERIAL_FINALIZED_ITINERARY))
    services
      .postViewFinalizedItinerary(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_VIEW_MATERIAL_FINALIZED_ITINERARY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_MATERIAL_FINALIZED_ITINERARY)))
      .catch(() => dispatch(fetchError(POST_VIEW_MATERIAL_FINALIZED_ITINERARY)))
  }
}

export const POST_SAVE_SPECIAL_REQUEST = 'POST_SAVE_SPECIAL_REQUEST'
export const postSaveSpecialRequest = (confirmSpecialRequestData) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_SPECIAL_REQUEST))
    services
      .postSaveSpecialRequest(confirmSpecialRequestData)
      .then((response) =>
        dispatch({
          type: POST_SAVE_SPECIAL_REQUEST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_SPECIAL_REQUEST)))
      .catch(() => dispatch(fetchError(POST_SAVE_SPECIAL_REQUEST)))
  }
}

export const POST_DELETE_RESERVATION = 'POST_DELETE_RESERVATION'
export const postDeleteReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DELETE_RESERVATION))
    services
      .postDeleteReservation(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_DELETE_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DELETE_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_DELETE_RESERVATION)))
  }
}

export const POST_DELETE_SPECIAL_RESERVATION = 'POST_DELETE_SPECIAL_RESERVATION'
export const postDeleteSpecialReservation = (type, selectedReservationId, user_email) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DELETE_SPECIAL_RESERVATION))
    services
      .postDeleteSpecialReservation(type, selectedReservationId, user_email)
      .then((response) =>
        dispatch({
          type: POST_DELETE_SPECIAL_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DELETE_SPECIAL_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_DELETE_SPECIAL_RESERVATION)))
  }
}

export const UPDATED_PASSENGER_VOYAGE = 'UPDATED_PASSENGER_VOYAGE'
export const setUpdatedPassengerVoyage = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATED_PASSENGER_VOYAGE,
      payload: data,
    })
  }
}

export const UPDATED_MATERIAL_VOYAGE = 'UPDATED_MATERIAL_VOYAGE'
export const setUpdatedMaterialVoyage = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATED_MATERIAL_VOYAGE,
      payload: data,
    })
  }
}

export const SET_CONFIRM_MATERIAL = 'SET_CONFIRM_MATERIAL'
export const setConfirmMaterialData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_CONFIRM_MATERIAL,
      payload: data,
    })
  }
}

export const POST_UPDATE_RESERVATION = 'POST_UPDATE_RESERVATION'
export const postUpdateReservation = (updatedReservation) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPDATE_RESERVATION))
    services
      .postUpdateReservation(updatedReservation)
      .then((response) =>
        dispatch({
          type: POST_UPDATE_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_UPDATE_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_UPDATE_RESERVATION)))
  }
}

export const SET_INCOMING_PASSENGER = 'SET_INCOMING_PASSENGER'
export const setIncomingPassenger = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_INCOMING_PASSENGER,
      payload: data,
    })
  }
}

export const SET_OUTGOING_PASSENGER = 'SET_OUTGOING_PASSENGER'
export const setOutgoingPassenger = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_OUTGOING_PASSENGER,
      payload: data,
    })
  }
}

export const RESET_BOOKING_PAGE = 'RESET_BOOKING_PAGE'
export const resetBookingPage = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_BOOKING_PAGE,
      payload: [],
    })
  }
}

export const SET_PROJECT_INPUT_DROPDOWN = 'SET_PROJECT_INPUT_DROPDOWN'
export const getProjectInputDemandDropdown = (type) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_PROJECT_INPUT_DROPDOWN))
    services
      .getProjectInputDemandDropdown(type)
      .then((response) =>
        dispatch({
          type: SET_PROJECT_INPUT_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_PROJECT_INPUT_DROPDOWN)))
      .catch(() => dispatch(fetchError(SET_PROJECT_INPUT_DROPDOWN)))
  }
}

export const SET_DISCIPLINE_INPUT_DROPDOWN = 'SET_DISCIPLINE_INPUT_DROPDOWN'
export const getDisciplineInputDemandDropdown = (project_name) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_PROJECT_INPUT_DROPDOWN))
    services
      .getDisciplineInputDemandDropdown(project_name)
      .then((response) =>
        dispatch({
          type: SET_DISCIPLINE_INPUT_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_DISCIPLINE_INPUT_DROPDOWN)))
      .catch(() => dispatch(fetchError(SET_DISCIPLINE_INPUT_DROPDOWN)))
  }
}

export const SET_LOCATION_INPUT_DROPDOWN = 'SET_LOCATION_INPUT_DROPDOWN'
export const getLocationInputDemandDropdown = (type) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_LOCATION_INPUT_DROPDOWN))
    services
      .getLocationInputDemandDropdown(type)
      .then((response) =>
        dispatch({
          type: SET_LOCATION_INPUT_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_LOCATION_INPUT_DROPDOWN)))
      .catch(() => dispatch(fetchError(SET_LOCATION_INPUT_DROPDOWN)))
  }
}

export const SET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN = 'SET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN'
export const getDemandTypeSpecialRequestDropdown = (type) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN))
    services
      .getDemandTypeSpecialRequestDropdown(type)
      .then((response) =>
        dispatch({
          type: SET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN)))
      .catch(() => dispatch(fetchError(SET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN)))
  }
}

export const SET_CARGO_SPECIAL_REQUEST_DROPDOWN = 'SET_CARGO_SPECIAL_REQUEST_DROPDOWN'
export const getCargoSpecialRequestDropdown = (type, demandType) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_CARGO_SPECIAL_REQUEST_DROPDOWN))
    services
      .getCargoSpecialRequestDropdown(type, demandType)
      .then((response) =>
        dispatch({
          type: SET_CARGO_SPECIAL_REQUEST_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_CARGO_SPECIAL_REQUEST_DROPDOWN)))
      .catch(() => dispatch(fetchError(SET_CARGO_SPECIAL_REQUEST_DROPDOWN)))
  }
}

export const SET_CARGO_UOM_DROPDOWN = 'SET_CARGO_UOM_DROPDOWN'
export const getCargoUomDropdown = (type, cargoName) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_CARGO_UOM_DROPDOWN))
    services
      .getCargoUomDropdown(type, cargoName)
      .then((response) =>
        dispatch({
          type: SET_CARGO_UOM_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_CARGO_UOM_DROPDOWN)))
      .catch(() => dispatch(fetchError(SET_CARGO_UOM_DROPDOWN)))
  }
}

export const POST_VIEW_ORIGINAL_RESERVATION = 'POST_VIEW_ORIGINAL_RESERVATION'
export const postViewOriginalReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_ORIGINAL_RESERVATION))
    services
      .postViewOriginalReservation(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_VIEW_ORIGINAL_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_ORIGINAL_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_VIEW_ORIGINAL_RESERVATION)))
  }
}

export const POST_TRIGGER_VIEWED_RESERVATION = 'POST_TRIGGER_VIEWED_RESERVATION'
export const postTriggerViewedReservation = (type, reservationId, voyageId, userEmail, postReservationStatusNotification) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_TRIGGER_VIEWED_RESERVATION))
    services
      .postTriggerViewedReservation(type, reservationId, voyageId, userEmail)
      .then((response) => {
        dispatch({
          type: POST_TRIGGER_VIEWED_RESERVATION,
          payload: response,
        })
        postReservationStatusNotification && postReservationStatusNotification(userEmail)
      }
      )
      .then(() => dispatch(fetchSuccess(POST_TRIGGER_VIEWED_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_TRIGGER_VIEWED_RESERVATION)))
  }
}

export const SET_IAP_PLANNER_DROPDOWN = 'SET_IAP_PLANNER_DROPDOWN'
export const viewIapPlannerDropdown = (userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_IAP_PLANNER_DROPDOWN))
    services
      .viewIapPlannerDropdown(userEmail)
      .then((response) =>
        dispatch({
          type: SET_IAP_PLANNER_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_IAP_PLANNER_DROPDOWN)))
      .catch(() => dispatch(fetchError(SET_IAP_PLANNER_DROPDOWN)))
  }
}

export const POST_UPDATE_APPROVAL_STATUS = 'POST_UPDATE_APPROVAL_STATUS'
export const postUpdateApprovalStatus = (mode, type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPDATE_APPROVAL_STATUS))
    services
      .postUpdateApprovalStatus(mode, type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_UPDATE_APPROVAL_STATUS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_UPDATE_APPROVAL_STATUS)))
      .catch(() => dispatch(fetchError(POST_UPDATE_APPROVAL_STATUS)))
  }
}

export const POST_SAVE_RESERVATION_APPROVER = 'POST_SAVE_RESERVATION_APPROVER'
export const postSaveReservationApprover = (type, reservationId, userEmail, iapEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_RESERVATION_APPROVER))
    services
      .postSaveReservationApprover(type, reservationId, userEmail, iapEmail)
      .then((response) =>
        dispatch({
          type: POST_SAVE_RESERVATION_APPROVER,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_RESERVATION_APPROVER)))
      .catch(() => dispatch(fetchError(POST_SAVE_RESERVATION_APPROVER)))
  }
}

export const POST_DOWNLOAD_RESERVATION_DETAILS = 'POST_DOWNLOAD_RESERVATION_DETAILS'
export const postDownloadReservationDetails = (type, reservationId, userEmail, voyageId) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DOWNLOAD_RESERVATION_DETAILS))
    services
      .postDownloadReservationDetails(type, reservationId, userEmail, voyageId)
      .then((response) =>
        dispatch({
          type: POST_DOWNLOAD_RESERVATION_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DOWNLOAD_RESERVATION_DETAILS)))
      .catch(() => dispatch(fetchError(POST_DOWNLOAD_RESERVATION_DETAILS)))
  }
}

export const POST_TRACK_VOYAGE_STATUS = 'POST_TRACK_VOYAGE_STATUS'
export const postTrackVoyageStatus = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_TRACK_VOYAGE_STATUS))
    services
      .postTrackVoyageStatus(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_TRACK_VOYAGE_STATUS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_TRACK_VOYAGE_STATUS)))
      .catch(() => dispatch(fetchError(POST_TRACK_VOYAGE_STATUS)))
  }
}

export const SET_ACTION_BOOKING_PAGE = "SET_ACTION_BOOKING_PAGE";
export const setActionBookingPage = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTION_BOOKING_PAGE,
      payload: {[key]: value},
    });
  };
};
