import { LAMBDA_API_INSTANCE } from "../serverInstances/lambdaServer";

const CONTROLLER_NAME = "/messaging";

export const postEmailConfirmReservation = (mode, type, reservationId, voyageId, userEmail) => {
  const bodyParams = {
    mode: mode,
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailConfirmationReservation`, bodyParams));
};

export const postEmailReservationApproval = (type, reservationId, userEmail, iapEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    user_email: userEmail,
    iap_email: iapEmail,
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailReservationApproval`, bodyParams));
};

export const postEmailSubmitReservation = (type, reservationId, userEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailSubmitReservation`, bodyParams));
};

export const postEmailSubmitSpecialRequest = (type, reservationId, userEmail, emailType) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    user_email: userEmail,
    email_type: emailType,
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailSubmitSpecialRequest`, bodyParams));
};

export const postEmailVODVesselAdminVerification = (registrationID) => {
  const bodyParams = {
    registration_id: registrationID
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailVODVesselAdminVerification`, bodyParams));
};

export const postEmailVesselRegistrationApproval = (registrationID, mode) => {
  const bodyParams = {
    registration_id: registrationID,
    mode: mode
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailVODVesselRegistrationApproval`, bodyParams));
};

export const postEmailApproveOrRejectNotificationToBuyerForVesselChartering = (bookingId, mode, leaseType) => {
  const bodyParams = {
    booking_id: bookingId,
    mode: mode,
    lease_type: leaseType
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailVODBuyerRequestApproval`, bodyParams));
};

export const postEmailApproveOrRejectNotificationToBuyerForVoyageSharing = (bookingId, mode, leaseType) => {
  const bodyParams = {
    booking_id: bookingId,
    mode: mode,
    lease_type: leaseType
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailVODBuyerRequestApproval`, bodyParams));
};

export const postEmailVODNewBookingApproval = (bookingID, leaseType) => {
  const bodyParams = {
    booking_id: bookingID, 
    lease_type: leaseType
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/emailVODNewBookingApproval`, bodyParams));
};
