import * as services from '../services/serviceKpi'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

// Freeform 
export const SET_TEST_DATA = 'SET_TEST_DATA'
export const setTestData = () => {
  return (dispatch) => {
    dispatch(fetchStart(SET_TEST_DATA))
    services
      .postKpiChart()
      .then((response) =>
        dispatch({
          type: SET_TEST_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_TEST_DATA)))
      .catch(() => dispatch(fetchError(SET_TEST_DATA)))
  }
}

export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const setFilterData = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_FILTER_DATA))
    services
      .postKpiChart(filterObject)
      .then((response) =>
        dispatch({
          type: SET_FILTER_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_FILTER_DATA)))
      .catch(() => dispatch(fetchError(SET_FILTER_DATA)))
  }
}

export const SET_KPI_LINE_CHART = 'SET_KPI_LINE_CHART'
export const setKpiLineChart = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_KPI_LINE_CHART))
    services
      .postKpiLineChart(filterObject)
      .then((response) =>
        dispatch({
          type: SET_KPI_LINE_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_KPI_LINE_CHART)))
      .catch(() => dispatch(fetchError(SET_KPI_LINE_CHART)))
  }
}


