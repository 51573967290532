import * as speedMonitoringPageActions from "../actions/actionSpeedMonitoring";

import {
  startOfMonth,
  endOfMonth,
} from "date-fns";

const defaultState = {
  searchData: {
    data: "",
  },
  speedMonitoringPage: {
    table: [],
    gauge: [],
    chart: [],
    saveActions: [],
    vessel: "",
    vessel_id: "",
    trip_id: "",
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  },
};

const REDUCERS = {
  [speedMonitoringPageActions.SET_FILTER_DATA]: (state, { payload }) => {
    return {
      ...state,
      speedMonitoringPage: {
        ...state.speedMonitoringPage,
        table: payload,
      },
    };
  },
  [speedMonitoringPageActions.SET_FUEL_GAUGE]: (state, { payload }) => {
    return {
      ...state,
      speedMonitoringPage: {
        ...state.speedMonitoringPage,
        gauge: payload,
      },
    };
  },
  [speedMonitoringPageActions.SET_LINE_CHART]: (state, { payload }) => {
    return {
      ...state,
      speedMonitoringPage: {
        ...state.speedMonitoringPage,
        chart: payload,
      },
    };
  },
  [speedMonitoringPageActions.SET_SAVE_ACTIONS]: (state, { payload }) => {
    return {
      ...state,
      speedMonitoringPage: {
        ...state.speedMonitoringPage,
        saveActions: payload,
      },
    };
  },
  [speedMonitoringPageActions.SET_DROPDOWN_DATA]: (state, { payload }) => {
    return {
      ...state,
      speedMonitoringPage: {
        ...state.speedMonitoringPage,
        vessel: payload,
      },
    };
  },
  [speedMonitoringPageActions.SET_DROPDOWN_ID_DATA]: (state, { payload }) => {
    return {
      ...state,
      speedMonitoringPage: {
        ...state.speedMonitoringPage,
        vessel_id: payload,
      },
    };
  },
};
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const SPEED_MONITORING_PAGE_REDUCER = "speedMonitoringPage";
