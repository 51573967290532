import * as landingPageActions from '../actions/actionLanding'
import { startOfMonth, endOfMonth } from 'date-fns'

const defaultState = {
  landingPage: {
    leadership: [],
    reservation: [],
    shipment: [],
    fleetPerformance: {},
    fleetSummary: {},
    reservationStatusNotification: {},
    mooringBouyUtilization: {
      mooring_buoy_next_schedules: [],
      mooring_buoys_data: []
    },
    period: [
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: 'selection',
      },
    ],
  },
}

const REDUCERS = {
  [landingPageActions.POST_LEADERSHIP_DATA]: (state, { payload }) => {
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
        leadership: payload,
      },
    }
  },
  [landingPageActions.POST_VIEW_RESERVATION_EN_ROUTE]: (state, { payload }) => {
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
        reservation: payload,
      },
    }
  },
  [landingPageActions.POST_SHIPMENT_EN_ROUTE]: (state, { payload }) => {
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
        shipment: payload,
      },
    }
  },
  [landingPageActions.POST_FLEET_SUMMARY]: (state, { payload }) => {
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
        fleetSummary: payload,
      },
    }
  },
  [landingPageActions.POST_FLEET_PERFORMANCE]: (state, { payload }) => {
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
        fleetPerformance: payload,
      },
    }
  },

  [landingPageActions.POST_MOORING_BOUY_UTILIZATION]: (state, { payload }) => {
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
        mooringBouyUtilization: payload,
      },
    }
  },
  [landingPageActions.POST_RESERVATION_STATUS_NOTIFICATION]: (state, { payload }) => {
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
        reservationStatusNotification: payload,
      },
    }
  }
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const LANDING_PAGE_REDUCER = 'landingPage'
