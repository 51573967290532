import * as generalActions from "../../actions/common/actionGeneral";

const defaultState = {
  general: {
    redirect_location: "home",
    reservationPage: "reservation",
    iapPage: "approval",
    tripItem: {},
    isShowNotifyByTripId: false,
    tripItemToContract: {},
    userGuideList: [],
    postUserGuide: {}
  },
};

const REDUCERS = {
  [generalActions.SET_REDIRECT_LOCATION]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        redirect_location: payload,
      },
    };
  },
  [generalActions.SET_RESERVATION_PAGE]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        reservationPage: payload,
      },
    };
  },
  [generalActions.SET_APPROVAL_PAGE]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        iapPage: payload,
      },
    };
  },
  [generalActions.SET_TRIP_ITEM]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        tripItem: payload,
      },
    };
  },
  [generalActions.SET_SHOW_NOTIFY_BY_TRIP_ID]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        isShowNotifyByTripId: payload,
      },
    };
  },
  [generalActions.SET_TRIP_TO_CONTRACT]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        tripItemToContract: payload,
      },
    };
  },
  [generalActions.GET_USER_GUIDE_LIST]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        userGuideList: payload.is_valid ? payload.data : [],
      },
    };
  },
  [generalActions.POST_USER_GUIDE]: (state, { payload }) => {
    return {
      ...state,
      general: {
        ...state.general,
        postUserGuide: payload,
      },
    };
  },
};
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const GENERAL_REDUCER = "commonGeneral";
