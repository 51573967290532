import _ from "lodash";
import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";

const CONTROLLER_NAME = "/uploadvesselschedule";

export const postVesselScheduleData = (filterObject) => {
  const getTripId = _.get(filterObject, 'vessel')
  const bodyParams = {
    trip_id: getTripId
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/uploadedVesselSchedules`, bodyParams));
};

export const postDeleteVesselSchedule = (filterObject) => {
  const getTripId = _.get(filterObject, 'vessel')
  const bodyParams = {
    trip_id: getTripId
  };
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/deleteUploadedVesselSchedule`, bodyParams));
};

