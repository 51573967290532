import _ from 'lodash'
import * as mooringBuoyDetailPageActions from '../actions/actionMooringBuoyDetail'

const defaultState = {
  mooringBuoyDetailPage: {
    maintenanceLogList: [],
    totalMaintenanceLogPage: 1,
    mooringBuoyDetail: { mooring_buoy: null, location: null, status: null, comment: null },
    equipmentTask: [],
    locationLogList: [],
    totalLocationLogPage: 1,
    regionLocations: {
      regionDropdowns: [],
      locationDropdowns: [],
    },
  },
  currentIndexTab: 0,
}

const REDUCERS = {
  [mooringBuoyDetailPageActions.GET_MAINTENANCE_LOG]: (state, { payload }) => {
    return {
      ...state,
      mooringBuoyDetailPage: {
        ...state.mooringBuoyDetailPage,
        maintenanceLogList: payload.maintenance_logs || [],
        mooringBuoyDetail: {
          mooring_buoy: payload.mooring_buoy || null,
          location: payload.location || null,
          status: payload.status || null,
          comment: payload.comment || null,
        },
        totalMaintenanceLogPage: payload.total_page || 1,
      },
    }
  },
  [mooringBuoyDetailPageActions.GET_EQUIPMENT_TASKS]: (state, { payload }) => {
    return {
      ...state,
      mooringBuoyDetailPage: {
        ...state.mooringBuoyDetailPage,
        equipmentTask: payload,
      },
    }
  },
  [mooringBuoyDetailPageActions.GET_REGION_LOCATION_DROPDOWNS]: (state, { payload }) => {
    return {
      ...state,
      mooringBuoyDetailPage: {
        ...state.mooringBuoyDetailPage,
        regionLocations: payload,
      },
    }
  },
  [mooringBuoyDetailPageActions.GET_LOCATION_LOG_LIST]: (state, { payload }) => {
    return {
      ...state,
      mooringBuoyDetailPage: {
        ...state.mooringBuoyDetailPage,
        locationLogList: payload.location_logs || [],
        totalLocationLogPage: payload.total_page || 1,
      },
    }
  },
  [mooringBuoyDetailPageActions.CHANGE_INDEX_TAB_LOG]: (state, { payload }) => {
    return {
      ...state,
      currentIndexTab: payload,
    }
  },
}

export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const MOORING_BUOY_DETAIL_PAGE_REDUCER = 'mooringBuoyDetailPage'
