import * as mooringBuoyManagementPageActions from '../actions/actionMooringBuoyManagement'

const defaultState = {
  mooringBuoyManagementPage: {
    data: '',
    filterDropdownData: {},
  },
}

const REDUCERS = {
  [mooringBuoyManagementPageActions.GET_MOORING_BUOY_MANAGEMENT]: (state, { payload }) => {
    return {
      ...state,
      mooringBuoyManagementPage: {
        ...state.mooringBuoyManagementPage,
        data: payload,
      },
    }
  },
  [mooringBuoyManagementPageActions.GET_MOORING_BUOY_MANAGEMENT_FILTER_DROPDOWN]: (
    state,
    { payload }
  ) => {
    return {
      ...state,
      mooringBuoyManagementPage: {
        ...state.mooringBuoyManagementPage,
        filterDropdownData: payload,
      },
    }
  },
}

export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const MOORING_BUOY_PAGE_REDUCER = 'mooringBuoyManagementPage'
