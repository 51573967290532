import * as services from '../services/serviceExplore'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

export const SET_CURRENT_VOYAGE = 'SET_CURRENT_VOYAGE'
export const setCurrentVoyage = (vesselID, tripID, service) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_CURRENT_VOYAGE))
    services
      .postCurrentVoyage(vesselID, tripID, service)
      .then((response) => {
        dispatch({
          type: SET_CURRENT_VOYAGE,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(SET_CURRENT_VOYAGE)))
      .catch(() => dispatch(fetchError(SET_CURRENT_VOYAGE)))
  }
}

export const SET_PLAN_VESSEL_DATA = 'SET_PLAN_VESSEL_DATA'
export const setPlanVesselData = (vesselID, tripID, service) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_PLAN_VESSEL_DATA))
    services
      .getPlanVesselData(vesselID, tripID, service)
      .then((response) =>{
        let parsedResponse = response;
        if(parsedResponse && parsedResponse?.body && typeof parsedResponse?.body === 'string') {
          parsedResponse = JSON.parse(parsedResponse?.body)
        }
        dispatch({
          type: SET_PLAN_VESSEL_DATA,
          payload: parsedResponse?.data,
        })
      })
      .then(() => dispatch(fetchSuccess(SET_PLAN_VESSEL_DATA)))
      .catch(() => dispatch(fetchError(SET_PLAN_VESSEL_DATA)))
  }
}


export const SET_PAST_VOYAGE = 'SET_PAST_VOYAGE'
export const setPastVoyage = (vesselID, tripID = '', setIsLoading = null) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_PAST_VOYAGE))
    setIsLoading && setIsLoading(true)
    services
      .postPastVoyage(vesselID, tripID)
      .then((response) =>
        response?.is_valid && dispatch({
          type: SET_PAST_VOYAGE,
          payload: {
            data: response.data,
            tripID
          }
        })
      )
      .then(() => dispatch(fetchSuccess(SET_PAST_VOYAGE)))
      .catch(() => dispatch(fetchError(SET_PAST_VOYAGE)))
      .finally(() => { setIsLoading && setIsLoading(false) })

  }
}

export const POST_UPDATE_CURRENT_VOYAGE_SCHEDULE = 'POST_UPDATE_CURRENT_VOYAGE_SCHEDULE'
export const postUpdateCurrentVoyageSchedule = (
  currentVoyageSchedule,
  vesselID,
  tripID,
  setCurrentVoyage,
  setIsEdit
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPDATE_CURRENT_VOYAGE_SCHEDULE))
    services
      .postUpdateCurrentVoyageSchedule(currentVoyageSchedule, tripID)
      .then((response) =>
        dispatch({
          type: POST_UPDATE_CURRENT_VOYAGE_SCHEDULE,
          payload: response,
        })
      )
      .then(() => {
        dispatch(fetchSuccess(POST_UPDATE_CURRENT_VOYAGE_SCHEDULE))
        setCurrentVoyage(vesselID, tripID)
        setIsEdit(false)
      })
      .catch(() => dispatch(fetchError(POST_UPDATE_CURRENT_VOYAGE_SCHEDULE)))
  }
}

export const SET_RESET_EXPLORE_PAGE = 'SET_RESET_EXPLORE_PAGE'
export const setResetExplorePage = () => {
  return {
    type: SET_RESET_EXPLORE_PAGE,
    payload: [],
  }
}

export const POST_VOYAGE_SCHEDULE_CHANGE_LOG = 'POST_VOYAGE_SCHEDULE_CHANGE_LOG'
export const postVoyageScheduleChangeLog = (tripID) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VOYAGE_SCHEDULE_CHANGE_LOG))
    services
      .postVoyageScheduleChangeLog(tripID)
      .then((response) =>
        dispatch({
          type: POST_VOYAGE_SCHEDULE_CHANGE_LOG,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VOYAGE_SCHEDULE_CHANGE_LOG)))
      .catch(() => dispatch(fetchError(POST_VOYAGE_SCHEDULE_CHANGE_LOG)))
  }
}

export const POST_VOYAGE_REPORT_LOADING = 'POST_VOYAGE_REPORT_LOADING'
export const POST_VOYAGE_REPORT = 'POST_VOYAGE_REPORT'
export const setPostVoyageReport = ({ from_date, to_date }) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VOYAGE_REPORT))
    dispatch({
      type: POST_VOYAGE_REPORT_LOADING,
      payload: true,
    })
    services
      .postVoyageReport(from_date, to_date)
      .then((response) =>
        dispatch({
          type: POST_VOYAGE_REPORT,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VOYAGE_REPORT)))
      .catch(() => dispatch(fetchError(POST_VOYAGE_REPORT)))
      .finally(() =>
        dispatch({
          type: POST_VOYAGE_REPORT_LOADING,
          payload: false,
        })
      )
  }
}

export const GET_VOYAGE_FEEDBACK = 'GET_VOYAGE_FEEDBACK'
export const getVoyageFeedback = (tripID) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VOYAGE_FEEDBACK))
    services
      .getVoyageFeedBack(tripID)
      .then((response) =>
        dispatch({
          type: GET_VOYAGE_FEEDBACK,
          payload: { data: response?.data.reverse(), trip_id: tripID },
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VOYAGE_FEEDBACK)))
      .catch(() => dispatch(fetchError(GET_VOYAGE_FEEDBACK)))
  }
}

export const CREATE_VOYAGE_FEEDBACK = 'CREATE_VOYAGE_FEEDBACK'
export const createVoyageFeedback = (comment, setIsAddComment, setCommentValue) => {
  return (dispatch, getState) => {
    dispatch(fetchStart(CREATE_VOYAGE_FEEDBACK))
    const getTripID = getState().explorePage.explorePage.trip_id
    services
      .createVoyageFeedback(getTripID, comment)
      .then((response) => {
        dispatch(getVoyageFeedback(getTripID))
        setIsAddComment && setIsAddComment(false)
        setCommentValue && setCommentValue('')
      }
      )
      .then(() => dispatch(fetchSuccess(CREATE_VOYAGE_FEEDBACK)))
      .catch(() => dispatch(fetchError(CREATE_VOYAGE_FEEDBACK)))
  }
}

export const UPDATE_VOYAGE_FEEDBACK = 'UPDATE_VOYAGE_FEEDBACK'
export const updateVoyageFeedback = (voyage_feedback_id, comment, setCommentToEdit, setCommentValue, setIsEditComment) => {
  return (dispatch, getState) => {
    dispatch(fetchStart(UPDATE_VOYAGE_FEEDBACK))
    const getTripID = getState().explorePage.explorePage.trip_id
    services
      .updateVoyageFeedback(voyage_feedback_id, comment)
      .then((response) => {
        dispatch({
          type: UPDATE_VOYAGE_FEEDBACK,
          payload: response?.data,
        })
        dispatch(getVoyageFeedback(getTripID))
        setIsEditComment && setIsEditComment(false)
        setCommentToEdit && setCommentToEdit({})
        setCommentValue && setCommentValue("")
      }
      )
      .then(() => dispatch(fetchSuccess(UPDATE_VOYAGE_FEEDBACK)))
      .catch(() => dispatch(fetchError(UPDATE_VOYAGE_FEEDBACK)))
  }
}

export const DELETE_VOYAGE_FEEDBACK = 'DELETE_VOYAGE_FEEDBACK'
export const deleteVoyageFeedback = (voyage_feedback_id) => {
  return (dispatch, getState) => {
    dispatch(fetchStart(DELETE_VOYAGE_FEEDBACK))
    const getTripID = getState().explorePage.explorePage.trip_id
    console.log(voyage_feedback_id)
    services
      .deleteVoyageFeedback(voyage_feedback_id)
      .then((response) => {
        dispatch({
          type: DELETE_VOYAGE_FEEDBACK,
          payload: response?.data,
        })
        dispatch(getVoyageFeedback(getTripID))
      }
      )
      .then(() => dispatch(fetchSuccess(DELETE_VOYAGE_FEEDBACK)))
      .catch(() => dispatch(fetchError(DELETE_VOYAGE_FEEDBACK)))
  }
}

export const SET_VESSEL_TRACK = "SET_VESSEL_TRACK";
export const setVesselTrack = (vessel_id) => {
  return (dispatch) => {
    dispatch({
      type: SET_VESSEL_TRACK,
      payload: vessel_id,
    });
  };
};

export const SET_VOYAGE_ID = "SET_VOYAGE_ID";
export const setVoyageId = (voyage_id) => {
  return (dispatch) => {
    dispatch({
      type: SET_VOYAGE_ID,
      payload: voyage_id,
    });
  };
};

export const SET_TIME_POINT_CURRENT_SCHEDULE = "SET_TIME_POINT_CURRENT_SCHEDULE";
export const setTimePointCurrentSchedule = (time) => {
  return (dispatch) => {
    dispatch({
      type: SET_TIME_POINT_CURRENT_SCHEDULE,
      payload: time,
    });
  };
};