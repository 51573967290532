import _ from 'lodash'
import jwt from 'jsonwebtoken'

import * as actions from '../../actions/common/actionSession';

import { getUserGroupFromIdToken } from '../../helpers/string';

const getDefaultState = () => {
  const token = localStorage.getItem('id_token')
  const accessToken = localStorage.getItem('access_token')
  let session = jwt.decode(token)
  let userGroups = getUserGroupFromIdToken(token);
	return {
		status: session ? actions.SESSION_STATUS.SUCCESS : null,
		...session,
    idToken: token,
    accessToken: accessToken,
    userGroups: userGroups,
		roles: [],
		roleList: [],
		userList: [],
		updatedUser: {},
		registeredUser: {},
		deletedUser: {}
	}
}


const REDUCERS = {
  [actions.SESSION_AUTHENTICATION_START]: (state, { payload }) => {
    return {
      ...state,
      status: actions.SESSION_STATUS.START
    }
  },
  [actions.SESSION_AUTHENTICATION_SUCCESS]: (state, { payload }) => {

    return {
      ...state,
      status: actions.SESSION_STATUS.SUCCESS,
      userGroups: getUserGroupFromIdToken(_.get(payload, 'idToken')),
      ...payload
    }
  },

  [actions.SESSION_AUTHENTICATION_FAILED]: (state, { payload }) => {
    return {
      ...state,
      status: actions.SESSION_STATUS.SUCCESS,
      ...payload
    }
  },
  [actions.SESSION_LOGOUT]: (state, { payload }) => {
    return {
      ...state,
      status: actions.SESSION_STATUS.SUCCESS,
      ...payload
    }
  }
}
// eslint-disable-next-line
export default (state = getDefaultState(), { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const SESSION_REDUCER = 'commonSession'
