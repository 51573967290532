import { io } from "socket.io-client";

//const SOCKET_ENDPOINT = "https://d3tl6w6iuk2x7g.cloudfront.net"

export function createNewSocketInstance(){
  const token = localStorage.getItem('accessToken'); // get jwt token from local storage
  if (token) {
    return io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      auth: { token: token },
      transports: ['websocket'], 
      reconnectionAttempts: 10
    });
  }
}
