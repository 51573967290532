import * as services from "../services/serviceStandardDailyAverage";

import { fetchError, fetchStart, fetchSuccess } from "./common/actionFetch";

export const GET_VESSEL_TYPES = "GET_VESSEL_TYPES";
export const getVesselTypes = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_TYPES));
    services
      .getVesselTypes()
      .then((response) => {
        dispatch({
          type: GET_VESSEL_TYPES,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_VESSEL_TYPES)))
      .catch(() => dispatch(fetchError(GET_VESSEL_TYPES)));
  };
};

export const POST_STANDARD_DAILY_SEARCH = "POST_STANDARD_DAILY_SEARCH";
export const postStandardDailySearch = (data) => {
  return async(dispatch) => {
    dispatch(fetchStart(POST_STANDARD_DAILY_SEARCH));
    return services
      .postStandardDailySearch(data)
      .then((response) => {
        dispatch(fetchSuccess(POST_STANDARD_DAILY_SEARCH));
        return response
      })
      .catch((error) => {
        dispatch(fetchError(POST_STANDARD_DAILY_SEARCH));
        return error.response.data
      });
  };
};
