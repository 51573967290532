import * as services from "../../services/common/serviceMessaging";

import { fetchStart, fetchSuccess, fetchError } from "./actionFetch";

export const POST_EMAIL_CONFIRMATION_RESERVATION = "POST_EMAIL_CONFIRMATION_RESERVATION";
export const postEmailConfirmReservation = (mode, type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_CONFIRMATION_RESERVATION));
    services
      .postEmailConfirmReservation(mode, type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_EMAIL_CONFIRMATION_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EMAIL_CONFIRMATION_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_EMAIL_CONFIRMATION_RESERVATION)));
  };
};

export const POST_EMAIL_RESERVATION_APPROVAL = "POST_EMAIL_RESERVATION_APPROVAL";
export const postEmailReservationApproval = (type, reservationId, userEmail, iapEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_RESERVATION_APPROVAL));
    services
      .postEmailReservationApproval(type, reservationId, userEmail, iapEmail)
      .then((response) =>
        dispatch({
          type: POST_EMAIL_RESERVATION_APPROVAL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EMAIL_RESERVATION_APPROVAL)))
      .catch(() => dispatch(fetchError(POST_EMAIL_RESERVATION_APPROVAL)));
  };
};

export const POST_EMAIL_SUBMIT_RESERVATION = "POST_EMAIL_SUBMIT_RESERVATION";
export const postEmailSubmitReservation = (type, reservationId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_SUBMIT_RESERVATION));
    services
      .postEmailSubmitReservation(type, reservationId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_EMAIL_SUBMIT_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EMAIL_SUBMIT_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_EMAIL_SUBMIT_RESERVATION)));
  };
};

export const POST_EMAIL_SUBMIT_SPECIAL_REQUEST = "POST_EMAIL_SUBMIT_SPECIAL_REQUEST";
export const postEmailSubmitSpecialRequest = (type, reservationId, userEmail, emailType) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_SUBMIT_SPECIAL_REQUEST));
    services
      .postEmailSubmitSpecialRequest(type, reservationId, userEmail, emailType)
      .then((response) =>
        dispatch({
          type: POST_EMAIL_SUBMIT_SPECIAL_REQUEST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EMAIL_SUBMIT_SPECIAL_REQUEST)))
      .catch(() => dispatch(fetchError(POST_EMAIL_SUBMIT_SPECIAL_REQUEST)));
  };
};

export const POST_EMAIL_VOD_VESSEL_ADMIN_VERIFICATION = "POST_EMAIL_VOD_VESSEL_ADMIN_VERIFICATION";
export const postEmailVODVesselAdminVerification = (registrationID) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_VOD_VESSEL_ADMIN_VERIFICATION));
    services
      .postEmailVODVesselAdminVerification(registrationID)
      .then((response) =>
        dispatch({
          type: POST_EMAIL_VOD_VESSEL_ADMIN_VERIFICATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EMAIL_VOD_VESSEL_ADMIN_VERIFICATION)))
      .catch(() => dispatch(fetchError(POST_EMAIL_VOD_VESSEL_ADMIN_VERIFICATION)));
  };
};

export const POST_EMAIL_VESSEL_REGISTRATION_APPROVAL = "POST_EMAIL_VESSEL_REGISTRATION_APPROVAL";
export const postEmailVesselRegistrationApproval = (registrationID, mode) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_VESSEL_REGISTRATION_APPROVAL));
    services
      .postEmailVesselRegistrationApproval(registrationID, mode)
      .then((response) =>
        dispatch({
          type: POST_EMAIL_VESSEL_REGISTRATION_APPROVAL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EMAIL_VESSEL_REGISTRATION_APPROVAL)))
      .catch(() => dispatch(fetchError(POST_EMAIL_VESSEL_REGISTRATION_APPROVAL)));
  };
};

export const POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VESSEL_CHARTERING = "POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VESSEL_CHARTERING";
export const postEmailApproveOrRejectNotificationToBuyerForVesselChartering = (bookingId, mode, leaseType) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VESSEL_CHARTERING));
    services
      .postEmailApproveOrRejectNotificationToBuyerForVesselChartering(bookingId, mode, leaseType)
      .then((response) =>{
        dispatch({
          type: POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VESSEL_CHARTERING,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VESSEL_CHARTERING)))
      .catch(() => dispatch(fetchError(POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VESSEL_CHARTERING)));
  };
};

export const POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VOYAGE_SHARING = "POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VOYAGE_SHARING";
export const postEmailApproveOrRejectNotificationToBuyerForVoyageSharing = (bookingId, mode, leaseType) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VOYAGE_SHARING));
    services
      .postEmailApproveOrRejectNotificationToBuyerForVoyageSharing(bookingId, mode, leaseType)
      .then((response) =>{
        dispatch({
          type: POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VOYAGE_SHARING,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VOYAGE_SHARING)))
      .catch(() => dispatch(fetchError(POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VOYAGE_SHARING)));
  };
};

export const POST_EMAIL_VOD_NEW_BOOKING_APPROVAL = "POST_EMAIL_VOD_NEW_BOOKING_APPROVAL";
export const postEmailVODNewBookingApproval = (bookingID, leaseType) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EMAIL_VOD_NEW_BOOKING_APPROVAL));
    services
      .postEmailVODNewBookingApproval(bookingID, leaseType)
      .then((response) =>
        dispatch({
          type: POST_EMAIL_VOD_NEW_BOOKING_APPROVAL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EMAIL_VOD_NEW_BOOKING_APPROVAL)))
      .catch(() => dispatch(fetchError(POST_EMAIL_VOD_NEW_BOOKING_APPROVAL)));
  };
};
