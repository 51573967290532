import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer';

const CONTROLLER_NAME = '/fms';

export const getFuelConsumptionSummaryWidget = (data) => {
    const { region, to, year } = data;

    const functionUrl = process.env.REACT_APP_GET_FUEL_CONSUMPTION_SUMMARY_WIDGET;

    return LAMBDA_API_INSTANCE().then(response => response.get(functionUrl, { params: { region, to, year } }));
}

export const getDashboardSummaryAlerts = (data) => {
    const { region, to, year } = data;

    const functionUrl = process.env.REACT_APP_DASHBOARD_SUMMARY_ALERT;

    return LAMBDA_API_INSTANCE().then(response => response.get(functionUrl, { params:{region, to, year} }));
}

export const getAverageFuelPriceSummaryWidget = (data) => {
    const { region, from, to, year } = data;

    return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/averageFuelPriceSummaryWidget?region=${region}&from=${from}&to=${to}&year=${year}`));
}

export const getPartnerRegions = () => {
    
    return LAMBDA_API_INSTANCE().then((response) => response.get(`${CONTROLLER_NAME}/regions`));
};

export const getMassBalanceWidget = (data = {}) => {
    const { regionCode=null, vesselId=null, fromDate=null, toDate=null, vesselName=null } = data;

    const params = [
        regionCode && `regionCode=${regionCode}`,
        vesselId && `vesselId=${vesselId}`,
        fromDate && `fromDate=${fromDate}`,
        toDate && `toDate=${toDate}`,
        vesselName && `vesselName=${vesselName}`
    ];

    const url = `${CONTROLLER_NAME}/massBalanceWidget?${params.filter(Boolean).join('&')}`;

    return LAMBDA_API_INSTANCE().then(response => response.get(url));
}