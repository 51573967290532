import _ from 'lodash'

import * as actions from '../actions/actionMainExplorePage'

const defaultState = {
  capabilitiesRedux: {}
}

const REDUCERS = {
  [actions.GET_DROPDOWN_WITH_OPTIONS]: (state, { payload }) => {
    return _.set(state, payload.path, payload.value)
  },
  [actions.RESET_DROPDOWN_DATA]: (state, { payload }) => {

    if (payload.path === 'regions')
      return {
        ...state,
        regions: [],
        services: [],
        vessels: [],
        voyages: []
      }
    if (payload.path === 'services')
      return {
        ...state,
        services: [],
        vessels: [],
        voyages: []
      }
    if (payload.path === 'vessels')
      return {
        ...state,
        vessels: [],
        voyages: []
      }

    if (payload.path === 'voyages')
      return {
        ...state,
        voyages: []
      }

    return {
      ...state
    }
  },
  [actions.GET_WMS_CAPABILITIES]: (state, { payload }) => {
    let {capabilitiesRedux} = state
    return {
      ...state,
      capabilitiesRedux: {
        ...capabilitiesRedux,
        basic: payload.basic,
        maritime: payload.maritime
      }
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const MAIN_EXPLORE_REDUCER = 'mainExplorePage'
