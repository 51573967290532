import _ from "lodash";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, List } from "semantic-ui-react";
import Btn from "../../components/button";
import Slider from "react-slick";
import OnBoardingStatus from "../onboarding-status"
// Components
import FetchStateWrapper from "../../components/fetch-state-wrapper";

// Hooks
import { useStateWithPaths, useActions } from "../../hooks/useConnect";

// Redux
import { RELEASE_NOTE_REDUCER } from "../../reducers/common/releaseNoteReducer";

// Actions
import * as ReleaseNoteActions from "../../actions/common/actionReleaseNote";
import { parseTimezone } from "../../helpers/momentTimezone";
import { ONBOARDING_STATUS_REDUCER } from "../../reducers/common/onBoardingStatusReducer";

const ReleaseNote = () => {
  const isActive = localStorage.getItem("releaseNoteActive");
  const [openModal, setOpenModal] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [openOnboardingStatusModal, setOpenOnboardingStatusModal] = useState(false); 

  const [commonReleaseNote] = useStateWithPaths([`${RELEASE_NOTE_REDUCER}`]);
  const releaseNoteData = _.get(commonReleaseNote?.releaseNote, "data");

  const [commonOnboardingStatus] = useStateWithPaths([`${ONBOARDING_STATUS_REDUCER}`]);
  const onBoardingStatusData = commonOnboardingStatus?.onBoardingStatus?.data;

  const { getReleaseNote } = useActions({
    getReleaseNote: ReleaseNoteActions.getReleaseNote,
  });

  useEffect(() => {
    if (_.isNull(isActive)) {
      setOpenModal(true)
      localStorage.setItem("releaseNoteActive", true);
    }
  }, [isActive]);

  useEffect(() => {
    getReleaseNote();
  }, []);

  const closeReleaseNote = () => {
    setOpenModal(false);
    localStorage.setItem("releaseNoteActive", false);
    if (!_.every(onBoardingStatusData, Boolean)) {
      setOpenOnboardingStatusModal(true);
    }
  };

  const slider = useRef();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => setCurrentSlideIndex(current),
  };

  const next = () => {
    slider.current.slickNext();
  };

  return (
    <>
      <FetchStateWrapper id={ReleaseNoteActions.GET_RELEASE_NOTE} />
      <Modal
        className={`release-note pad-2`}
        onClose={closeReleaseNote}
        open={openModal}
        size={"tiny"}
      >
        <Modal.Content>
          <div className={`flex header`}>
            <div>Release Notes: </div>
            <div className={`text-purple`}>What's New?</div>
          </div>
          <div className={`mar-y-1`}>
            <Slider ref={slider} {...settings} className={"slider-body"}>
              {_.map(releaseNoteData, (item, index) => (
                <div className={`content-scrollable scroll-y`} key={`releaseNote-${index}`}>
                  <div className={`title-type`}>{item.type}</div>
                  <List bulleted className={`content`}>
                    {_.map(item.content, (a, contentIdx) => (
                      <List.Item key={`item-${contentIdx}`}>
                        <List.Content className={`content-description pad-bottom-1`}>
                          {a.description}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                  <div className={`release-date`}>
                    * Release update as of {parseTimezone(item.release_date).format("DD MMM YYYY")}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className={`flex end align-self-end pad-top-1`}>
            <Btn
              className={`button-demand`}
              text={"Close"}
              onClick={closeReleaseNote}
            />
            {releaseNoteData.length > 1 && <Btn className={`button-demand button-bg`} text={'Next'} onClick={next} />}
          </div>
        </Modal.Content>
      </Modal>

      <OnBoardingStatus 
        open={openOnboardingStatusModal} 
        onClose={() => setOpenOnboardingStatusModal(false)} 
      />
    </>
  );
};

export default ReleaseNote;
