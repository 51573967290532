import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import _ from 'lodash'

const token = localStorage.getItem('idToken')
const session = jwt.decode(token)

const CONTROLLER_NAME = '/explore'

export const postCurrentVoyage = (vesselID, tripID, service) => {
  const bodyParams = {
    vessel_id: vesselID,
    trip_id: tripID,
    service
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/currentVoyageSchedule`, bodyParams))
}

export const getPlanVesselData = (vesselID, tripID, service) => {
 const functionUrl = process.env.REACT_APP_GET_PLAN_VESSEL_DATA_LAMBDA_FUNCTION_URL || '';

  let bodyParams = `vessel_id=${vesselID}&trip_id=${tripID}`
  if (!_.isEmpty(service)) bodyParams = bodyParams + `&service=${service}`

  if ( functionUrl ) {
    return LAMBDA_API_INSTANCE().then(response => response.get(`${functionUrl}?${bodyParams}`));
  } else {
    return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/planVesselData?${bodyParams}`))
  }
}

export const postPastVoyage = (vesselID, tripID) => {
  const bodyParams = {
    vessel_id: vesselID,
    trip_id: tripID,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/pastVoyages`, bodyParams))
}

export const postUpdateCurrentVoyageSchedule = (currentVoyageSchedule, tripID) => {
  const bodyParams = {
    trip_id: tripID,
    user_id: session.name,
    current_voyage_schedules: currentVoyageSchedule,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/updateCurrentVoyageSchedule`, bodyParams))
}

export const postVoyageScheduleChangeLog = (tripID) => {
  const bodyParams = {
    trip_id: tripID,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/voyageScheduleChangeLog`, bodyParams))
}

export const postVoyageReport = (fromDate, toDate) => {
  const bodyParams = {
    from_date: moment(fromDate).format("YYYY-MM-DD"),
    to_date: moment(toDate).format("YYYY-MM-DD"),
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/voyageReport`, bodyParams))
}

export const getVoyageFeedBack = (tripID) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/voyageFeedback?trip_id=${tripID}`))
}

export const createVoyageFeedback = (trip_id, comment) => {
  const bodyParams = {
    trip_id,
    comment,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/voyageFeedback`, bodyParams))
}

export const updateVoyageFeedback = (voyage_feedback_id, comment) => {
  const bodyParams = {
    voyage_feedback_id,
    comment,
  }
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/voyageFeedback`, bodyParams))
}

export const deleteVoyageFeedback = (voyage_feedback_id) => {
  const bodyParams = {
    data: {
      voyage_feedback_id
    }
  }
  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_NAME}/voyageFeedback`, bodyParams))
}
