export const MODULE_LIST = {
  EXECUTE: "Execute",
  DEMAND: "Demand",
  OPTIMIZE: "Optimize",
  SUPPLY: "Supply",
  GENERAL: "General",
};

export const SERVICE = {
  PLATFORM_PLUS: "Platform +",
  VOD: "VOD",
};

export const PAGE_LIST = {
  //GENERAL
  GENERAL: "Landing Page",
  SETTINGS: "Settings Page",
  SUPPORT: "Support Page",

  //EXECUTE
  KPI_DASHBOARD: "Power BI KPI Dashboard",
  DELETE_UPLOADED_VESSEL_SCHEDULE: "Delete Uploaded Vessel Schedule",
  EXPLORE: "Explore",
  KPI_OVERVIEW: "KPI Overview",
  MAIN_EXPLORE: "Main Explore",
  MAIN_PAGE: "Main Page",
  MB_MAINTENANCE_LOG: "Mooring Buoy - Maintenance Log",
  MB_MAINTENANCE: "Mooring Buoy - Management Page",
  PLANNING: "Planning Page",
  SPEED_MONITORING: "Speed Monitoring",
  TRACK_VOYAGE: "Track Voyage",
  VESSEL_MANAGEMENT: "Vessel Management",
  VESSEL_MANAGEMENT_NEW: "(New) Vessel Management",

  //DEMAND
  BOOKING_LIST: "Booking List",
  BOOKING_PAGE: "Booking Page",
  IAP_APPROVAL: "IAP Approval",
  ALLOCATE_VESSEL: "Allocate Vessel",
  IAP_APPROVAL_NEW: "(New) IAP Approval",
  RESERVATION: "Reservation",
  VIEW_RESERVATION: "View Reservation",

  //OPTIMIZE
  CREATE_VESSEL_SCHEDULE: "Create Vessel Schedule",
  FORECAST_DEMAND: "Forecast Demand",
  SCHEDULE_PLANNER: "Schedule Planner",
  UPLOAD_DATA: "Upload Data",
  VESSEL_SCHEDULE: "Vessel Schedule",

  //SUPPLY
  SELLER_REGISTRATION_APPROVAL: "Seller Registration Approval",
  VESSEL_RESERVATION: "Vessel Reservation Page",
  TRACK_AND_TRACE: "Track and Trace",
  VESSEL_LIST: "Vessel List",
  VESSEL_REGISTRATION: "Vessel Registration",
  REGISTRATION_LIST: "Vessel Registration List",
  NEW_BOOKING: "New Booking",
  SELLER_BOOKING: "Seller Booking",
  LOCATION_MANAGEMENT: "Location Management",
};

export const PAGES_WITH_D3_SCHEDULE_STATUS = [
  "/optimise-create",
  "/optimise-upload",
  "/vessel-schedule",
  "/demand",
  "/reservation",
  "/iap-approval",
  "/main-explore",
  "/dashboard",
  "/speed",
  "/planning",
  "/vessel-management",
  "/mooring-buoy-management",
  "/faq",
  "/user-guide",
  "/carbon-calculator",
  "/settings",
  "/",
];

export const FEATURE_CODE_ACCESS_SCHEDULE_STATUS = ["opt-all-user-page-access", "olv-admin-access"];
