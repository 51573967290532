import * as actions from "../../actions/common/actionMessaging";
import moment from "moment";

const defaultState = {
  emailConfirmationReservationResponse: [],
  emailReservationApprovalResponse: [],
  emailSubmitReservationResponse: [],
  emailSubmitSpecialRequestResponse: [],
  emailVodVesselAdminVerificationResponse: [],
  emailApproveOrRejectNotificationToBuyerForVesselChartering: [],
  emailApproveOrRejectNotificationToBuyerForVoyageSharing: [],
  emailVodNewBookingApprovalResponse: [],
};

const REDUCERS = {
  [actions.POST_EMAIL_CONFIRMATION_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      emailConfirmationReservationResponse: payload,
    };
  },
  [actions.POST_EMAIL_RESERVATION_APPROVAL]: (state, { payload }) => {
    return {
      ...state,
      emailReservationApprovalResponse: payload,
    };
  },
  [actions.POST_EMAIL_SUBMIT_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      emailSubmitReservationResponse: payload,
    };
  },
  [actions.POST_EMAIL_SUBMIT_SPECIAL_REQUEST]: (state, { payload }) => {
    return {
      ...state,
      emailSubmitSpecialRequestResponse: payload,
    };
  },
  [actions.POST_EMAIL_VOD_VESSEL_ADMIN_VERIFICATION]: (state, { payload }) => {
    return {
      ...state,
      emailVodVesselAdminVerificationResponse: payload,
    };
  },
  [actions.POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VESSEL_CHARTERING]: (state, { payload }) => {
    return {
      ...state,
      emailApproveOrRejectNotificationToBuyerForVesselChartering: payload,
    }
  },
  [actions.POST_EMAIL_APPROVE_OR_REJECT_NOTIFICATION_TO_BUYER_FOR_VOYAGE_SHARING]: (state, { payload }) => {
    return {
      ...state,
      emailApproveOrRejectNotificationToBuyerForVoyageSharing: payload,
    }
  },
  [actions.POST_EMAIL_VOD_NEW_BOOKING_APPROVAL]: (state, { payload }) => {
    return {
      ...state,
      emailVodNewBookingApprovalResponse: payload,
    };
  },
};
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const MESSAGING_REDUCER = "messaging";
