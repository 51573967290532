import * as services from '../services/serviceVesselManagement'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

export const GET_VESSEL_MANAGEMENT = 'GET_VESSEL_MANAGEMENT'
export const getVesselManagement = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_MANAGEMENT))
    services
      .getVesselManagement()
      .then((response) =>
        dispatch({
          type: GET_VESSEL_MANAGEMENT,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_MANAGEMENT)))
      .catch(() => dispatch(fetchError(GET_VESSEL_MANAGEMENT)))
  }
}

export const GET_VESSELS_DROPDOWN = 'GET_VESSELS_DROPDOWN'
export const getVesselsDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSELS_DROPDOWN))
    services
      .getVesselManagementDropdown('vessel')
      .then((response) =>
        dispatch({
          type: GET_VESSELS_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSELS_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_VESSELS_DROPDOWN)))
  }
}

export const GET_REGIONS_DROPDOWN = 'GET_REGIONS_DROPDOWN'
export const getRegionsDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_REGIONS_DROPDOWN))
    services
      .getVesselManagementDropdown('region')
      .then((response) =>
        dispatch({
          type: GET_REGIONS_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_REGIONS_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_REGIONS_DROPDOWN)))
  }
}

export const GET_JOB_TYPES_DROPDOWN = 'GET_JOB_TYPES_DROPDOWN'
export const getJobTypesDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_JOB_TYPES_DROPDOWN))
    services
      .getVesselManagementDropdown('job')
      .then((response) =>
        dispatch({
          type: GET_JOB_TYPES_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_JOB_TYPES_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_JOB_TYPES_DROPDOWN)))
  }
}

export const GET_MASTER_LOCATION_PLATFORM_DROPDOWN = 'GET_MASTER_LOCATION_PLATFORM_DROPDOWN'
export const getMasterLocationPlatformDropdown = (region) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MASTER_LOCATION_PLATFORM_DROPDOWN))
    services
      .getVesselManagementDropdown('masterLocationPlatform', region)
      .then((response) =>
        dispatch({
          type: GET_MASTER_LOCATION_PLATFORM_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MASTER_LOCATION_PLATFORM_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_MASTER_LOCATION_PLATFORM_DROPDOWN)))
  }
}

export const GET_MANAGED_BY_DROPDOWN = 'GET_MANAGED_BY_DROPDOWN'
export const getManagedByDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MANAGED_BY_DROPDOWN))
    services
      .getVesselManagementDropdown('managedBy')
      .then((response) =>
        dispatch({
          type: GET_MANAGED_BY_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MANAGED_BY_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_MANAGED_BY_DROPDOWN)))
  }
}

export const POST_VESSEL_MANAGEMENT = 'POST_VESSEL_MANAGEMENT'
export const postVesselManagement = (contractData, callBack = null) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_MANAGEMENT))
    services
      .postVesselManagement(contractData)
      .then((response) => {
        dispatch({
          type: POST_VESSEL_MANAGEMENT,
          payload: response,
        })
        callBack && callBack()
      })
      .then(() => dispatch(fetchSuccess(POST_VESSEL_MANAGEMENT)))
      .catch(() => dispatch(fetchError(POST_VESSEL_MANAGEMENT)))
  }
}

export const PUT_VESSEL_MANAGEMENT = 'PUT_VESSEL_MANAGEMENT'
export const putVesselManagement = (contractData, handleSuccess, showPopup = null, handleFailed = null) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_VESSEL_MANAGEMENT))
    services
      .putVesselManagement(contractData)
      .then((response) => {

        if (response?.is_valid) {
          handleSuccess(contractData?.secondary_jobs)
        } else {
          handleFailed && handleFailed()
        }

        dispatch({
          type: PUT_VESSEL_MANAGEMENT,
          payload: response,
        })
        showPopup && showPopup()
      })
      .then(() => dispatch(fetchSuccess(PUT_VESSEL_MANAGEMENT)))
      .catch(() => dispatch(fetchError(PUT_VESSEL_MANAGEMENT)))
  }
}

export const DELETE_VESSEL_MANAGEMENT = 'DELETE_VESSEL_MANAGEMENT'
export const deleteVesselManagement = (primaryManagementID,callBack = null) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_VESSEL_MANAGEMENT))
    services
      .deleteVesselManagement(primaryManagementID)
      .then((response) =>{
         dispatch({
          type: DELETE_VESSEL_MANAGEMENT,
          payload: response,
        })
        callBack && callBack()
      }
      )
      .then(() => dispatch(fetchSuccess(DELETE_VESSEL_MANAGEMENT)))
      .catch(() => dispatch(fetchError(DELETE_VESSEL_MANAGEMENT)))
  }
}



export const SET_MODAL_RESPONSE = 'SET_MODAL_RESPONSE'
export const setModalResponse = (dataResponse) => {
  return (dispatch) => {
    dispatch({
      type: SET_MODAL_RESPONSE,
      payload: dataResponse,
    })
  }
}


// ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ----------
export const GET_DROPDOWN_VESSEL_TYPE = "GET_DROPDOWN_VESSEL_TYPE";
export const getDropdownVesselType = (dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_VESSEL_TYPE));
    services
      .getDropdown(dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_VESSEL_TYPE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_VESSEL_TYPE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_VESSEL_TYPE)));
  };
};

export const GET_DROPDOWN_VESSEL_FLAG = "GET_DROPDOWN_VESSEL_FLAG";
export const getDropdownVesselFlag = (dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_VESSEL_FLAG));
    services
      .getDropdown(dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_VESSEL_FLAG,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_VESSEL_FLAG)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_VESSEL_FLAG)));
  };
};

export const GET_DROPDOWN_VESSEL_REGION = "GET_DROPDOWN_VESSEL_REGION";
export const getDropdownVesselRegion = (dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_VESSEL_REGION));
    services
      .getDropdown(dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_VESSEL_REGION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_VESSEL_REGION)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_VESSEL_REGION)));
  };
};

export const GET_DROPDOWN_PORT_OF_ANCHORAGE = "GET_DROPDOWN_PORT_OF_ANCHORAGE";
export const getDropdownPortOfAnchorage = (dropdown_category, vessel_region) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_PORT_OF_ANCHORAGE));
    services
      .getDropdown(dropdown_category, "region", vessel_region)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_PORT_OF_ANCHORAGE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_PORT_OF_ANCHORAGE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_PORT_OF_ANCHORAGE)));
  };
};

export const POST_VESSEL_REGISTRATION = "POST_VESSEL_REGISTRATION";
export const postVesselRegistration = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_REGISTRATION));
    return services
      .postVesselRegistration(bodyParams)
      .then((response) => {
        dispatch(fetchSuccess(POST_VESSEL_REGISTRATION));
        return response;
      })
      .catch(() => {
        dispatch(fetchError(POST_VESSEL_REGISTRATION));
      });
  };
};
