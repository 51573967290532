import * as locationPageActions from "../actions/actionLocation";

const defaultState = {
  locationManagementPage: {
    locationList: [],
    locationRegion: [],
    locationType: [],
    locationCountry: [],
    totalLocation: 0,
    locationDetail: {},
  },
};

const REDUCERS = {
  [locationPageActions.GET_LOCATION_LIST]: (state, { payload }) => {
    return {
      ...state,
      locationManagementPage: {
        ...state.locationManagementPage,
        locationList: payload?.locations ?? [],
        totalLocation: payload.total_locations,
      },
    };
  },

  [locationPageActions.GET_LOCATION_DETAIL]: (state, { payload }) => {
    return {
      ...state,
      locationManagementPage: {
        ...state.locationManagementPage,
        locationDetail: payload,
      },
    };
  },

  // [locationPageActions.GET_LOCATION_FILE_DOWNLOAD]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     locationManagementPage: {
  //       ...state.locationManagementPage,
  //       fileDetail: payload,
  //     },
  //   };
  // },

  [locationPageActions.GET_LOCATION_COUNTRY_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      locationManagementPage: {
        ...state.locationManagementPage,
        locationCountry: payload,
      },
    };
  },

  [locationPageActions.GET_LOCATION_REGION_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      locationManagementPage: {
        ...state.locationManagementPage,
        locationRegion: payload,
      },
    };
  },

  [locationPageActions.GET_LOCATION_TYPE_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      locationManagementPage: {
        ...state.locationManagementPage,
        locationType: payload,
      },
    };
  },
};

export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const LOCATION_PAGE_REDUCER = "locationPage";
