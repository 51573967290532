import React from 'react'
import { ReactSVG } from 'react-svg'
import { Divider, Popup, Progress } from 'semantic-ui-react'

// Icons
import CHECKED_CIRCLE_ICON from "../../../../../assets/images/tick-circle-2.svg";
import WARNING_CIRCLE_ICON from "../../../../../assets/images/alert.svg";
import EXCHANGE_ICON from "../../../../../assets/images/refresh-circle.svg";

const getIconForStatus = (status) => {
  switch (status) {
    case "Completed":
      return {
        icon: CHECKED_CIRCLE_ICON,
        color: "#26A671"
      }
    
    case "Error":
      return {
        icon: WARNING_CIRCLE_ICON,
        color: "#E74C3B"
      };
    
    default:
      return {
        icon: EXCHANGE_ICON,
        color: "#FAC72B"
      };
  }
}

const boxShadow = "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px"
const ModalD3ScheduleStatus = (props) => {
  const {openD3ScheduleStatusModal, setOpenD3ScheduleStatusModal, scheduleD3VoyageStatus, openSidebar, checkScheduleD3Status} = props;

  return (
    <Popup
        open={openD3ScheduleStatusModal}
        onClose={() => setOpenD3ScheduleStatusModal(false)}
        content={
          <div className='modal-d3-schedule-status'>
            <div className="modal-d3-schedule-status-title flex center" onClick={() => setOpenD3ScheduleStatusModal(true)}>
              <ReactSVG style={{height: 31}} className={checkScheduleD3Status?.isProcessing && "processing-icon"} src={checkScheduleD3Status?.isProcessing ? EXCHANGE_ICON : CHECKED_CIRCLE_ICON} /> 
              <span style={{color: checkScheduleD3Status?.color}}>{checkScheduleD3Status?.title}</span>
            </div>
            <div className='modal-d3-schedule-status-content'>            
              {scheduleD3VoyageStatus?.data?.map((item, index) => (
                <div className='modal-container' key={index}>
                  <div className='modal-content' onClick={() => openSidebar(item?.trip_id, item?.ds_status)}>
                    <div className='modal-content-icon'>
                      <ReactSVG src={getIconForStatus(item?.ds_status).icon} className={item?.ds_status === "Processing" && "processing-icon"} />
                    </div>
                    <div className='modal-content-detail-message'>
                      <p className='trip-id'>Voyage id: {item?.trip_id}</p>
                      <p className='status'>{item?.ds_status}</p>
                      <Progress percent={item?.ds_status === "Processing" ? 50 : 100} size='tiny' className={`progress-bar progress-bar-${item?.ds_status.toLowerCase()}`}/>
                    </div>
                  </div>
                  {(index !== scheduleD3VoyageStatus?.data.length - 1) && <Divider />}
                </div>
              ))}            
            </div>
          </div>        
        }
        on='click'
        style={{ zIndex: '9999' }}
        position='bottom right'
        className='modal-d3-schedule-status'
        pinned      
        basic
        offset="0,20"
        trigger={(
          <div className="header-d3-status flex justify-center center" onClick={() => setOpenD3ScheduleStatusModal(true)}>
            <span>D-3</span>
            {checkScheduleD3Status?.isProcessing && (
              <div className="header-d3-status-total flex justify-center center">
                <span>{scheduleD3VoyageStatus?.data.length}</span>
              </div>
            )}
          </div>
        )}
    />
  )
}

export default ModalD3ScheduleStatus