import React, { useEffect } from "react";
import _ from "lodash";
import jwt from "jsonwebtoken";
import { useLocation, Link } from "react-router-dom";
import { Dropdown, Sidebar, Segment, Divider, Icon } from "semantic-ui-react";
import { ReactSVG } from "react-svg";

import ProfileMenu from "../profile-menu/profileMenu";
import { menu } from "./menuData";
//Redux
import { UAC_REDUCER } from "../../../reducers/common/uacReducer";
import * as LandingPageActions from "../../../actions/actionLanding";

// Redux
import { GENERAL_REDUCER } from "../../../reducers/common/generalReducer";

//Hooks
import { useStateWithPaths, useActions } from "../../../hooks/useConnect";

// Actions
import * as GeneralActions from "../../../actions/common/actionGeneral";
import * as OptimisePageActions from "../../../actions/actionOptimise";
import * as BookingPageActions from "../../../actions/actionBooking";
import * as VODPageActions from "../../../actions/actionVOD";

// Helper
import { isMobileScreen } from "../../../helpers/isMobileScreen";

import ArrowDown from '../../../assets/images/arrow-dropdown-down.svg';

//#region Private Methods

const filterHeaderMenus = (persona, userAccess, i) => {
  if (persona || !_.isEmpty(persona))
    return persona.some((forPersona) => {
      return _.includes(userAccess, forPersona);
    });
  else return false;
};

const filterMenus = (menus, userAccess) => {
  let filteredMenus =  _.filter(menus, (item) => {
    return (
      !item.forPersona ||
      _.isEmpty(item.forPersona) ||
      item.forPersona.some((forPersona) => {
        return _.includes(userAccess, forPersona);
      })
    );
  });

  const isMobile = isMobileScreen();

  if (isMobile) {
    filteredMenus = menus.filter(menus => menus.showOnMobile);
  }

  return filteredMenus;
};

//#endregion

const HeaderMenuTop = (props) => {
  const [uacData] = useStateWithPaths([`${UAC_REDUCER}`]);
  const [generalData] = useStateWithPaths([`${GENERAL_REDUCER}`]); 

  const token = localStorage.getItem("idToken");
  const session = jwt.decode(token);
  const email = _.get(session, "email");
  const { pathname } = useLocation();
  const isMobile = isMobileScreen();

  const userAccess = _.map(_.get(uacData?.uac.data, "all_features"), (item) => item.feature_code);
  const accessModule = localStorage.getItem("accessModule");

  const {
    setRedirectLocation,
    setReservationPage,
    setTripItem,
    setTripToContract,
    setVesselScheduleInfo,
    postReservationStatusNotification,
    setBookingType,
    resetBookingPage,
    resetVODBookings,
    resetVODResponse,
    setModifyDemand,
    setActionRequired
  } = useActions({
    setRedirectLocation: GeneralActions.setRedirectLocation,
    setReservationPage: GeneralActions.setReservationPage,
    setTripItem: GeneralActions.setTripItem,
    setTripToContract: GeneralActions.setTripToContract,
    setVesselScheduleInfo: OptimisePageActions.setVesselScheduleInfo,
    postReservationStatusNotification: LandingPageActions.postReservationStatusNotification,
    setBookingType: BookingPageActions.setBookingType,
    resetBookingPage: BookingPageActions.resetBookingPage,
    resetVODBookings: VODPageActions.resetVODBookings,
    resetVODResponse: VODPageActions.resetVODResponse,
    setModifyDemand: VODPageActions.setModifyDemand,
    setActionRequired: VODPageActions.setActionRequired    
  });

  const updateRedirectLocation = (page) => {
    setRedirectLocation(page);
    setTripItem(null);
    setTripToContract(null);
    setVesselScheduleInfo({
      regionCode: "",
      period: [{ startDate: "", endDate: "", key: "selection" }],
      fromDraft: false,
    });
  };

  const onClickResetBooking = (page) => {
    if (page === generalData?.general?.redirect_location) {
      resetBookingPage();
    }
    setBookingType("Material");
    setReservationPage("reservation");
  };

  const resetVOD = () => {
    resetVODResponse();
    resetVODBookings();
    resetActionRequired();
  };

  const resetActionRequired = () => {
    const newObj = {
      isActionView: false,
      isActionApprove: false,
      isActionReject: false,
      isActionUpdate: false,
      isActionReport: false,
      registrationID: "",
      vesselName: "",
      submittedBy: "",
      trackID: "",
      tripID: "",
    };
    setActionRequired(newObj);
  };

  const setVodBuyerReservation = (title) => {
    if (title === "Voyage Sharing" || title === "Vessel Chartering") {
      const type = title === "Voyage Sharing" ? "voyage-sharing" : "vessel-chartering";
      const newObj = {
        isFromBookings: false,
        bookingID: "",
        leaseType: type,
        vesselSchedule: [],
        projectName: "",
        discipline: "",
        vesselType: "",
        portOfAnchorage: "",
        startDate: "",
        endDate: "",
        freshWater: 0,
        fuelOil: 0,
        cargoSpace: 0,
        baseSeatCapacity: 0,
      };
      setModifyDemand(newObj);
    }
  };

  useEffect(() => {
    if (email) {
      postReservationStatusNotification(email);
    }
  }, [email]);

  return (

        isMobile ? 
    <div className={`mobile-sidebar-container mar-0`}>
      <Sidebar
            as={Segment}
            visible={props.isSidebarOpen}
            width='wide'
            direction="right"
            animation="overlay"
            style={{backgroundColor:"#242433"}}
          >
        <div className={"sidebar-container"}>
          <div>
            {/* <Icon className={"mobile-close-button-icon"} onClick={() => {props.handleSetIsSidebarOpen(false)}}>X</Icon> */}
            <span className={"mobile-close-button-icon"} onClick={() => {props.handleSetIsSidebarOpen(false)}}>&times;</span>
          </div>
          <div className={"mobile-profile-menu"}>
            <div><ProfileMenu type={props.type} handleSetIsSidebarOpen={props.handleSetIsSidebarOpen}/></div>
            <div className={"display_name"}>{uacData?.uac.data?.display_name}</div>
          </div>
          <div className={"mobile-sidebar-divider"}>
            <Divider />
          </div>
          <div>
            {_.map(menu, (item, index) => {
              const activeDarkHeader = _.some(item.options, ["route", pathname])
                ? "dark-header-active"
                : "";

              const header = filterHeaderMenus(item.forPersona, userAccess, index);
              if (_.isEmpty(item.options) && header) {
                return (
                  <Link
                    key={`header-${index}`}
                    to={item.route}
                    onClick={() => {
                      updateRedirectLocation(item.page);
                      onClickResetBooking(item.page);
                      resetVOD();
                    }}
                  >
                    <div
                      className={`menu-item dark-header mar-0 ${
                        _.isEqual(item.route, pathname) ? "dark-header-active" : ""
                      }`}
                    >
                      {item.title}
                    </div>
                  </Link>
                );
              }

              const content = filterMenus(item.options, userAccess);
              if (_.isEmpty(content)) return;
              return (
                <Dropdown className={"mobile-dropdown"} text={item.title} key={`mobile-dropdown-${index}`}>
                  <Dropdown.Menu>
                    {_.map(content, (a, indexMenu) => (
                      <Link
                        to={a.route}
                        key={`mobile-dropdown-menu-${indexMenu}`}
                        onClick={() => {
                          updateRedirectLocation(item.page);
                          onClickResetBooking();
                          resetVOD();
                          setVodBuyerReservation(a.title);
                        }}
                      >
                        <Dropdown.Item className={`mobile-dropdown-item`} text={a.title} />
                      </Link>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              );
            })}
          </div>
        </div>
      </Sidebar>
    </div>:
  <div className={`header-menu-top-container mar-0`} style={{ height: '80px' }}>
  {_.map(menu, (item, index) => {
    const activeDarkHeader = _.some(item.options, ["route", pathname])
      ? "dark-header-active"
      : "";

    const header = filterHeaderMenus(item.forPersona, userAccess, index);
    if (_.isEmpty(item.options) && header) {
      return (
        <Link
          key={`header-${index}`}
          to={item.route}
          onClick={() => {
            updateRedirectLocation(item.page);
            onClickResetBooking(item.page);
            resetVOD();
          }}
        >
          <div
            className={`menu-item dark-header mar-0 ${
              _.isEqual(item.route, pathname) ? "dark-header-active" : ""
            }`}
          >
            {item.title}
          </div>
        </Link>
      );
    }

    const content = filterMenus(item.options, userAccess);
    if (_.isEmpty(content)) return;

    return (
      <Dropdown
        text={item.title}
        key={`dropdown-${index}`}
        icon={<ReactSVG src={ArrowDown} style={{ textAlign: 'right', marginLeft: '8px' }} />}
        simple
      >
        <Dropdown.Menu>
          {_.map(content, (a, indexMenu) => {
            const contentChildren = a.children;
            const renderContent = _.isEmpty(contentChildren) ? (
              <Link
                to={a.route}
                key={`dropdown-menu-${indexMenu}`}
                onClick={() => {
                  updateRedirectLocation(item.page);
                  onClickResetBooking();
                  resetVOD();
                  setVodBuyerReservation(a.title);
                }}
              >
                <Dropdown.Item className={`dropdown-item`} text={a.title} />
              </Link>
            ) : (
              <Dropdown.Item as={Link} to={a.route} key={`dropdown-item-menu-${indexMenu}`} className={`dropdown-item`}>
                <span className="text">{a.title}</span>
                <Dropdown.Menu id="sub-menu" key={`dropdown-item-menu-${indexMenu}-item-${index}`}>
                  {contentChildren.map((item, index) => (
                    <Link
                      to={item.route}
                      key={`dropdown-item-menu-${indexMenu}-item-${index}`}
                      onClick={() => {
                        updateRedirectLocation(item.page);
                        onClickResetBooking();
                        resetVOD();
                        setVodBuyerReservation(item.title);
                      }}
                    >
                      <Dropdown.Item className={`dropdown-item`} text={item.title} />
                    </Link>
                  ))}
                </Dropdown.Menu>
              </Dropdown.Item>
            );

            return renderContent;
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  })}
</div>

  );
};

export default HeaderMenuTop;
