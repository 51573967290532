import _ from "lodash";
import * as services from "../services/serviceVOD";
import * as MessagingActions from "../actions/common/actionMessaging";

import { fetchStart, fetchSuccess, fetchError } from "./common/actionFetch";

// ---------- RESET ---------- RESET ---------- RESET ---------- RESET ---------- RESET ---------- RESET ---------- RESET ----------
export const RESET_VOD_RESPONSE = "RESET_VOD_RESPONSE";
export const resetVODResponse = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VOD_RESPONSE,
      payload: [],
    });
  };
};

export const RESET_VOD_SELLER_VESSEL_LIST = "RESET_VOD_SELLER_VESSEL_LIST";
export const resetVODSellerVesselList = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VOD_SELLER_VESSEL_LIST,
      payload: [],
    });
  };
};

export const RESET_VOD_SELLER_VESSEL_REGISTRATION = "RESET_VOD_SELLER_VESSEL_REGISTRATION";
export const resetVODSellerVesselRegistration = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VOD_SELLER_VESSEL_REGISTRATION,
      payload: [],
    });
  };
};

export const RESET_VOD_BUYER = "RESET_VOD_BUYER";
export const resetVODBuyer = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VOD_BUYER,
      payload: [],
    });
  };
};

export const RESET_VOD_BOOKINGS = "RESET_VOD_BOOKINGS";
export const resetVODBookings = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VOD_BOOKINGS,
      payload: [],
    });
  };
};

export const RESET_VOD_APPROVAL = "RESET_VOD_APPROVAL";
export const resetVODApproval = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VOD_APPROVAL,
      payload: [],
    });
  };
};

export const RESET_SELLER_BOOKING_RESPONSE = "RESET_SELLER_BOOKING_RESPONSE";
export const resetSellerBookingResponse = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SELLER_BOOKING_RESPONSE,
      payload: [],
    });
  };
};

export const GET_LEADERBOARD = "GET_LEADERBOARD";
export const getLeaderboard = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LEADERBOARD));
    services
      .getLeaderboard()
      .then((response) =>
        dispatch({
          type: GET_LEADERBOARD,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LEADERBOARD)))
      .catch(() => dispatch(fetchError(GET_LEADERBOARD)));
  };
};

// ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ----------
export const GET_DROPDOWN_VESSEL_TYPE = "GET_DROPDOWN_VESSEL_TYPE";
export const getDropdownVesselType = (page_name, dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_VESSEL_TYPE));
    services
      .getDropdown(page_name, dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_VESSEL_TYPE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_VESSEL_TYPE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_VESSEL_TYPE)));
  };
};

export const GET_DROPDOWN_VESSEL_FLAG = "GET_DROPDOWN_VESSEL_FLAG";
export const getDropdownVesselFlag = (page_name, dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_VESSEL_FLAG));
    services
      .getDropdown(page_name, dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_VESSEL_FLAG,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_VESSEL_FLAG)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_VESSEL_FLAG)));
  };
};

export const GET_DROPDOWN_VESSEL_REGION = "GET_DROPDOWN_VESSEL_REGION";
export const getDropdownVesselRegion = (page_name, dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_VESSEL_REGION));
    services
      .getDropdown(page_name, dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_VESSEL_REGION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_VESSEL_REGION)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_VESSEL_REGION)));
  };
};

export const GET_DROPDOWN_PROJECT_NAME = "GET_DROPDOWN_PROJECT_NAME";
export const getDropdownProjectName = (page_name, dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_PROJECT_NAME));
    services
      .getDropdown(page_name, dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_PROJECT_NAME,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_PROJECT_NAME)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_PROJECT_NAME)));
  };
};

export const GET_DROPDOWN_DISCIPLINE = "GET_DROPDOWN_DISCIPLINE";
export const getDropdownDiscipline = (page_name, dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_DISCIPLINE));
    services
      .getDropdown(page_name, dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_DISCIPLINE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_DISCIPLINE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_DISCIPLINE)));
  };
};

export const GET_DROPDOWN_PORT_OF_ANCHORAGE = "GET_DROPDOWN_PORT_OF_ANCHORAGE";
export const getDropdownPortOfAnchorage = (page_name, dropdown_category, vessel_region) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_PORT_OF_ANCHORAGE));
    services
      .getDropdown(page_name, dropdown_category, "vessel_region", vessel_region)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_PORT_OF_ANCHORAGE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_PORT_OF_ANCHORAGE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_PORT_OF_ANCHORAGE)));
  };
};

export const GET_DROPDOWN_CERTIFICATE_TYPE = "GET_DROPDOWN_CERTIFICATE_TYPE";
export const getDropdownCertificateType = (page_name, dropdown_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_CERTIFICATE_TYPE));
    services
      .getDropdown(page_name, dropdown_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_CERTIFICATE_TYPE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_CERTIFICATE_TYPE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_CERTIFICATE_TYPE)));
  };
};

export const GET_DROPDOWN_TYPE = "GET_DROPDOWN_TYPE";
export const getDropdownType = (page_name) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_TYPE));
    services
      .getDropdown(page_name)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_TYPE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_TYPE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_TYPE)));
  };
};

export const GET_DROPDOWN_SUB_TYPE = "GET_DROPDOWN_SUB_TYPE";
export const getDropdownSubType = (page_name, dropdown_category, sub_category) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_SUB_TYPE));
    services
      .getDropdown(page_name, dropdown_category, "sub_category", sub_category)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_SUB_TYPE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_SUB_TYPE)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_SUB_TYPE)));
  };
};

// ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ----------
export const GET_MY_VESSELS = "GET_MY_VESSELS";
export const getMyVessels = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MY_VESSELS));
    services
      .getMyVessels()
      .then((response) => {
        dispatch({
          type: GET_MY_VESSELS,
          payload: _.isUndefined(response?.is_valid) ? response : [],
        });
      })
      .then(() => dispatch(fetchSuccess(GET_MY_VESSELS)))
      .catch(() => dispatch(fetchError(GET_MY_VESSELS)));
  };
};

export const GET_LEASE_VESSEL = "GET_LEASE_VESSEL";
export const getLeaseVessel = (registrationID, leaseType) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LEASE_VESSEL));
    services
      .getLeaseVessel(registrationID, leaseType)
      .then((response) =>
        dispatch({
          type: GET_LEASE_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LEASE_VESSEL)))
      .catch(() => dispatch(fetchError(GET_LEASE_VESSEL)));
  };
};

export const DELETE_LEASE_VESSEL = "DELETE_LEASE_VESSEL";
export const deleteLeaseVessel = (registrationID, leaseType) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_LEASE_VESSEL));
    services
      .deleteLeaseVessel(registrationID, leaseType)
      .then((response) =>
        dispatch({
          type: DELETE_LEASE_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_LEASE_VESSEL)))
      .catch(() => dispatch(fetchError(DELETE_LEASE_VESSEL)));
  };
};

export const PUT_LEASE_VESSEL = "PUT_LEASE_VESSEL";
export const putLeaseVessel = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_LEASE_VESSEL));
    services
      .putLeaseVessel(bodyParams)
      .then((response) =>
        dispatch({
          type: PUT_LEASE_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_LEASE_VESSEL)))
      .catch(() => dispatch(fetchError(PUT_LEASE_VESSEL)));
  };
};

export const POST_LEASE_VESSEL = "POST_LEASE_VESSEL";
export const postLeaseVessel = (bodyParams, onDone) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_LEASE_VESSEL));
    services
      .postLeaseVessel(bodyParams)
      .then((response) =>
        dispatch({
          type: POST_LEASE_VESSEL,
          payload: response,
        })
      )
      .then((res) => {
        onDone?.(res);
        return dispatch(fetchSuccess(POST_LEASE_VESSEL));
      })
      .catch((err) => {
        onDone?.(null, err);
        return dispatch(fetchError(POST_LEASE_VESSEL));
      });
  };
};

// ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ----------
export const GET_VESSEL_REGISTRATION = "GET_VESSEL_REGISTRATION";
export const getVesselRegistration = (registrationID, isDraft) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_REGISTRATION));
    services
      .getVesselRegistration(registrationID, isDraft)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_REGISTRATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_REGISTRATION)))
      .catch(() => dispatch(fetchError(GET_VESSEL_REGISTRATION)));
  };
};

export const POST_VESSEL_REGISTRATION = "POST_VESSEL_REGISTRATION";
export const postVesselRegistration = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_REGISTRATION));
    services
      .postVesselRegistration(bodyParams)
      .then((response) => {
        dispatch({
          type: POST_VESSEL_REGISTRATION,
          payload: response,
        });

        if (response?.is_valid) {
          dispatch(MessagingActions.postEmailVODVesselAdminVerification(response?.registrationID));
        }
      })
      .then(() => dispatch(fetchSuccess(POST_VESSEL_REGISTRATION)))
      .catch(() => dispatch(fetchError(POST_VESSEL_REGISTRATION)));
  };
};

export const PUT_VESSEL_REGISTRATION = "PUT_VESSEL_REGISTRATION";
export const putVesselRegistration = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_VESSEL_REGISTRATION));
    services
      .putVesselRegistration(bodyParams)
      .then((response) => {
        dispatch({
          type: PUT_VESSEL_REGISTRATION,
          payload: response,
        });

        if (response?.is_valid) {
          dispatch(MessagingActions.postEmailVODVesselAdminVerification(response?.registrationID));
        }
      })
      .then(() => dispatch(fetchSuccess(PUT_VESSEL_REGISTRATION)))
      .catch(() => dispatch(fetchError(PUT_VESSEL_REGISTRATION)));
  };
};

export const DELETE_VESSEL_REGISTRATION = "DELETE_VESSEL_REGISTRATION";
export const deleteVesselRegistration = (isDraft, registrationID, originalRegistrationID) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_VESSEL_REGISTRATION));
    services
      .deleteVesselRegistration(isDraft, registrationID, originalRegistrationID)
      .then((response) =>
        dispatch({
          type: DELETE_VESSEL_REGISTRATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_VESSEL_REGISTRATION)))
      .catch(() => dispatch(fetchError(DELETE_VESSEL_REGISTRATION)));
  };
};

// ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ----------
export const GET_BUYER_SEARCH_VESSEL = "GET_BUYER_SEARCH_VESSEL";
export const getBuyerSearchVessel = (
  lease_type,
  project_name,
  vessel_type,
  discipline,
  base_seat_capacity,
  port_of_anchorage,
  start_date,
  end_date
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_BUYER_SEARCH_VESSEL));
    services
      .getBuyerSearchVessel(
        lease_type,
        project_name,
        vessel_type,
        discipline,
        base_seat_capacity,
        port_of_anchorage,
        start_date,
        end_date
      )
      .then((response) =>
        dispatch({
          type: GET_BUYER_SEARCH_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_BUYER_SEARCH_VESSEL)))
      .catch(() => dispatch(fetchError(GET_BUYER_SEARCH_VESSEL)));
  };
};

export const PUT_BUYER_SEARCH_VESSEL = "PUT_BUYER_SEARCH_VESSEL";
export const putBuyerSearchVessel = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_BUYER_SEARCH_VESSEL));
    services
      .putBuyerSearchVessel(bodyParams)
      .then((response) =>
        dispatch({
          type: PUT_BUYER_SEARCH_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_BUYER_SEARCH_VESSEL)))
      .catch(() => dispatch(fetchError(PUT_BUYER_SEARCH_VESSEL)));
  };
};

export const POST_BUYER_SEARCH_VESSEL = "POST_BUYER_SEARCH_VESSEL";
export const postBuyerSearchVessel = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_BUYER_SEARCH_VESSEL));
    services
      .postBuyerSearchVessel(bodyParams)
      .then((response) =>
        dispatch({
          type: POST_BUYER_SEARCH_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_BUYER_SEARCH_VESSEL)))
      .catch(() => dispatch(fetchError(POST_BUYER_SEARCH_VESSEL)));
  };
};

export const DELETE_BUYER_SEARCH_VESSEL = "DELETE_BUYER_SEARCH_VESSEL";
export const deleteBuyerSearchVessel = (lease_type) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_BUYER_SEARCH_VESSEL));
    services
      .deleteBuyerSearchVessel(lease_type)
      .then((response) =>
        dispatch({
          type: DELETE_BUYER_SEARCH_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_BUYER_SEARCH_VESSEL)))
      .catch(() => dispatch(fetchError(DELETE_BUYER_SEARCH_VESSEL)));
  };
};

export const PUT_BUYER_RESERVE_VESSEL = "PUT_BUYER_RESERVE_VESSEL";
export const putBuyerReserveVessel = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_BUYER_RESERVE_VESSEL));
    services
      .putBuyerReserveVessel(bodyParams)
      .then((response) =>
        dispatch({
          type: PUT_BUYER_RESERVE_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_BUYER_RESERVE_VESSEL)))
      .catch(() => dispatch(fetchError(PUT_BUYER_RESERVE_VESSEL)));
  };
};

export const POST_BUYER_RESERVE_VESSEL = "POST_BUYER_RESERVE_VESSEL";
export const postBuyerReserveVessel = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_BUYER_RESERVE_VESSEL));
    services
      .postBuyerReserveVessel(bodyParams)
      .then((response) => {
        dispatch({
          type: POST_BUYER_RESERVE_VESSEL,
          payload: response,
        });

        if (response?.is_valid) {
          dispatch(
            MessagingActions.postEmailVODNewBookingApproval(
              response?.bookingID,
              bodyParams?.lease_type
            )
          );
        }
      })
      .then(() => dispatch(fetchSuccess(POST_BUYER_RESERVE_VESSEL)))
      .catch(() => dispatch(fetchError(POST_BUYER_RESERVE_VESSEL)));
  };
};

// ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ----------
export const GET_MY_BOOKINGS = "GET_MY_BOOKINGS";
export const getMyBookings = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MY_BOOKINGS));
    services
      .getMyBookings()
      .then((response) =>
        dispatch({
          type: GET_MY_BOOKINGS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MY_BOOKINGS)))
      .catch(() => dispatch(fetchError(GET_MY_BOOKINGS)));
  };
};

export const GET_MY_BOOKINGS_DETAILS = "GET_MY_BOOKINGS_DETAILS";
export const getMyBookingsDetails = (bookingID, leaseType) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MY_BOOKINGS_DETAILS));
    services
      .getMyBookingsDetails(bookingID, leaseType)
      .then((response) =>
        dispatch({
          type: GET_MY_BOOKINGS_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MY_BOOKINGS_DETAILS)))
      .catch(() => dispatch(fetchError(GET_MY_BOOKINGS_DETAILS)));
  };
};

export const DELETE_MY_BOOKINGS_DETAILS = "DELETE_MY_BOOKINGS_DETAILS";
export const deleteMyBookingsDetails = (bookingID, leaseType) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_MY_BOOKINGS_DETAILS));
    services
      .deleteMyBookingsDetails(bookingID, leaseType)
      .then((response) =>
        dispatch({
          type: DELETE_MY_BOOKINGS_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_MY_BOOKINGS_DETAILS)))
      .catch(() => dispatch(fetchError(DELETE_MY_BOOKINGS_DETAILS)));
  };
};

export const SET_MODIFY_DEMAND = "SET_MODIFY_DEMAND";
export const setModifyDemand = (modifyDemand) => {
  return {
    type: SET_MODIFY_DEMAND,
    payload: modifyDemand,
  };
};

// ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ----------
export const GET_APPROVAL_REGISTRATION = "GET_APPROVAL_REGISTRATION";
export const getApprovalRegistration = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_APPROVAL_REGISTRATION));
    services
      .getApprovalRegistration()
      .then((response) =>
        dispatch({
          type: GET_APPROVAL_REGISTRATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_APPROVAL_REGISTRATION)))
      .catch(() => dispatch(fetchError(GET_APPROVAL_REGISTRATION)));
  };
};

export const GET_APPROVAL_REGISTRATION_DETAILS = "GET_APPROVAL_REGISTRATION_DETAILS";
export const getApprovalRegistrationDetails = (registrationID) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_APPROVAL_REGISTRATION_DETAILS));
    services
      .getApprovalRegistrationDetails(registrationID)
      .then((response) =>
        dispatch({
          type: GET_APPROVAL_REGISTRATION_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_APPROVAL_REGISTRATION_DETAILS)))
      .catch(() => dispatch(fetchError(GET_APPROVAL_REGISTRATION_DETAILS)));
  };
};

export const POST_APPROVAL_REGISTRATION = "POST_APPROVAL_REGISTRATION";
export const postApprovalRegistration = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_APPROVAL_REGISTRATION));
    services
      .postApprovalRegistration(bodyParams)
      .then((response) => {
        dispatch({
          type: POST_APPROVAL_REGISTRATION,
          payload: response,
        });

        if (response?.is_valid) {
          dispatch(
            MessagingActions.postEmailVesselRegistrationApproval(
              bodyParams?.registration_id,
              bodyParams?.approval_status
            )
          );
        }
      })
      .then(() => dispatch(fetchSuccess(POST_APPROVAL_REGISTRATION)))
      .catch(() => dispatch(fetchError(POST_APPROVAL_REGISTRATION)));
  };
};

// ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ----------
export const SET_ACTION_REQUIRED = "SET_ACTION_REQUIRED";
export const setActionRequired = (actionRequired) => {
  return {
    type: SET_ACTION_REQUIRED,
    payload: actionRequired,
  };
};

export const GET_ACTION_REQUIRED = "GET_ACTION_REQUIRED";
export const getActionRequired = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_ACTION_REQUIRED));
    services
      .getActionRequired()
      .then((response) =>
        dispatch({
          type: GET_ACTION_REQUIRED,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ACTION_REQUIRED)))
      .catch(() => dispatch(fetchError(GET_ACTION_REQUIRED)));
  };
};

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const getNotifications = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_NOTIFICATIONS));
    services
      .getNotifications()
      .then((response) =>
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_NOTIFICATIONS)))
      .catch(() => dispatch(fetchError(GET_NOTIFICATIONS)));
  };
};

export const GET_VOYAGE_PLANNING = "GET_VOYAGE_PLANNING";
export const getVoyagePlanning = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VOYAGE_PLANNING));
    services
      .getVoyagePlanning()
      .then((response) =>
        dispatch({
          type: GET_VOYAGE_PLANNING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VOYAGE_PLANNING)))
      .catch(() => dispatch(fetchError(GET_VOYAGE_PLANNING)));
  };
};

// ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ----------
export const GET_MY_SELLER_BOOKINGS = "GET_MY_SELLER_BOOKINGS";
export const getMySellerBookings = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MY_SELLER_BOOKINGS));
    services
      .getMySellerBookings()
      .then((response) =>
        dispatch({
          type: GET_MY_SELLER_BOOKINGS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_MY_SELLER_BOOKINGS)))
      .catch(() => dispatch(fetchError(GET_MY_SELLER_BOOKINGS)));
  };
};

export const GET_VOD_SELLER_BOOKING_VOYAGE_SUMMARY = "GET_VOD_SELLER_BOOKING_VOYAGE_SUMMARY";
export const getVODSellerBookingSummary = (tripId) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VOD_SELLER_BOOKING_VOYAGE_SUMMARY));
    services
      .getVODSellerBookingSummary(tripId)
      .then((response) =>
        dispatch({
          type: GET_VOD_SELLER_BOOKING_VOYAGE_SUMMARY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VOD_SELLER_BOOKING_VOYAGE_SUMMARY)))
      .catch(() => dispatch(fetchError(GET_VOD_SELLER_BOOKING_VOYAGE_SUMMARY)));
  };
};

export const POST_BUYER_RESERVATION = "POST_BUYER_RESERVATION";
export const postBuyerReservation = (reservation_id, mode, lease_type) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_BUYER_RESERVATION));
    services
      .postBuyerReservation(reservation_id, mode, lease_type)
      .then((response) => {
        dispatch({
          type: POST_BUYER_RESERVATION,
          payload: response,
        });
        if (response?.is_valid) {
          if (lease_type === "vessel-chartering") {
            dispatch(
              MessagingActions.postEmailApproveOrRejectNotificationToBuyerForVesselChartering(
                reservation_id,
                mode,
                lease_type
              )
            );
          } else if (lease_type === "voyage-sharing") {
            dispatch(
              MessagingActions.postEmailApproveOrRejectNotificationToBuyerForVoyageSharing(
                reservation_id,
                mode,
                lease_type
              )
            );
          }
        }
      })
      .then(() => dispatch(fetchSuccess(POST_BUYER_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_BUYER_RESERVATION)));
  };
};

export const POST_CONFIRM_VOYAGE_COMPLETION = "POST_CONFIRM_VOYAGE_COMPLETION";
export const postConfirmVoyageCompletion = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CONFIRM_VOYAGE_COMPLETION));
    services
      .postConfirmVoyageCompletion(bodyParams)
      .then((response) =>
        dispatch({
          type: POST_CONFIRM_VOYAGE_COMPLETION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CONFIRM_VOYAGE_COMPLETION)))
      .catch(() => dispatch(fetchError(POST_CONFIRM_VOYAGE_COMPLETION)));
  };
};

export const GET_SCHEDULE_VARIANCE = "GET_SCHEDULE_VARIANCE";
export const getScheduleVariance = (reservationId, tripId) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_VARIANCE));
    return services
      .getScheduleVarianceApi(reservationId, tripId)
      .then((res) => {
        dispatch(fetchSuccess(GET_SCHEDULE_VARIANCE));
        return res;
      })
      .catch(() => dispatch(fetchError(GET_SCHEDULE_VARIANCE)));
  };
};
