import * as services from '../services/servicePlanning'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

export const SET_VALIDATE_VESSEL_SCHEDULE = 'SET_VALIDATE_VESSEL_SCHEDULE'
export const setValidateVesselSchedule = (vesselSchedules) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_VALIDATE_VESSEL_SCHEDULE))
    services
      .setValidateVesselSchedule(vesselSchedules)
      .then((response) =>
        dispatch({
          type: SET_VALIDATE_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_VALIDATE_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(SET_VALIDATE_VESSEL_SCHEDULE)))
  }
}

export const SET_INSERT_VESSEL_SCHEDULE = 'SET_INSERT_VESSEL_SCHEDULE'
export const setInsertVesselSchedule = (vesselSchedules) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_INSERT_VESSEL_SCHEDULE))
    services
      .setInsertVesselSchedule(vesselSchedules)
      .then((response) =>
        dispatch({
          type: SET_INSERT_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_INSERT_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(SET_INSERT_VESSEL_SCHEDULE)))
  }
}

export const POST_SOC_VALIDATE_INSERT = 'POST_SOC_VALIDATE_INSERT'
export const postScheduleOptionValidateInsert = (vesselSchedules) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SOC_VALIDATE_INSERT))
    services
      .postScheduleOptionValidateInsert(vesselSchedules)
      .then((response) =>
        dispatch({
          type: POST_SOC_VALIDATE_INSERT,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SOC_VALIDATE_INSERT)))
      .catch(() => dispatch(fetchError(POST_SOC_VALIDATE_INSERT)))
  }
}

export const POST_SOC_LOCATION_DATA = 'POST_SOC_LOCATION_DATA'
export const postScheduleOptionLocationData = (uploadId) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SOC_LOCATION_DATA))
    services
      .postScheduleOptionLocationData(uploadId)
      .then((response) =>
        dispatch({
          type: POST_SOC_LOCATION_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SOC_LOCATION_DATA)))
      .catch(() => dispatch(fetchError(POST_SOC_LOCATION_DATA)))
  }
}

export const POST_SOC_KPI_DATA = 'POST_SOC_KPI_DATA'
export const postScheduleOptionKpiData = (uploadId) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SOC_KPI_DATA))
    services
      .postScheduleOptionKpiData(uploadId)
      .then((response) =>
        dispatch({
          type: POST_SOC_KPI_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SOC_KPI_DATA)))
      .catch(() => dispatch(fetchError(POST_SOC_KPI_DATA)))
  }
}

export const POST_SOC_SELECTED_VESSEL_DATA = 'POST_SOC_SELECTED_VESSEL_DATA'
export const postScheduleOptionVesselData = (uploadId, optionNumber) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SOC_SELECTED_VESSEL_DATA))
    services
      .postScheduleOptionVesselData(uploadId, optionNumber)
      .then((response) =>
        dispatch({
          type: POST_SOC_SELECTED_VESSEL_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SOC_SELECTED_VESSEL_DATA)))
      .catch(() => dispatch(fetchError(POST_SOC_SELECTED_VESSEL_DATA)))
  }
}

export const UPDATE_SELECTED_OPTION_NUMBER = 'UPDATE_SELECTED_OPTION_NUMBER'
export const updateSelectedOptionNumber = (optionID) => {
  return {
    type: UPDATE_SELECTED_OPTION_NUMBER,
    payload: optionID,
  }
}

export const SET_RESET_PLANNING_PAGE = 'SET_RESET_PLANNING_PAGE'
export const setResetPlanningPage = () => {
  return {
    type: SET_RESET_PLANNING_PAGE,
    payload: [],
  }
}

export const SET_RESET_SOC_VALIDATE_INSERT = 'SET_RESET_SOC_VALIDATE_INSERT'
export const setResetScheduleOptionValidateInsert = () => {
  return {
    type: SET_RESET_SOC_VALIDATE_INSERT,
    payload: [],
  }
}
