import * as services from '../../services/common/serviceOnBoardingStatus'
import { fetchStart, fetchSuccess, fetchError } from './actionFetch'

export const GET_ONBOARDING_STATUS = 'GET_ONBOARDING_STATUS'
export const getOnboardingStatus = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_ONBOARDING_STATUS))
    services
      .getOnboadingStatus()
      .then((response) => {
        dispatch({
          type: GET_ONBOARDING_STATUS,
          payload: response.data,
        })
      })
      .then(() => dispatch(fetchSuccess(GET_ONBOARDING_STATUS)))
      .catch(() => dispatch(fetchError(GET_ONBOARDING_STATUS)))
  }
}