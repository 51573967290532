import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'

export const useStateWithKeyPaths = (statePaths = {}) => {
	const selector = useCallback((state) => {
		return _.mapValues(statePaths, (path) => {
			return _.get(state, path)
		})
	}, [statePaths])
	const states = useSelector(selector)
	return states
}

export const useStateWithPaths = (statePaths = []) => {
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	const paths = useMemo(() => statePaths, [...statePaths])
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	const selector = useCallback((state) => {
		return _.map(paths, (path) => {
			return _.get(state, path)
		})
	}, [paths])
	const states = useSelector(selector)
	return states
}

export const useActions = (actionCreators = {}) => {
	const dispatch = useDispatch()
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	const actions = useMemo(() => {
		return bindActionCreators(actionCreators, dispatch)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, _.values(actionCreators))
	return actions
}

export const useStateWithFunction = (defaultValue, method) => {
	const [state, setState] = useState(defaultValue)
	const onCallbackFunction = useCallback(
		(...args) => {
			setState(method(state, ...args))
		},
		[state, method]
	)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return [state, onCallbackFunction, setState]
}
// eslint-disable-next-line
export default function (statePaths = [], actionCreators = {}) {
	const states = useStateWithPaths(statePaths)
	const actions = useActions(actionCreators)
	return [
		states,
		actions
	]
}