import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { ReactSVG } from 'react-svg'

// Components
import { Button, Modal, Form, TextArea, Dropdown } from 'semantic-ui-react'
import Btn from '../../../../../../components/button'

// Assets
import CLOSE_ICON from '../../../../../../assets/images/close.svg'

// Hooks
import { useActions } from '../../../../../../hooks/useConnect'

// Actions
import * as NotificationActions from '../../../../../../actions/common/actionNotification'

// Icon
import ATTENTION_ICON from '../../../../../../assets/images/attention.svg'
import CustomDropdown from '../../../../../dropdown/custom-mobile-dropdown'

const AddComment = (props) => {
  const { isShowComment, setIsShowComment, setReasonComment, reasonComment,isMobile } = props

  useEffect(() => {
    !isShowComment && setReasonComment('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowComment])
  return (isMobile ? 
    <div className='comment-container'>
      <div className='comment-title'>Comment</div>
      <Form className='comment-box'>
        <TextArea
          placeholder="Leave your comment here..."
          onChange={(e) => setReasonComment(e.target.value)}
          value={reasonComment}
        />
      </Form>
    </div> :
    <div className="active-log-comment">
    {isShowComment ? (
      <div>
        <ReactSVG
          src={CLOSE_ICON}
          className={`close-method-icon`}
          onClick={() => {
            setIsShowComment(false)
          }}
        />
        <div className={`sidebar-input-label`}>Comments</div>
        <Form className="mt">
          <TextArea
            placeholder="Type comment here..."
            onChange={(e) => setReasonComment(e.target.value)}
            value={reasonComment}
          />
        </Form>
      </div>
    ) : (
      <div className="label-text" onClick={() => setIsShowComment(true)}>
        + Add Comment
      </div>
    )}
  </div>
  )
}

const AddReason = (props) => {
  const {
    incidentReasons,
    incidentReasonsDescription,
    isShowReason,
    setIsShowReason,
    setReasonDetail,
    reasonDetail,
    isMobile = false,
  } = props
  const [selectedReason, setSelectedReason] = useState('')
  const [selectReasonDescription, setSelectReasonDescription] = useState('')
  const [descriptionOptions, setDescriptionOptions] = useState([])

  useEffect(() => {
    if (selectedReason === 'Other') {
      setDescriptionOptions([])
      setReasonDetail(getReasonDetail(incidentReasonsDescription[selectedReason][0]))
      return
    }
    if (selectedReason) {
      setDescriptionOptions(incidentReasonsDescription[selectedReason])
      let object = incidentReasonsDescription[selectedReason].find(
        (item) => item.reason_id === selectReasonDescription
      )
      setReasonDetail(getReasonDetail(object))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReason, selectReasonDescription])

  useEffect(() => {
    !isShowReason && resetReasonDropdown()
  }, [isShowReason])

  useEffect(() => {
    if (_.isEmpty(reasonDetail)) {
      resetReasonDropdown()
    }
  }, [reasonDetail])

  const resetReasonDropdown = () => {
    setSelectedReason('')
    setSelectReasonDescription('')
  }

  const handleChangSelectReason = (selectedReason) => {
    setSelectedReason(selectedReason)
    setSelectReasonDescription('')
  }

  const getReasonDetail = (data) => {
    return {
      user_added_reason: data?.reason,
      user_added_reason_description: data?.reason_description || '',
      reason_is_avoidable: data?.is_avoidable,
    }
  }

  return (isMobile ? 
    <div className='add-reason-container'>
      <div className='title'>Incident Reason and Description</div>
      <div className={`reason-dropdown`}>
        <CustomDropdown 
          value={selectedReason}
          setValue={handleChangSelectReason}
          options={incidentReasons}
          placeholder={"Select Reason*"}
          style={{ width: '100%' }}
        />
      </div>
      <div className={`reason-dropdown`}>
        <CustomDropdown 
          value={selectReasonDescription}
          setValue={setSelectReasonDescription}
          options={descriptionOptions.map(({ text, value }) => ({ text, value }))}
          placeholder={"Select Reason Description*"}
          style={{ width: '100%' }}
        />
      </div>
    </div> :
    <div className="active-log-reason">
      {isShowReason ? (
        <div>
          <ReactSVG
            src={CLOSE_ICON}
            className={`close-method-icon`}
            onClick={() => {
              setIsShowReason(false)
            }}
          />
          <div className={`sidebar-input-label`}>SELECT A REASON</div>
          <Dropdown
            placeholder="Select Reason*"
            className={`reason-dropdown mt`}
            selection
            fluid
            value={selectedReason}
            options={incidentReasons}
            onChange={(e, data) => handleChangSelectReason(data.value)}
          />
          {selectedReason && !_.isEmpty(descriptionOptions) && (
            <>
              <div className={`sidebar-input-label mar-top-1`}>SELECT REASON DESCRIPTION</div>
              <Dropdown
                placeholder="Select Reason Description*"
                className={`reason-dropdown mt`}
                selection
                fluid
                value={selectReasonDescription}
                options={descriptionOptions.map(({ text, value }) => ({ text, value }))}
                onChange={(e, data) => setSelectReasonDescription(data.value)}
              />
            </>
          )}
        </div>
      ) : (
        <div className="label-text" onClick={() => setIsShowReason(true)}>
          + Add Incident Reason
        </div>
      )}
  </div>)
}

const ReasonMethod = (props) => {
  const {
    incidentReasons,
    incidentReasonsDescription,
    selectedNotification, // eslint-disable-next-line
    reflectActiveLogs,
    isMobile = false,
    handleIsOpen
  } = props
  const { postSaveVesselTrackingStatusReason, postVesselTrackingIncidentUserComment } = useActions({
    postSaveVesselTrackingStatusReason: NotificationActions.postSaveVesselTrackingStatusReason,
    postVesselTrackingIncidentUserComment:
      NotificationActions.postVesselTrackingIncidentUserComment,
  })

  const [reasonComment, setReasonComment] = useState(null)
  const [reasonDetail, setReasonDetail] = useState({})
  const [isShowReason, setIsShowReason] = useState(false)
  const [isShowComment, setIsShowComment] = useState(false)
  const [isShowMissingReasonModel, setIsShowMissingReasonModel] = useState(false)
  const handleAddReason = () => {
    if (reasonDetail?.user_added_reason) {
      const payload = {
        trip_id: selectedNotification?.trip_id,
        seq: selectedNotification?.seq,
        actual_seq: selectedNotification?.actual_seq,
        notification: selectedNotification?.notification,
        notification_type_id: selectedNotification?.notification_type_id,
        location: selectedNotification?.location,
        user_comment: reasonComment,
        ...reasonDetail,
      }
      postSaveVesselTrackingStatusReason(payload, refreshActivityLogList)
      setReasonDetail({})
      setReasonComment('')
      document.getElementsByClassName('log-viewer-scrollable')[0]?.scroll(0, 0)
    } else {
      setIsShowMissingReasonModel(true)
    }
  }

  const refreshActivityLogList = () => {
    postVesselTrackingIncidentUserComment(selectedNotification)
    reflectActiveLogs()
  }

  return (
    <div className={`flex column mar-top-2`}>
      <AddReason
        incidentReasons={incidentReasons}
        isShowReason={isShowReason}
        setIsShowReason={setIsShowReason}
        incidentReasonsDescription={incidentReasonsDescription}
        reasonDetail={reasonDetail}
        setReasonDetail={setReasonDetail}
        isMobile={isMobile}
      ></AddReason>
      <AddComment
        isShowComment={isShowComment}
        setIsShowComment={setIsShowComment}
        setReasonComment={setReasonComment}
        reasonComment={reasonComment}
        isMobile={isMobile}
      ></AddComment>
      {(isShowReason || isShowComment) && (
        <Btn
          className={'mar-top-1 btn sidebar-btn-submit'}
          text={'Submit'}
          disabled={!reasonComment && !reasonDetail?.user_added_reason}
          onClick={handleAddReason}
        />
      )}
      {isMobile && <div className='btn-container'>
        <Button
          className={'btn-cancel'}
          text={'Cancel'}
          onClick={() => handleIsOpen(false)}
        >
          <p className='value'>Cancel</p>
        </ Button>
        <Button
          className={'btn-submit'}
          onClick={handleAddReason}
        >
          <p className='value'>Submit</p>
        </Button>
      </div>}
      <Modal
        closeIcon
        onClose={() => setIsShowMissingReasonModel(false)}
        open={isShowMissingReasonModel}
        size={'tiny'}
      >
        <Modal.Content className={`pad-x-4`}>
          <div className={`flex column`}>
            <div className={`flex modal-title`}>
              <img src={ATTENTION_ICON} alt="" />
              <div className={`modal-title`}>Missing Reason</div>
            </div>
            <div className={`flex-column`}>
              <p className={`mar-bottom-1`}>
                You can not add comment without any reason. Please add reason detail and submit
                again!
              </p>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={`button-custom button-closed`}
            onClick={() => setIsShowMissingReasonModel(false)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default ReasonMethod
