import React, { useState, useEffect } from 'react'
import {BrowserRouter, Route, Routes as Router } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import _ from "lodash";
import TagManager from 'react-gtm-module'
import "inter-ui/inter.css";

//library css
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import Routes from './routes';

import { ReduxProvider } from "./helpers/redux";

// Components
import UnauthorisedAccessPage from './containers/views/common/unauthorisedAccessPage';
import ScrollToTop from './components/scroll-to-top/scrollToTop';
import CustomLoader from './components/custom-loader/customLoader';
import { reactPlugin, appInsights } from "./appInsights";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#DE1782',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#004A97',
      // contrastText:'#'
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'],
    button: {
      textTransform: "none",
    },
  },
})

let intervalId;
const timeOutNumber = 3000;
const tagManagerArgs = { // google tag manager args
  gtmId: process.env.REACT_APP_GTM_ID ,
  auth: process.env.REACT_APP_GTM_AUTH ,
  preview: process.env.REACT_APP_GTM_PREVIEW
}

const App = ({auth}) => {
  const [access, setAccess] = useState({});
  const [isShownResetBrowser, setIsShownResetBrowser] = useState(false);
  
  const setApplicationStorage = (access) => {
    let getUACModule = _.get(access?.data?.data, "module_list");
    let accessModule = _.map(getUACModule, (item) => item.module_name);
    localStorage.setItem("accessModule", _.reject(accessModule, _.isEmpty));
  }

  const handelGetUserAccessToken = async (isRefresh = false) => {
    const result = await auth.getToken(isRefresh);
    if (result) {
      try {
        const accessData = await auth.getUserAccess();
        if (accessData) {
          setApplicationStorage(accessData);
          setAccess(accessData);
          return true;
        }
      } catch (err) {
        if (err?.response?.data?.is_valid === false) {
          setAccess(err?.response);
        } else {
          setIsShownResetBrowser(true)
        }
       return false;
      }
    }
    clearInterval(intervalId);
    auth.logout();
  }

  const handleRefreshTokens = async () => {
    const authResponse = JSON.parse(localStorage.getItem("authResponse") || "{}");
    const expiresOnTime = (new Date(authResponse?.expiresOn))?.getTime();
    const currentDateTime = (new Date()?.getTime()) + 5 * 60 * 1000; // will refresh token before 5 mins of the token is expired
    
    if (expiresOnTime && currentDateTime) {
      if (currentDateTime >= expiresOnTime) {
        // console.log('CALL :::: REFRESH TOKEN');
        appInsights.clearAuthenticatedUserContext();
        await handelGetUserAccessToken(true);
      }
      /// console.log("count ::::::: check console log", intervalId, count);
    }
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs); // Google tag manager
    (async () => {
      try {
        const account = auth.account();
        const error = auth.error();
        //console.log(' error :::::::: 80 :::::::::: ', error)
        //console.log(' account :::::::: 91 :::::::::: ', account)
        if (error) {
          console.log("Check error in app ", error);
          auth.logout();
          return;
        }
        if (!account && !error) {
          localStorage.setItem("urlHit", window.location.href);
          auth.login();
          return;
        }
        if (account) {
          await handelGetUserAccessToken();
        }
        if (intervalId) {
          clearInterval(intervalId);
        }
        // console.log(' THE APP INIT INTERVAL to handleRefreshTokens ');
        intervalId = setInterval(async() => await handleRefreshTokens(), timeOutNumber);
      } catch (err) {
        console.log(' err :::::::: 102 :::::::::: ', err);
        setIsShownResetBrowser(true);
      }
    })();
    
    // browser.tabs.onActivated.hasListener((tabData) => {
    //   console.log({tabData})
    // })

    return () => {
      console.log(' THE APP clearInterval ')
      clearInterval(intervalId);
    }
  }, [])
  
  useEffect(() => {
    const { is_valid = '', message = '' } =  access?.data || {};
    if (is_valid === false && message) {
      setIsShownResetBrowser(true);
    }
  }, [access?.data])


  if (isShownResetBrowser) {
    if (access?.data?.message) {
      return <UnauthorisedAccessPage message={access?.data?.message}/>;
    } else {
      return <UnauthorisedAccessPage/>;
    }
  }

  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ReduxProvider>
          {access?.data?.is_valid ? (
            <React.Suspense fallback={<CustomLoader isPage={false} isActive={true}/>}>
              <BrowserRouter>
                <ThemeProvider theme={theme}>
                  <div className="app container">
                      <ScrollToTop />
                      <Routes />
                  </div>
                </ThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          ) : (
            <BrowserRouter>
              <Router>
                <Route element={(<>
                  {access?.data?.is_valid === false ? (
                    <UnauthorisedAccessPage
                     message={access?.data?.message ?? 'Something went wrong, please try again later.'}
                    />
                  ) : (
                    <CustomLoader isPage={false} isActive={true} />
                  )}
                </>)} />
              </Router>
            </BrowserRouter>
          )}
        </ReduxProvider>
      </AppInsightsContext.Provider>
    </>
  )
}


export default App;