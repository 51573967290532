export const STANDARD = 'standard';
export const MANUAL = 'manual';

const commonOptions = [
  {
    key: 1,
    value: STANDARD,
    text: STANDARD,
  },
  {
    key: 2,
    value: MANUAL,
    text: MANUAL,
  },
];

export const initalOffshoreFoConsumptionOptions = [...commonOptions];

export const OFFSHORE_OPERATION_POPUP_DESCRIPTION =
  'Rate is 50% extra from Standard rate. Can be combination of Offshore Op and Critical Op';

export const KEYS = {
  OFFSHORE: 'offshore',
  CRITICAL: 'critical',
  DP_MODE: 'dpmode',
  DAILY_AVERAGE_BENCHMARK: 'daily_average_benchmark',
  OPERATION_DAYS: 'operation_days',
  FO_CONSUMPTION_TYPE: 'fo_consumption_type',
};

export const OFFSHORE_EMPTY_DP_MODE = 'No DP Mode';
export const OFFSHORE_EMPTY_CRITICAL = 'Unable to support Critical Op';
