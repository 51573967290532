import * as vodPageActions from "../actions/actionVOD";
import { startOfMonth, endOfMonth } from "date-fns";

const defaultState = {
  vodPage: {
    defaultDatePicker: [
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: "selection",
      },
    ],
    leaderboard: [],
    dropdown: {
      vesselType: [],
      vesselFlag: [],
      vesselRegion: [],
      projectName: [],
      discipline: [],
      portOfAnchorage: [],
      certificateType: [],
      type: [],
      subType: [],
    },
    seller: {
      vesselList: {
        myVessels: [],
        getLeaseVessel: {},
        deleteLeaseVessel: {},
        putLeaseVessel: {},
        postLeaseVessel: {},
      },
      vesselRegistration: {
        getVesselRegistration: {},
        postVesselRegistration: {},
        putVesselRegistration: {},
        deleteVesselRegistration: {},
      },
      bookings: {
        data: [],
        response: {},
        voyageSummary: {},
      },
    },
    buyer: {
      getBuyerSearchVessel: {},
      putBuyerSearchVessel: {},
      postBuyerSearchVessel: {},
      deleteBuyerSearchVessel: {},
      putBuyerReserveVessel: {},
      postBuyerReserveVessel: {},
    },
    bookings: {
      myBookings: [],
      myBookingsDetails: {},
      deleteBookingsDetails: {},
      modifyDemand: {
        isFromBookings: false,
        bookingID: "",
        leaseType: "",
        vesselSchedule: [],
        projectName: "",
        discipline: "",
        vesselType: "",
        portOfAnchorage: "",
        startDate: "",
        endDate: "",
        freshWater: 0,
        fuelOil: 0,
        cargoSpace: 0,
        baseSeatCapacity: 0,
      },
    },
    approval: {
      getApprovalRegistration: {},
      getApprovalRegistrationDetails: {},
      postApprovalRegistration: {},
    },
    landingWidget: {
      getActionRequired: {},
      actionRequired: {
        isActionView: false,
        isActionApprove: false,
        isActionReject: false,
        isActionUpdate: false,
        isActionReport: false,
        registrationID: "",
        vesselName: "",
        submittedBy: "",
        trackID: "",
        tripID: "",
      },
      getNotifications: {},
      getVoyagePlanning: {},
    },
  },
};

const REDUCERS = {
  // ---------- RESET ---------- RESET ---------- RESET ---------- RESET ---------- RESET ---------- RESET ---------- RESET ----------
  [vodPageActions.RESET_VOD_RESPONSE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: defaultState.vodPage.seller.vesselList,
          vesselRegistration: defaultState.vodPage.seller.vesselRegistration,
        },
      },
    };
  },
  [vodPageActions.RESET_VOD_SELLER_VESSEL_LIST]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: {
            ...state.vodPage.seller.vesselList,
            getLeaseVessel: defaultState.vodPage.seller.vesselList.getLeaseVessel,
            deleteLeaseVessel: defaultState.vodPage.seller.vesselList.deleteLeaseVessel,
            putLeaseVessel: defaultState.vodPage.seller.vesselList.putLeaseVessel,
            postLeaseVessel: defaultState.vodPage.seller.vesselList.postLeaseVessel,
          },
          vesselRegistration: defaultState.vodPage.seller.vesselRegistration,
        },
      },
    };
  },
  [vodPageActions.RESET_VOD_SELLER_VESSEL_REGISTRATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselRegistration: defaultState.vodPage.seller.vesselRegistration,
        },
      },
    };
  },
  [vodPageActions.RESET_VOD_BUYER]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        buyer: defaultState.vodPage.buyer,
      },
    };
  },
  [vodPageActions.RESET_VOD_BOOKINGS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        bookings: {
          ...state.vodPage.bookings,
          myBookingsDetails: defaultState.vodPage.bookings.myBookingsDetails,
          deleteBookingsDetails: defaultState.vodPage.bookings.deleteBookingsDetails,
          modifyDemand: defaultState.vodPage.bookings.modifyDemand,
        },
      },
    };
  },
  [vodPageActions.RESET_VOD_APPROVAL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        approval: defaultState.vodPage.approval,
      },
    };
  },
  [vodPageActions.RESET_SELLER_BOOKING_RESPONSE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: {
            ...state.vodPage.seller.vesselList,
            deleteLeaseVessel: defaultState.vodPage.seller.vesselList.deleteLeaseVessel,
          },
          bookings: {
            ...state.vodPage.seller.bookings,
            response: defaultState.vodPage.seller.bookings.response,
          },
        },
      },
    };
  },
  [vodPageActions.GET_LEADERBOARD]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        leaderboard: payload,
      },
    };
  },
  // ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ----------
  [vodPageActions.GET_DROPDOWN_VESSEL_TYPE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          vesselType: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_VESSEL_FLAG]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          vesselFlag: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_VESSEL_REGION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          vesselRegion: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_PROJECT_NAME]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          projectName: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_DISCIPLINE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          discipline: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_PORT_OF_ANCHORAGE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          portOfAnchorage: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_CERTIFICATE_TYPE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          certificateType: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_TYPE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          type: payload,
        },
      },
    };
  },
  [vodPageActions.GET_DROPDOWN_SUB_TYPE]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        dropdown: {
          ...state.vodPage.dropdown,
          subType: payload,
        },
      },
    };
  },
  // ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ----------
  [vodPageActions.GET_MY_VESSELS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: {
            ...state.vodPage.seller.vesselList,
            myVessels: payload,
          },
        },
      },
    };
  },
  [vodPageActions.GET_LEASE_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: {
            ...state.vodPage.seller.vesselList,
            getLeaseVessel: payload,
          },
        },
      },
    };
  },
  [vodPageActions.DELETE_LEASE_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: {
            ...state.vodPage.seller.vesselList,
            deleteLeaseVessel: payload,
          },
        },
      },
    };
  },
  [vodPageActions.PUT_LEASE_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: {
            ...state.vodPage.seller.vesselList,
            putLeaseVessel: payload,
          },
        },
      },
    };
  },
  [vodPageActions.POST_LEASE_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselList: {
            ...state.vodPage.seller.vesselList,
            postLeaseVessel: payload,
          },
        },
      },
    };
  },
  // ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ----------
  [vodPageActions.GET_VESSEL_REGISTRATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselRegistration: {
            ...state.vodPage.seller.vesselRegistration,
            getVesselRegistration: payload,
          },
        },
      },
    };
  },
  [vodPageActions.POST_VESSEL_REGISTRATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselRegistration: {
            ...state.vodPage.seller.vesselRegistration,
            postVesselRegistration: payload,
          },
        },
      },
    };
  },
  [vodPageActions.PUT_VESSEL_REGISTRATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselRegistration: {
            ...state.vodPage.seller.vesselRegistration,
            putVesselRegistration: payload,
          },
        },
      },
    };
  },
  [vodPageActions.DELETE_VESSEL_REGISTRATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          vesselRegistration: {
            ...state.vodPage.seller.vesselRegistration,
            deleteVesselRegistration: payload,
          },
        },
      },
    };
  },
  // ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ----------
  [vodPageActions.GET_BUYER_SEARCH_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        buyer: {
          ...state.vodPage.buyer,
          getBuyerSearchVessel: payload,
        },
      },
    };
  },
  [vodPageActions.PUT_BUYER_SEARCH_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        buyer: {
          ...state.vodPage.buyer,
          putBuyerSearchVessel: payload,
        },
      },
    };
  },
  [vodPageActions.POST_BUYER_SEARCH_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        buyer: {
          ...state.vodPage.buyer,
          postBuyerSearchVessel: payload,
        },
      },
    };
  },
  [vodPageActions.DELETE_BUYER_SEARCH_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        buyer: {
          ...state.vodPage.buyer,
          deleteBuyerSearchVessel: payload,
        },
      },
    };
  },
  [vodPageActions.PUT_BUYER_RESERVE_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        buyer: {
          ...state.vodPage.buyer,
          putBuyerReserveVessel: payload,
        },
      },
    };
  },
  [vodPageActions.POST_BUYER_RESERVE_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        buyer: {
          ...state.vodPage.buyer,
          postBuyerReserveVessel: payload,
        },
      },
    };
  },
  // ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ----------
  [vodPageActions.GET_MY_BOOKINGS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        bookings: {
          ...state.vodPage.bookings,
          myBookings: payload,
        },
      },
    };
  },
  [vodPageActions.GET_MY_BOOKINGS_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        bookings: {
          ...state.vodPage.bookings,
          myBookingsDetails: payload,
        },
      },
    };
  },
  [vodPageActions.DELETE_MY_BOOKINGS_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        bookings: {
          ...state.vodPage.bookings,
          deleteBookingsDetails: payload,
        },
      },
    };
  },
  [vodPageActions.SET_MODIFY_DEMAND]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        bookings: {
          ...state.vodPage.bookings,
          modifyDemand: payload,
        },
      },
    };
  },
  // ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ----------
  [vodPageActions.GET_APPROVAL_REGISTRATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        approval: {
          ...state.vodPage.approval,
          getApprovalRegistration: payload,
        },
      },
    };
  },
  [vodPageActions.GET_APPROVAL_REGISTRATION_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        approval: {
          ...state.vodPage.approval,
          getApprovalRegistrationDetails: payload,
        },
      },
    };
  },
  [vodPageActions.POST_APPROVAL_REGISTRATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        approval: {
          ...state.vodPage.approval,
          postApprovalRegistration: payload,
        },
      },
    };
  },
  // ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ----------
  [vodPageActions.SET_ACTION_REQUIRED]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        landingWidget: {
          ...state.vodPage.landingWidget,
          actionRequired: payload,
        },
      },
    };
  },
  [vodPageActions.GET_ACTION_REQUIRED]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        landingWidget: {
          ...state.vodPage.landingWidget,
          getActionRequired: payload,
        },
      },
    };
  },
  [vodPageActions.GET_NOTIFICATIONS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        landingWidget: {
          ...state.vodPage.landingWidget,
          getNotifications: payload,
        },
      },
    };
  },
  [vodPageActions.GET_VOYAGE_PLANNING]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        landingWidget: {
          ...state.vodPage.landingWidget,
          getVoyagePlanning: payload,
        },
      },
    };
  },
  // ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ----------
  [vodPageActions.GET_MY_SELLER_BOOKINGS]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          bookings: {
            ...state.vodPage.seller.bookings,
            data: payload,
          },
        },
      },
    };
  },
  [vodPageActions.GET_VOD_SELLER_BOOKING_VOYAGE_SUMMARY]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          bookings: {
            ...state.vodPage.seller.bookings,
            voyageSummary: payload,
          },
        },
      },
    };
  },
  [vodPageActions.POST_BUYER_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          bookings: {
            ...state.vodPage.seller.bookings,
            response: payload,
          },
        },
      },
    };
  },
  [vodPageActions.POST_CONFIRM_VOYAGE_COMPLETION]: (state, { payload }) => {
    return {
      ...state,
      vodPage: {
        ...state.vodPage,
        seller: {
          ...state.vodPage.seller,
          bookings: {
            ...state.vodPage.seller.bookings,
            response: payload,
          },
        },
      },
    };
  },
};

export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const VOD_PAGE_REDUCER = "vodPage";
