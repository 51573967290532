import _ from 'lodash'
import * as NotificationActions from '../../actions/common/actionNotification'

const defaultState = {
  searchData: {
    data: '',
  },
  notificationPage: {
    voyageCompleted: [],
    vesselTrackingStatusReason: [],
    incidentReasons: [],
    incidentReasonsDescription: {},
    greenNotificationWithTripId: [],
    redNotificationWithTripId: [],
    activityLogs: [],
    systemActiveLogs: [],
    shipToShip: [],
    notificationListing: [],
    totalNotifications: 0,
    totalUnReadNotification: 0,
  },
}

const REDUCERS = {
  [NotificationActions.POST_VOYAGE_COMPLETED]: (state, { payload }) => {
    const objectKey = payload.isShowNotifyByTripId ? 'greenNotificationWithTripId' : 'voyageCompleted'
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        [objectKey]: payload.response,
      },
    }
  },
  [NotificationActions.POST_VESSEL_TRACKING_STATUS_REASON]: (state, { payload }) => {
    const objectKey = payload.isShowNotifyByTripId ? 'redNotificationWithTripId' : 'vesselTrackingStatusReason'
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        [objectKey]: payload.response,
      },
    }
  },
  [NotificationActions.POST_SHIP_TO_SHIP]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        shipToShip: payload,
      },
    }
  },
  [NotificationActions.POST_INCIDENT_REASONS]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        incidentReasons: payload.incidentReasons,
        incidentReasonsDescription: payload.incidentReasonsDescription
      },
    }
  },
  [NotificationActions.SET_GREEN_NOTIFYCATION_WITH_TRIP_ID]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        greenNotificationWithTripId: payload,
      },
    }
  },
  [NotificationActions.SET_RED_NOTIFYCATION_WITH_TRIP_ID]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        redNotificationWithTripId: payload,
      },
    }
  },
  [NotificationActions.POST_SAVE_VESSEL_TRACKING_STATUS_REASON]: (state, { payload }) => {
    let arr = [...state.notificationPage.vesselTrackingStatusReason, ...state.notificationPage.redNotificationWithTripId]
    let data = arr.filter(p => p.trip_id === payload?.data?.trip_id && p.seq === payload?.data?.seq && p.location === payload?.data?.location && p.notification === payload?.data.notification)
    _.each(data, dataItem => {
      dataItem.has_activity_log = true
    })

    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        activityLogs: [payload?.data, ...state.notificationPage.activityLogs]
      },
    }
  },
  [NotificationActions.DELETE_ACTIVE_LOG]: (state, { payload }) => {
    const tempLogs = state.notificationPage.activityLogs.filter(activity => activity.user_comment_id !== payload.user_comment_id)
    if (tempLogs.length === 0) {
      let arr = [...state.notificationPage.vesselTrackingStatusReason, ...state.notificationPage.redNotificationWithTripId]
      let data = arr.filter(p => p.trip_id === payload?.trip_id && p.seq === payload?.seq && p.location === payload?.location && p.notification === payload?.notification)
      _.each(data, dataItem => {
        dataItem.has_activity_log = false
      })
    }

    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        activityLogs: tempLogs,
      },
    }
  },
  [NotificationActions.POST_VESSEL_TRACKING_INCIDENT_USER_COMMENT]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        activityLogs: [...payload, ...state.notificationPage.systemActiveLogs],
      },
    }
  },
  [NotificationActions.SET_SYSTEM_ACTIVE_LOGS]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        systemActiveLogs: payload,
      },
    }
  },
  [NotificationActions.RESET_ACTIVE_LOGS]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        activityLogs: payload,
        systemActiveLogs: payload
      },
    }
  },
  [NotificationActions.POST_SAVE_USER_NOTIFICATIONS]: (state, { payload }) => {
    _.each(payload, (item) => {

      let arr = item.notification_type_id === 1 ?
        [...state.notificationPage.voyageCompleted, ...state.notificationPage.greenNotificationWithTripId] :
        [...state.notificationPage.vesselTrackingStatusReason, ...state.notificationPage.redNotificationWithTripId]
      let data = arr.filter(p => p.trip_id === item.trip_id && p.seq === item.seq && p.location === item.location && p.notification === item.notification)
      _.each(data, dataItem => {
        dataItem.is_active = item.is_active
        dataItem.is_flagged = item.is_flagged
        dataItem.is_read = item.is_read
        dataItem.is_removed = item.is_removed
      })
    })

    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        saveUserNotifications: payload
      }
    }
  },

  [NotificationActions.GET_NOTIFICATION_LISTING]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        notificationListing: payload?.notifications ?? [],
        totalNotifications: payload.total_notifications_count,
      },
    };
  },

  [NotificationActions.GET_UNREAD_NOTIFICATION_COUNT]: (state, { payload }) => {
    return {
      ...state,
      notificationPage: {
        ...state.notificationPage,
        totalUnReadNotification: payload,
      },
    };
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const NOTIFICATION_REDUCER = 'commonNotification'
