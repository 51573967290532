import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";

const CONTROLLER_NAME = "/fms";

export const getVesselTypes = () => {
  return LAMBDA_API_INSTANCE().then((response) => response.get(`${CONTROLLER_NAME}/vesselTypes`));
};

export const getVessels = (data) => {
  const bodyParams = {
    regions: data,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(
      `${CONTROLLER_NAME}/vessels`, bodyParams
    )
  );
};

export const postStandardDailySearch = (data) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/standardDailyAverage`, data, {})
  );
};

export const getStandardDailyAverageDetails = (vessel_standard_rate_id) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/standardDailyAverage?vessel_standard_rate_id=${vessel_standard_rate_id}`
    )
  );
};

export const upadteStandardDailyAverageDetails = (data) => {
  const bodyParams = {
    ...data,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.put(`${CONTROLLER_NAME}/standardDailyAverage`, bodyParams)
  );
};
