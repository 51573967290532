import { LAMBDA_API_INSTANCE } from '../serverInstances/lambdaServer'

const CONTROLLER_NAME = '/general'

export const postUserRating = (comment, rating, npsScore, getUserEmail, getUserName, getPathname) => {
  const bodyParams = {
    email: getUserEmail,
    name: getUserName,
    comment: comment,
    pathname: getPathname,
    rating: rating,
    nps_score: npsScore
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/submitUserRating`, bodyParams))
}