import { useState } from "react";
import SupportCallIcon from "../../assets/images/support-call-float.svg";
import SupportCallActiveIcon from "../../assets/images/support-call-float-active.svg";

import { Image } from "semantic-ui-react";

import UserRating from "../user-rating";
import VirtusSupport from "../virtus-support";

const SupportDesk = () => {
  const [openSupportPop, setOpenSupportPop] = useState(false);
  const [isOpenUserRating, setIsOpenUserRating] = useState(false);
  const [isOpenVirtusSupport, setIsOpenVirtusSupport] = useState(false);

  const handleSupportPopup = () => {
    setOpenSupportPop(!openSupportPop);
    setIsOpenUserRating(false);
    setIsOpenVirtusSupport(false);
  };

  const openUserRatingHandler = (value) => {
    setIsOpenUserRating(value);
    setIsOpenVirtusSupport(false);
  };

  const openVirtusSupportHandler = (value) => {
    setIsOpenVirtusSupport(value);
    setIsOpenUserRating(false);
  };

  return (
    <>
      <div
        className={`support-desk support-desk-btn ${openSupportPop && "support-active"} flex`}
        onClick={handleSupportPopup}
      >
        <Image src={!openSupportPop ? SupportCallIcon : SupportCallActiveIcon} />
      </div>

      {openSupportPop && (
        <div>
          <VirtusSupport setIsOpen={openVirtusSupportHandler} isOpen={isOpenVirtusSupport} />
          <UserRating setIsOpen={openUserRatingHandler} isOpen={isOpenUserRating} />
        </div>
      )}
    </>
  );
};

export default SupportDesk;
