import _ from 'lodash'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

const CONTROLLER_NAME = '/speed'

export const postSpeedComplianceWarnings = (filterObject) => {
  const bodyParams = {
    vessel_id: _.get(filterObject, 'vessel'),
    trip_id: _.get(filterObject, 'vesselID'),
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/speedComplianceWarnings`, bodyParams))
}

export const postFuelGauge = (filterObject) => {
  const bodyParams = {
    trip_id: filterObject,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/speedComplianceFuelGauge`, bodyParams))
}

export const postLineChart = (filterObject) => {
  const bodyParams = {
    trip_id: filterObject,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/speedComplianceFuelLineChart`, bodyParams))
}

export const postSaveActions = (filterObject) => {
  const bodyParams = {
    vessel_name: filterObject.vessel_name,
    vessel_id: filterObject.vessel_id,
    trip_id: filterObject.trip_id,
    voyage_round: filterObject.voyage_round,
    row_num: filterObject.row_num,
    flag_to_marine_exec: filterObject.flag_to_marine_exec,
    resolved: filterObject.resolved,
    user_comments: filterObject.user_comments,
    updated_by: filterObject.updated_by,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/saveSpeedComplianceAction`, bodyParams))
}

// Dropdowns
export const postVesselDropdownData = (filterObject) => {
  const bodyParams = {
    page: filterObject,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`/master/dropdown`, bodyParams))
}
export const postVesselIDDropdownData = (filterObject) => {
  const bodyParams = {
    page: _.get(filterObject, 'page_type'),
    vessel_id: _.get(filterObject, 'vessel'),
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`/master/dropdown`, bodyParams))
}
