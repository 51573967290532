import * as actionDashBoardSummaryActions from '../actions/actionDashBoardSummaryPage';

const defaultState = {
    dashboardSummaryPage: {
        fuelConsumptionSummaryWidget: {},
        averageFuelPriceSummaryWidget: {},
        massBalanceWidget:[],
        dashboardSummaryAlerts:{
            vdrVsVfmsAlert:[],
            vdrVsMtAlert:[]
        }
    }
}

const REDUCERS = {
    [actionDashBoardSummaryActions.GET_FUEL_CONSUMPTION_SUMMARY_WIDGET]: (state, { payload }) => {
        return {
            ...state,
            dashboardSummaryPage: {
                ...state.dashboardSummaryPage,
                fuelConsumptionSummaryWidget: payload
            }
        };
    },
    [actionDashBoardSummaryActions.GET_AVERAGE_FUEL_PRICE_SUMMARY_WIDGET]: (state, { payload }) => {
        return {
            ...state,
            dashboardSummaryPage: {
                ...state.dashboardSummaryPage,
                averageFuelPriceSummaryWidget: payload
            }
        };
    },
    [actionDashBoardSummaryActions.GET_PARTNER_REGIONS]: (state, { payload }) => {
        return {
          ...state,
          dashboardSummaryPage: {
            ...state.dashboardSummaryPage,
            partnerRegions: payload,
          },
        };
    },
    [actionDashBoardSummaryActions.GET_MASS_BALANCE_WIDGET]: (state, { payload }) => {
        return {
            ...state,
            dashboardSummaryPage: {
                ...state.dashboardSummaryPage,
                massBalanceWidget: payload
            }
        };
    },
    [actionDashBoardSummaryActions.GET_DASHBOARD_SUMMARY_ALERTS]: (state, { payload }) => {
        return {
            ...state,
            dashboardSummaryPage: {
                ...state.dashboardSummaryPage,
                dashboardSummaryAlerts: payload
            }
        };
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, { type, payload, error }) => {
    if (REDUCERS[type]) {
      return REDUCERS[type](state, { payload, error })
    }
    return state
}
  
export const DASHBOARD_SUMMARY_PAGE_REDUCER = 'dashboardSummaryPageReducer';