import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const body = document.getElementsByTagName('body')[0]
  const location = useLocation();

  useEffect(() => {
      body?.scrollTo(0, 0);
  }, [location]);

  return (null);
}

export default ScrollToTop;
