import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

const CONTROLLER_NAME = '/mooring-buoy-management'

export const getMooringBuoyManagement = (mooring_buoy, location, utilization, status, page_number, page_size) => {
  const bodyParams = {
    mooring_buoy,
    location,
    utilization,
    page_number,
    page_size,
    status
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/list`, bodyParams))
}

export const updateMooringBuoyManagement = (mooring_buoy, location, status, comment) => {
  const bodyParams = {
    mooring_buoy,
    location,
    status,
    comment
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/update`, bodyParams))
}

export const getMooringBuoyManagementFilterDropdown = () => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/filter-dropdown`))
}

