import * as explorePageActions from '../actions/actionExplore'
import _ from 'lodash'

const defaultState = {
  searchData: {
    data: '',
  },
  explorePage: {
    cleanScheduleData: [],
    unplannedLocationsData: [],
    enhancedScheduleVoyage: [],
    vesselToWorkboatLocationsData: [],
    isScheduleEditable: false,
    pastVoyage: [],
    vesselData: [],
    jobTypeDropdown: 'All',
    vesselDropdown: '',
    textDropdown: '',
    departureDropdown: '',
    idDropdown: '',
    showPlatform: true,
    showSupplyBase: true,
    showWorkBarge: true,
    showDrilling: true,
    showMooring: true,
    showWorkBoat: true,
    showWindSpeed: false,
    showWaveHeight: false,
    showTemperatures: false,
    clicked: false,
    status: '',
    vesselSpeed: '',
    cycleTime: '',
    lastUpdate: '',
    showVoyageFilter: true,
    showRatingFilter: false,
    isTripFuture: undefined,
    isTripOngoing: undefined,
    ongoingGpsPingDatetime: undefined,
    averageSpeed: undefined,
    voyageChangeLog: {},
    voyageReportLoading: false,
    voyageReport: [],
    vesselTrack: '',
    voyageId: '',
    timePointCurrentSchedule: undefined,
  },
}

const REDUCERS = {
  [explorePageActions.SET_CURRENT_VOYAGE]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        isScheduleEditable: _.get(payload, 'is_schedule_editable'),
        cleanScheduleData: _.get(payload, 'clean_schedule_data'),
        unplannedLocationsData: _.get(payload, 'unplanned_locations_data'),
        enhancedScheduleVoyage: _.get(payload, 'enhanced_schedule_data'),
        vesselToWorkboatLocationsData: _.get(payload, 'vessel_to_workboat_locations_data'),
      },
    }
  },
  [explorePageActions.SET_PLAN_VESSEL_DATA]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        vesselData: _.get(payload, 'data') || [],
        //Not setting default value as their default are undefined, which should not crash the page
        isTripFuture: _.get(payload, 'is_trip_future'),
        isTripOngoing: _.get(payload, 'is_trip_ongoing'),
        ongoingGpsPingDatetime: _.get(payload, 'ongoing_gps_ping_datetime'),
        averageSpeed: _.get(payload, 'average_speed'),
      },
    }
  },
  [explorePageActions.SET_PAST_VOYAGE]: (state, { payload }) => {
    let data
    if (!payload?.tripID) {
      data = payload.data
    } else {
      data = state.explorePage.pastVoyage
      const item = data.find((item) => item.trip_id == payload.tripID)
      item.enhanced_schedule = payload.data
    }
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        pastVoyage: data,
      },
    }
  },
  [explorePageActions.SET_RESET_EXPLORE_PAGE]: (state, { payload }) => {
    return {
      ...state,
      explorePage: defaultState.explorePage,
    }
  },
  [explorePageActions.POST_VOYAGE_SCHEDULE_CHANGE_LOG]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        voyageChangeLog: payload,
      },
    }
  },
  [explorePageActions.POST_VOYAGE_REPORT_LOADING]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        voyageReportLoading: payload,
      },
    }
  },
  [explorePageActions.POST_VOYAGE_REPORT]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        voyageReport: payload,
      },
    }
  },
  [explorePageActions.GET_VOYAGE_FEEDBACK]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        voyageFeedback: payload.data,
        trip_id: payload.trip_id
      },
    }
  },
  [explorePageActions.SET_VESSEL_TRACK]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        vesselTrack: payload
      },
    }
  },
  [explorePageActions.SET_VOYAGE_ID]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        voyageId: payload
      },
    }
  },
  [explorePageActions.SET_TIME_POINT_CURRENT_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      explorePage: {
        ...state.explorePage,
        timePointCurrentSchedule: payload
      },
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const EXPLORE_PAGE_REDUCER = 'explorePage'
