import React from 'react'
import './modal.scss'

const Modal = ({ show, children, className }) => {
  const showHideClassName = show ? 'display-block' : 'display-none'

  return (
    <div className={`modal-main-container ${className} ${showHideClassName}`}>
      <section className="modal-main">{children}</section>
    </div>
  )
}

export default Modal
