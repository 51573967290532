// import {
//   UPDATE_MCA_FCL_TABLE,
// } from '../../actions/adp/action_input'

const defaultState = {
  
  isCargoLockInErrors: false
}

const REDUCERS = {
  // [SET_IS_CARGO_LOCK_IN_ERRORS]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     isCargoLockInErrors: payload
  //   }
  // }
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const SYSTEM_REDUCER = 'system'
