import React from 'react'
import { Button, Image } from 'semantic-ui-react'
import { ReactSVG } from 'react-svg';
import './style.scss';

const Btn = (props) => {
  const { className, text, onClick, disabled, isMobile = false, icon = false, type = 'button' } = props

  return (
    <div className="button">
        <Button disabled={disabled} className={`button-custom btn-primary ${className}`} onClick={onClick} type={type}>
        <div className="button-content">
          {!isMobile && icon && (
            <ReactSVG
              src={icon?.src}
              beforeInjection={(svg) => {
                svg.classList.add(icon?.className);
              }}
            />
          )}{' '}
            {text}
            {isMobile && icon && <Image className={icon?.className} src={icon?.src} alt={icon?.alt} />}
          </div>
        </Button>
    </div>
  )
}

export default Btn
