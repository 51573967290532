
import React, { useEffect } from 'react'

import TopHeader from '../topHeader';

const AppHeader = (props) => {
    const handleResize = () => {
        const height = window.screen.height;
        const width = window.screen.width;
        height < 900 ? document.body.style.zoom = "77.5%" : width <= 1024 ? document.body.style.zoom = "77.5%" : document.body.style.zoom = "100%"
    }

    useEffect(() => {
        handleResize()
    }, [])

    window.onresize = function () {
        handleResize()
    }

   

    return (
        <>
            <TopHeader type={props.type} />
        </>
    )
}

export default AppHeader;