import * as services from '../services/serviceDashboard'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

export const POST_POWERBI_DASHBOARD_INFO = 'POST_POWERBI_DASHBOARD_INFO'
export const postPowerBIDashboardInfo = (module = 'execute', page = null, region = null, month = null, year = null) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_POWERBI_DASHBOARD_INFO))
    services
      .postPowerBIDashboardInfo(module, page, region, month, year)
      .then((response) =>
        dispatch({
          type: POST_POWERBI_DASHBOARD_INFO,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_POWERBI_DASHBOARD_INFO)))
      .catch(() => dispatch(fetchError(POST_POWERBI_DASHBOARD_INFO)))
  }
}
