import * as services from '../services/serviceMain'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

// export const POST_SEARCH_DATA = 'POST_SEARCH_DATA'
// export const setSearchData = () => {
//   return (dispatch) => {
//     dispatch(fetchStart(POST_SEARCH_DATA))
//     services
//       .postSearchData()
//       .then((response) =>
//         dispatch({
//           type: POST_SEARCH_DATA,
//           payload: response,
//         })
//       )
//       .then(() => dispatch(fetchSuccess(POST_SEARCH_DATA)))
//       .catch(() => dispatch(fetchError(POST_SEARCH_DATA)))
//   }
// }

export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const setFilterData = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_FILTER_DATA))
    services
      .getMainPageData(filterObject)
      .then((response) => {
        const { body } = response;
        if(body && typeof body === 'string') 
          response.body = JSON.parse(body);
        dispatch({
          type: SET_FILTER_DATA,
          payload: response.body,
        })
      })
      .then(() => dispatch(fetchSuccess(SET_FILTER_DATA)))
      .catch(() => dispatch(fetchError(SET_FILTER_DATA)))
  }
}

export const SET_LOCATION_DATA = 'SET_LOCATION_DATA'
export const setLocationData = () => {
  return (dispatch) => {
    dispatch(fetchStart(SET_LOCATION_DATA))
    services
      .postLocationData()
      .then((response) =>
        dispatch({
          type: SET_LOCATION_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_LOCATION_DATA)))
      .catch(() => dispatch(fetchError(SET_LOCATION_DATA)))
  }
}

export const GET_WORKBOAT_VESSELS = 'GET_WORKBOAT_VESSELS'
export const getWorkboatVessels = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_WORKBOAT_VESSELS))
    services
      .getWorkboatVessels()
      .then((response) =>
        dispatch({
          type: GET_WORKBOAT_VESSELS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_WORKBOAT_VESSELS)))
      .catch(() => dispatch(fetchError(GET_WORKBOAT_VESSELS)))
  }
}

export const SET_RESET_MAIN_PAGE = 'SET_RESET_MAIN_PAGE'
export const setResetMainPage = () => {
  return {
    type: SET_RESET_MAIN_PAGE,
    payload: [],
  }
}
