import * as services from '../../services/common/serviceUserRating'
import { fetchStart, fetchSuccess, fetchError } from './actionFetch'

export const POST_USER_RATING = 'POST_USER_RATING'
export const postUserRating = (comment, rating, npsScore, getUserEmail, getUserName, getPathname) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_USER_RATING))
    services
      .postUserRating(comment, rating, npsScore, getUserEmail, getUserName, getPathname)
      .then((response) =>
        dispatch({
          type: POST_USER_RATING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_USER_RATING)))
      .catch(() => dispatch(fetchError(POST_USER_RATING)))
  }
}