import * as services from '../../services/common/serviceUac'
import { fetchStart, fetchSuccess, fetchError } from './actionFetch'

export const POST_USER_ACCESS = 'POST_USER_ACCESS'
export const postUserAccess = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_USER_ACCESS))
    services
      .postUserAccess()
      .then((response) => {
        localStorage.setItem("timezone", response?.data?.timezone);
        dispatch({
          type: POST_USER_ACCESS,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(POST_USER_ACCESS)))
      .catch(() => dispatch(fetchError(POST_USER_ACCESS)))
  }
}

export const GET_USER_ACCESS_CONTROL_DETAIL = 'GET_USER_ACCESS_CONTROL_DETAIL'
export const getUserAccessControlDetail = (user_id) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_USER_ACCESS_CONTROL_DETAIL))
    services
      .getUserAccessControlDetail(user_id)
      .then((response) =>
        dispatch({
          type: GET_USER_ACCESS_CONTROL_DETAIL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_USER_ACCESS_CONTROL_DETAIL)))
      .catch(() => dispatch(fetchError(GET_USER_ACCESS_CONTROL_DETAIL)))
  }
}

export const GET_USERS_WITH_FEATURE_LIST = 'GET_USERS_WITH_FEATURE_LIST'
export const getUsersWithFeatureList = (arrayFeature) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_USERS_WITH_FEATURE_LIST))
    services
      .getUsersWithFeatureList(arrayFeature)
      .then((response) =>
        dispatch({
          type: GET_USERS_WITH_FEATURE_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_USERS_WITH_FEATURE_LIST)))
      .catch(() => dispatch(fetchError(GET_USERS_WITH_FEATURE_LIST)))
  }
}

export const RESET_USERS_WITH_FEATURE_LIST = 'RESET_USERS_WITH_FEATURE_LIST'
export const resetUsersWithFeatureList = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USERS_WITH_FEATURE_LIST,
      payload: null,
    })
  }
}

export const GET_USER_ACCESS_CONTROL = 'GET_USER_ACCESS_CONTROL'
export const getUserAccessControl = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_USER_ACCESS_CONTROL))
    services
      .getUserAccessControl()
      .then((response) =>
        dispatch({
          type: GET_USER_ACCESS_CONTROL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_USER_ACCESS_CONTROL)))
      .catch(() => dispatch(fetchError(GET_USER_ACCESS_CONTROL)))
  }
}

export const POST_USER_ACCESS_CONTROL = 'POST_USER_ACCESS_CONTROL'
export const postUserAccessControl = (name, email, company, role, region, location, iapPlanner, bookingFocals) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_USER_ACCESS_CONTROL))
    services
      .postUserAccessControl(name, email, company, role, region, location, iapPlanner, bookingFocals)
      .then((response) =>
        dispatch({
          type: POST_USER_ACCESS_CONTROL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_USER_ACCESS_CONTROL)))
      .catch(() => dispatch(fetchError(POST_USER_ACCESS_CONTROL)))
  }
}

export const PUT_USER_ACCESS_CONTROL = 'PUT_USER_ACCESS_CONTROL'
export const putUserAccessControl = (userID, name, email, company, role, region, location, iapPlanner, bookingFocals) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_USER_ACCESS_CONTROL))
    services
      .putUserAccessControl(userID, name, email, company, role, region, location, iapPlanner, bookingFocals)
      .then((response) =>
        dispatch({
          type: PUT_USER_ACCESS_CONTROL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_USER_ACCESS_CONTROL)))
      .catch(() => dispatch(fetchError(PUT_USER_ACCESS_CONTROL)))
  }
}

export const PUT_CURRENT_USER_ACCESS_CONTROL = 'PUT_CURRENT_USER_ACCESS_CONTROL'
export const putCurrentUserAccessControl = (userID, name, email, company, role, region, location, iapPlanner, bookingFocals) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_CURRENT_USER_ACCESS_CONTROL))
    services
      .putUserAccessControl(userID, name, email, company, role, region, location, iapPlanner, bookingFocals)
      .then((response) =>
        dispatch({
          type: PUT_CURRENT_USER_ACCESS_CONTROL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_CURRENT_USER_ACCESS_CONTROL)))
      .catch(() => dispatch(fetchError(PUT_CURRENT_USER_ACCESS_CONTROL)))
  }
}

export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER'
export const deleteAdminUser = (userID) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_ADMIN_USER))
    services
      .deleteAdminUser(userID)
      .then((response) =>
        dispatch({
          type: DELETE_ADMIN_USER,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_ADMIN_USER)))
      .catch(() => dispatch(fetchError(DELETE_ADMIN_USER)))
  }
}

export const POST_COMPANY_DROPDOWN = 'POST_COMPANY_DROPDOWN'
export const postUacDropdownCompany = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_COMPANY_DROPDOWN))
    services
      .postUacDropdownCompany()
      .then((response) =>
        dispatch({
          type: POST_COMPANY_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_COMPANY_DROPDOWN)))
      .catch(() => dispatch(fetchError(POST_COMPANY_DROPDOWN)))
  }
}

export const POST_ROLE_DROPDOWN = 'POST_ROLE_DROPDOWN'
export const postUacDropdownRole = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_ROLE_DROPDOWN))
    services
      .postUacDropdownRole()
      .then((response) =>
        dispatch({
          type: POST_ROLE_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_ROLE_DROPDOWN)))
      .catch(() => dispatch(fetchError(POST_ROLE_DROPDOWN)))
  }
}

export const POST_FEATURE_DROPDOWN = 'POST_FEATURE_DROPDOWN'
export const postUacDropdownFeature = (roleIdList) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_FEATURE_DROPDOWN))
    services
      .postUacDropdownFeature(roleIdList)
      .then((response) =>
        dispatch({
          type: POST_FEATURE_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_FEATURE_DROPDOWN)))
      .catch(() => dispatch(fetchError(POST_FEATURE_DROPDOWN)))
  }
}

export const POST_REGION_DROPDOWN = 'POST_REGION_DROPDOWN'
export const postUacDropdownRegion = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_REGION_DROPDOWN))
    services
      .postUacDropdownRegion()
      .then((response) =>
        dispatch({
          type: POST_REGION_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_REGION_DROPDOWN)))
      .catch(() => dispatch(fetchError(POST_REGION_DROPDOWN)))
  }
}

export const POST_LOCATION_DROPDOWN = 'POST_LOCATION_DROPDOWN'
export const postUacDropdownLocation = (regionIdList, companyList) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_LOCATION_DROPDOWN))
    services
      .postUacDropdownLocation(regionIdList, companyList)
      .then((response) =>
        dispatch({
          type: POST_LOCATION_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_LOCATION_DROPDOWN)))
      .catch(() => dispatch(fetchError(POST_LOCATION_DROPDOWN)))
  }
}

export const POST_IAP_PLANNER_DROPDOWN = 'POST_IAP_PLANNER_DROPDOWN'
export const postUacDropdownIapPlanner = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_IAP_PLANNER_DROPDOWN))
    services
      .postUacDropdownIapPlanner()
      .then((response) =>
        dispatch({
          type: POST_IAP_PLANNER_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_IAP_PLANNER_DROPDOWN)))
      .catch(() => dispatch(fetchError(POST_IAP_PLANNER_DROPDOWN)))
  }
}

export const GET_TENANT_LIST = 'GET_TENANT_LIST'
export const getTenantList = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_TENANT_LIST))
    services
      .getTenantsApi()
      .then((response) => 
        dispatch({
          type: GET_TENANT_LIST,
          payload: response?.data?.tenants,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_TENANT_LIST)))
      .catch(() => dispatch(fetchError(GET_TENANT_LIST)))
  }
}

export const SWITCH_COMPANY_FOR_SUPER_ADMIN = 'SWITCH_COMPANY_FOR_SUPER_ADMIN'
export const putSwitchCompanyForSuperAdmin = (companyId) => {
  return (dispatch) => {
    dispatch(fetchStart(SWITCH_COMPANY_FOR_SUPER_ADMIN))
    services
      .switchCompanyApiForSuperAdmin(companyId)
      .then((response) =>
        dispatch({
          type: SWITCH_COMPANY_FOR_SUPER_ADMIN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SWITCH_COMPANY_FOR_SUPER_ADMIN)))
      .catch(() => dispatch(fetchError(SWITCH_COMPANY_FOR_SUPER_ADMIN)))
  }
}