import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

const CONTROLLER_NAME = '/demand'

export const postViewLeadershipDashboard = (email) => {
  const bodyParams = {
    user_email: email,
  }

  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/viewLeadershipDashboard`, bodyParams))
}

export const postViewReservationEnRoute = (type, email) => {
  const bodyParams = {
    type: type,
    user_email: email,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/viewReservationEnRoute`, bodyParams))
}

export const postShipmentEnroute = () => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`widget/shipmentEnroute`))
}

export const postFleetSummary = (start_date, end_date) => {
  const bodyParams = {
    start_date: start_date,
    end_date: end_date,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`widget/fleetSummary`, bodyParams))
}

export const postFleetPerformance = (start_date, end_date) => {
  const bodyParams = {
    start_date: start_date,
    end_date: end_date,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`widget/fleetPerformance`, bodyParams))
}

export const postMooringBouyUtilization = (start_date, end_date) => {
  const bodyParams = {
    start_date: start_date,
    end_date: end_date
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`widget/mooringBouyUtilization`, bodyParams))
}

export const postReservationStatusNotification = (user_email) => {
  const bodyParams = {
    user_email: user_email,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/viewReservationStatusNotification`, bodyParams))
}