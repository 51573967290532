import * as vesselManagementPageActions from '../actions/actionVesselManagement'
import moment from 'moment'
import {GET_MASTER_LOCATION_PLATFORM_DROPDOWN} from "../actions/actionVesselManagement";

const defaultState = {
  vesselManagementPage: {
    data: {
      is_valid: false,
      message: '',
      data_by_vessels: [],
    },
    dropdownVessels: [],
    dropdownRegions: [],
    dropdownJobTypes: [],
    dropdownManagedBy: [],
    dropdownLocationList: [],
    period: {
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      key: 'selection',
    },
    actionResponse: { is_valid: false, message: '' },
    modalResponse: { action: '', open: false },
  },
}

const REDUCERS = {
  [vesselManagementPageActions.GET_VESSEL_MANAGEMENT]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        data: payload,
      },
    }
  },
  [vesselManagementPageActions.GET_VESSELS_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdownVessels: payload,
      },
    }
  },
  [vesselManagementPageActions.GET_REGIONS_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdownRegions: payload,
      },
    }
  },
  [vesselManagementPageActions.GET_JOB_TYPES_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdownJobTypes: payload,
      },
    }
  },
  [vesselManagementPageActions.GET_MASTER_LOCATION_PLATFORM_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdownLocationList: payload,
      },
    }
  },
  [vesselManagementPageActions.GET_MANAGED_BY_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdownManagedBy: payload,
      },
    }
  },
  [vesselManagementPageActions.POST_VESSEL_MANAGEMENT]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        actionResponse: payload,
      },
    }
  },
  [vesselManagementPageActions.PUT_VESSEL_MANAGEMENT]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        actionResponse: payload,
      },
    }
  },
  [vesselManagementPageActions.DELETE_VESSEL_MANAGEMENT]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        actionResponse: payload,
      },
    }
  },
  [vesselManagementPageActions.SET_MODAL_RESPONSE]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        modalResponse: payload,
      },
    }
  },
  // ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ----------
  [vesselManagementPageActions.GET_DROPDOWN_VESSEL_TYPE]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdown: {
          ...state.vesselManagementPage.dropdown,
          vesselType: payload,
        },
      },
    };
  },
  [vesselManagementPageActions.GET_DROPDOWN_VESSEL_FLAG]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdown: {
          ...state.vesselManagementPage.dropdown,
          vesselFlag: payload,
        },
      },
    };
  },
  [vesselManagementPageActions.GET_DROPDOWN_VESSEL_REGION]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdown: {
          ...state.vesselManagementPage.dropdown,
          vesselRegion: payload,
        },
      },
    };
  },
  [vesselManagementPageActions.GET_DROPDOWN_PORT_OF_ANCHORAGE]: (state, { payload }) => {
    return {
      ...state,
      vesselManagementPage: {
        ...state.vesselManagementPage,
        dropdown: {
          ...state.vesselManagementPage.dropdown,
          portOfAnchorage: payload,
        },
      },
    };
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const VESSEL_MANAGEMENT_PAGE_REDUCER = 'vesselManagementPage'
