import React from "react";

import AppHeader from "../../components/header/app-header";
import ReleaseNote from "../../components/release-note";
import SupportDesk from "../../components/support-desk";
import { Responsive } from "semantic-ui-react";

const WithMasterLayout = (ChildComponent, type, rate = true) => {
  return (
    <>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <div className="layout-master">
          <AppHeader type={type} />
          <ChildComponent type={type} />
          {rate && <SupportDesk />}
          <ReleaseNote />
        </div>
      </Responsive>
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        <div className="mobile-layout-master">
          <AppHeader type={type} />
          <ChildComponent type={type} />
          {rate && <SupportDesk />}
          <ReleaseNote />
        </div>
      </Responsive>
    </>
  );

};

export default WithMasterLayout;
