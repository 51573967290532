import _ from 'lodash'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import moment from 'moment'

const CONTROLLER_NAME = '/main'

export const getMainPageData = (filterObject) => {
  const period = _.get(filterObject, 'period')
  const region = _.get(filterObject, 'region')
  const job = _.get(filterObject, 'job')
  const vessel = _.get(filterObject, 'vessel')
  const divisionID = _.get(filterObject, 'divisionID')

  const startDate = _.map(period, (a) => moment(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment(a.endDate).format('YYYY-MM-DD')).toString()

  const functionUrl = process.env.REACT_APP_POST_VESSEL_DATA_LAMBDA_FUNCTION_URL;

  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${functionUrl}?region=${region}&job_type=${job}&vessel_name=${vessel}&from_date=${startDate}&to_date=${endDate}&division_id=${divisionID}`
  ))
}

export const postLocationData = () => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/locationData`))
}

export const getWorkboatVessels = () => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/workboatVessels`))
}

// export const postSearchData = () => {
//   return LAMBDA_API_INSTANCE().post(
//     `a41218e1-ff1b-445d-aaf6-5dcdf1ff424a`
//   );
// };
