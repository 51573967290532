import moment from 'moment'
import _ from 'lodash'
import { UNIT } from '../../../constants/userGroups'

export const getDatesInBetween = (startDate, endDate) => {
    let dates = []

    const theDate = new Date(startDate)
    const theEndDate = new Date(endDate)

    while (theDate < theEndDate) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
    }
    dates.push(theEndDate)

    return dates
}

export const getSeqDisabledDates = (index, accordions = [], minDate, departureDateKey = 'departure_date') => {
    if (index == 0 || accordions.length == 0) {
        return []
    }

    const prevSeqAccordion = accordions[index - 1]
    const prevDepartureDate = _.get(prevSeqAccordion, departureDateKey)
    const datesInBetween = getDatesInBetween(minDate, prevDepartureDate)
    const disabledDates = _.filter(datesInBetween, (item) => {
        return moment(item).format('YYYY-MM-DD') !== moment(prevDepartureDate).format('YYYY-MM-DD')
    })

    return disabledDates
}

export const getCurrentPickerDisabledDates = (index, voyageTravelPathData = [], minDate, dateKey = 'departure_date') => {
    if (index === 0 || voyageTravelPathData.length === 0) {
        return []
    }

    const currCheckpointData = voyageTravelPathData[index]
    const prevCheckpointData = voyageTravelPathData[index - 1]
    var prevDate = moment()._d

    if (_.includes(dateKey, 'departure')) {
        const prevDateKey = _.includes(dateKey, 'planned_') ? 'planned_arrival_date' : 'arrival_date'
        prevDate = _.get(currCheckpointData, prevDateKey)
    } else {
        const prevDateKey = _.includes(dateKey, 'planned_') ? 'planned_departure_date' : 'departure_date'
        prevDate = _.get(prevCheckpointData, prevDateKey)
    }

    const datesInBetween = getDatesInBetween(minDate, prevDate)
    const disabledDates = _.filter(datesInBetween, (item) => {
        return moment(item).format('YYYY-MM-DD') !== moment(prevDate).format('YYYY-MM-DD')
    })

    return disabledDates
}

export const getExistingVoyageDates = (currentVesselId = '', chartData = {}, currentVoyageId = '') => {
    const filteredTransformData = (_.get(chartData, 'data')).length > 0 ? _.find(_.get(chartData, 'data'), (item) => item.vessel_id === currentVesselId) : {}
    const currentVesselVoyages = (_.isUndefined(filteredTransformData) || _.isEmpty(filteredTransformData)) ? [] : _.get(filteredTransformData, 'vessel_type')
    const currentVesselOtherVoyages = (_.isUndefined(currentVesselVoyages) || _.isEmpty(currentVesselVoyages)) ? [] : _.filter(currentVesselVoyages, (item) => item.voyage_id !== currentVoyageId)

    const otherVoyagesDates = _.map(_.reduce(_.map(currentVesselOtherVoyages, (mapItem) => { return mapItem.vessel_detail }), (acc, curr) => { return acc.concat(curr) }), (mapItem2) => { return mapItem2.start_date })
    const otherVoyagesDatesUniq = _.uniq(otherVoyagesDates)
    const otherVoyagesDatesUniqFormatted = _.map(otherVoyagesDatesUniq, (item) => moment(item)._d)

    return otherVoyagesDatesUniqFormatted
}

const getCurrentVoyageDisabledDates = (index = 0, voyageTravelPathData = [], startDate, dateKey, currentVoyageId, chartData = {}, vesselList = [], currentVessel = '') => {
    const currentVesselObj = _.find(vesselList, (item) => _.toLower(item.vessel_name) === _.toLower(currentVessel))
    const currentVesselId = _.isUndefined(currentVesselObj) ? '' : _.get(currentVesselObj, 'vessel_id')

    // const disabledDates1 = getSeqDisabledDates(index, accordions, startDate, departureDateKey)
    const disabledDates1 = getCurrentPickerDisabledDates(index, voyageTravelPathData, startDate, dateKey)
    const disabledDates2 = getExistingVoyageDates(currentVesselId, chartData, currentVoyageId)

    const allDisabledDates = disabledDates1.concat(disabledDates2)

    return allDisabledDates
}

export const handleCustomDropdownSearch = (dropdownItems = [], searchInput = '', searchType = 'startwith') => {
    const searchInputLowerCase = _.toLower(searchInput)
    var regExPattern = ''

    if (_.toLower(searchType) === 'startwith') {
        regExPattern = `^${searchInputLowerCase}`
    }

    const validSearch = new RegExp(regExPattern)

    return _.filter(dropdownItems, (item) => { return validSearch.test(_.toLower(item.value)) })
}

export const calculateTotalJourneyUpperDeck = (accordions = [], type = 'payload', isImperial = false) => {
    const journeyUpperList = _.reduce(
        _.map(
            _.filter(accordions, (filterItem, filterIndex) => (filterIndex > 0 && (filterIndex < accordions.length - 1))),
            (mapItem) => _.get(mapItem, `${type}_upper_deck`)),
        (acc, currVal) => {
            if (acc)
                return acc.concat(currVal)
        })

    const journeyUpperSum = _.reduce(
        _.map(journeyUpperList, (mapItem) => _.toNumber(mapItem.value)),
        (acc, currVal) => { return acc + currVal })

    return [{
        cargo_type: 'Upper Deck Cargo',
        value: `${journeyUpperSum}`,
        uom: isImperial ? UNIT.FT2 : UNIT.M2
    }]
}

export const calculateTotalJourneyUnderDeck = (accordions = [], type = 'payload', isImperial = false) => {
    const journeyUnderList = _.reduce(
        _.map(
            _.filter(accordions, (filterItem, filterIndex) => (filterIndex > 0 && (filterIndex < accordions.length - 1))),
            (mapItem) => _.get(mapItem, `${type}_under_deck`)),
        (acc, currVal) => {
            if (acc)
                return acc.concat(currVal)
        })

    const journeyUnderListGrouped = _.groupBy(journeyUnderList, (item) => _.toLower(item.cargo_type))

    const journeyUnderSum = _.map(journeyUnderListGrouped, (value, key) => {
        const mappedValue = _.map(value, (mapItem) => _.toNumber(mapItem.value))
        const totalValue = _.reduce(mappedValue, (acc, currVal) => { return acc + currVal })
        const uom = value.length > 0 ? _.get(value[0], 'uom') : ( isImperial ? UNIT.GAL : UNIT.KL )

        return ({ value: totalValue, cargo_type: _.startCase(key), uom: uom })
    })

    return journeyUnderSum
}

export const calculateTotalJourneyCargoes = (accordions = [], isImperial = false) => {
    const payloadUpperDeckSum = calculateTotalJourneyUpperDeck(accordions, 'payload', isImperial)
    const backloadUpperDeckSum = calculateTotalJourneyUpperDeck(accordions, 'backload', isImperial)
    const payloadUnderDeckSum = calculateTotalJourneyUnderDeck(accordions, 'payload', isImperial)
    const backloadUnderDeckSum = calculateTotalJourneyUnderDeck(accordions, 'backload', isImperial)

    return {
        payload_upper_deck: payloadUpperDeckSum,
        payload_under_deck: payloadUnderDeckSum,
        backload_upper_deck: backloadUpperDeckSum,
        backload_under_deck: backloadUnderDeckSum,
    }
}

export const totalCargoCapacityValidation = (accordions = [], cargoCapacity = [], loadType = 'payload') => {
    const payloadUpperDeckSum = calculateTotalJourneyUpperDeck(accordions, 'payload')
    const backloadUpperDeckSum = calculateTotalJourneyUpperDeck(accordions, 'backload')
    const payloadUnderDeckSum = calculateTotalJourneyUnderDeck(accordions, 'payload')
    const backloadUnderDeckSum = calculateTotalJourneyUnderDeck(accordions, 'backload')

    const upperDeckCapacityObj = _.find(cargoCapacity, (item) => { return _.toLower(item.cargo_name) === 'upper deck cargo' })
    const upperDeckCapacity = _.isUndefined(upperDeckCapacityObj) ? 0 : _.get(upperDeckCapacityObj, 'cargo_capacity')

    let upperDeckHasError = false
    let underDeckHasErrorList = []

    if (_.toLower(loadType) === 'payload') {
        //Payload Cargo Capacity Validation Logic
        const totalUpperDeck = _.toNumber(_.get(payloadUpperDeckSum[0], 'value'))
        upperDeckHasError = totalUpperDeck > _.toNumber(upperDeckCapacity)

        underDeckHasErrorList = _.map(payloadUnderDeckSum, (item) => {
            const currCargoCapacityObj = _.find(cargoCapacity, (findItem) => { return _.toLower(findItem.cargo_name) === _.toLower(item.cargo_type) })
            const currCargoCapacity = _.isUndefined(currCargoCapacityObj) ? 0 : _.get(currCargoCapacityObj, 'cargo_capacity')

            return item.value > _.toNumber(currCargoCapacity)
        })
    } else {
        //Backload Cargo Capacity Validation Logic
        const totalUpperDeck = _.toNumber(_.get(backloadUpperDeckSum[0], 'value'))
        upperDeckHasError = totalUpperDeck > _.toNumber(upperDeckCapacity)

        underDeckHasErrorList = _.map(backloadUnderDeckSum, (item) => {
            const currCargoCapacityObj = _.find(cargoCapacity, (findItem) => { return _.toLower(findItem.cargo_name) === _.toLower(item.cargo_type) })
            const currCargoCapacity = _.isUndefined(currCargoCapacityObj) ? 0 : _.get(currCargoCapacityObj, 'cargo_capacity')

            return item.value > _.toNumber(currCargoCapacity)
        })
    }

    const cargoesHasError = _.includes(underDeckHasErrorList.concat([upperDeckHasError]), true)

    return cargoesHasError
}

export const getMaxSeats = (vesselCapacity = []) => {
    const seatsCapacityObject = vesselCapacity.length > 0 ? vesselCapacity[0] : {}
    return seatsCapacityObject?.base_seats || 0
}

export const totalSeatsCapacityValidation = (accordions = [], seatsCapacity = []) => {
    const maxSeats = getMaxSeats(seatsCapacity)

    const originLocationObject = _.find(accordions, (item, index) => { return index === 0 })
    const destinationLocationObject = _.find(accordions, (item, index) => { return index === (accordions.length - 1) })

    const totalIncomingSeats = _.isUndefined(originLocationObject) ? 0 : _.get(originLocationObject, 'incoming_seats')
    const totalOutgoingSeats = _.isUndefined(destinationLocationObject) ? 0 : _.get(destinationLocationObject, 'outgoing_seats')

    const incomingExceedMax = _.toNumber(totalIncomingSeats) > _.toNumber(maxSeats)
    const outgoingExceedMax = _.toNumber(totalOutgoingSeats) > _.toNumber(maxSeats)

    return (incomingExceedMax || outgoingExceedMax)
}

export const isDateTime = (dateString = '') => {
    if (!_.isEmpty(dateString)) {
        if (dateString.length > 10) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const dateCanEnable = (enableDate) => {
    return enableDate.reduce((arr, item) => {
        return arr.concat(getDatesInBetween(item.contract_start_date, item.contract_end_date));
    }, []).map(item => moment(item).format("YYYY-MM-DD"));
};

export const getDisabledDatesByContract = (vesselContract) => {
    const dateMinToMax = getDatesInBetween(
        new Date(vesselContract.vesselContractPeriod.start_date),
        new Date(vesselContract.vesselContractPeriod.end_date)
    );
    return dateMinToMax.filter(item => {
        const isDateExists =  dateCanEnable(vesselContract.contractList).includes(moment(item).format("YYYY-MM-DD"))
        return !isDateExists;
    })
};

export const getVesselDisabledDates = (ganttData = [], vesselId = '', voyageId = '', vesselDisabledDates = []) => {
    const currVesselGanttData = _.find(ganttData, (item) => (_.toNumber(item.vessel_id) === _.toNumber(vesselId))) || {}
    const currVesselVoyages = _.get(currVesselGanttData, 'vessel_sched') || []
    const currVesselVoyagesFiltered = _.filter(currVesselVoyages, (item) => {
        const voyId = _.get(item, 'voyage_id') || ''
        return _.toLower(voyId) !== _.toLower(voyageId)
    })

    // if(_.isEmpty(currVesselGanttData) && !_.isEmpty(vesselDisabledDates)) {
    //     return vesselDisabledDates;
    // }

    const voyagesOccupiedDates = _.reduce(_.map(currVesselVoyagesFiltered, (mapItem) => {
        return getDatesInBetween(mapItem.start_date, mapItem.end_date).slice(0, -1)
    }), (acc = [], curr) => {
        return acc.concat(curr)
    })

    if (!_.isArray(voyagesOccupiedDates)) {
        return vesselDisabledDates
    }

    return voyagesOccupiedDates.concat(vesselDisabledDates)
}

export const getMaintenance = (chartData = {}, vesselName = '') => {
    const maintenanceArray = _.get(chartData, 'maintenance') || []

    const filterMaintenance = _.filter(maintenanceArray, {
        vessel_name: vesselName,
    })

    return _.isEmpty(filterMaintenance) ? [] : filterMaintenance[0].maintenance_detail
}

export const getGroupOccupiedDates = (ganttData = [], currentVesselId) => {
    const vesselInfo = ganttData.find((item) => item.vessel_id === currentVesselId);
    if (vesselInfo) {
      let groups = [];
      vesselInfo.vessel_sched.forEach((item) => {
        groups.push([item.start_date_raw, item.end_date_raw]);
      });
      return groups;
    }
    return [];
  };

export const checkBetween = (occupiedDateTimes = [], checkTime) =>
    !_.isEmpty(
      occupiedDateTimes.find((item) => {
        return moment(checkTime).isBetween(...item);
      })
    );