import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'

// Components
import {
  Rating,
  Form,
  TextArea,
  Button
} from 'semantic-ui-react'

import Btn from '../../../../components/button'

// Assets
import CLOSE_ICON from '../../../../assets/images/close.svg'
import WARNING_ICON from '../../../../assets/images/warning-circle.svg'
import CustomSmallPopup from '../../../custom-small-popup'

const ModalContentRateCompletedTrip = (props) => {
    const { setIsOpenModal, data, onClickSave, setFilterTripIDtoExplorePage, isMobile = false, isOpenModal = false } = props
    const [userRating, setUserRating] = useState(0)
    const [userComment, setUserComment] = useState('')
    useEffect(() => {
      setUserComment(data.user_comment)
      setUserRating(data.user_rating ? data.user_rating : 0)
    }, [data])
  
    return (isMobile ? 
        <CustomSmallPopup
          title = {'Rate Completed Trip'}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          styles={{
            // container: {
            //   borderRadius: '10px 10px 0px 0px',
            //   background: 'var(--queen-blue-25, #EBF0F5)',
            // },
            title: {
              color: 'var(--queen-blue-300-main, #4B7093)',
              fontFamily: 'Inter',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px',
            }
          }}
        >
          <div className='rate-completed-trip-container'>
            <div className='row-1'>
              <ReactSVG src={WARNING_ICON} />
              <Link to={'/main-explore'} onClick={setFilterTripIDtoExplorePage}>
                <div className={`rate-modal-sub-title`}>
                  {data.vessel_name + ' | ' + data.actual_arrival + ' | ' + data.location}
                </div>
              </Link>
            </div>
            <div className='row-2'>
              <div className={`planned-label`}>Planned</div>
              <div className='container planned-vit'>
                <div className='title vit-title'>Planned VIT:</div>
                <div className='value vit-value'>{data.planned_vessel_idle_time}</div>
              </div>
              <div className='container planned-pct'>
                <div className='title pct-title'>Planned Cycle Time:</div>
                <div className='value pct-value'>{data.planned_cycle_time}</div>
              </div>
              <div className={`actual-label`}>Actual</div>
              <div className='container actual-vit'>
                <div className='title vit-title'>Actual VIT:</div>
                <div className='value vit-value'>{data.actual_vessel_idle_time}</div>
              </div>
              <div className='container actual-pct'>
                <div className='title pct-title'>Actual Cycle Time:</div>
                <div className='value pct-value'>{data.actual_cycle_time}</div>
              </div>
              <div className='container actual-sa'>
                <div className='title sa-title'>Scheduled Adherence:</div>
                <div className='value sa-value'>{data.scheduled_adherence}</div>
              </div>
            </div>
            <div className='row-3'>
              <div className={`rating-title`}>Rating</div>
              <Rating
                icon="star"
                rating={userRating}
                maxRating={5}
                size="huge"
                onRate={(e, { rating }) => setUserRating(rating)}
              />
            </div>
            <div className='row-4'>
              <div className={`comment-title`}>Additional Comments</div>
              <Form className='comment-box'>
                <TextArea
                  placeholder="Leave your comment here..."
                  value={!_.isEmpty(userComment) ? userComment : ''}
                  onChange={(e) => setUserComment(e.target.value)}
                  rows={7}
                />
              </Form>
            </div>
            <div className='row-5'>
              {/* <Btn
                className={`mar-bottom-1`}
                primary={'primary'}
                text={'SAVE AS DRAFT'}
                onClick={() => onClickSave(data, userRating, userComment)}
              /> */}
              <div className='btn-container'>
                <Button
                  className={'btn-cancel'}
                  text={'Cancel'}
                  onClick={() => setIsOpenModal(false)}
                >
                  <p className='value'>Cancel</p>
                </ Button>
                <Button
                  className={'btn-submit'}
                  onClick={() => onClickSave(data, userRating, userComment)}
                >
                  <p className='value'>Submit</p>
                </Button>
              </div>
            </div>
          </div>
        </CustomSmallPopup>
        :
        <div className={`mar-1 pad-x-1`}>
        <div className={`rate-modal-body pad-y-1`}>
          <div className={`flex`}>
            <div className={`rate-modal-title mar-bottom-2`}>Rate Completed Trip</div>
            <div className={`close-icon-modal`}>
              <ReactSVG src={CLOSE_ICON} onClick={() => setIsOpenModal(false)} />
            </div>
          </div>
          <div className={`flex mar-bottom-1_5`}>
            <ReactSVG src={WARNING_ICON} />
            <Link to={'/main-explore'} onClick={setFilterTripIDtoExplorePage}>
              <div className={`rate-modal-sub-title`}>
                {data.vessel_name + ' | ' + data.actual_arrival + ' | ' + data.location}
              </div>
            </Link>
          </div>
          <div className={`flex space-between`}>
            <div className={`flex column`}>
              <div className={`rate-content-label mar-bottom-1`}>Planned</div>
              <div className={`rate-content-details`}>Planned VIT:</div>
              <div className={`rate-content-details`}>Planned Cycle Time:</div>
            </div>
            <div className={`planned-column flex column`}>
              <div className={`rate-content-details`}>{data.planned_vessel_idle_time}</div>
              <div className={`rate-content-details`}>{data.planned_cycle_time}</div>
            </div>
            <div className={`flex column`}>
              <div className={`rate-content-label mar-bottom-1`}>Actual</div>
              <div className={`rate-content-details`}>Actual VIT:</div>
              <div className={`rate-content-details`}>Actual Cycle Time:</div>
              <div className={`rate-content-details`}>Scheduled Adherence:</div>
            </div>
            <div className={`trip-details flex column`}>
              <div className={`rate-content-details`}>{data.actual_vessel_idle_time}</div>
              <div className={`rate-content-details`}>{data.actual_cycle_time}</div>
              <div className={`rate-content-details`}>{data.scheduled_adherence}</div>
            </div>
          </div>
          <div className={`rate-trip-title mar-top-1 mar-bottom-0_5`}>Rate this trip</div>
          <Rating
            icon="star"
            rating={userRating}
            maxRating={5}
            size="huge"
            onRate={(e, { rating }) => setUserRating(rating)}
          />
          <div className={`rate-trip-title mar-top-1 mar-bottom-0_5`}>Additional Comments</div>
          <Form>
            <TextArea
              value={!_.isEmpty(userComment) ? userComment : ''}
              onChange={(e) => setUserComment(e.target.value)}
            />
          </Form>
        </div>
        <div>
          <Btn
            className={`mar-bottom-1`}
            primary={'primary'}
            text={'SAVE AS DRAFT'}
            onClick={() => onClickSave(data, userRating, userComment)}
          />
        </div>
      </div>)
}

export default ModalContentRateCompletedTrip