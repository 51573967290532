import * as kpiPageActions from '../actions/actionKpi'

import { startOfMonth, endOfMonth } from 'date-fns'

const defaultState = {
  kpiPage: {
    chart: [],
    line: [],
    selected: 'co2_emission',
    region: 'PMA',
    location: 'All',
    job: 'All',
    vessel: 'All',
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  },
}

const REDUCERS = {
  [kpiPageActions.SET_FILTER_DATA]: (state, { payload }) => {
    return {
      ...state,
      kpiPage: {
        ...state.kpiPage,
        chart: payload,
      },
    }
  },
  [kpiPageActions.SET_KPI_LINE_CHART]: (state, { payload }) => {
    return {
      ...state,
      kpiPage: {
        ...state.kpiPage,
        line: payload,
      },
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const KPI_PAGE_REDUCER = 'kpiPage'
