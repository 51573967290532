import { useEffect } from 'react'

// Hooks
import { useStateWithPaths } from './useConnect';

// Reducers
import { HEADER_TITLE_REDUCER } from '../reducers/common/headerTitleReducer';
import { UAC_REDUCER } from '../reducers/common/uacReducer';

const useListenSocketEvent = (data, isEnableSocket = true) => {
  const [headerTitleData] = useStateWithPaths([`${HEADER_TITLE_REDUCER}`]);
  const [uacData] = useStateWithPaths([`${UAC_REDUCER}`]);

  const divisionCode = uacData?.uac?.data?.division_id;
  const socketConnection = headerTitleData?.socketConnection;

  useEffect(() => {
    if (socketConnection && divisionCode && isEnableSocket) {
      data?.forEach(element => {       
        socketConnection.on(element?.eventName, (param) => {
          console.log(`Socket Update [${element?.eventName}]:`, param)
          element?.handlerFn(param)
        })
      });
    }

    return () => {
      if (socketConnection) {
        data?.forEach(element => {          
          socketConnection.off(element?.eventName)
        });
      }
    }
  }, [socketConnection, divisionCode])

  return socketConnection
}

export default useListenSocketEvent