import * as services from "../services/serviceVOD";

import { fetchError, fetchStart, fetchSuccess } from "./common/actionFetch";

export const GET_LOCATION_LIST = "GET_LOCATION_LIST";
export const getLocationList = (query, offset, limit) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_LIST));
    services
      .getLocationListApi(query, offset, limit)
      .then((response) =>
        dispatch({
          type: GET_LOCATION_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LOCATION_LIST)))
      .catch(() => dispatch(fetchError(GET_LOCATION_LIST)));
  };
};

export const GET_LOCATION_DETAIL = "GET_LOCATION_DETAIL";
export const getLocationDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_DETAIL));
    services
      .getLocationDetailApi(id)
      .then((response) =>
        dispatch({
          type: GET_LOCATION_DETAIL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LOCATION_DETAIL)))
      .catch(() => dispatch(fetchError(GET_LOCATION_DETAIL)));
  };
};

export const GET_LOCATION_FILE_DOWNLOAD = "GET_LOCATION_FILE_DOWNLOAD";
export const getLocationFileDownload = (id) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_FILE_DOWNLOAD));
    return services
      .getLocationFileDownloadApi(id)
      .then((response) => {
        dispatch(fetchSuccess(GET_LOCATION_FILE_DOWNLOAD));
        return response;
      })
      .catch(() => dispatch(fetchError(GET_LOCATION_FILE_DOWNLOAD)));
  };
};

export const PUT_LOCATION = "PUT_LOCATION";
export const putLocation = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_LOCATION));
    return services
      .putLocationApi(bodyParams)
      .then((response) => {
        dispatch(fetchSuccess(PUT_LOCATION));
        return response;
      })
      .catch(() => {
        dispatch(fetchError(PUT_LOCATION));
      });
  };
};


export const DELETE_LOCATION = "DELETE_LOCATION";
export const deleteLocation = (locationId) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_LOCATION));
    return services
      .deleteLocationApi(locationId)
      .then((response) => {
        dispatch(fetchSuccess(DELETE_LOCATION));
        return response;
      })
      .catch(() => {
        dispatch(fetchError(DELETE_LOCATION));
      });
  };
};

export const POST_LOCATION = "POST_LOCATION";
export const postLocation = (bodyParams) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_LOCATION));
    return services
      .postLocationApi(bodyParams)
      .then((response) => {
        dispatch(fetchSuccess(POST_LOCATION));
        return response;
      })
      .catch(() => {
        dispatch(fetchError(POST_LOCATION));
      });
  };
};

export const GET_LOCATION_REGION_DROPDOWN = "GET_LOCATION_REGION_DROPDOWN";
export const getLocationRegionDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_REGION_DROPDOWN));
    services
      .getLocationPageDropdownApi("location-region")
      .then((response) =>
        dispatch({
          type: GET_LOCATION_REGION_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LOCATION_REGION_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_LOCATION_REGION_DROPDOWN)));
  };
};

export const GET_LOCATION_TYPE_DROPDOWN = "GET_LOCATION_TYPE_DROPDOWN";
export const getLocationTypeDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_TYPE_DROPDOWN));
    services
      .getLocationPageDropdownApi("location-type")
      .then((response) =>
        dispatch({
          type: GET_LOCATION_TYPE_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LOCATION_TYPE_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_LOCATION_TYPE_DROPDOWN)));
  };
};

export const GET_LOCATION_COUNTRY_DROPDOWN = "GET_LOCATION_COUNTRY_DROPDOWN";
export const getLocationCountryDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_COUNTRY_DROPDOWN));
    services
      .getLocationPageDropdownApi("country")
      .then((response) =>
        dispatch({
          type: GET_LOCATION_COUNTRY_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LOCATION_COUNTRY_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_LOCATION_COUNTRY_DROPDOWN)));
  };
};
