import { LAMBDA_API_INSTANCE } from '../serverInstances/lambdaServer'

const CONTROLLER_NAME = '/general'

export const getUserGuideList = () => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/getUserGuideList`))
}

export const postUserGuide = (fileName) => {
  const bodyParams = {
    file_name: fileName
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/postUserGuide`, bodyParams))
}