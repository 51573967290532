import moment from "moment";
import momentTimezone from "moment-timezone";

const DEFAULT_TIMEZONE = 'Asia/Kuala_Lumpur';
const timezone = localStorage.getItem('timezone') ?? 'Asia/Kuala_Lumpur';

export const parseTimezone = (date) => {

    // Considering UTC time as MYT time (Through APIs, MYT time is receiving as zero timezone/Z)
    // date = date?.toString();
    // date = date?.replace('Z', '');
    const myt = moment.tz(date, DEFAULT_TIMEZONE);

    if (timezone) {
        const convertedTime = momentTimezone(myt).tz(timezone);
        return convertedTime;
    } else {
        // If the timezone is not provided in the DB, showing the MYT time.
        return momentTimezone(myt).tz(DEFAULT_TIMEZONE);
    }
}

export const formatTimezone = (date) => {
    const dateWithoutZ = date && typeof date === 'string' && date.endsWith('Z') ? date.slice(0, -1) : date;

    return dateWithoutZ ? moment.tz(dateWithoutZ, timezone) : moment.tz('1900-01-01', timezone);
};
