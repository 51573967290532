import React from "react";
import { Modal } from "semantic-ui-react";
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'

const CustomSmallPopup = ({ title, isOpen, setIsOpen, children, styles = {} }) => {
  return (
    <Modal
      onClose={() => setIsOpen(false)}
      open={isOpen}
      style={{
        top: 180,
        width: "90%",
        borderRadius: "10px",
      }}
      className="custom-small-modal"
      dimmer={true}
    >
      <Modal.Content>
        <div className="custom-small-modal-header flex space-between width-100" style={{ ...styles?.container }}>
          <span className="title" style={{ ...styles?.title }}>{title}</span>
          <CloseIcon className="close-icon" onClick={() => setIsOpen(false)} />
        </div>
        <div className="custom-small-modal-header">{children}</div>
      </Modal.Content>
    </Modal>
  );
};

export default CustomSmallPopup;
