// import {
//   validateAPIToken,
// } from '../../services/common/serviceSession'

export const PERSONAL_SWITCH_PERSONA = 'PERSONAL_SWITCH_PERSONA'
export const switchPersona = (switchToPersona) => {
  return dispatch => {
    dispatch({
      type: PERSONAL_SWITCH_PERSONA,
      payload: switchToPersona
    })
  }
}

export const PERSONAL_SWITCH_PERSONA_MODAL = 'PERSONAL_SWITCH_PERSONA_MODAL'
export const switchPersonaModal = (isShowModal) => {
  return dispatch => {
    dispatch({
      type: PERSONAL_SWITCH_PERSONA_MODAL,
      payload: isShowModal
    })
  }
}
