import React, { useEffect } from 'react';
import { Grid, Rating } from 'semantic-ui-react'
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'
import { useActions, useStateWithPaths } from "../../hooks/useConnect"
import * as HeaderTitleActions from '../../actions/common/actionHeaderTitle'

const FullScreenPopUP = ({ 
    headerTitle=null,
    title, 
    onClickCloseBtn, 
    children, 
    isHeaderControl=false 
}) => {
    const {
        pushHeaderTitleAndCallBack,
        popHeaderTitleAndCallBack,
    } = useActions({ 
        pushHeaderTitleAndCallBack: HeaderTitleActions.pushHeaderTitleAndCallBack,
        popHeaderTitleAndCallBack: HeaderTitleActions.popHeaderTitleAndCallBack
    })

    const callBackHandler = () => {
        onClickCloseBtn(false);
        popHeaderTitleAndCallBack();
    }

    useEffect(() => {
        pushHeaderTitleAndCallBack && 
        pushHeaderTitleAndCallBack(headerTitle || title, callBackHandler)
    }, [])
    return (
        <div className="full-screen-popup">
            <div className='content'>
                <Grid >
                    <Grid.Row>
                        <Grid.Column width={10} className='left-box'>
                            <h5 className="title">{title}</h5>
                        </Grid.Column>
                       {!isHeaderControl && <Grid.Column width={6} style={{ textAlign: 'end' }}>
                            <CloseIcon className="close-icon" onClick={() => {
                                callBackHandler(false);
                            }} />
                        </Grid.Column>}
                    </Grid.Row>
                </Grid>
                <div>
                    {children} 
                </div>
            </div>
        </div>
    );
};

export default FullScreenPopUP;
