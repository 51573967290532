export const EXTERNAL_SUPPLY_RUN_TYPE = Object.freeze({
  SUPPLY_TO_PLATFORM: { label: "Supply to Platform*", objKey: "supplyToPlatform" },
  SUPPLY_TO_RIG: {
    label: "Supply to Rig / JU / Semi Sub / Drill Ship* Naga4",
    objKey: "supplyToRig",
  },
  AWB: { label: "AWB", objKey: "awb" },
  GPV: { label: "GPV 1", objKey: "gpv" },
  FCB: { label: "FCB 1", objKey: "fcb" },
  AHTS: { label: "AHTS 1", objKey: "ahts" },
});

export const PB_PAGENAME = {
  FUEL_CONSUMPTION_CO2_SUMMARY: 'FuelConsumptionCO2Summary',
  DAILY_FUEL_MONITORING: 'DailyFuelMonitoring',
  SPEED_MONITORING: 'SpeedMonitoring'
};