import _ from 'lodash'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import moment from 'moment'

const CONTROLLER_NAME = '/kpi'

export const postKpiChart = (filterObject) => {
  const period = _.get(filterObject, 'period')
  const startDate = _.map(period, (a) => moment.utc(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment.utc(a.endDate).format('YYYY-MM-DD')).toString()
  const bodyParams = {
    region: _.get(filterObject, 'region'),
    starting_location: _.get(filterObject, 'location'),
    job_type: _.get(filterObject, 'job'),
    vessel_name: _.get(filterObject, 'vessel'),
    start_date: startDate,
    end_date: endDate,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/chart`, bodyParams))
}

export const postKpiLineChart = (filterObject) => {
  const period = _.get(filterObject, 'period')
  const startDate = _.map(period, (a) => moment.utc(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment.utc(a.endDate).format('YYYY-MM-DD')).toString()
  const bodyParams = {
    type: _.get(filterObject, 'selected'),
    region: _.get(filterObject, 'region'),
    starting_location: _.get(filterObject, 'location'),
    job_type: _.get(filterObject, 'job'),
    vessel_name: _.get(filterObject, 'vessel'),
    start_date: startDate,
    end_date: endDate,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/lineChart`, bodyParams))
}
