import * as reservationPageActions from "../actions/actionReservation";
import {
  defineTab,
  PERIOD_OF_WEEK,
  RESERVATION_STATUS_FOR_INACTIVE
} from "../constants/reservation";

const defaultState = {
  reservationPage: {
    dropdown: {
      projectName: [],
      discipline: [],
      location: [],
      demandType: [],
      cargoType: [],
      cargoUom: [],
      iap: [],
    },
    viewMatchingVoyages: [],
    saveSpecialRequestResponse: [],
    saveReservationResponse: [],
    updateReservationResponse: [],
    validateReservedCapacityResponse: {},
    showConfirmDemandModal: false,
    newReservationPage: "searchVoyage",
    isSaveReservationHubNotify: false,
    isShowIAPPlannerModal: false,
  },
  reservationViewPage: {
    isEdit: false,
    reservationListData: {
      reservation_list: [],
      total_reservation: 0,
    },
    originalVoyage: [],
    triggerViewedReservationResponse: [],
    downloadReservationDetails: [],
    deleteReservationResponse: [],
    deleteSpecialReservationResponse: [],
    editReservation: [],
    viewFinalizedItinerary: [],
    trackVoyage: {},
    saveBookingDetails: [],
    isGoBackEdit: false,
    modal: { action: "", open: false },
    isUpdateReservationFromHub: {},
    filterOptions: {
      filterStatus: undefined,
      filterStartLocation: undefined,
      filterEndLocation: undefined,
      filterStartDate: undefined,
      filterEndDate: undefined,
      searchInput: "",
    },
    dropdown: {
      start_location: [],
      end_location: [],
    },
    activePage: 1,
    tabActive: 0,
    filterType: "All",
    inactiveTabStatus: RESERVATION_STATUS_FOR_INACTIVE.completed,
    isGoThroughVoyagePage: false,
    sortByDepartureDate: 1,
    calendarFilter: {
      isShowAll: true,
      period: [
        {
          ...PERIOD_OF_WEEK,
          key: "selection",
        },
      ],
    },
  },
  reservationIapApprovalPage: {
    updateApprovalStatusResponse: [],
  },
  reservationAllocateVesselPage: {
    viewVoyageList: [],
    viewVoyageDetails: [],
  },
};

const REDUCERS = {
  [reservationPageActions.RESET_RESERVATION_PAGE]: (state) => {
    return {
      ...state,
      reservationPage: defaultState.reservationPage,
    };
  },
  [reservationPageActions.RESET_RESERVATION_VIEW_PAGE]: (state) => {
    return {
      ...state,
      reservationViewPage: defaultState.reservationViewPage,
    };
  },
  [reservationPageActions.RESET_RESERVATION_DATA]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        viewMatchingVoyages: payload,
      },
      reservationViewPage: {
        ...state.reservationViewPage,
        editReservation: payload,
        isEdit: false,
      },
    };
  },
  [reservationPageActions.RESET_RESERVATION_LIST]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        reservationListData: {
          ...state.reservationViewPage.reservationListData,
          reservation_list: [],
        },
      },
    };
  },
  [reservationPageActions.SET_IS_EDIT]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        isEdit: payload,
      },
    };
  },
  [reservationPageActions.GET_PROJECT_INPUT_DEMAND_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        dropdown: {
          ...state.reservationPage.dropdown,
          projectName: payload,
        },
      },
    };
  },
  [reservationPageActions.GET_DISCIPLINE_INPUT_DEMAND_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        dropdown: {
          ...state.reservationPage.dropdown,
          discipline: payload,
        },
      },
    };
  },
  [reservationPageActions.GET_LOCATION_INPUT_DEMAND_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        dropdown: {
          ...state.reservationPage.dropdown,
          location: payload,
        },
      },
    };
  },
  [reservationPageActions.GET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        dropdown: {
          ...state.reservationPage.dropdown,
          demandType: payload,
        },
      },
    };
  },
  [reservationPageActions.GET_CARGO_SPECIAL_REQUEST_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        dropdown: {
          ...state.reservationPage.dropdown,
          cargoType: payload,
        },
      },
    };
  },
  [reservationPageActions.GET_CARGO_UOM_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        dropdown: {
          ...state.reservationPage.dropdown,
          cargoUom: payload,
        },
      },
    };
  },
  [reservationPageActions.GET_VIEW_IAP_PLANNER_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        dropdown: {
          ...state.reservationPage.dropdown,
          iap: payload,
        },
      },
    };
  },
  [reservationPageActions.POST_VIEW_MATCHING_VOYAGES]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        viewMatchingVoyages: payload,
      },
    };
  },
  [reservationPageActions.POST_SAVE_SPECIAL_REQUEST]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        saveSpecialRequestResponse: payload,
      },
    };
  },
  [reservationPageActions.POST_SAVE_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        saveReservationResponse: payload,
      },
    };
  },
  [reservationPageActions.POST_UPDATE_RESERVATION]: (state, { payload }) => {
    const updatedReservationList =
      state?.reservationViewPage?.reservationListData.reservation_list.map((item) =>
        item?.reservation_id === payload[0]?.data?.reservation_id ? payload[0]?.data : item
      );
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        updateReservationResponse: payload,
      },
      reservationViewPage: {
        ...state.reservationViewPage,
        reservationListData: {
          ...state.reservationViewPage.reservationListData,
          reservation_list: [...updatedReservationList],
        },
      },
    };
  },
  [reservationPageActions.RESET_UPDATE_RESERVATION_RESPONSE]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        updateReservationResponse: defaultState.reservationPage.updateReservationResponse,
      },
    };
  },
  [reservationPageActions.POST_VIEW_RESERVATION_LIST]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        reservationListData: payload,
      },
    };
  },
  [reservationPageActions.POST_VIEW_ALL_RESERVATION_LIST]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        allReservationListData: payload,
      },
    };
  },
  [reservationPageActions.POST_VIEW_ORIGINAL_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        originalVoyage: payload,
      },
    };
  },
  [reservationPageActions.POST_TRIGGER_VIEWED_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        triggerViewedReservationResponse: payload,
      },
    };
  },
  [reservationPageActions.POST_DOWNLOAD_RESERVATION_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        downloadReservationDetails: payload,
      },
    };
  },
  [reservationPageActions.POST_DELETE_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        deleteReservationResponse: payload,
      },
    };
  },
  [reservationPageActions.POST_DELETE_SPECIAL_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        deleteSpecialReservationResponse: payload,
      },
    };
  },
  [reservationPageActions.POST_EDIT_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        editReservation: payload,
      },
    };
  },
  [reservationPageActions.POST_VIEW_FINALIZED_ITINERARY]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        viewFinalizedItinerary: payload,
      },
    };
  },
  [reservationPageActions.POST_UPDATE_DELIVERED_STATUS]: (state, { payload }) => {
    const arrUpdated = state.reservationViewPage?.viewFinalizedItinerary[0][payload.arrayUpdated];
    let indexItemUpdated = arrUpdated.findIndex(
      (item) =>
        ((item.che_id === payload.response[0]?.material_id)
          || (item.cargo_name === payload.response[0]?.material_id))
        && item.booking_id === payload.response[0]?.booking_id
    );
    arrUpdated[indexItemUpdated] = payload.response[0]?.is_valid ? {
      ...arrUpdated[indexItemUpdated],
      container_delivered: payload.response[0]?.container_delivered,
    } : arrUpdated[indexItemUpdated];
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        viewFinalizedItinerary: [
          {
            ...state.viewFinalizedItinerary[0],
            [payload.arrayUpdated]: arrUpdated,
          },
        ],
      },
    };
  },
  [reservationPageActions.POST_TRACK_VOYAGE_STATUS]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        trackVoyage: payload,
      },
    };
  },
  [reservationPageActions.POST_UPDATE_APPROVAL_STATUS]: (state, { payload }) => {
    return {
      ...state,
      reservationIapApprovalPage: {
        ...state.reservationIapApprovalPage,
        updateApprovalStatusResponse: payload,
      },
    };
  },
  [reservationPageActions.POST_SAVE_BOOKING_DETAILS]: (state, { payload }) => {
    let uploadedItem = state.reservationViewPage.reservationListData.reservation_list.find(
      (item) => item.reservation_id === payload.reservation_id
    );
    uploadedItem = payload?.is_update_status ? { ...uploadedItem, status: "Confirmed" } : uploadedItem;
    const reservationList = state.reservationViewPage.reservationListData.reservation_list.map(
      (item) => (item.reservation_id === payload.reservation_id ? uploadedItem : item)
    );
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        saveBookingDetails: Object.keys(payload).length !== 0 ? [...state.reservationViewPage.saveBookingDetails, payload] : [],
        reservationListData: {
          ...state.reservationViewPage.reservationListData,
          reservation_list: reservationList,
        },
      },
    };
  },
  [reservationPageActions.POST_VIEW_VOYAGE_LIST]: (state, { payload }) => {
    return {
      ...state,
      reservationAllocateVesselPage: {
        ...state.reservationAllocateVesselPage,
        viewVoyageList: payload,
      },
    };
  },
  [reservationPageActions.POST_VIEW_VOYAGE_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      reservationAllocateVesselPage: {
        ...state.reservationAllocateVesselPage,
        viewVoyageDetails: payload,
      },
    };
  },
  [reservationPageActions.POST_VALIDATE_RESERVED_CAPACITY]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        validateReservedCapacityResponse: payload,
      },
    };
  },
  [reservationPageActions.HANDLE_RESET_VALIDATE_RESERVED_CAPACITY]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        validateReservedCapacityResponse: {},
      },
    };
  },
  [reservationPageActions.SET_NEW_RESERVATION_PAGE]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        newReservationPage: payload,
      },
    };
  },
  [reservationPageActions.SET_IS_GO_BACK_EDIT]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        isGoBackEdit: payload,
      },
    };
  },
  [reservationPageActions.SET_SHOW_CONFIRM_DEMAND_MODAL]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        showConfirmDemandModal: payload,
      },
    };
  },
  [reservationPageActions.SET_IS_SHOW_IAP_PLANNER_MODAL]: (state, { payload }) => {
    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        isShowIAPPlannerModal: payload,
      },
    };
  },
  [reservationPageActions.SET_MODAL_RESERVATION_VIEW]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        modal: payload,
      },
    };
  },
  [reservationPageActions.SAVE_HUB_RESERVATION_ITEM_ON_PAGE]: (state, { payload }) => {
    const {
      reservationPage: { viewMatchingVoyages },
    } = state;
    const reservationType = payload?.data?.type;
    const tripId = payload?.data?.voyage_id;
    const propertiesCapacity = payload?.properties_capacity;
    const reservationIndex = viewMatchingVoyages.findIndex((item) => item?.trip_id === tripId);
    let newViewMatchingVoyages = viewMatchingVoyages;

    if (reservationIndex > -1) {
      newViewMatchingVoyages = viewMatchingVoyages?.map((item) => {
        if (
          item.trip_id === tripId &&
          item.start_location === payload.data.start_location &&
          item.end_location === payload.data.end_location
        ) {
          return !reservationType.includes("Passenger")
            ? {
                ...item,
                deckProperties: item?.deckProperties?.map((idi) => ({
                  ...idi,
                  properties: idi?.properties?.map((idiPI) => {
                    const npCI =
                      propertiesCapacity?.find((pci) => pci?.cargo_name === idiPI?.cargo_name) ||
                      {};
                    return {
                      ...idiPI,
                      ...npCI,
                    };
                  }),
                })),
              }
            : {
                ...item,
                incoming_passengers_capacity: propertiesCapacity[0].incoming_capacity,
                outgoing_passengers_capacity: propertiesCapacity[0].outgoing_capacity,
              };
        }
        return item;
      });
      console.log({
        payload,
        newViewMatchingVoyages,
      });
    }

    return {
      ...state,
      reservationPage: {
        ...state.reservationPage,
        viewMatchingVoyages: [...newViewMatchingVoyages],
        isSaveReservationHubNotify: true,
      },
    };
  },

  [reservationPageActions.UPDATE_HUB_RESERVATION_ITEM_ON_PAGE]: (state, { payload }) => {
    let {
      reservationViewPage: {
        reservationListData: { reservation_list, total_reservation },
      },
    } = state;
    const isSameReservationGroup = getIsSameReservationGroup(reservation_list[0], payload);
    let reservationIndex = getReservationIndex(reservation_list, payload);

    if (isSameReservationGroup) {
      if (reservationIndex === -1 && isSameReservationGroup) {
        let isAddReservationItem = false;

        if (
          /// view
          (payload?.data?.created_by &&
            payload?.userEmail &&
            payload?.data?.created_by.toLowerCase() === payload?.userEmail) ||
          /// approve
          (payload?.data?.approver_user_id &&
            payload?.userId &&
            payload?.data?.approver_user_id === payload?.userId)
        ) {
          isAddReservationItem = true;
        }

        if (isAddReservationItem) {
          reservation_list.unshift(payload?.data);
          reservationIndex = 0;
          state.reservationViewPage.reservationListData.total_reservation =
            state.reservationViewPage.reservationListData?.total_reservation + 1;
        }
      }

      reservation_list = reservation_list.map((item, ind) => {
        if (getIsSameReservationGroup(item, payload)) {
          console.log("UPDATING HERE", item, payload);
          if (!payload?.data.type.includes("Passenger")) {
            item.deckProperties = item?.deckProperties.map((rl) => {
              const ppCI =
                payload?.properties_capacity.find((ppC) => ppC?.cargo_name === rl?.property_name) ||
                {};
              const dpI =
                payload?.data?.deckProperties?.find(
                  (pci) => pci?.property_name === rl?.property_name
                ) || {};
              if (ppCI) {
                return {
                  ...rl,
                  ...ppCI,
                  ...(reservationIndex === ind ? dpI : {}),
                };
              }
              return rl;
            });
          } else {
            item.deckProperties = [
              {
                ...(item?.deckProperties[0] || {}),
                ...payload?.properties_capacity[0],
              },
            ];
          }
        }

        if (reservationIndex === ind) {
          item.deckProperties = payload?.data?.deckProperties;
          item.status = payload?.data?.status;
          if (
            payload?.data?.hasOwnProperty("secondary_status") &&
            payload?.data?.secondary_status
          ) {
            item.secondary_status = payload?.data?.secondary_status;
            item.secondary_status_update_date = payload?.data?.secondary_status_update_date;
          }
        }

        return item;
      });
    }

    const arrayStatus = (defineTab[state.reservationViewPage?.tabActive]?.status || []).toString();

    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        reservationListData: {
          ...state.reservationViewPage.reservationListData,
          reservation_list: reservation_list.filter((rli) => arrayStatus.includes(rli?.status)),
        },
        isUpdateReservationFromHub: {
          ...state.reservationViewPage.isUpdateReservationFromHub,
          [payload?.data?.voyage_id]: true,
        },
      },
    };
  },
  [reservationPageActions.RESET_IS_HUB_RESERVATION_ITEM_ON_PAGE]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        isUpdateReservationFromHub: {
          ...state.reservationViewPage.isUpdateReservationFromHub,
          ...payload,
        },
      },
    };
  },
  [reservationPageActions.GET_VIEW_RESERVATION_LOCATION_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        dropdown: {
          ...state.reservationViewPage.dropdown,
          [payload.locationName]: payload.response,
        },
      },
    };
  },
  [reservationPageActions.GET_DROPDOWN_BY_VOYAGEID]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        voyageDetails: {
          ...state.reservationViewPage.voyageDetails,
          [payload.fieldName]: [
            ...payload.response,
            {
              key: "All",
              text: "All",
              value: "All",
            },
          ],
        },
      },
    };
  },
  [reservationPageActions.SET_STATUS_RESERVATION_VIEW_PAGE]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        ...payload,
      },
    };
  },
  [reservationPageActions.RESET_SEARCH_AND_FILTER]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        filterOptions: defaultState.reservationViewPage.filterOptions,
      },
    };
  },
  [reservationPageActions.SET_FILTER_OPTIONS]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        filterOptions: payload,
      },
    };
  },
  [reservationPageActions.SET_IS_GO_THROUGH_VOYAGE_PAGE]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        isGoThroughVoyagePage: payload,
      },
    };
  },
  [reservationPageActions.SET_SORT_BY_DEPARTURE_DATE]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        sortByDepartureDate: payload,
      },
    };
  },
  [reservationPageActions.SET_PERIOD_CALENDAR_FILTER]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        calendarFilter: {
          ...state.reservationViewPage.calendarFilter,
          period: payload,
        },
      },
    };
  },
  [reservationPageActions.SET_IS_SHOW_ALL_CALENDAR_FILTER]: (state, { payload }) => {
    return {
      ...state,
      reservationViewPage: {
        ...state.reservationViewPage,
        calendarFilter: {
          ...state.reservationViewPage.calendarFilter,
          isShowAll: payload,
        },
      },
    };
  },
};

const getReservationIndex = (reservation_list, payload) =>
  reservation_list.findIndex((data) => data?.reservation_id === payload?.data?.reservation_id);

const getIsSameReservationGroup = (reservationItem, payload) =>
  reservationItem?.start_location === payload?.data?.start_location &&
  reservationItem?.end_location === payload?.data?.end_location;

export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const RESERVATION_PAGE_REDUCER = "reservationPage";
