// Hooks
import { useStateWithPaths } from './useConnect';

// Reducers
import { UAC_REDUCER } from '../reducers/common/uacReducer';

const useDisplayNamesMap = (type) => {
  const [uacData] = useStateWithPaths([`${UAC_REDUCER}`]);

  const divisionRegions = uacData?.uac?.data?.divisionRegions;

  const regionCodeMap = {};
  const regionNameMap = {};

  divisionRegions?.forEach((d) => {
    regionCodeMap[d?.region_code] = d?.display_region_code ?? d?.region_code;
    regionNameMap[d?.region_name] = d?.display_region_name ?? d?.region_name;
  });

  switch (type) {
    case 'REGION_CODE': {
      return regionCodeMap;
    }
    case 'REGION_NAME': {
      return regionNameMap;
    }
    default:
      return {};
  }
};

export default useDisplayNamesMap;
