import { fetchError, fetchStart, fetchSuccess } from "./actionFetch";
import * as services from '../../services/serviceOptimise'

export const SET_HEADER_TITLE = 'SET_HEADER_TITLE';
export const setHeaderTitle = (title) => {
    return (dispatch) => {
        dispatch({
            type: SET_HEADER_TITLE,
            payload: title
        });
    }
}


export const PUSH_HEADER_TITLE_CALLBACK_STACK = 'PUSH_HEADER_TITLE_CALLBACK_STACK';
export const pushHeaderTitleAndCallBack = (title, cb) => {
    return (dispatch) => {
        dispatch({
            type: PUSH_HEADER_TITLE_CALLBACK_STACK,
            payload: {title, cb}
        });
    }
}

export const POP_HEADER_TITLE_CALLBACK_STACK = 'POP_HEADER_TITLE_CALLBACK_STACK';
export const popHeaderTitleAndCallBack = () => {
    return (dispatch) => {
        dispatch({
            type: POP_HEADER_TITLE_CALLBACK_STACK,
            payload: {}
        });
    }
}

export const GET_SCHEDULE_STATUS_D3 = 'GET_SCHEDULE_STATUS_D3'
export const getScheduleStatusDMinus3Header = (object) => {
    return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_STATUS_D3))
    services
        .getScheduleStatusDMinus3(object)
        .then((response) =>
        dispatch({
            type: GET_SCHEDULE_STATUS_D3,
            payload: response,
        })
        )
        .then(() => dispatch(fetchSuccess(GET_SCHEDULE_STATUS_D3)))
        .catch(() => dispatch(fetchError(GET_SCHEDULE_STATUS_D3)))
    }
}

export const SET_SOCKET_CONNECTION = 'SET_SOCKET_CONNECTION';
export const setSocketConnection = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_SOCKET_CONNECTION,
            payload
        });
    }
}