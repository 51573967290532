import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";

const CONTROLLER_NAME = "/fms";

export const getPartnerRegions = () => {
  return LAMBDA_API_INSTANCE().then((response) => response.get(`${CONTROLLER_NAME}/regions`));
};

export const createNewSystemAlert = (data) => {
  const bodyParams = {
    ...data,
  };

  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/createNewSystemAlert`, bodyParams)
  );
};

export const getVolumeCategory = (type) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/systemTypes/${type}`)
  );
};

export const getVessels = (data) => {
  const bodyParams = {
    regions: data,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(
      `${CONTROLLER_NAME}/vessels`, bodyParams
    )
  );
};

export const postFuelVolumeData = (data) => {

  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/volume`, data)
  );
};

export const fetchPresignedUrls = (data) => {

  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/fetchPreSignedUrls`, data)
  );
};

export const getVolumeData = async (queries) => {
  return await LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/volume`, { params: queries })
  );
};
export const getVolumeById = (data) => {
  const { volumeId, categoryName } = data;
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/volume?volumeId=${volumeId}&categoryName=${categoryName}`)
  );
};

export const putVolume = (requestBody) => {

  return LAMBDA_API_INSTANCE().then((response) =>
    response.put(`${CONTROLLER_NAME}/volume`, requestBody)
  );
};

export const deactivateVolume = (data) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.patch(`${CONTROLLER_NAME}/volume`, data)
  );
};

export const getVolumeFileList = (volumeId) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/volumeFileList?volumeId=${volumeId}`)
  );
};

export const getFuelCappingData = (params) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/fuelCappingData`, {
      params,
    })
  );
};
