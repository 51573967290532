import _ from 'lodash'
import * as services from '../../services/common/serviceMasterData'

//Key Action
export const GET_MASTER_DATA_WITH_OPTIONS = 'GET_MASTER_DATA_WITH_OPTIONS'
export const getMasterDataWithOptions = (
  { apiPath, storePath, bodyData },
  cache
  // ,ignoreFetchState
) => {
  return (dispatch, getState) => {
    const currentState = _.get(getState(), `masterDataPage.${storePath}`)
    if (cache && !_.isEmpty(currentState)) return
    //if (!ignoreFetchState) dispatch(fetchStart(GET_MASTER_DATA_WITH_OPTIONS))
    services
      .getMasterData(apiPath, bodyData)
      .then((result) => {
        dispatch({
          type: GET_MASTER_DATA_WITH_OPTIONS,
          payload: {
            path: storePath,
            value: result,
          },
        })
        //if (!ignoreFetchState) dispatch(fetchSuccess(GET_MASTER_INPUT_DATA))
      })
      .catch((e) => {
        //if (!ignoreFetchState) dispatch(fetchError(GET_MASTER_INPUT_DATA, e))
      })
    dispatch({
      type: GET_MASTER_DATA_WITH_OPTIONS,
      payload: {
        path: storePath,
        value: [],
      },
      isAttach: true,
    })
  }
}

export const GET_MASTER_DATA_MERGE_WITH_OPTIONS = 'GET_MASTER_DATA_MERGE_WITH_OPTIONS'
export const getMasterDataMergeWithOptions = (
  { apiPath, storePath, bodyData },
  cache,
  ignoreFetchState
) => {
  return (dispatch, getState) => {
    const currentState = _.get(getState(), `masterDataPage.${storePath}`)
    if (cache && !_.isEmpty(currentState)) return
    //if (!ignoreFetchState) dispatch(fetchStart(GET_MASTER_DATA_WITH_OPTIONS))
    services
      .getMasterData(apiPath, bodyData)
      .then((result) => {
        dispatch({
          type: GET_MASTER_DATA_MERGE_WITH_OPTIONS,
          payload: {
            path: storePath,
            value: result,
          },
        })
        //if (!ignoreFetchState) dispatch(fetchSuccess(GET_MASTER_INPUT_DATA))
      })
      .catch((e) => {
        //if (!ignoreFetchState) dispatch(fetchError(GET_MASTER_INPUT_DATA, e))
      })
    dispatch({
      type: GET_MASTER_DATA_MERGE_WITH_OPTIONS,
      payload: {
        path: storePath,
        value: [],
      },
      isAttach: true,
    })
  }
}

//Various master data apis

// Linked Main Page Master Dropdown
export const getMasterRegionDropdown = (page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'regionDropdown',
    bodyData: {
      page: page_type,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterLocationDropdown = (region, page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'locationDropdown',
    bodyData: {
      page: page_type,
      region: region,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterJobDropdown = (region, location, page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'jobDropdown',
    bodyData: {
      page: page_type,
      region: region,
      starting_location: location,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterVesselDropdown = (region, location, job, page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'vesselDropdown',
    bodyData: {
      page: page_type,
      region: region,
      starting_location: location,
      job_type: job,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// Linked Explore Page Master Dropdown
export const getExploreJobTypeDropdown = (page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'exploreJobType',
    bodyData: {
      page: page_type,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getExploreVesselDropdown = (jobType, page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'exploreVessel',
    bodyData: {
      page: page_type,
      job_type: jobType,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getExploreDepartureDropdown = (jobType, vesselDropdown, page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'exploreDeparture',
    bodyData: {
      page: page_type,
      job_type: jobType,
      vessel_id: vesselDropdown,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getExploreIdDropdown = (jobType, vesselDropdown, departureDropdown, page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'exploreId',
    bodyData: {
      page: page_type,
      job_type: jobType,
      vessel_id: vesselDropdown,
      berthing_date: departureDropdown,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getVoyageRound = (
  jobType,
  vesselDropdown,
  departureDropdown,
  idDropdown,
  page_type,
  isCache
) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'voyageRound',
    bodyData: {
      page: page_type,
      job_type: jobType,
      vessel_id: vesselDropdown,
      berthing_date: departureDropdown,
      trip_id: idDropdown,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// Vessel Schedule Delete Dropdown
export const getVesselScheduleDropdown = (page_type, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: 'vesselScheduleDropdown',
    bodyData: {
      page: page_type,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getLastVesselSchedule = (vessel, isCache) => {
  const config = {
    apiPath: 'lastScheduleVessel',
    storePath: 'lastScheduleVessel',
    bodyData: {
      vessel_name: vessel,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// Master Search Input
export const getMasterSearchInput = (vessel, isCache) => {
  const config = {
    apiPath: 'search',
    storePath: 'masterSearchInput',
    bodyData: {},
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// Master Search Input
export const getMasterLocation = (isCache) => {
  const config = {
    apiPath: 'location',
    storePath: 'masterLocation',
    bodyData: {},
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}
