import * as standardDailyAverageActions from "../actions/actionStandardDailyAverage";
import * as volumePageActions from "../actions/actionVolumePage";

const defaultState = {
  standardDailyAverage: {
    vessels: [],
    vesselTypes: [],
    searchData: []
  },
};

const REDUCERS = {

  [volumePageActions.GET_TENANT_VESSELS]: (state, { payload }) => {
    return {
      ...state,
      standardDailyAverage: {
        ...state.standardDailyAverage,
        vessels: payload,
      },
    };
  },

  [standardDailyAverageActions.GET_VESSEL_TYPES]: (state, { payload }) => {
    return {
      ...state,
      standardDailyAverage: {
        ...state.standardDailyAverage,
        vesselTypes: payload,
      },
    };
  },

};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const STANDARD_DAILY_AVERAGE_REDUCER = "standardDailyAverageReducer";
