import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
// Hooks
import { useActions } from '../../../../../../hooks/useConnect'

// Components
import { Icon, Modal, Button } from 'semantic-ui-react'
// Actions
import * as NotificationActions from '../../../../../../actions/common/actionNotification'

// Icon
import ATTENTION_ICON from '../../../../../../assets/images/attention.svg'

const Reason = props => {
	const {
		isSelfComment,
		reason,
		description,
		reasonColor,
		isMobile= false
	} = props

	return (
		<div className='reason-card'>
			<div className={`reason-card-title 
                ${description ? 'border-top-radius' : 'border-radius'}  
                ${reasonColor}
				${isMobile && (isSelfComment ? 'self-comment' : 'system-comment')}
				`}>
				{reason}
			</div>
			{description && <div className='reason-card-body'>{description} </div>}
		</div>
	)
}

export const LogDetail = props => {
	const {
		data,
		disabled,
		reflectActiveLogs,
		isMobile= false,
		incrWidth
	} = props

	const activityDescription = data.updated_by_name ? data.updated_by_name + ' updated incident reason ' : 'System detected incident '

	const colorBar = {
		true: 'color-orange',
		false: 'color-red'
	}

	const reasonColor = colorBar[data.reason_is_avoidable] || 'color-purple'
	const [isShowDeleteWarning, setIsShowDeleteWarning] = useState(false)
	const { 
		deleteActiveLog
	 } = useActions({
		deleteActiveLog: NotificationActions.deleteActiveLog,
	})

	const deleteAction = () => {
		setIsShowDeleteWarning(false)
		deleteActiveLog(data, reflectActiveLogs())
	}

	return (
		<>
			<div 
				className={`log-detail ${isMobile && (data.is_self_comment ? 'self-comment' : 'system-comment')}`}
				style={(incrWidth && isMobile) ? {width: incrWidth}: {}}
			>
				{!disabled && data.is_self_comment && <Icon
					color="purple"
					size="large"
					name="trash alternate outline"
					className='delete-log-icon'
					onClick={() => setIsShowDeleteWarning(true)} />}
				<div className={`log-description ${isMobile && (data.is_self_comment ? 'self-comment' : 'system-comment')}`}>{activityDescription}</div>
				<div className="log-time">{moment.utc(data.updated_date).format('LT, DD MMM YYYY')}</div>
				<Reason
				    isSelfComment={data.is_self_comment}
					reason={data.user_added_reason}
					description={data.user_added_reason_description}
					reasonColor={reasonColor}
					isMobile={isMobile}
				></Reason>
				{data.user_comment && <div className="mt">{data.user_comment}</div>}
			</div>
			<Modal
				closeIcon
				onClose={() => setIsShowDeleteWarning(false)}
				open={isShowDeleteWarning}
				size={'tiny'}
			>
				<Modal.Content className={`pad-x-4`}>
					<div className={`flex column`}>
						<div className={`flex modal-title`}>
							<img src={ATTENTION_ICON} alt="" />
							<div className={`modal-title`}>Delete Warning</div>
						</div>
						<div className={`flex-column`}>
							<p className={`mar-bottom-1`}>
								This activity log will be deleted. Do you want to continue?
							</p>
						</div>
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button className={`button-custom button-primary`} onClick={() => deleteAction()}>Continue</Button>
					<Button className={`button-custom button-closed`} onClick={() => setIsShowDeleteWarning(false)}>Close</Button>
				</Modal.Actions>
			</Modal>
		</>
	)
}

const ActiveLog = (props) => {
	const {
		data,
		disabled = false,
		reflectActiveLogs,
		isMobile = false,
		incrWidth = ''
	} = props
	const isScrollable = data?.length > 3 ? 'log-viewer-scrollable' : ''
	const sort = (arr) => {
		return arr?.sort((a, b) => new Date(b?.updated_date) - new Date(a?.updated_date)) || [];
	}
	return (
		<div className={`active-log-viewer flex column mar-top-2 `}>
			<div className='title'>{isMobile ? `Activity Log` : `ACTIVITY LOG`}</div>
			<div className={`wrapper ${isScrollable}`}>
				{_.map(sort(data), (item, index) => (
					<LogDetail
						key={`log-viewer-${index}`}
						data={item}
						disabled={disabled}
						reflectActiveLogs={reflectActiveLogs}
						isMobile={isMobile}
						incrWidth={incrWidth}
					></LogDetail>
				))}
			</div>
		</div>
	)
}

export default ActiveLog