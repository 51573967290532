import * as services from '../services/serviceMooringBuoyManagement'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'
import _ from 'lodash'

export const GET_MOORING_BUOY_MANAGEMENT = 'GET_MOORING_BUOY_MANAGEMENT'
export const getMooringBuoyManagement = (
  mooring_buoy,
  location,
  utilizationRange,
  status,
  page_number = 1,
  page_size = 10
) => {
  const utilizationFiltered = {
    min: utilizationRange.min,
    max: utilizationRange.max,
  }
  const mooringBuoyNamesFiltered = _.map(mooring_buoy, (m) => m.value)
  const locationNamesFiltered = _.map(location, (l) => l.value)
  const statusFiltered = _.map(status, (s) => s.value)

  return (dispatch) => {
    dispatch(fetchStart(GET_MOORING_BUOY_MANAGEMENT))
    services
      .getMooringBuoyManagement(
        mooringBuoyNamesFiltered,
        locationNamesFiltered,
        utilizationFiltered,
        statusFiltered,
        page_number,
        page_size
      )
      .then((response) => {
        dispatch({
          type: GET_MOORING_BUOY_MANAGEMENT,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(GET_MOORING_BUOY_MANAGEMENT)))
      .catch(() => dispatch(fetchError(GET_MOORING_BUOY_MANAGEMENT)))
  }
}

export const UPDATE_MOORING_BUOY_MANAGEMENT = 'UPDATE_MOORING_BUOY_MANAGEMENT'
export const updateMooringBuoyManagement = (
  mooring_buoy,
  location,
  status,
  comment,
  getMaintenanceLogList,
  setOpenEditMooringBuoy
) => {
  return (dispatch) => {
    dispatch(fetchStart(UPDATE_MOORING_BUOY_MANAGEMENT))
    services
      .updateMooringBuoyManagement(mooring_buoy, location, status, comment)
      .then((response) => {
        dispatch({
          type: UPDATE_MOORING_BUOY_MANAGEMENT,
          payload: response,
        })
        getMaintenanceLogList && getMaintenanceLogList(mooring_buoy)
        setOpenEditMooringBuoy && setOpenEditMooringBuoy(false)
      })
      .then(() => dispatch(fetchSuccess(UPDATE_MOORING_BUOY_MANAGEMENT)))
      .catch(() => dispatch(fetchError(UPDATE_MOORING_BUOY_MANAGEMENT)))
  }
}

export const GET_MOORING_BUOY_MANAGEMENT_FILTER_DROPDOWN =
  'GET_MOORING_BUOY_MANAGEMENT_FILTER_DROPDOWN'
export const getMooringBuoyManagementFilterDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MOORING_BUOY_MANAGEMENT_FILTER_DROPDOWN))
    services
      .getMooringBuoyManagementFilterDropdown()
      .then((response) => {
        dispatch({
          type: GET_MOORING_BUOY_MANAGEMENT_FILTER_DROPDOWN,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(GET_MOORING_BUOY_MANAGEMENT_FILTER_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_MOORING_BUOY_MANAGEMENT_FILTER_DROPDOWN)))
  }
}
