import * as PlanningPageActions from '../actions/actionPlanning'

const defaultState = {
  searchData: {
    data: '',
  },
  planningPage: {
    data: [],
    validateVesselSchedules: [],
    insertVesselSchedules: [],
    scheduleOptionValidateInsert: [],
    scheduleOptionLocationData: [],
    scheduleOptionKpiData: [],
    scheduleOptionVesselData: [],
    selectedOptionNumber: 0,
  },
}

const REDUCERS = {
  [PlanningPageActions.SET_VALIDATE_VESSEL_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        validateVesselSchedules: payload,
      },
    }
  },
  [PlanningPageActions.SET_INSERT_VESSEL_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        insertVesselSchedules: payload,
      },
    }
  },
  [PlanningPageActions.POST_SOC_VALIDATE_INSERT]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        scheduleOptionValidateInsert: payload,
      },
    }
  },
  [PlanningPageActions.POST_SOC_LOCATION_DATA]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        scheduleOptionLocationData: payload,
      },
    }
  },
  [PlanningPageActions.POST_SOC_KPI_DATA]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        scheduleOptionKpiData: payload,
      },
    }
  },
  [PlanningPageActions.POST_SOC_SELECTED_VESSEL_DATA]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        scheduleOptionVesselData: payload,
      },
    }
  },
  [PlanningPageActions.UPDATE_SELECTED_OPTION_NUMBER]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        selectedOptionNumber: payload,
      },
    }
  },
  [PlanningPageActions.SET_RESET_PLANNING_PAGE]: (state, { payload }) => {
    return {
      ...state,
      planningPage: defaultState.planningPage,
    }
  },
  [PlanningPageActions.SET_RESET_SOC_VALIDATE_INSERT]: (state, { payload }) => {
    return {
      ...state,
      planningPage: {
        ...state.planningPage,
        scheduleOptionValidateInsert: payload,
      },
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const PLANNING_PAGE_REDUCER = 'planningPage'
