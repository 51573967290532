import _ from "lodash";
import { PERMISSIONS } from "../../../constants/userGroups";

const menu = [
    {
        title: "Optimise",
        page: "schedule",
        options: [
            {
                title: "View Vessel Schedule",
                route: "/vessel-schedule",
                forPersona: PERMISSIONS.vesselSchedule,
                showOnMobile: false
            },
            {
                title: "Create Vessel Schedule",
                route: "/optimise-create",
                forPersona: PERMISSIONS.optimiseCreate,
                showOnMobile: false
            },
            {
                title: "Upload Data",
                route: "/optimise-upload",
                forPersona: PERMISSIONS.optimiseUpload,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Demand",
        page: "reserve",
        options: [
            {
                title: "New Reservation",
                route: "/demand",
                forPersona: PERMISSIONS.demand,
                showOnMobile: false
            },
            {
                title: "My Reservations",
                route: "/reservation",
                forPersona: PERMISSIONS.reservation,
                showOnMobile: false
            },
            {
                title: "IAP Approvals",
                route: "/iap-approval",
                forPersona: PERMISSIONS.iapApproval,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Execute",
        page: "execute",
        options: [
            {
                title: "Track Vessel",
                route: "/main-explore",
                forPersona: PERMISSIONS.mainExplore,
                showOnMobile: true
            },
            {
              title: "Planning",
              route: "/planning",
              forPersona: PERMISSIONS.plainning,
              showOnMobile: false
            },
            {
                title: "KPI Dashboard",
                route: "/dashboard",
                forPersona: _.union([...PERMISSIONS.dashboard, ...PERMISSIONS.kpi]),
                showOnMobile: true
            },
            // {
            //     title: "Speed Monitoring",
            //     route: "/speed",
            //     forPersona: PERMISSIONS.speed,
            //     showOnMobile: false
            // }
        ],
    },
    {
        title: "Manage",
        page: "manage",
        options: [
            {
                title: "Vessels Management",
                route: "/vessel-management",
                forPersona: PERMISSIONS.vesselManagement,
                showOnMobile: false
            },
            // {
            //   title: "Schedules",
            //   route: "/optimise-planner",
            //   forPersona: PERMISSIONS.optimisePlanner,
            //   showOnMobile: false
            // },
            {
                title: "Mooring Buoys Management",
                route: "/mooring-buoy-management",
                forPersona: PERMISSIONS.mooringBuoyManagement,
                showOnMobile: false
            },
            // {
            //     title: "Users Management",
            //     route: "/user",
            //     forPersona: PERMISSIONS.setting,
            //     showOnMobile: false
            // },
            {
                title: "Vessel Registration Approval",
                route: "/vod-approval-registration",
                forPersona: PERMISSIONS.vodAdmin,
                showOnMobile: false
            },
            {
                title: "Location Management",
                route: "/location-management",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Sell",
        page: "seller",
        options: [
            {
                title: "Register Vessel",
                route: "/vod-seller-vessel-registration",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "All Vessels",
                route: "/vod-seller-vessel-list",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "Bookings",
                route: "/vod-seller-booking",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Buy",
        page: "buyer",
        options: [
            {
                title: "All Bookings",
                route: "/vod-booking",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "Voyage Sharing",
                route: "/vod-buyer-reservation",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "Vessel Chartering",
                route: "/vod-buyer-reservation",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Track",
        page: "vod-track-and-trace",
        options: [
            {
                title: "Track Vessel",
                route: "/vod-track-and-trace",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    {
        title: "FMS",
        page: "fms",
        options: [
            {
                title: "FMS Dashboard",
                route: "/fms-dashboard",
                forPersona: PERMISSIONS.fmsDashboardView,
                showOnMobile: false,
                children:[
                    {
                        title: "KPI Report",
                        route: "/fms-dashboard/kpi-report",
                        forPersona: PERMISSIONS.fmsDashboardView,
                        showOnMobile: false
                    }
                ]
            },
            {
                title: "Data Management",
                route: "/data-management",
                forPersona: PERMISSIONS.dataManagement,
                showOnMobile: false
            },
            {
                title: "System Alert",
                route: "/system-alert",
                forPersona: PERMISSIONS.systemAlertView,
                showOnMobile: false
            },
            
        ],
    },
    {
        title: "Support",
        page: "support",
        options: [
            {
                title: "FAQ",
                route: "/faq",
                showOnMobile: true
            },
            {
                title: "User Guide",
                route: "/user-guide",
                showOnMobile: true
            },
            {
                title: "Carbon Calculator",
                route: "/carbon-calculator",
                showOnMobile: false
            },
            {
                title: "Settings",
                route: "/settings",
                forPersona: PERMISSIONS.setting,
                showOnMobile: false
            },
        ],
    }
];

const menuTester = [
    {
        title: "Optimise",
        page: "schedule",
        options: [
            {
                title: "View Vessel Schedule",
                route: "/vessel-schedule",
                forPersona: PERMISSIONS.vesselSchedule,
                showOnMobile: false
            },
            {
                title: "Create Vessel Schedule",
                route: "/optimise-create",
                forPersona: PERMISSIONS.optimiseCreate,
                showOnMobile: false
            },
            {
                title: "Upload Data",
                route: "/optimise-upload",
                forPersona: PERMISSIONS.optimiseUpload,
                showOnMobile: false
            },
            {
                title: "Planning",
                route: "/planning",
                forPersona: PERMISSIONS.plainning,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Demand",
        page: "reserve",
        options: [
            {
                title: "New Reservation",
                route: "/demand",
                forPersona: PERMISSIONS.demand,
                showOnMobile: false
            },
            {
                title: "My Reservations",
                route: "/reservation",
                forPersona: PERMISSIONS.reservation,
                showOnMobile: false
            },
            {
                title: "IAP Approvals",
                route: "/iap-approval",
                forPersona: PERMISSIONS.iapApproval,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Execute",
        page: "execute",
        options: [
            {
                title: "Track Vessel",
                route: "/main-explore",
                forPersona: PERMISSIONS.mainExplore,
                showOnMobile: true
            },
            // {
            //   title: "Planning",
            //   route: "/planning",
            //   forPersona: PERMISSIONS.plainning,
            //   showOnMobile: false
            // },
            {
                title: "KPI Dashboard",
                route: "/dashboard",
                forPersona: _.union([...PERMISSIONS.dashboard, ...PERMISSIONS.kpi]),
                showOnMobile: true
            },
            // {
            //     title: "Speed Monitoring",
            //     route: "/speed",
            //     forPersona: PERMISSIONS.speed,
            //     showOnMobile: false
            // }
        ],
    },
    {
        title: "Manage",
        page: "manage",
        options: [
            {
                title: "Vessels Management",
                route: "/vessel-management",
                forPersona: PERMISSIONS.vesselManagement,
                showOnMobile: false
            },
            // {
            //   title: "Schedules",
            //   route: "/optimise-planner",
            //   forPersona: PERMISSIONS.optimisePlanner,
            //   showOnMobile: false
            // },
            {
                title: "Mooring Buoys Management",
                route: "/mooring-buoy-management",
                forPersona: PERMISSIONS.mooringBuoyManagement,
                showOnMobile: false
            },
            {
                title: "Users Management",
                route: "/user",
                forPersona: PERMISSIONS.setting,
                showOnMobile: false
            },
            {
                title: "Vessel Registration Approval",
                route: "/vod-approval-registration",
                forPersona: PERMISSIONS.vodAdmin,
                showOnMobile: false
            },
            {
                title: "Location Management",
                route: "/location-management",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Sell",
        page: "seller",
        options: [
            {
                title: "Register Vessel",
                route: "/vod-seller-vessel-registration",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "All Vessels",
                route: "/vod-seller-vessel-list",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "All Bookings",
                route: "/vod-seller-booking",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Buy",
        page: "buyer",
        options: [
            {
                title: "All Bookings",
                route: "/vod-booking",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "Voyage Sharing",
                route: "/vod-buyer-reservation",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
            {
                title: "Vessel Chartering",
                route: "/vod-buyer-reservation",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    {
        title: "Track",
        page: "vod-track-and-trace",
        options: [
            {
                title: "Track Vessel",
                route: "/vod-track-and-trace",
                forPersona: PERMISSIONS.vodTester,
                showOnMobile: false
            },
        ],
    },
    // {
    //   title: "Tools",
    //   page: "tools",
    //   options: [
    //     {
    //       title: "Support",
    //       route: "/support",
    //       showOnMobile: false
    //     },
    //   ],
    // },
    {
        title: "Support",
        page: "support",
        options: [
            {
                title: "FAQ",
                route: "/faq",
                showOnMobile: true
            },
            {
                title: "User Guide",
                route: "/user-guide",
                showOnMobile: true
            },
            {
                title: "Carbon Calculator",
                route: "/carbon-calculator",
                showOnMobile: false
            },
            {
                title: "Settings",
                route: "/settings",
                forPersona: PERMISSIONS.setting,
                showOnMobile: false
            },
        ],
    }
];

export { menu, menuTester }