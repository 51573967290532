import _ from "lodash";
import React, { useState, useCallback, useRef } from "react";
import { Image, Form, TextArea, Rating } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";

// Hooks
import { useStateWithPaths, useActions } from "../../hooks/useConnect";

// Assets
import SupportMessageIcon from "../../assets/images/support-message.svg";
import SupportMessageActiveIcon from "../../assets/images/support-message-active.svg";
import CloseIcon from "../../assets/images/close-white.svg";

import Tick from "../../assets/images/tick.svg";

// Redux
import { UAC_REDUCER } from "../../reducers/common/uacReducer";

// Actions
import * as UserRatingActions from "../../actions/common/actionUserRating";

const UserRating = ({ isOpen, setIsOpen }) => {
  const [uacData] = useStateWithPaths([`${UAC_REDUCER}`]);
  const location = useLocation();

  const [isComplete, setIsComplete] = useState(false);

  const [comment, setComment] = useState();
  const [rating, setRating] = useState();
  const [npsScore, setNpsScore] = useState(null);
  const getUserEmail = _.get(uacData?.uac.data, "email");
  const getUserName = _.get(uacData?.uac.data, "display_name");
  const getPathname = _.get(location, "pathname");

  // Actions Calls
  const { postUserRating } = useActions({
    postUserRating: UserRatingActions.postUserRating,
  });

  const onConfirmFeedback = useCallback(() => {
    setIsComplete(!isComplete);
    postUserRating(comment, rating, npsScore, getUserEmail, getUserName, getPathname);
  }, [isComplete, comment, rating, npsScore, getUserEmail, getUserName, getPathname]);

  const onClickRating = () => {
    setIsOpen(!isOpen)
    setIsComplete(false)
    setComment(null)
    setRating(null)
    setNpsScore(null)
  };

  const handleIsComplete = () => {
    setIsComplete(!isComplete);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsComplete(false);
    setIsOpen(false);
  };

  const slider = useRef();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false
  };

  const next = () => {
    slider.current.slickNext();
  };

  const getNpsScore = (val) => {
    setNpsScore(val);
  };

  // NPS score rating scale
  const ScaleRating = ({ onRatingClicked }) => {
    return (
      <div className={`scale-rating`}>
        {Array.from(Array(10).keys()).map((index, key) => {
          return (
            <>
              <button
                key={key + 1}
                onClick={() => {
                  onRatingClicked(key + 1);
                }}
              >
                {key + 1}
              </button>
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div>
        <div
          className={`user-rating user-rating-btn ${isOpen && 'user-rating-active'} flex`}
          onClick={onClickRating}
          data-testid="rating-button" 
        >
          <Image src={!isOpen ?  SupportMessageIcon: SupportMessageActiveIcon} />
        </div>
      {isOpen && (
        <div className={`user-rating-popup-container`} data-testid="popup-container" >
          <div className="content-header pad-1">
            <p className="content-header-text">Feedback</p>
            <Image src={CloseIcon} onClick={handleClose} className="content-header-icon" />
          </div>
          <div className="flex column pad-1">
            {!isComplete && (
              
              <Slider ref={slider} {...settings} >
                <div className={`slider-body`}>
                  <div className={`content-title pad-bottom-0_5`}>
                    We would love to hear your Feedback!
                  </div>
                  <Rating
                    className={`pad-bottom-0_5`}
                    clearable
                    rating={rating}
                    maxRating={5}
                    icon="star"
                    size="huge"
                    onRate={(e, { rating }) => setRating(rating)}
                    data-testid="rating-input" 
                  />
                  <Form className={`pad-bottom-0_5`}>
                    <Form.Field
                      control={TextArea}
                      label="Comment"
                      placeholder="Type here (Optional)"
                      onChange={(e) => setComment(e.target.value)}
                      data-testid="rating-comment" 
                    />
                  </Form>
                  <div className={`flex space-between`}>
                    <div
                      className={`cancel-btn`}
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      Cancel
                    </div>
                    <div className={`feedback-btn`} onClick={next} data-testid="next-btn" >
                      Next
                    </div>
                  </div>
                </div>
                <div className={`slider-body`}>
                  <div className={`content-title pad-bottom-0_5`}>
                    How likely is it that you would recommend STEAR as an
                    optimization tool to others?
                  </div>

                  <div className="scale-nps">
                    <ScaleRating onRatingClicked={getNpsScore} />
                  </div>
                  <div className={`flex space-between`}>
                    <div
                      className={`cancel-btn`}
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      Cancel
                    </div>
                    <div className={`feedback-btn`} onClick={onConfirmFeedback}>
                      Give Feedback
                    </div>
                  </div>
                </div>
              </Slider>
            
          )}
          {isComplete && (
            <div
              className={`flex column pad-top-4 center justify-center`}
              onClick={handleIsComplete}
            >
              <Image className={`complete-icon`} src={Tick} size="mini" />
              <div className={`complete-title`}>
                Thank you for your feedback!
              </div>
            </div>
          )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserRating;
