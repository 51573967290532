import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

const CONTROLLER_NAME = '/maintenance-log'

export const getEquipmentTasksData = () => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/equipment-tasks`))
}

export const getMaintenanceLogList = (mooring_buoy, page_number, page_size) => {
  const bodyParams = {
    mooring_buoy,
    page_number,
    page_size,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/list`, bodyParams))
}

export const deleteMaintenanceTask = (maintenance_task_id, maintenance_id) => {
  const bodyParams = {
    maintenance_task_id,
    maintenance_id
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/delete-task`, bodyParams))
}

export const createUpdateMaintenanceTask = (formData) => {
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/tasks`, JSON.stringify(formData)))
}

const LOCATION_PATH = '/location-log';
export const getRegionLocationDropdown = () => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${LOCATION_PATH}/region-location-dropdowns`))
}

export const getLocationLogList = (mooring_buoy, page_number, page_size) => {
  const bodyParams = {
    mooring_buoy,
    page_number,
    page_size,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${LOCATION_PATH}/list`, bodyParams));
}

export const createUpdateLocationLog = (payloadObj) => {
  return LAMBDA_API_INSTANCE().then(response => response.put(`${LOCATION_PATH}/create-update`, payloadObj));
}