// Freeform
import _ from 'lodash'
import { fetchError, fetchStart, fetchSuccess } from "../common/actionFetch";
import * as services from '../../services/common/serviceGeneral';

export const SET_REDIRECT_LOCATION = 'SET_REDIRECT_LOCATION'
export const setRedirectLocation = (location) => {
  return {
    type: SET_REDIRECT_LOCATION,
    payload: location,
  }
}

export const SET_RESERVATION_PAGE = 'SET_RESERVATION_PAGE'
export const setReservationPage = (reservationPage) => {
  return {
    type: SET_RESERVATION_PAGE,
    payload: reservationPage,
  }
}

export const SET_APPROVAL_PAGE = 'SET_APPROVAL_PAGE'
export const setApprovalPage = (approvalPage) => {
  return {
    type: SET_APPROVAL_PAGE,
    payload: approvalPage,
  }
}

export const SET_TRIP_ITEM = 'SET_TRIP_ITEM'
export const setTripItem = (tripItem) => {
  const adjustTripItem = !_.isEmpty(tripItem)
    ? _.isEmpty(tripItem.fromPath)
      ? { ...tripItem, fromPath: '/explore' }
      : { ...tripItem }
    : null

  return {
    type: SET_TRIP_ITEM,
    payload: adjustTripItem,
  }
}

export const SET_SHOW_NOTIFY_BY_TRIP_ID = 'SET_SHOW_NOTIFY_BY_TRIP_ID'
export const setIsShowNotifyByTripId = (isShowNotifyByTripId = false) => {
  return {
    type: SET_SHOW_NOTIFY_BY_TRIP_ID,
    payload: isShowNotifyByTripId,
  }
}

export const SET_TRIP_TO_CONTRACT = 'SET_TRIP_TO_CONTRACT'
export const setTripToContract = (tripItem) => {
  const adjustTripItem = !_.isEmpty(tripItem)
    ? _.isEmpty(tripItem.fromPath)
      ? { ...tripItem, fromPath: '/explore' }
      : { ...tripItem }
    : null

  return {
    type: SET_TRIP_TO_CONTRACT,
    payload: adjustTripItem,
  }
}

export const GET_USER_GUIDE_LIST = "GET_USER_GUIDE_LIST";
export const getUserGuideList = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_USER_GUIDE_LIST));
    services
      .getUserGuideList()
      .then((response) =>
        dispatch({
          type: GET_USER_GUIDE_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_USER_GUIDE_LIST)))
      .catch(() => dispatch(fetchError(GET_USER_GUIDE_LIST)));
  };
};

export const POST_USER_GUIDE = "POST_USER_GUIDE";
export const postUserGuide = (fileName) => {
  return (dispatch) => {
    if (fileName === '') {
      dispatch({
        type: POST_USER_GUIDE,
        payload: {},
      })
    } else {
      dispatch(fetchStart(POST_USER_GUIDE));
      services
        .postUserGuide(fileName)
        .then((response) =>
          dispatch({
            type: POST_USER_GUIDE,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(POST_USER_GUIDE)))
        .catch(() => dispatch(fetchError(POST_USER_GUIDE)));
    }
  };
};