import { LAMBDA_API_INSTANCE } from '../serverInstances/lambdaServer'

const CONTROLLER_NAME = '/uac'
const CONTROLLER_CONTROL_NAME = '/admin'

export const postUserAccess = () => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`saas/userAccessData`))
}

export const getUserAccessControlDetail = (user_id) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_CONTROL_NAME}/userAccessControlDetail?user_id=${user_id}`))
}

export const getUserAccessControl = () => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_CONTROL_NAME}/usersAccessControl`))
}

export const getUsersWithFeatureList = (arrayFeature) => {
  const bodyParams = {
    features: arrayFeature
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/usersWithFeatureList`, bodyParams))
}

export const postUserAccessControl = (name, email, company, role, region, location, iapPlanner, bookingFocals) => {
  const bodyParams = {
    display_name: name,
    email: email,
    division_id: company,
    role_ids: role,
    region_ids: region,
    location_names: location,
    iap_planner_ids: iapPlanner,
    booking_focal_ids: bookingFocals
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/usersAccessControl`, bodyParams))
}

export const putUserAccessControl = (userID, name, email, company, role, region, location, iapPlanner, bookingFocals) => {
  const bodyParams = {
    user_id: userID,
    display_name: name,
    email: email,
    division_id: company,
    role_ids: role,
    region_ids: region,
    location_names: location,
    iap_planner_ids: iapPlanner,
    booking_focal_ids: bookingFocals
  }
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_CONTROL_NAME}/usersAccessControl`, bodyParams))
}

export const deleteAdminUser = (userID) => {
  const bodyParams = {
    data: {
      user_id: userID,
      is_permanent_delete: false,
    },
  }

  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_CONTROL_NAME}/user`, bodyParams))
}

export const postUacDropdownCompany = () => {
  const bodyParams = {
    dd_type: 'company',
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/dropdown`, bodyParams))
}

export const postUacDropdownRole = () => {
  const bodyParams = {
    dd_type: 'role',
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/dropdown`, bodyParams))
}

export const postUacDropdownFeature = (roleIdList) => {
  const bodyParams = {
    dd_type: 'feature',
    role_ids: roleIdList,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/dropdown`, bodyParams))
}

export const postUacDropdownRegion = () => {
  const bodyParams = {
    dd_type: 'region',
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/dropdown`, bodyParams))
}

export const postUacDropdownLocation = (regionIdList, companyList) => {
  const bodyParams = {
    dd_type: 'location',
    region_ids: regionIdList,
    division_ids: companyList
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/dropdown`, bodyParams))
}

export const postUacDropdownIapPlanner = () => {
  const bodyParams = {
    dd_type: 'iapplanner',
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_CONTROL_NAME}/dropdown`, bodyParams))
}

export const getTenantsApi = () => { 
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_CONTROL_NAME}/tenants`))
}

export const switchCompanyApiForSuperAdmin = (companyId) => {
  const bodyParams = {
    new_division_id: companyId,
  }
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_CONTROL_NAME}/super-admin/company-switch`, bodyParams))
}