import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { ReactSVG } from 'react-svg'
import { Link, useLocation } from 'react-router-dom'
import moment from "moment";

// Components
import {
  Divider,
  Icon,
  Popup
} from 'semantic-ui-react'

import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import Modal from './component/modal'
import ReportIncidenceSideBar from './component/report-incidence-sidebar'
import ModalContentReportIncidenceMap from './component/report-incidence-map'
import ModalContentRateCompletedTrip from './component/rate-completed-trip'
import ActiveLog from './component/activeLog/view'
import { isMobileScreen } from "../../../helpers/isMobileScreen";
import FullScreenPopUP from "../../../components/full-screen-popup";

// Hooks
import { useStateWithPaths, useActions } from '../../../hooks/useConnect'

// Redux
import { NOTIFICATION_REDUCER } from '../../../reducers/common/notificationReducer'
import { EXPLORE_PAGE_REDUCER } from '../../../reducers/explorePageReducer'
import { GENERAL_REDUCER } from '../../../reducers/common/generalReducer'

// Actions
import * as NotificationActions from '../../../actions/common/actionNotification'
import * as HeaderTitleActions from '../../../actions/common/actionHeaderTitle'
import * as OptimisePageActions from '../../../actions/actionOptimise'

import * as ExploreActions from '../../../actions/actionExplore'
import * as GeneralActions from '../../../actions/common/actionGeneral'

// Assets
import CLOSE_ICON from '../../../assets/images/close.svg'
import CustomLoader from '../../custom-loader/customLoader'
import NotificationIconWhite from '../../../assets/images/stear-notification-white.svg'
import { CATEGORY_SCHEDULE_D3_VOYAGE_STATUS } from '../../../constants/optimise';

export const NotificationItem = (props) => {
  const {
    item,
    setIsOpenModal,
    setShowReportIncidence,
    setSelectedNotification,
    idNotificationClicked,
    getIdNotification,
    setIdNotificationClicked,
    setIsLoading,
    postVesselTrackingIncidentUserComment,
    activityLogs,
    resetActiveLogs,
    setSystemActiveLogs,
    handleClosePopup,
    isMobile = false
  } = props

  const [isNeedRender, setIsNeedRender] = useState(false)
  const [isOpenSee, setIsOpenSee] = useState(false)

  const { getNotificationDetails } = useActions({
    getNotificationDetails: NotificationActions.getNotificationDetails,
  });

  const handleLoadActiveLog = (item, event) => {
    event.stopPropagation() // prevent parent event
    setIsOpenSee(!isOpenSee)

    if (getIdNotification(item) !== idNotificationClicked || _.isEmpty(activityLogs)) {
      setIsOpenSee(true)
      resetActiveLogs()
      setIdNotificationClicked(getIdNotification(item))
      postVesselTrackingIncidentUserComment(item, setIsLoading)
      setSystemActiveLogs(item)
    }
    setIsNeedRender(!isNeedRender)
  }

  const getNotifDetail = (notification_type_id, trip_id, seq, location, notification) => {
    getNotificationDetails(notification_type_id, trip_id, seq, location, notification)
      .then((res) => {
        setSelectedNotification(res);
        handleClosePopup();
        setIsOpenModal(true)
        if (notification_type_id === 2) {
          setShowReportIncidence(true)
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getIdNotification(item) === idNotificationClicked && setIsNeedRender(!isNeedRender)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.has_activity_log, activityLogs.length])
  
  return (<div className={`flex mar-bottom-0_5 space-between`}>
      {(
        <div
          className={`${!isMobile && 'flex'} pad-x-1`}
          style={isMobile ? {flex: '100%'} : {}}
          onClick={() => {
            getNotifDetail(item.notification_type_id, item.trip_id, item.seq, item.location, item.notification);
            setIdNotificationClicked(getIdNotification(item));
          }}
        >
          <div className={`flex column notification-itemline`}>
            <div className={`notification-title ${!item.is_read && 'unread'}`}>
              {item.notification_type_id === 1 ? 
              (`${item.vessel_name} has completed the trip on ${moment.utc(item.actual_arrival_date).format("DD MMMM")} at ${item.location}, sequence ${item.seq}`)
              : 
                <>
                  <span className={isMobile ? `mobile-notification-title ${!item.is_read ? 'unread' : ''}` : ''}>{item.notification} at {item.location}, sequence {item.seq}</span>
                  {isMobile ?<><br /></> : 'for'} 
                  <span className={isMobile ? 'mobile-notification-sub-title' : ''}>Voyage ID: {item.trip_id}</span>
                </>
              }
            </div>
            <p className={`notification-subtitle ${isMobile ? 'mobile-subtitile-color' : ''}`}>
              {item.notification_type_id === 1 ? 
                (<>Please rate this trip.</>)
                :
                (<>{!item.has_activity_log ? (
                  <> incidence or provide a reason.</>
                ) : (
                  <>
                    An activity log has been added.
                    {isMobile && <><br /><br /></>}
                    <span
                      className={`btn-see ${isMobile ? 'mobile-subtitile-btn' : ''}`}
                      onClick={(event) => {
                        handleLoadActiveLog(item, event)
                      }}
                    >
                      {' '}
                      {!(isOpenSee && getIdNotification(item) === idNotificationClicked) &&
                        <>{isMobile ? <> show more <Icon name='chevron down'/> </>: 'See more...'}</>}{' '}
                    </span>
                    {!_.isEmpty(activityLogs) &&
                      isOpenSee &&
                      getIdNotification(item) === idNotificationClicked && (
                        <ActiveLog data={activityLogs} disabled isMobile={isMobile}></ActiveLog>
                      )}
                      <span
                        className={`btn-see ${isMobile ? 'mobile-subtitile-btn' : ''}`}
                        onClick={(event) => {
                          handleLoadActiveLog(item, event)
                        }}
                      >
                        {' '}
                        {isOpenSee && getIdNotification(item) === idNotificationClicked &&
                          <>{isMobile ? <> Show less <Icon name='chevron up'/> </>: 'See less'}</>}{' '}
                      </span>  
                  </>
                )}</>)
              }
              
            </p>
            <div className={isMobile ? 'mobile-subtitile-date' :'notification-time'}>{moment.utc(item.notification_date).format("HH:mm · DD MMM YYYY")}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export const NotificationContent = (props) => {
  const {
    setIsOpenModal,
    setShowReportIncidence,
    setSelectedNotification,
    selectedNotification,
    saveUserNotificationStatus,
    isLoading,
    setIsLoading,
    postVesselTrackingIncidentUserComment,
    activityLogs,
    resetActiveLogs,
    setSystemActiveLogs,
    handleClosePopup,
    isMobile=false
  } = props


  const [idNotificationClicked, setIdNotificationClicked] = useState(undefined)
  const [notificationReducer] = useStateWithPaths([`${NOTIFICATION_REDUCER}`])

  const notificationListing = notificationReducer?.notificationPage?.notificationListing ?? [];
  const totalNotifications = notificationReducer?.notificationPage?.totalNotifications ?? 0;
  const { getNotificationListing, popHeaderTitleAndCallBack, getScheduleStatusDMinus3 } = useActions({
    getNotificationListing: NotificationActions.getNotificationListing,
    popHeaderTitleAndCallBack: HeaderTitleActions.popHeaderTitleAndCallBack,
    getScheduleStatusDMinus3: OptimisePageActions.getScheduleStatusDMinus3
  });

  useEffect(() => {
    getListing();
  }, [])

  const getListing = () => {
    getNotificationListing("", 0, 10, null, false);
  }

  useEffect(() => {
    if (!selectedNotification.is_read) {
      saveUserNotificationStatus(
        [{ ...selectedNotification, is_read: true }],
        null,
        setTimeout(function () {
          getListing();
        }, 2000)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotification])

  const getIdNotification = (noti) => `${noti.trip_id}-${noti.notification_type}-${noti.vessel_id}-${noti.region}-${noti.seq}-${noti.berthing_date}-${noti.location}-${noti.notification}`
  const notification_list_style = isMobile ? {} : {height: "84%"}
  return (
    <div className={`notification-dimmer`}>
      <div className={`notification-list`} 
      style={notification_list_style} 
      >
        {!isMobile && 
        <div className={`notification-header-cover`}>
          <div className={`notification-label-section flex`}>
            Notifications
          </div>
        </div>
        }
        <div className={`notification-list-body`} 
          style={{overflowY: `${totalNotifications === 0 ? 'hidden' : 'scroll'}`}}
        >         
          {totalNotifications > 0 && _.map(notificationListing.slice(0, 5), (item, index) => (
                <div className="notification-itemwrapper" key={`${index}`}>
                  <div className="fetch-state-container width-100" style={{ position: 'relative' }}>
                    <div
                      className="fetch-state-container width-100"
                      style={{ position: 'relative' }}
                    >
                      {getIdNotification(item) === idNotificationClicked && isLoading && (
                        <CustomLoader isActive={true} />
                      )}
                      <NotificationItem
                        item={item}
                        setIsOpenModal={setIsOpenModal}
                        setShowReportIncidence={setShowReportIncidence}
                        setSelectedNotification={setSelectedNotification}
                        idNotificationClicked={idNotificationClicked}
                        getIdNotification={getIdNotification}
                        setIdNotificationClicked={setIdNotificationClicked}
                        saveUserNotificationStatus={saveUserNotificationStatus}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        postVesselTrackingIncidentUserComment={
                          postVesselTrackingIncidentUserComment
                        }
                        activityLogs={activityLogs}
                        resetActiveLogs={resetActiveLogs}
                        setSystemActiveLogs={setSystemActiveLogs}
                        handleClosePopup={handleClosePopup}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>

                  <Divider className={`mar-bottom-0 mar-top-0`} />
                </div>
              )
          )}
          {totalNotifications === 0 && (
            <div className="notification-nomsg">There are no unread notifications.</div>
          )}
        </div>
        
        <div className={`notification-footer`} onClick={popHeaderTitleAndCallBack}>
          {totalNotifications > 0 && (
            <Link to={'/notifications'}>View All</Link>
          )} 
        </div>
      </div>
    </div>
  )
}

const NotificationMenu = (props) => {
  // Actions Calls
  const {
    saveGreenNotification,
    getIncidentReasons,
    saveUserNotificationStatus,
    setCurrentVoyage,
    setPlanVesselData,
    setTripItem,
    postVesselTrackingIncidentUserComment,
    setSystemActiveLogs,
    resetActiveLogs,
    setPastVoyage,
  } = useActions({
    saveGreenNotification: NotificationActions.postSaveVoyageCompleted,
    getIncidentReasons: NotificationActions.postIncidentReasons,
    saveUserNotificationStatus: NotificationActions.postSaveUserNotifications,
    setCurrentVoyage: ExploreActions.setCurrentVoyage,
    setPlanVesselData: ExploreActions.setPlanVesselData,
    setTripItem: GeneralActions.setTripItem,
    postVesselTrackingIncidentUserComment: NotificationActions.postVesselTrackingIncidentUserComment,
    setSystemActiveLogs: NotificationActions.setSystemActiveLogs,
    resetActiveLogs: NotificationActions.resetActiveLogs,
    setPastVoyage: ExploreActions.setPastVoyage,
  })

  const isMobile = isMobileScreen();

  const [{ explorePage }] = useStateWithPaths([`${EXPLORE_PAGE_REDUCER}`])
  const [notificationReducer] = useStateWithPaths([`${NOTIFICATION_REDUCER}`])
  const [generalReducer] = useStateWithPaths([`${GENERAL_REDUCER}`])
  const tripItem = generalReducer?.general.tripItem

  const [selectedNotification, setSelectedNotification] = useState({})
  const { pathname } = useLocation()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenModalMsg, setIsOpenModalMsg] = useState(false)
  const [showReportIncidence, setShowReportIncidence] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const totalNotifications = notificationReducer?.notificationPage?.totalNotifications ?? 0;
  const { getNotificationListing } = useActions({
    getNotificationListing: NotificationActions.getNotificationListing
  });

  const [isOpenPopup, setIsOpenPopup] = useState(false)

  const handleOpenPopup = () => {
    setIsOpenPopup(true)
  }

  const handleClosePopup = () => {
    setIsOpenPopup(false)
  }

  const onClickSave = (item, userRating, userComment) => {
    saveGreenNotification(item, userRating, userComment)
    resetAllValue()
  }

  const resetAllValue = () => {
    setSelectedNotification({})
    setIsOpenModal(false)
    setIsOpenModalMsg(false)
    setShowReportIncidence(false)
  }

  const setFilterTripIDtoExplorePage = () => {
    if (pathname.includes('explore')) {
      setIsOpenModal(false)
      setShowReportIncidence(false)
    }
    if (selectedNotification) {
      setTripItem(selectedNotification)
    }
  }

  useEffect(() => {
    getNotificationListing("", 0, 10, null, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    resetActiveLogs()
    if (tripItem?.isActive || tripItem?.bunkering_ship_name) {
      setCurrentVoyage(tripItem.vessel_id, tripItem.trip_id)
      setPlanVesselData(tripItem.vessel_id, tripItem.trip_id)
      postVesselTrackingIncidentUserComment(tripItem)
      setSystemActiveLogs(tripItem)
    }
  }, [selectedNotification])

  useEffect(() => {
    if (isOpenModalMsg) {
      setTimeout(function () {
        setIsOpenModalMsg(!isOpenModalMsg)
      }, 4000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModalMsg])


  useEffect(() => {
    if (!_.isEmpty(explorePage?.cleanScheduleData)) {
      const payload = {
        starting_location: explorePage.cleanScheduleData[0]?.location || '',
        last_location: _.last(explorePage.cleanScheduleData)?.location || '',
      }
      getIncidentReasons(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [explorePage?.cleanScheduleData])

  /**const getNumberOfNotification = () => {
    if (notificationList.length > 999) return '999'
    return _.reduce(
      notificationList,
      (sum, item) => {
        if (!item.is_removed) {
          sum = sum + 1
        }
        return sum
      },
      0
    )
  }**/

  const reflectActiveLogs = () => {
    setCurrentVoyage(selectedNotification.vessel_id, selectedNotification.trip_id)
    setPastVoyage(selectedNotification.vessel_id, selectedNotification.trip_id)
  }

  return (
    <>
      <div className={`notification-menu-container ${props.type}`}>
        <FetchStateWrapper id={NotificationActions.POST_SAVE_VOYAGE_COMPLETED} />
        <FetchStateWrapper id={NotificationActions.POST_INCIDENT_REASONS} />
        <FetchStateWrapper
          id={NotificationActions.POST_SAVE_VESSEL_TRACKING_STATUS_REASON}
        />
        <FetchStateWrapper id={NotificationActions.DELETE_ACTIVE_LOG} />
        {!isMobile ? <Popup
          content={
            <NotificationContent
              setIsOpenModal={setIsOpenModal}
              setShowReportIncidence={setShowReportIncidence}
              setSelectedNotification={setSelectedNotification}
              selectedNotification={selectedNotification}
              saveUserNotificationStatus={saveUserNotificationStatus}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              postVesselTrackingIncidentUserComment={
                postVesselTrackingIncidentUserComment
              }
              activityLogs={notificationReducer?.notificationPage.activityLogs}
              resetActiveLogs={resetActiveLogs}
              setSystemActiveLogs={setSystemActiveLogs}
              handleClosePopup={handleClosePopup}
            />}
          on='click'
          open={window.location.pathname !== '/notifications' ? isOpenPopup : false}
          onClose={handleClosePopup}
          onOpen={handleOpenPopup}
          pinned
          trigger={
            <div className="cursor-pointer">
              <div
                className={`new-notification-red ${
                  totalNotifications > 0 ? "" : "none"
                }`}
              >
              </div>
              <ReactSVG src={NotificationIconWhite} />
            </div>}
          position='bottom right'
          className={`popup-notification ${
            totalNotifications > 0 ? "" : "height-mini"
          }`}
        /> :
        <>
        <div className="cursor-pointer" onClick={() => setIsOpenPopup(!isOpenPopup)}>
          <div
            className={`new-notification-red ${
              totalNotifications > 0 ? "" : "none"
            }`}
          >
          </div>
          <ReactSVG src={NotificationIconWhite} />
        </div>
        {isOpenPopup && (<FullScreenPopUP
          headerTitle={"Back"}
          title={"Notifications"}
          onClickCloseBtn={handleClosePopup}
        >
          <NotificationContent
            setIsOpenModal={setIsOpenModal}
            setShowReportIncidence={setShowReportIncidence}
            setSelectedNotification={setSelectedNotification}
            selectedNotification={selectedNotification}
            saveUserNotificationStatus={saveUserNotificationStatus}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            postVesselTrackingIncidentUserComment={
              postVesselTrackingIncidentUserComment
            }
            activityLogs={notificationReducer?.notificationPage.activityLogs}
            resetActiveLogs={resetActiveLogs}
            setSystemActiveLogs={setSystemActiveLogs}
            handleClosePopup={handleClosePopup}
            isMobile={isMobile}
          />
        </FullScreenPopUP>)}
        </>}
        {showReportIncidence && (
          <ReportIncidenceSideBar
            setShowReportIncidence={setShowReportIncidence}
            incidentReasons={
              notificationReducer?.notificationPage.incidentReasons
            }
            incidentReasonsDescription={
              notificationReducer?.notificationPage.incidentReasonsDescription
            }
            selectedNotification={selectedNotification}
            setIsOpenModal={setIsOpenModal}
            setFilterTripIDtoExplorePage={setFilterTripIDtoExplorePage}
            activityLogs={notificationReducer?.notificationPage.activityLogs}
            reflectActiveLogs={reflectActiveLogs}
            isMobile={isMobile}
            showReportIncidence={showReportIncidence}
          />
        )}

        <Modal show={isOpenModal} className={`modal-notification`}>
          {selectedNotification.notification_type_id === 1 ? (
            <ModalContentRateCompletedTrip
              setIsOpenModal={setIsOpenModal}
              data={selectedNotification}
              onClickSave={onClickSave}
              setFilterTripIDtoExplorePage={setFilterTripIDtoExplorePage}
              isMobile={isMobile}
              isOpenModal={isOpenModal}
            />
          ) : (!isMobile && 
            <ModalContentReportIncidenceMap
              setIsOpenModal={setIsOpenModal}
              setShowReportIncidence={setShowReportIncidence}
              currentVoyage={explorePage.cleanScheduleData}
              vesselData={explorePage.vesselData}
            />
          )}
        </Modal>

        <Modal show={isOpenModalMsg} className={`modal-msg`}>
          <div className={`rate-modal-body pad-1`}>
            <div className={`flex`}>
              <div className={`rate-modal-title mar-bottom-2`}>
                Thank you for Rating
              </div>
              <div className={`close-icon-modal`}>
                <ReactSVG
                  src={CLOSE_ICON}
                  onClick={() => setIsOpenModalMsg(false)}
                />
              </div>
            </div>
            <div className={`flex mar-bottom-1_5`}>
              Thank you for providing the rating. Your Rating has been saved
              successfully.
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default NotificationMenu
