import { fetchError, fetchStart, fetchSuccess } from "./common/actionFetch";
import * as services from "../services/serviceFuelCapping";

export const GET_FUEL_CAPPING_SECTIONS_DATA = "GET_FUEL_CAPPING_SECTIONS_DATA";
export const getFuelCappingSectionsData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_SECTIONS_DATA));
    services
      .getFuelCappingSectionsData(data)
      .then((response) => {
        dispatch({
          type: GET_FUEL_CAPPING_SECTIONS_DATA,
          payload: response.result,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_SECTIONS_DATA)))
      .catch(() => dispatch(fetchError(GET_FUEL_CAPPING_SECTIONS_DATA)));
  };
};

export const FUEL_CAPPING_SELECT_VOYAGES = "FUEL_CAPPING_SELECT_VOYAGES";
export const selectVoyagesFuelCappingSectionData = (voyages) => {
  return (dispatch) => {
    dispatch({
      type: FUEL_CAPPING_SELECT_VOYAGES,
      payload: voyages,
    });
  };
};

export const FUEL_CAPPING_SAVE_HEADERID = 'FUEL_CAPPING_SAVE_HEADERID';
export const saveHeaderId = (headerId) => {
  return (dispatch) => {
    dispatch({
      type: FUEL_CAPPING_SAVE_HEADERID,
      payload: headerId,
    });
  };
};

export const FUEL_CAPPING_SAVE_BUFFER_SECTION = "FUEL_CAPPING_SAVE_BUFFER_SECTION";
export const saveBufferToRedux = (data) => {
  return (dispatch) => {
    dispatch({
      type: FUEL_CAPPING_SAVE_BUFFER_SECTION,
      payload: data,
    });
  };
};

export const FUEL_CAPPING_SAVE_OFFSHORE_SECTION = "FUEL_CAPPING_SAVE_OFFSHORE_SECTION";
export const saveOffshoreToRedux = (data) => {
  return (dispatch) => {
    dispatch({
      type: FUEL_CAPPING_SAVE_OFFSHORE_SECTION,
      payload: data,
    });
  };
};

export const FUEL_CAPPING_SAVE_ESR_SECTION = "FUEL_CAPPING_SAVE_ESR_SECTION";
export const saveESRToRedux = (data) => {
  return (dispatch) => {
    dispatch({
      type: FUEL_CAPPING_SAVE_ESR_SECTION,
      payload: data,
    });
  };
};

export const UPDATE_FUEL_CAPPING_OFFSHORE_OPERATION = "UPDATE_FUEL_CAPPING_OFFSHORE_OPERATION";
export const updateFuelCappingOffshoreOperation = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(UPDATE_FUEL_CAPPING_OFFSHORE_OPERATION));
    services
      .updateFuelCappingOffshoreOperation(data)
      .then((response) => {
        dispatch({
          type: UPDATE_FUEL_CAPPING_OFFSHORE_OPERATION,
          payload: response?.result,
        });
        if (response) cb(null, response);
      })
      .then(() => dispatch(fetchSuccess(UPDATE_FUEL_CAPPING_OFFSHORE_OPERATION)))
      .catch((err) => {
        cb(err, null);
        dispatch(fetchError(UPDATE_FUEL_CAPPING_OFFSHORE_OPERATION));
      });
  };
};

export const UPDATE_FUEL_CAPPING_BUFFER_SECTION = "UPDATE_FUEL_CAPPING_BUFFER_SECTION";
export const updateFuelCappingBufferSection = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(UPDATE_FUEL_CAPPING_BUFFER_SECTION));
    services
      .updateFuelCappingBufferSection(data)
      .then((response) => {
        dispatch({
          type: UPDATE_FUEL_CAPPING_BUFFER_SECTION,
          payload: response?.result,
        });
        if (response) cb(null, response);
      })
      .then(() => dispatch(fetchSuccess(UPDATE_FUEL_CAPPING_BUFFER_SECTION)))
      .catch((err) => {
        cb(err, null);
        dispatch(fetchError(UPDATE_FUEL_CAPPING_BUFFER_SECTION));
      });
  };
};

export const GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA =
  "GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA";
export const getExternalSupplyRunData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA));
    services
      .getExternalSupplyRunData(data)
      .then((response) => {
        if (response?.is_valid) {
          dispatch({
            type: GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA,
            payload: response?.result,
          });
        }
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA)))
      .catch(() => dispatch(fetchError(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA)));
  };
};

export const GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_TYPES =
  "GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_TYPES";
export const getAllExternalSupplyRunTypes = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_TYPES));
    services
      .getAllExternalSupplyRunTypes()
      .then((response) => {
        if (response?.is_valid) {
          dispatch({
            type: GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_TYPES,
            payload: response?.result,
          });
        }
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_TYPES)))
      .catch(() => dispatch(fetchError(GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_TYPES)));
  };
};

export const CALCULATE_FUEL_CAPPING_SUMMARY = "CALCULATE_FUEL_CAPPING_SUMMARY";
export const calculateFuelCappingSummary = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(CALCULATE_FUEL_CAPPING_SUMMARY));
    services
      .calculateFuelCappingSummary(data)
      .then((response) => {
        dispatch({
          type: CALCULATE_FUEL_CAPPING_SUMMARY,
          payload: response?.result,
        });
        if (response) cb?.(null, response);
      })
      .then(() => dispatch(fetchSuccess(CALCULATE_FUEL_CAPPING_SUMMARY)))
      .catch((err) => {
        cb?.(err, null);
        dispatch(fetchError(CALCULATE_FUEL_CAPPING_SUMMARY));
      });
  };
};

export const GET_FUEL_CAPPING_SUMMARY = "GET_FUEL_CAPPING_SUMMARY";
export const getFuelCappingSummary = (data, cb) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_SUMMARY));
    services
      .getFuelCappingSummary(data)
      .then((response) => {
        dispatch({
          type: GET_FUEL_CAPPING_SUMMARY,
          payload: response.data,
        });
        cb?.(null, response);
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_SUMMARY)))
      .catch((err) => {
        cb?.(err, null);
        dispatch(fetchError(GET_FUEL_CAPPING_SUMMARY));
      });
  };
};

export const GET_FUEL_CAPPING_SUMMARY_LIST = "GET_FUEL_CAPPING_SUMMARY_LIST";
export const getFuelCappingSummaryList = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CAPPING_SUMMARY_LIST));
    services
      .getFuelCappingSummaryList(data)
      .then((response) => {
        dispatch({
          type: GET_FUEL_CAPPING_SUMMARY_LIST,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CAPPING_SUMMARY_LIST)))
      .catch((err) => {
        dispatch(fetchError(GET_FUEL_CAPPING_SUMMARY_LIST));
      });
  };
};
