import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import jwt from 'jsonwebtoken'

const token = localStorage.getItem('idToken')
const session = jwt.decode(token)

const CONTROLLER_NAME = '/planning'

export const setValidateVesselSchedule = (vesselSchedulesValue) => {
  const bodyParams = {
    updated_by: session.name,
    vessel_schedules: vesselSchedulesValue,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/validateVesselSchedules`, bodyParams))
}

export const setInsertVesselSchedule = (vesselSchedulesValue) => {
  const bodyParams = {
    vesselSchedules: vesselSchedulesValue,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/insertVesselSchedules`, bodyParams))
}

export const postScheduleOptionValidateInsert = (vesselSchedulesValue) => {
  const bodyParams = {
    existing_upload_id: null,
    updated_by: session.name,
    schedule_options: vesselSchedulesValue,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/scheduleOptionValidateInsert`, bodyParams))
}

export const postScheduleOptionLocationData = (upload_id) => {
  const bodyParams = {
    upload_id,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/scheduleOptionLocationData`, bodyParams))
}

export const postScheduleOptionKpiData = (upload_id) => {
  const bodyParams = {
    upload_id,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/scheduleOptionKpiData`, bodyParams))
}

export const postScheduleOptionVesselData = (upload_id, option_number) => {
  const bodyParams = {
    upload_id,
    option_number,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/scheduleOptionVesselData`, bodyParams))
}
