import * as deleteVesselPageActions from "../actions/actionDeleteVessel";

const defaultState = {
  deleteVesselPage: {
    data: "",
    dropdown: "",
    message: ""
  },
};

const REDUCERS = {
  [deleteVesselPageActions.POST_VESSEL_SCHEDULE_DATA]: (state, { payload }) => {
    return {
      ...state,
      deleteVesselPage: {
        ...state.deleteVesselPage,
        data: payload,
      },
    };
  },
  [deleteVesselPageActions.POST_DELETE_VESSEL_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      deleteVesselPage: {
        ...state.deleteVesselPage,
        message: payload,
      },
    };
  },
};
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const DELETE_VESSEL_PAGE_REDUCER = "deleteVesselPage";
