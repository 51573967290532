import Axios from 'axios';
import _ from 'lodash';

import { LAMBDA_API_ENDPOINT_URL, SUBSCRIPTION_KEY } from '../../constants/environmentVariables';

import AuthService from '../../AuthService';

let auth = new AuthService();
let currentAccessToken = '';

const setCurrentAccessToken = (token) => {
  currentAccessToken = token;
};

const getLocalBaseUrl = (url) => {
  if (process.env.NODE_ENV !== 'development') return undefined;
  if (url.startsWith('/demand')) return process.env.REACT_APP_DEMAND_API_ENDPOINT;
  if (url.startsWith('/optimise')) return process.env.REACT_APP_OPTIMISE_API_ENDPOINT;
  if (url.startsWith('/messaging')) return process.env.REACT_APP_MESSAGING_API_ENDPOINT;
  if (url.startsWith('/supply')) return process.env.REACT_APP_SUPPLY_API_ENDPOINT;
  if (url.startsWith('/fms')) return process.env.REACT_APP_FMS_API_ENDPOINT;
  if (url.startsWith('/admin')) return process.env.REACT_APP_ADMIN_API_ENDPOINT;
  return process.env.REACT_APP_EXECUTE_API_ENDPOINT;
};

export const LAMBDA_API_INSTANCE = async (baseURL) => {
  const authResponse = JSON.parse(localStorage.getItem('authResponse') || '{}');
  setCurrentAccessToken(authResponse?.accessToken);

  const instance = Axios.create({
    baseURL: baseURL || LAMBDA_API_ENDPOINT_URL,
    headers: {
      'Content-Type': 'application/json', // charset=utf-8",
      // Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      Authorization: `Bearer ${currentAccessToken}`,
      'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY,
      'Cache-Control': 'no-store',
      Pragma: 'no-cache',
    },
  });

  instance.interceptors.response.use((response) => {
    return _.get(response, 'data');
  });
  instance.interceptors.request.use((config) => {
    config.baseURL = getLocalBaseUrl(config.url) || config.baseURL;
    return config;
  });

  return instance;
};

export const LAMBDA_API_UPLOAD = async () => {
  await auth.getToken().then((result) => {
    setCurrentAccessToken(result.accessToken);
  });

  return Axios.create({
    baseURL: LAMBDA_API_ENDPOINT_URL,
    headers: {
      // Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      Authorization: `Bearer ${currentAccessToken}`,
      'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY,
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-store',
      Pragma: 'no-cache',
    },
  });
};

// LAMBDA_API_INSTANCE.interceptors.request.use(
//   async function (config) {
//     const token = await getAccessToken()
//     if (token) config.headers.Authorization = `Bearer ${getAccessToken()}`

//     return config
//   },
//   function (error) {
//     return Promise.reject(error)
//   }
// )

// LAMBDA_API_INSTANCE.interceptors.response.use(
//   function (response) {
//     return _.get(response, 'data')
//   },
// function (error) {
//   if (Axios.isCancel(error)) {
//     return Promise.reject(error)
//   }

//   //const originalRequest = error.config;
//   const errorResponse = error.response

//   if (errorResponse) {
//     // Get latest access token and retry api when 401
//     // Max retry will redirect to logout
//     if (errorResponse.status === 401 || errorResponse.data.message === 'TOKEN_EXPIRED') {
//       serviceUnauthorized()
//     } else {
//       return Promise.reject(error)
//     }
//   } else if (error.request) {
//     // The request was made but no response was received
//     // `error.request` is an API_INSTANCE of XMLHttpRequest in the browser and an API_INSTANCE of
//     // http.ClientRequest in node.js
//     return Promise.reject(error)
//   } else {
//     // Something happened in setting up the request that triggered an Error
//     throw Error('Sorry! Something went wrong, please contact our administrator.')
//   }
// }
// )

// export function errorHandler(error) {
//   let message = ''

//   // PSM error status 403
//   if (error.response) {
//     if (error.response.data.message) {
//       message = error.response.data.message
//     } else {
//       message = JSON.parse(error.request.responseText).msg
//     }
//   } else {
//     message = error.message
//   }

//   console.log('Error:', message)
//   return message
// }
