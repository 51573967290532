import _ from 'lodash'
import moment from 'moment'

import * as masterServices from '../services/common/serviceMasterData'
import { getWMSCapabilities } from '../services/helper'

//Key Action
export const GET_DROPDOWN_WITH_OPTIONS = 'GET_DROPDOWN_WITH_OPTIONS'
export const getDropdownWithOptions = (
  { apiPath, storePath, bodyData },
  setIsLoading,
  cache
  // ,ignoreFetchState
) => {
  return (dispatch, getState) => {
    const currentState = _.get(getState(), `mainExplorePage.${storePath}`)
    if (cache && !_.isEmpty(currentState)) return
    setIsLoading && setIsLoading(true)
    masterServices
      .getMasterData(apiPath, bodyData)
      .then((result) => {
        dispatch({
          type: GET_DROPDOWN_WITH_OPTIONS,
          payload: {
            path: storePath,
            value: result,
          },
        })
      })
      .catch((e) => {
        //if (!ignoreFetchState) dispatch(fetchError(GET_MASTER_INPUT_DATA, e))
      })
      .finally(() => setIsLoading && setIsLoading(false))
    dispatch({
      type: RESET_DROPDOWN_DATA,
      payload: {
        path: storePath,
        value: [],
      },
      isAttach: true,
    })
  }
}

export const getRegionsDropdown = (bodyData, setIsLoading, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: `regions`,
    bodyData
  }
  return (dispatch) => {
    dispatch(getDropdownWithOptions(config, setIsLoading, isCache))
  }
}

export const getServicesDropdown = (bodyData, setIsLoading, isTrack = false, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: `services${isTrack ? 'Track' : ''}`,
    bodyData,
  }
  return (dispatch) => {
    dispatch(getDropdownWithOptions(config, setIsLoading, isCache))
  }
}

export const getVesselsDropdown = (bodyData, setIsLoading, isTrack = false, isCache) => {
  const config = {
    apiPath: 'dropdown',
    storePath: `vessels${isTrack ? 'Track' : ''}`,
    bodyData,
  }
  return (dispatch) => {
    dispatch(getDropdownWithOptions(config, setIsLoading, isCache))
  }
}

export const getDeparturesDropdown = (page_type, region, jobType, vessel, period, setIsLoading, isCache) => {
  const startDate = _.map(period, (a) => moment(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment(a.endDate).format('YYYY-MM-DD')).toString()

  const config = {
    apiPath: 'dropdown',
    storePath: 'departures',
    bodyData: {
      page: page_type,
      region: region,
      job_type: jobType,
      vessel_id: vessel,
      from_date: startDate,
      to_date: endDate,
    },
  }
  return (dispatch) => {
    dispatch(getDropdownWithOptions(config, setIsLoading, isCache))
  }
}

export const getVoyagesDropdown = (bodyData, setIsLoading, isCache) => {

  const config = {
    apiPath: 'dropdown',
    storePath: 'voyages',
    bodyData,
  }
  return (dispatch) => {
    dispatch(getDropdownWithOptions(config, setIsLoading, isCache))
  }
}

export const RESET_DROPDOWN_DATA = 'RESET_DROPDOWN_DATA'
export const resetDropdownData = (currentField) => {
  return {
    type: RESET_DROPDOWN_DATA,
    payload: {
      path: currentField,
      value: []
    },
  }
}

export const GET_WMS_CAPABILITIES = "GET_WMS_CAPABILITIES";
export const getWMSCapabilitiesRedux = () => {
  return async (dispatch) => {
    let resultBasic
    let resultMaritime
    try {
      resultBasic = await getWMSCapabilities("basic");
      resultMaritime = await getWMSCapabilities("maritime");
    } catch (error) {
      console.error(error)
    }
    dispatch({
      type: GET_WMS_CAPABILITIES,
      payload: {
        basic: resultBasic ?? {},
        maritime: resultMaritime ?? {},
      },
    });
  };
};