import * as services from '../../services/common/serviceReleaseNote'
import { fetchStart, fetchSuccess, fetchError } from './actionFetch'

export const GET_RELEASE_NOTE = 'GET_RELEASE_NOTE'
export const getReleaseNote = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_RELEASE_NOTE))
    services
      .getReleaseNote()
      .then((response) =>
        dispatch({
          type: GET_RELEASE_NOTE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_RELEASE_NOTE)))
      .catch(() => dispatch(fetchError(GET_RELEASE_NOTE)))
  }
}