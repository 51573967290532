import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import * as _ from 'lodash'

const CONTROLLER_NAME = '/managevessel'

export const getVesselManagement = () => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/vesselManagement`))
}

export const getVesselManagementDropdown = (ddtype, region = undefined) => {
  const bodyParams = {
    dd_type: ddtype,
    region: region
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/dropdown`, bodyParams))
}

export const postVesselManagement = (contractData) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselManagement`, contractData))
}

export const putVesselManagement = (contractData) => {
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/vesselManagement`, contractData))
}

export const deleteVesselManagement = (primaryManagementID) => {
  const bodyParams = {
    data: {
      primary_management_id: primaryManagementID,
    },
  }

  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_NAME}/vesselManagement`, bodyParams))
}


// ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ----------
export const getDropdown = (dropdown_category, objName, objValue) => {
  var bodyParams = `category`;
  if (!_.isEmpty(dropdown_category)) bodyParams += `=${dropdown_category}`;
  if (!_.isEmpty(objName)) bodyParams += `&${objName}=${objValue}`;

  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`/addVessel/dropdown?${bodyParams}`)
  );
};

export const postVesselRegistration = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`/addVessel/postAddVessel`, bodyParams)
  );
};