import * as UacActions from "../../actions/common/actionUac";

const defaultState = {
  uac: {},
  uacControl: {
    data: "",
    actionResponse: [],
  },
  dropdown: {
    company: [],
    role: [],
    features: [],
    region: [],
    location: [],
    iapPlanner: [],
  },
  tenants: [],
};

const REDUCERS = {
  [UacActions.POST_USER_ACCESS]: (state, { payload }) => {
    return {
      ...state,
      uac: payload,
    };
  },
  [UacActions.GET_USER_ACCESS_CONTROL_DETAIL]: (state, { payload }) => {
    const newUacControlData = state.uacControl?.data.map(item => 
      item.user_id === payload.data.user_id ? { ...item, ...payload.data } : item)
    return {
      ...state,
      uacControl: {
        ...state.uacControl,
        data: [...newUacControlData],
      }
    }
  },
  [UacActions.GET_USERS_WITH_FEATURE_LIST]: (state, { payload }) => {
    return {
      ...state,
      uacControl: {
        ...state.uacControl,
        usersWithFeature: payload.data,
      }
    }
  },
  [UacActions.RESET_USERS_WITH_FEATURE_LIST]: (state, { payload }) => {
    return {
      ...state,
      uacControl: {
        ...state.uacControl,
      }
    }
  },
  [UacActions.GET_USER_ACCESS_CONTROL]: (state, { payload }) => {
    return {
      ...state,
      uacControl: {
        ...state.uacControl,
        data: payload.data,
      },
    };
  },
  [UacActions.POST_USER_ACCESS_CONTROL]: (state, { payload }) => {
    return {
      ...state,
      uacControl: {
        ...state.uacControl,
        actionResponse: payload,
      },
    };
  },
  [UacActions.PUT_USER_ACCESS_CONTROL]: (state, { payload }) => {
    return {
      ...state,
      uacControl: {
        ...state.uacControl,
        actionResponse: payload,
      },
    };
  },
  [UacActions.PUT_CURRENT_USER_ACCESS_CONTROL]: (state, { payload }) => {
    payload.is_valid && window.location.reload(false)
    return {
      ...state,
    };
  },
  [UacActions.SWITCH_COMPANY_FOR_SUPER_ADMIN]: (state, { payload }) => {
    payload.is_valid && window.location.reload(false)
    return {
      ...state,
    };
  },
  [UacActions.DELETE_ADMIN_USER]: (state, { payload }) => {
    return {
      ...state,
      uacControl: {
        ...state.uacControl,
        actionResponse: payload,
      },
    };
  },
  [UacActions.POST_COMPANY_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      dropdown: {
        ...state.dropdown,
        company: payload,
      },
    };
  },
  [UacActions.POST_ROLE_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      dropdown: {
        ...state.dropdown,
        role: payload,
      },
    };
  },
  [UacActions.POST_FEATURE_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      dropdown: {
        ...state.dropdown,
        features: payload,
      },
    };
  },
  [UacActions.POST_REGION_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      dropdown: {
        ...state.dropdown,
        region: payload,
      },
    };
  },
  [UacActions.POST_LOCATION_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      dropdown: {
        ...state.dropdown,
        location: payload,
      },
    };
  },
  [UacActions.POST_IAP_PLANNER_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      dropdown: {
        ...state.dropdown,
        iapPlanner: payload,
      },
    };
  },
  [UacActions.GET_TENANT_LIST]: (state, { payload }) => {
    return {
      ...state,
      tenants: payload,
    };
  },
};
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};
export const UAC_REDUCER = "commonUac";
