import * as services from '../services/serviceDashboardSummary';

import { fetchError, fetchStart, fetchSuccess } from './common/actionFetch';

export const GET_FUEL_CONSUMPTION_SUMMARY_WIDGET = 'GET_FUEL_CONSUMPTION_SUMMARY_WIDGET';
export const getFuelConsumptionSummaryWidget = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_FUEL_CONSUMPTION_SUMMARY_WIDGET))
    services.getFuelConsumptionSummaryWidget(data)
      .then((response) => {
        const { body } = response;
        if(body && typeof body === 'string') 
          response.body = JSON.parse(body);
        dispatch({
          type: GET_FUEL_CONSUMPTION_SUMMARY_WIDGET,
          payload: response.body.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_FUEL_CONSUMPTION_SUMMARY_WIDGET)))
      .catch(() => dispatch(fetchError(GET_FUEL_CONSUMPTION_SUMMARY_WIDGET)))
  }
}

export const GET_AVERAGE_FUEL_PRICE_SUMMARY_WIDGET = 'GET_AVERAGE_FUEL_PRICE_SUMMARY_WIDGET';
export const getAverageFuelPriceSummaryWidget = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_AVERAGE_FUEL_PRICE_SUMMARY_WIDGET))
    services.getAverageFuelPriceSummaryWidget(data)
      .then((response) => {
        dispatch({
          type: GET_AVERAGE_FUEL_PRICE_SUMMARY_WIDGET,
          payload: response.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_AVERAGE_FUEL_PRICE_SUMMARY_WIDGET)))
      .catch(() => dispatch(fetchError(GET_AVERAGE_FUEL_PRICE_SUMMARY_WIDGET)))
  }
}

export const GET_PARTNER_REGIONS = "GET_PARTNER_REGIONS";
export const getPartnerRegions = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_PARTNER_REGIONS));
    services
      .getPartnerRegions(data)
      .then((response) => {
        dispatch({
          type: GET_PARTNER_REGIONS,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_PARTNER_REGIONS)))
      .catch(() => dispatch(fetchError(GET_PARTNER_REGIONS)));
  };
};

export const GET_DASHBOARD_SUMMARY_ALERTS = 'GET_DASHBOARD_SUMMARY_ALERTS';
export const getDashboardSummaryAlerts = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DASHBOARD_SUMMARY_ALERTS))
    services.getDashboardSummaryAlerts(data)
      .then((response) => {
        const { body } = response;
        if(body && typeof body === 'string') 
          response.body = JSON.parse(body);
        dispatch({
          type: GET_DASHBOARD_SUMMARY_ALERTS,
          payload: response.body.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_DASHBOARD_SUMMARY_ALERTS)))
      .catch(() => dispatch(fetchError(GET_DASHBOARD_SUMMARY_ALERTS)))
    }
  };

export const GET_MASS_BALANCE_WIDGET = 'GET_MASS_BALANCE_WIDGET';
export const getMassBalanceWidget = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MASS_BALANCE_WIDGET))
    services.getMassBalanceWidget(data)
      .then((response) => {
        dispatch({
          type: GET_MASS_BALANCE_WIDGET,
          payload: response.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_MASS_BALANCE_WIDGET)))
      .catch(() => dispatch(fetchError(GET_MASS_BALANCE_WIDGET)))
  }
}