import _ from 'lodash'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

const CONTROLLER_NAME = '/vessel-maintenance-log'


export const getMaintenanceLogList = (vessel_id) => {
  const bodyParams = {
    vessel_id,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/list`, bodyParams))
}

export const getTypeAndLocationDropdown = () => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/drop-down`))
}

export const getFilterDropdown = (vessel_id) => {
  const bodyParams = {
    vessel_id,
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/filter-drop-down`, bodyParams))
}

export const deleteMaintenanceTask = (maintenance_task_id, maintenance_id) => {
  const bodyParams = {
    data: {
      maintenance_id,
      maintenance_task_id
    }
  }
  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_NAME}/item`, bodyParams))
}


export const createUpdateMaintenanceLog = (formData) => {
  const bodyParams = {
    ...formData,
    schedule_maintenance_tasks: _.map(formData.schedule_maintenance_tasks, (item) => {
      if (_.isEqual(item.maintenance_type, 'Others') || _.isEqual(item.maintenance_location, 'Others')) {
        return {
          ...item,
          maintenance_type: _.isEqual(item.maintenance_type, 'Others') ? item.other_maintenance_type : item.maintenance_type,
          maintenance_location: _.isEqual(item.maintenance_location, 'Others') ? item.other_location : item.maintenance_location
        }
      } else return item

    })
  }
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/item`, bodyParams))
}
