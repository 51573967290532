import * as HeaderTitleActions from '../../actions/common/actionHeaderTitle';

const defaultState = {
    data: '',
    titleAndCallBackStack: [],
    socketConnection: null
};

const REDUCERS = {
    [HeaderTitleActions.SET_HEADER_TITLE]: (state, { payload }) => {
        return {
            ...state,
            data: payload
        };
    },
    [HeaderTitleActions.PUSH_HEADER_TITLE_CALLBACK_STACK]: (state, { payload }) => {
        return {
            ...state,
            titleAndCallBackStack: [...state.titleAndCallBackStack, {...payload}]
        };
    },
    [HeaderTitleActions.POP_HEADER_TITLE_CALLBACK_STACK]: (state, { payload }) => {
        return {
            ...state,
            titleAndCallBackStack: state.titleAndCallBackStack.slice(0, -1),
        };
    },
    [HeaderTitleActions.GET_SCHEDULE_STATUS_D3]: (state, { payload }) => {
        return {
            ...state,
            scheduleD3VoyageStatus: payload,
        };
    },
    [HeaderTitleActions.SET_SOCKET_CONNECTION]: (state, { payload }) => {
        return {
            ...state,
            socketConnection: payload,
        };
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, { type, payload, error }) => {
    if (REDUCERS[type]) {
      return REDUCERS[type](state, { payload, error });
    }
    return state;
};

export const HEADER_TITLE_REDUCER = "commonHeaderTitleReducer";