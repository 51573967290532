import React from "react";
import AuthService from "../../../AuthService";
import  accessDenied from "../../../assets/images/access-denied.svg";

// Components
import { Button } from "semantic-ui-react";

const UnauthorisedAccessPage = (props) => {
  const {
    title = 'Access Denied',
    message = 'Sorry, you are not authorized to access this page.',
  } = props;

  const logout = () => {
    let auth = new AuthService();
    auth.logout();
  };


  return (
    <div  className={`unauthorised-access-page`}>
      <div>
        <img src={accessDenied} alt="access denied" />
      </div>
      <div className={`titleArea`}>{title}</div>
      <div className={`messageArea`}>{message}</div>
      <Button primary className={`button-bg`}  onClick={() => logout()}>
        Back to Login Page
      </Button>
    </div>
  );
};

export default UnauthorisedAccessPage;
