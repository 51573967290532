import React from 'react';
import { useNavigate } from 'react-router-dom';

const MobileBackButton = (props) => {
    const navigate = useNavigate();
    const { headerTitleData } = props

    const handleOnClick = () => {
        const length = headerTitleData?.titleAndCallBackStack?.length || 0
        if(length > 0) {
            let cb = headerTitleData?.titleAndCallBackStack[length-1].cb
            cb();
            
        } else {
            navigate("/");
        }
    }

    return (
        <div className={`mobile-back-button arrow left`} onClick={handleOnClick}></div>
    );
}

export default MobileBackButton;