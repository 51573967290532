const SENTINEL_MODULE = 'SENTINEL';
//const PLATFORM_PLUS_MODULE = ['Demand', 'Execute', 'FMS', 'Optimize', 'VOD'];

export const isValidSentinelModule = () => {
  const accessModules = localStorage.getItem("accessModule");
  const accessModule = accessModules?.toLocaleLowerCase().split(',').map(module=> module.toLocaleLowerCase()) || [];
  return accessModule.includes(SENTINEL_MODULE.toLocaleLowerCase());
};

export const hasSentinelPermission = () => {
  return isValidSentinelModule();
};