export const SCHEDULE_STATUS_D3 = ["Completed", "Error"]

export const SCHEDULE_DS_STATUS_D3 = {
    completed: "Completed",
    error: "Error",
    processing: "Processing"
}

export const WARRNING_UPLOAD_DF_FILE = "Please upload demand fulfillment file to trigger D-3 optimization."

export const TYPE_OF_PLT_INTEGRATION = {
    postVoyageManual: "postVoyageManual",
    postVoyagePublish: "postVoyagePublish",
    putVoyage: "putVoyage",
    putD3OptimizedVoyage: "putD3OptimizedVoyage",
    deleteVoyage: "deleteVoyage"
}

export const CATEGORY_SCHEDULE_D3_VOYAGE_STATUS = {
    schedulePendingVerification : "schedule_pending_verification",
    scheduleByTripId: "schedule_by_trip_id"
}

export const SOCKET_EVENTS_NAME = {
    scenarioSelected: "scenario-selected",
    vesselSchedulePublished: "vessel-schedule-published",
    serviceDeleted: "service-deleted",
    d30Optimisation: "d30-optimisation",
    customScenarioSaved: "custom-scenario-saved",
    connect: "connect",
    connect_failed: "connect_failed",
    d3Optimisation: "d3-optimisation",
    updateStatusD3Voyage: "update-status-d3-voyage",
    updateReservationData: "update-reservation-data"
}

export const EXECUTED_VOYAGE_STATUS = ["Inprogress", "Enroute", "Completed"]