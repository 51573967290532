import _ from 'lodash'
import * as services from '../../services/common/serviceNotification'
import { fetchStart, fetchSuccess, fetchError } from './actionFetch'

export const POST_VOYAGE_COMPLETED = 'POST_VOYAGE_COMPLETED'
export const postVoyageCompleted = (data = {}) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VOYAGE_COMPLETED))
    services
      .postVoyageCompleted(data)
      .then((response) =>
        dispatch({
          type: POST_VOYAGE_COMPLETED,
          payload: { response, ...data },
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VOYAGE_COMPLETED)))
      .catch(() => dispatch(fetchError(POST_VOYAGE_COMPLETED)))
  }
}

export const POST_SAVE_VOYAGE_COMPLETED = 'POST_SAVE_VOYAGE_COMPLETED'
export const postSaveVoyageCompleted = (ratingDetail, userRating, userComment) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_VOYAGE_COMPLETED))
    services
      .postSaveVoyageCompleted(ratingDetail, userRating, userComment)
      .then((response) =>
        dispatch({
          type: POST_SAVE_VOYAGE_COMPLETED,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_VOYAGE_COMPLETED)))
      .catch(() => dispatch(fetchError(POST_SAVE_VOYAGE_COMPLETED)))
  }
}

export const POST_SHIP_TO_SHIP = 'POST_SHIP_TO_SHIP'
export const postShipToShip = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SHIP_TO_SHIP))
    services
      .postShipToShip()
      .then((response) =>
        dispatch({
          type: POST_SHIP_TO_SHIP,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SHIP_TO_SHIP)))
      .catch(() => dispatch(fetchError(POST_SHIP_TO_SHIP)))
  }
}

export const POST_VESSEL_TRACKING_STATUS_REASON = 'POST_VESSEL_TRACKING_STATUS_REASON'
export const postVesselTrackingStatusReason = (data = {}) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_TRACKING_STATUS_REASON))
    services
      .postVesselTrackingStatusReason(data)
      .then((response) =>
        dispatch({
          type: POST_VESSEL_TRACKING_STATUS_REASON,
          payload: { response, ...data },
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VESSEL_TRACKING_STATUS_REASON)))
      .catch(() => dispatch(fetchError(POST_VESSEL_TRACKING_STATUS_REASON)))
  }
}

export const POST_INCIDENT_REASONS = 'POST_INCIDENT_REASONS'
export const postIncidentReasons = (data) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_INCIDENT_REASONS))
    services
      .postIncidentReasons(data)
      .then((response) => {
        const incidentReasons = _.uniqBy(response, 'reason')?.map(item => ({
          text: item?.reason,
          value: item?.reason
        }))
        const incidentReasonsDescription = _.groupBy(response?.map(item => ({
          ...item,
          value: item.reason_id,
          text: item.reason_description,
        })), 'reason')
        return dispatch({
          type: POST_INCIDENT_REASONS,
          payload: { incidentReasons, incidentReasonsDescription },
        })
      }
      )
      .then(() => dispatch(fetchSuccess(POST_INCIDENT_REASONS)))
      .catch(() => dispatch(fetchError(POST_INCIDENT_REASONS)))
  }
}

export const POST_SAVE_VESSEL_TRACKING_STATUS_REASON = 'POST_SAVE_VESSEL_TRACKING_STATUS_REASON'
export const postSaveVesselTrackingStatusReason = (activity, setCurrentVoyage) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_VESSEL_TRACKING_STATUS_REASON))
    services
      .postSaveVesselTrackingStatusReason(activity)
      .then((response) => {
        if (response?.message.indexOf("successfully") > -1) {
          setCurrentVoyage && setCurrentVoyage()
          return dispatch({
            type: POST_SAVE_VESSEL_TRACKING_STATUS_REASON,
            payload: response,
          })
        }
      })
      .then(() => dispatch(fetchSuccess(POST_SAVE_VESSEL_TRACKING_STATUS_REASON)))
      .catch(() => dispatch(fetchError(POST_SAVE_VESSEL_TRACKING_STATUS_REASON)))
  }
}

export const POST_SAVE_USER_NOTIFICATIONS = "POST_SAVE_USER_NOTIFICATIONS"
export const postSaveUserNotifications = (
  checkedNotificationList,
  refreshNotificationList,
  setCheckedNotificationList,
  setIsLoading,
  resetCheckboxListForFlag = () => { }
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_USER_NOTIFICATIONS))
    setIsLoading && setIsLoading(true)
    services
      .postSaveUserNotificationStatus(checkedNotificationList)
      .then((response) => {
        if (response.is_valid) {
          dispatch({
            type: POST_SAVE_USER_NOTIFICATIONS,
            payload: checkedNotificationList
          })
        }
      })
      .then(() => {
        dispatch(fetchSuccess(POST_SAVE_USER_NOTIFICATIONS))
        // refreshNotificationList() // No neeed to be call API again to fetch new data
        setCheckedNotificationList([])
        resetCheckboxListForFlag()
      })
      .catch(() => dispatch(fetchError(POST_SAVE_USER_NOTIFICATIONS)))
      .finally(() => { setIsLoading && setIsLoading(false) })
  }
}

export const SET_GREEN_NOTIFYCATION_WITH_TRIP_ID = 'SET_GREEN_NOTIFYCATION_WITH_TRIP_ID'
export const setGreenNotificationWithTripId = (list) => {
  return {
    type: SET_GREEN_NOTIFYCATION_WITH_TRIP_ID,
    payload: list,
  }
}

export const SET_RED_NOTIFYCATION_WITH_TRIP_ID = 'SET_RED_NOTIFYCATION_WITH_TRIP_ID'
export const setRedNotificationWithTripId = (list) => {
  return {
    type: SET_RED_NOTIFYCATION_WITH_TRIP_ID,
    payload: list,
  }
}

export const DELETE_ACTIVE_LOG = 'DELETE_ACTIVE_LOG'
export const deleteActiveLog = (data, setCurrentVoyage) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_ACTIVE_LOG))
    services
      .postDeleteVesselTrackingIncidentUserComment(data.user_comment_id)
      .then(response => {
        if (response?.message.indexOf('Successfully') > -1) {
          dispatch({
            type: DELETE_ACTIVE_LOG,
            payload: data,
          })
          setCurrentVoyage && setCurrentVoyage()
        }
      })
      .then(() => dispatch(fetchSuccess(DELETE_ACTIVE_LOG)))
      .catch(() => dispatch(fetchError(DELETE_ACTIVE_LOG)))
  }
}

export const POST_VESSEL_TRACKING_INCIDENT_USER_COMMENT = 'POST_VESSEL_TRACKING_INCIDENT_USER_COMMENT'
export const postVesselTrackingIncidentUserComment = (selectedNotification, setIsLoading) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_TRACKING_INCIDENT_USER_COMMENT))
    setIsLoading && setIsLoading(true)
    services
      .postVesselTrackingIncidentUserComment(selectedNotification)
      .then((response) =>
        dispatch({
          type: POST_VESSEL_TRACKING_INCIDENT_USER_COMMENT,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VESSEL_TRACKING_INCIDENT_USER_COMMENT)))
      .catch(() => dispatch(fetchError(POST_VESSEL_TRACKING_INCIDENT_USER_COMMENT)))
      .finally(() => { setIsLoading && setIsLoading(false) })
  }
}

export const SET_SYSTEM_ACTIVE_LOGS = 'SET_SYSTEM_ACTIVE_LOGS'
export const setSystemActiveLogs = (selectedNotification) => {
  const list = selectedNotification.system_assigned_reason?.split(',')
    .filter(item => item?.trim())
    .map(value => ({
      is_self_comment: false,
      updated_date: selectedNotification.berthing_date,
      user_added_reason: value
    }))
  return {
    type: SET_SYSTEM_ACTIVE_LOGS,
    payload: list,
  }
}

export const RESET_ACTIVE_LOGS = 'RESET_ACTIVE_LOGS'
export const resetActiveLogs = () => {
  return {
    type: RESET_ACTIVE_LOGS,
    payload: [],
  }
}

export const POST_UPDATE_VESSEL_TRACKING_STATUS_REASON = "POST_UPDATE_VESSEL_TRACKING_STATUS_REASON"
export const postUpdateVesselTrackingStatusReason = (
  data,
  setCurrentVoyage
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPDATE_VESSEL_TRACKING_STATUS_REASON))
    services
      .postUpdateVesselTrackingStatusReason(data)
      .then((response) => {
        dispatch({
          type: POST_UPDATE_VESSEL_TRACKING_STATUS_REASON,
          payload: response
        })
        setCurrentVoyage && setCurrentVoyage()
      })
      .then(() => {
        dispatch(fetchSuccess(POST_UPDATE_VESSEL_TRACKING_STATUS_REASON))
      })
      .catch(() => dispatch(fetchError(POST_UPDATE_VESSEL_TRACKING_STATUS_REASON)))
  }
}

export const POST_RATING_USER_COMMENT = "POST_RATING_USER_COMMENT"
export const postRatingUserComment = (payload, method, setCurrentVoyage) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_RATING_USER_COMMENT))
    services
      .postRatingUserComment(payload, method)
      .then((response) => {
        if (response.is_valid) {
          setCurrentVoyage && setCurrentVoyage()
        }
      })
      .then(() => {
        dispatch(fetchSuccess(POST_RATING_USER_COMMENT))
      })
      .catch(() => dispatch(fetchError(POST_RATING_USER_COMMENT)))
  }
}


export const GET_NOTIFICATION_LISTING = "GET_NOTIFICATION_LISTING";
export const getNotificationListing = (query, offset, limit, is_flagged, is_read, all_notif = false) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_NOTIFICATION_LISTING));
    services
      .getNotificationListingApi(query, offset, limit, is_flagged, all_notif ? null : is_read)
      .then((response) =>
        dispatch({
          type: GET_NOTIFICATION_LISTING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_NOTIFICATION_LISTING)))
      .catch(() => dispatch(fetchError(GET_NOTIFICATION_LISTING)));
  };
};

export const GET_UNREAD_NOTIFICATION_COUNT = "GET_UNREAD_NOTIFICATION_COUNT";
export const getUnreadNotificationCount = (query, is_flagged) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_UNREAD_NOTIFICATION_COUNT));
    services
      .getNotificationListingApi(query, 0, 1, is_flagged, false)
      .then((response) =>
        dispatch({
          type: GET_UNREAD_NOTIFICATION_COUNT,
          payload: response?.total_notifications_count || 0,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_UNREAD_NOTIFICATION_COUNT)))
      .catch(() => dispatch(fetchError(GET_UNREAD_NOTIFICATION_COUNT)));
  };
};

export const GET_NOTIFICATION_DETAILS = "GET_NOTIFICATION_DETAILS";
export const getNotificationDetails = (notification_type_id, trip_id, seq, location, notification) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_NOTIFICATION_DETAILS));
    return services
      .getNotificationDetailsApi(notification_type_id, trip_id, seq, location, notification)
      .then((response) => {
        dispatch(fetchSuccess(GET_NOTIFICATION_DETAILS));
        return response;
      })
      .catch(() => dispatch(fetchError(GET_NOTIFICATION_DETAILS)));
  };
};