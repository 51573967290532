import { combineReducers } from "redux";

//Common
import masterDataReducer from "./common/masterDataReducer";
import notificationReducer from "./common/notificationReducer";
import sessionReducer from "./common/sessionReducer";
import systemReducer from "./common/systemReducer";
import personalSettingReducer from "./common/personalSettingReducer";
import fetchReducer from "./common/fetchReducer";
import generalReducer from "./common/generalReducer";
import uacReducer from "./common/uacReducer";
import releaseNoteReducer from "./common/releaseNoteReducer";
import messagingReducer from "./common/messagingReducer";
import headerTitleReducer from "./common/headerTitleReducer";

//Page Reducer
import mainPageReducer from "./mainPageReducer";
import kpiPageReducer from "./kpiPageReducer";
import explorePageReducer from "./explorePageReducer";
import speedMonitoringReducer from "./speedMonitoringPageReducer";
import planningPageReducer from "./planningPageReducer";
import bookingPageReducer from "./bookingPageReducer";
import deleteVesselPageReducer from "./deleteVesselPageReducer";
import optimisePageReducer from "./optimisePageReducer";
import landingPageReducer from "./landingPageReducer";
import vesselDetailReducer from "./vesselDetailReducer";
import vesselManagementReducer from "./vesselManagementPageReducer";
import mooringBuoyManagementPageReducer from "./mooringBuoyManagementPageReducer";
import dashboardPageReducer from "./dashboardPageReducer";
import mooringBuoyDetailReducer from "./mooringBuoyDetailReducer";
import mainExploreReducer from "./mainExploreReducer";
import reservationPageReducer from "./reservationPageReducer";
import vodPageReducer from "./vodPageReducer";
import locationPageReducer from "./locationManagementPageReducer";
import onboardingStatusReducer from "./common/onBoardingStatusReducer";
import systemAlertPageReducer from './systemAlertPageReducer';
import volumePageReducer from "./volumePageReducer";
import standardDailyAverageReducer from "./standardDailyAverageReducer";
import fuelCappingPageReducer from "./fuelCappingPageReducer";
import dashboardSummaryPageReducer from "./dashboardSummaryPageReducer";

const rootReducer = combineReducers({
  commonMasterData: masterDataReducer,
  commonNotification: notificationReducer,
  commonUac: uacReducer,
  commonSession: sessionReducer,
  commonSystem: systemReducer,
  commonPersonalSetting: personalSettingReducer,
  commonFetch: fetchReducer,
  commonGeneral: generalReducer,
  commonReleaseNote: releaseNoteReducer,
  commonMessaging: messagingReducer,
  commonHeaderTitleReducer: headerTitleReducer,
  commonOnboardingStatus: onboardingStatusReducer,
  mainPage: mainPageReducer,
  explorePage: explorePageReducer,
  kpiPage: kpiPageReducer,
  speedMonitoringPage: speedMonitoringReducer,
  planningPage: planningPageReducer,
  bookingPage: bookingPageReducer,
  deleteVesselPage: deleteVesselPageReducer,
  optimisePage: optimisePageReducer,
  landingPage: landingPageReducer,
  vesselManagementPage: vesselManagementReducer,
  vesselDetail: vesselDetailReducer,
  mooringBuoyManagementPage: mooringBuoyManagementPageReducer,
  dashboardPage: dashboardPageReducer,
  mooringBuoyDetailPage: mooringBuoyDetailReducer,
  mainExplorePage: mainExploreReducer,
  reservationPage: reservationPageReducer,
  vodPage: vodPageReducer,
  locationPage : locationPageReducer,
  systemAlertPageReducer: systemAlertPageReducer,
  volumePageReducer: volumePageReducer,
  standardDailyAverageReducer: standardDailyAverageReducer,
  fuelCappingPageReducer,
  dashboardSummaryPageReducer
});

export default rootReducer;
