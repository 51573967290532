import moment from "moment";

export const RESERVATION_STATUS = {
    cancelledByUser: 'Cancelled by User',
    pendingIapApproval: 'Pending Approval',
    voyageDetailsUpdated: 'Voyage Details Updated',
    voyageCancelled:'Voyage Cancelled',
    rejectedByIapPlanner:'Rejected',
    pendingBooking:'Pending Booking',
    completed: 'Completed',
    confirmed: 'Confirmed',
    enRoute: 'En Route',
    pendingVoyageAllocation: 'Pending Voyage Allocation',
    modifiedOLV: 'Modified in OLV'
}


export const RESERVATION_STATUS_FOR_INACTIVE = {
  cancelled: "Cancelled",
  rejected: "Rejected",
  expired: "Expired",
  completed: "Completed",
}

export const PERIOD_OF_WEEK = { 
  startDate: new Date(),
  endDate: new Date().setDate(new Date().getDate() + 7),
}

export const DROPDOWN_LIST_VOYAGE_DETAILS_PAGE = [
  {
    key: 'start_location'
  }, 
  {
    key: 'end_location'
  }, 
  {
    key: 'status'
  }
]

export const arrStatusCanBeCancel = [
    'Pending Approval',
    'Pending Booking',
    'Modified in OLV',
    'Voyage Details Updated',
    'Rejected',
    'Confirmed',
    'Pending Voyage Allocation'
  ]

  export const arrStatusCanBeEdit = [
    'Pending Approval',
    'Pending Booking',
    'Voyage Details Updated',
    'Rejected',
    'Confirmed'
  ]

//#region For render Icon and color
  export const arrDeleteStatus = [
  "Voyage Cancelled",
  "Cancelled by User",
  "Cancelled",
  "Rejected",
];

export const arrReadyStatus = ["Modified in OLV", "Completed", "En Route"];

export const arrWarningStatus = [
  "Voyage Details Updated",
  "Pending Voyage Allocation",
  "Pending Approval",
  "Pending Booking",
];

export const statusCanHaveEditedSecondaryStatus = ["Confirmed", "Pending Approval", "Pending Booking"]

export const statusCanHaveRejectedSecondaryStatus = ["Confirmed", "Pending Booking"]

export const statusCanHaveUpdatedSecondaryStatus = ["Confirmed", "Pending Approval", "Pending Booking"]

//#endregion 

export const defineDeckproperties = [
  {
    propertiesName: 'Payload Upper Deck',
    propertiesItem: ['Upper Deck Cargo'],
  },
  {
    propertiesName: 'Payload Under Deck',
    propertiesItem: ['Fuel Oil', 'Fresh Water'],
  },
  {
    propertiesName: 'Backload Upper Deck',
    propertiesItem: ['Upper Deck Cargo'],
  },
  {
    propertiesName: 'Backload Under Deck',
    propertiesItem: ['Oil Based Mud', 'Water Based Mud']
  }
]

export const defineTab = [
  {
    nameTab: 'Pending',
    status: ["Pending Booking", "Pending Approval", "Pending Voyage Allocation"],
  },
  {
    nameTab: 'Confirmed',
    status: ["Confirmed", "En Route"],
  },
  {
    nameTab: 'Inactive',
    status: ["Voyage Cancelled", "Cancelled by User", "Rejected", "Completed", "Expired"],
  }
]

export const filterTypeList = {
  pendingAndConfirm: ['All', 'Material', 'Passenger'],
  inactive: [
    RESERVATION_STATUS_FOR_INACTIVE.cancelled,
    RESERVATION_STATUS_FOR_INACTIVE.rejected, 
    RESERVATION_STATUS_FOR_INACTIVE.expired, 
    RESERVATION_STATUS_FOR_INACTIVE.completed
  ]
}

export const statusShowOnExpired = ["Pending Booking", "Pending Approval", "Pending Voyage Allocation"]
export const statusShowOnCancelled = ["Voyage Cancelled", "Cancelled by User"]

export const sortByDateDepartureOptions = [
  {
    key: "earliest_departure",
    text: "Earliest Date of Departure",
    value: 1,
  },
  {
    key: "latest_departure",
    text: "Latest Date of Departure",
    value: 0,
  }
]

export const reservationType = {
  specialReservation: "Special Reservation"
}

export const reservationStatusOptions = [
  { key: 1, text: RESERVATION_STATUS_FOR_INACTIVE.cancelled, value: RESERVATION_STATUS_FOR_INACTIVE.cancelled },
  { key: 2, text: RESERVATION_STATUS_FOR_INACTIVE.rejected, value: RESERVATION_STATUS_FOR_INACTIVE.rejected },
  { key: 3, text: RESERVATION_STATUS_FOR_INACTIVE.expired, value: RESERVATION_STATUS_FOR_INACTIVE.expired },
  { key: 4, text: RESERVATION_STATUS_FOR_INACTIVE.completed, value: RESERVATION_STATUS_FOR_INACTIVE.completed },
]

export const getSecondaryStatus = (secondary_status, secondary_status_update_date, status, reservationItem) => {
  if (secondary_status === 'Edited' && statusCanHaveEditedSecondaryStatus.includes(status)) {
      return <div className={`reservation-secondary-status`}>
          Reserved Capacity 
          <span className={`text-black-bold-14`}> edited </span> 
          on {moment.utc(secondary_status_update_date).format("DD MMM YYYY · HH:mm")}
      </div>
  } else if (secondary_status === 'Edit Request Rejected' && statusCanHaveRejectedSecondaryStatus.includes(status)) {
      return <div className={`reservation-secondary-status`}>
          Edit request has been 
          <span className={`text-black-bold-14`}> rejected </span> 
          on {moment.utc(secondary_status_update_date).format("DD MMM YYYY · HH:mm")}
      </div>
  } else if (secondary_status === 'Voyage Date Updated' && statusCanHaveUpdatedSecondaryStatus.includes(status)) {
      return <div className={`reservation-secondary-status`}> 
          <span className={`text-black-bold-14`}>Voyage Dates Updated: </span>
          departure date preponed to {moment.utc(reservationItem?.start_date).format("DD MMM YYYY")} on {moment.utc(secondary_status_update_date).format("DD MMM YYYY · HH:mm")}
      </div>
  }
}