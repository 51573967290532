import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";

const CONTROLLER_NAME = "/fms";

export const getSystemAlertTypes = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/systemTypes/system_alerts`)
  );
};

export const getVessels = (data) => {
  const bodyParams = {
    regions: data,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(
      `${CONTROLLER_NAME}/vessels`, bodyParams
    )
  );
};;

export const createNewSystemAlert = (data) => {
  const bodyParams = {
    ...data,
  };

  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/systemAlerts`, bodyParams)
  );
};

export const getSystemAlerts = (data) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/systemAlerts?page=${data?.page}&pageSize=${data?.pageSize}&search=${
        data?.searchPayload ? "true" : "false"
      }`
    )
  );
};

export const updateSystemAlert = (data) => {
  const bodyParams = {
    ...data,
  };

  return LAMBDA_API_INSTANCE().then((response) =>
    response.put(`${CONTROLLER_NAME}/systemAlerts`, bodyParams)
  );
};

export const deleteSystemAlert = (data) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.delete(`${CONTROLLER_NAME}/systemAlerts?systemAlertTypeId=${data?.systemAlertTypeId}`)
  );
};
