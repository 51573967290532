import React, { useEffect, useRef, forwardRef } from 'react'
import _ from 'lodash'
import { ReactSVG } from 'react-svg'
import * as L from 'leaflet'
import 'leaflet-rotatedmarker'

// Components
import { MapContainer, TileLayer, Marker, Polyline, useMap, Tooltip } from 'react-leaflet'

// Assets
import PlatformIcon from '../../../../assets/images/platform.png'
import SupplybaseIcon from '../../../../assets/images/supply-base.png'
import DrillingIcon from '../../../../assets/images/workbarge.png'
import CLOSE_ICON from '../../../../assets/images/close.svg'
import MaterialVessel_G from '../../../../assets/images/material-vessel-g.png'
import MaterialVessel_R from '../../../../assets/images/material-vessel-r.png'
import MaterialVessel_Y from '../../../../assets/images/material-vessel-y.png'

const ModalContentReportIncidenceMap = (props) => {
    const { setIsOpenModal, setShowReportIncidence, currentVoyage, vesselData } = props
  
    const transformPlatformPosition = _.map(currentVoyage, (item) => [
      item.latitude_degree,
      item.longitude_degree,
    ])
  
    const position = _.isEmpty(transformPlatformPosition)
      ? [5.32983611, 103.1228669]
      : transformPlatformPosition[0]
  
    const actualVessel = !_.isEmpty(vesselData) ? vesselData[0].actual_vessel : []
    const plannedVessel = !_.isEmpty(vesselData) ? vesselData[0].planned_vessel : []
  
    const fillGreyOptions = {
      color: 'grey',
      weight: '1',
      dashArray: '2',
    }
  
    /* const fillBlueOptions = {
      color: 'blue',
      weight: '1',
    } */

    return (
      <div className={`rate-modal-body`}>
        <div className={`flex`}>
          <div className={`close-icon-modal mar-1`}>
            <ReactSVG
              src={CLOSE_ICON}
              onClick={() => {
                setIsOpenModal(false)
                setShowReportIncidence(false)
              }}
            />
          </div>
        </div>
        <div>
          <MapContainer style={{ height: '60vh' }} center={position} zoom={8} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocateOnLoad locationData={currentVoyage} />
            {!_.isEmpty(transformPlatformPosition) && (
              <Polyline pathOptions={fillGreyOptions} positions={transformPlatformPosition} />
            )}
            <DrawPlannedVessels plannedVesselsData={plannedVessel} />
            <DrawVessels data={actualVessel} />
          </MapContainer>
        </div>
      </div>
    )
}

const LocateOnLoad = (props) => {
    const map = useMap()
  
    // Filter Location Data base on location type
    const platformPosition = _.filter(props.locationData, ['location_type', 'Platform'])
    const transformPlatformPosition = _.map(platformPosition, (item) => [
      item.latitude_degree,
      item.longitude_degree,
    ])
    const supplyBasePosition = _.filter(props.locationData, ['location_type', 'Supply Base'])
    const workbargePosition = _.filter(props.locationData, ['location_type', 'Drilling Rig'])
  
    const position = _.isEmpty(transformPlatformPosition)
      ? [5.32983611, 103.1228669]
      : transformPlatformPosition[0]
  
    useEffect(() => {
      map.zoomControl.setPosition('bottomright')
      map.flyTo(position, map.getZoom())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map])
  
    //  Create the Icon
    const LeafIcon = L.Icon.extend({
      options: {
        className: 'platform-position',
      },
    })
  
    const platformIcon = new LeafIcon({
      iconUrl: PlatformIcon,
    }),
      supplyBaseIcon = new LeafIcon({
        iconUrl: SupplybaseIcon,
      }),
      drillingIcon = new LeafIcon({
        iconUrl: DrillingIcon,
      })
  
    return (
      <>
        <DrawMarkers data={platformPosition} icon={platformIcon} />
        <DrawMarkers data={supplyBasePosition} icon={supplyBaseIcon} />
        <DrawMarkers data={workbargePosition} icon={drillingIcon} />
      </>
    )
}

const DrawMarkers = (props) => {
    const { data, icon } = props
  
    const checkValue = (data) => {
      return _.isEmpty(data) ? 'NA' : data
    }
  
    var markers = []
    for (var i = 0; i < data.length; i++) {
      const transfromLocation = [data[i].latitude_degree, data[i].longitude_degree]
      markers.push(
        <Marker position={transfromLocation} icon={icon}>
          <Tooltip>
            <div className={`flex column map-tooltip-container  pad-x-0_5`}>
              <div className={`flex map-tooltip-content mar-0`}>
                <div
                  className={`round-icon mar-right-0_5`}
                  style={{
                    backgroundColor: `${_.isEqual(data[i].location_type, 'Platform')
                      ? '#FF3246'
                      : _.isEqual(data[i].location_type, 'Supply Base')
                        ? '#FF50A0'
                        : '#6f42c1'
                      }`,
                  }}
                />
                <div className={`map-tooltip-title`}>{data[i].location}</div>
              </div>
              <div className={`map-tooltip-title mar-left-1 mar-y-0`}>
                ETA: {checkValue(data[i].tar_arrival_date)}
              </div>
              <div className={`map-tooltip-title mar-left-1 mar-y-0`}>
                ETD: {checkValue(data[i].tar_dept_date)}
              </div>
            </div>
          </Tooltip>
        </Marker>
      )
    }
  
    return markers
}

const DrawPlannedVessels = (props) => {
    const { plannedVesselsData } = props
    let jobTypeIcon = ''
  
    const LeafIcon = L.Icon.extend({
      options: {
        iconSize: new L.Point(20, 30),
        className: 'planned-vessel-position',
      },
    })
  
    const vesselIcon = new LeafIcon({
      iconUrl: MaterialVessel_R,
    }),
      vesselGIcon = new LeafIcon({
        iconUrl: MaterialVessel_G,
      }),
      vesselRIcon = new LeafIcon({
        iconUrl: MaterialVessel_R,
      }),
      vesselYIcon = new LeafIcon({
        iconUrl: MaterialVessel_Y,
      })
    /* vesselLegendIcon = new LeafIcon({
        iconUrl: MaterialVesselLegend,
      }) */
  
    if (!_.isEmpty(plannedVesselsData)) {
      if (_.isEqual(plannedVesselsData.job_type, 'Material Vessel')) jobTypeIcon = vesselIcon
      else if (_.isEqual(plannedVesselsData.job_type, 'Material Vessel G')) jobTypeIcon = vesselGIcon
      else if (_.isEqual(plannedVesselsData.job_type, 'Material Vessel R')) jobTypeIcon = vesselRIcon
      else if (_.isEqual(plannedVesselsData.job_type, 'Material Vessel Y')) jobTypeIcon = vesselYIcon
      else jobTypeIcon = vesselIcon
  
      return (
        <DrawVessel
          bounds={[plannedVesselsData.latitude, plannedVesselsData.longitude]}
          direction={plannedVesselsData.direction}
          icon={jobTypeIcon}
        />
      )
    }
  
    return null
}

const DrawVessels = (props) => {
    const { data } = props
    let jobTypeIcon = ''
  
    const LeafIcon = L.Icon.extend({
      options: {
        iconSize: new L.Point(20, 30),
        className: 'actual-vessel-position',
      },
    })
  
    const vesselIcon = new LeafIcon({
      iconUrl: MaterialVessel_R,
    }),
      vesselGIcon = new LeafIcon({
        iconUrl: MaterialVessel_G,
      }),
      vesselRIcon = new LeafIcon({
        iconUrl: MaterialVessel_R,
      }),
      vesselYIcon = new LeafIcon({
        iconUrl: MaterialVessel_Y,
      })
    /* vesselLegendIcon = new LeafIcon({
        iconUrl: MaterialVesselLegend,
      }) */
  
    if (!_.isEmpty(data)) {
      if (_.isEqual(data.job_type, 'Material Vessel') || _.isEqual(data.job_type, 'Standby Vessel'))
        jobTypeIcon = vesselIcon
      else if (_.isEqual(data.job_type, 'Material Vessel G')) jobTypeIcon = vesselGIcon
      else if (_.isEqual(data.job_type, 'Material Vessel R')) jobTypeIcon = vesselRIcon
      else if (_.isEqual(data.job_type, 'Material Vessel Y')) jobTypeIcon = vesselYIcon
      else jobTypeIcon = vesselIcon
  
      return (
        <DrawVessel
          bounds={[data.gps_pings.location.latitude, data.gps_pings.location.longitude]}
          direction={data.gps_pings.location.direction}
          icon={jobTypeIcon}
        />
      )
    }
  
    return null
}

const DrawVessel = (props) => {
    const { bounds, direction, icon } = props
    const markerRef = useRef()
  
    return (
      <RotatedMarker
        ref={markerRef}
        position={bounds}
        icon={icon}
        rotationAngle={direction}
        rotationOrigin="center"
      />
    )
}

const RotatedMarker = forwardRef(({ children, ...props }, forwardRef) => {
    const markerRef = useRef()
  
    const { rotationAngle, rotationOrigin } = props
    useEffect(() => {
      const marker = markerRef.current
      if (marker) {
        marker.setRotationAngle(rotationAngle)
        marker.setRotationOrigin(rotationOrigin)
      }
    }, [rotationAngle, rotationOrigin])
  
    return (
      <Marker
        ref={(ref) => {
          markerRef.current = ref
          if (forwardRef) {
            forwardRef.current = ref
          }
        }}
        {...props}
      >
        {children}
      </Marker>
    )
})

export default ModalContentReportIncidenceMap