import * as services from '../services/serviceSpeedMonitoring'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const setFilterData = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_FILTER_DATA))
    services
      .postSpeedComplianceWarnings(filterObject)
      .then((response) =>
        dispatch({
          type: SET_FILTER_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_FILTER_DATA)))
      .catch(() => dispatch(fetchError(SET_FILTER_DATA)))
  }
}

export const SET_FUEL_GAUGE = 'SET_FUEL_GAUGE'
export const setFuelGauge = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_FUEL_GAUGE))
    services
      .postFuelGauge(filterObject)
      .then((response) =>
        dispatch({
          type: SET_FUEL_GAUGE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_FUEL_GAUGE)))
      .catch(() => dispatch(fetchError(SET_FUEL_GAUGE)))
  }
}

export const SET_LINE_CHART = 'SET_LINE_CHART'
export const setLineChart = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_LINE_CHART))
    services
      .postLineChart(filterObject)
      .then((response) =>
        dispatch({
          type: SET_LINE_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_LINE_CHART)))
      .catch(() => dispatch(fetchError(SET_LINE_CHART)))
  }
}

export const SET_SAVE_ACTIONS = 'SET_SAVE_ACTIONS'
export const setSaveActions = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_SAVE_ACTIONS))
    services
      .postSaveActions(filterObject)
      .then((response) =>
        dispatch({
          type: SET_SAVE_ACTIONS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_SAVE_ACTIONS)))
      .catch(() => dispatch(fetchError(SET_SAVE_ACTIONS)))
  }
}

export const SET_DROPDOWN_DATA = 'SET_DROPDOWN_DATA'
export const setVesselDropdownData = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_DROPDOWN_DATA))
    services
      .postVesselDropdownData(filterObject)
      .then((response) =>
        dispatch({
          type: SET_DROPDOWN_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_DROPDOWN_DATA)))
      .catch(() => dispatch(fetchError(SET_DROPDOWN_DATA)))
  }
}

export const SET_DROPDOWN_ID_DATA = 'SET_DROPDOWN_ID_DATA'
export const setVesselDropdownIDData = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(SET_DROPDOWN_ID_DATA))
    services
      .postVesselIDDropdownData(filterObject)
      .then((response) =>
        dispatch({
          type: SET_DROPDOWN_ID_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(SET_DROPDOWN_ID_DATA)))
      .catch(() => dispatch(fetchError(SET_DROPDOWN_ID_DATA)))
  }
}



