import * as services from '../services/serviceLanding'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

export const POST_LEADERSHIP_DATA = 'POST_LEADERSHIP_DATA'
export const postViewLeadershipDashboard = (email) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_LEADERSHIP_DATA))
    services
      .postViewLeadershipDashboard(email)
      .then((response) =>
        dispatch({
          type: POST_LEADERSHIP_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_LEADERSHIP_DATA)))
      .catch(() => dispatch(fetchError(POST_LEADERSHIP_DATA)))
  }
}

export const POST_VIEW_RESERVATION_EN_ROUTE = 'POST_VIEW_RESERVATION_EN_ROUTE'
export const postViewReservationEnRoute = (type, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_RESERVATION_EN_ROUTE))
    services
      .postViewReservationEnRoute(type, userEmail)
      .then((response) =>
        dispatch({
          type: POST_VIEW_RESERVATION_EN_ROUTE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_RESERVATION_EN_ROUTE)))
      .catch(() => dispatch(fetchError(POST_VIEW_RESERVATION_EN_ROUTE)))
  }
}

export const POST_SHIPMENT_EN_ROUTE = 'POST_SHIPMENT_EN_ROUTE'
export const postShipmentEnroute = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SHIPMENT_EN_ROUTE))
    services
      .postShipmentEnroute()
      .then((response) =>
        dispatch({
          type: POST_SHIPMENT_EN_ROUTE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SHIPMENT_EN_ROUTE)))
      .catch(() => dispatch(fetchError(POST_SHIPMENT_EN_ROUTE)))
  }
}

export const POST_FLEET_SUMMARY = 'POST_FLEET_SUMMARY'
export const postFleetSummary = (start_date, end_date) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_FLEET_SUMMARY))
    services
      .postFleetSummary(start_date, end_date)
      .then((response) => {
        dispatch({
          type: POST_FLEET_SUMMARY,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(POST_FLEET_SUMMARY)))
      .catch(() => dispatch(fetchError(POST_FLEET_SUMMARY)))
  }
}

export const POST_FLEET_PERFORMANCE = 'POST_FLEET_PERFORMANCE'
export const postFleetPerformance = (start_date, end_date) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_FLEET_PERFORMANCE))
    services
      .postFleetPerformance(start_date, end_date)
      .then((response) => {
        if (response.is_valid) {
          dispatch({
            type: POST_FLEET_PERFORMANCE,
            payload: response.data,
          })
        }
      })
      .then(() => dispatch(fetchSuccess(POST_FLEET_PERFORMANCE)))
      .catch(() => dispatch(fetchError(POST_FLEET_PERFORMANCE)))
  }
}


export const POST_MOORING_BOUY_UTILIZATION = 'POST_MOORING_BOUY_UTILIZATION'
export const postMooringBouyUtilization = (start_date, end_date) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_MOORING_BOUY_UTILIZATION))
    services
      .postMooringBouyUtilization(start_date, end_date)
      .then((response) => {
        if (response.is_valid) {
          dispatch({
            type: POST_MOORING_BOUY_UTILIZATION,
            payload: {
              mooring_buoy_next_schedules: response.mooring_buoy_next_schedules,
              mooring_buoys_data: response.mooring_buoys_data
            }
          })
        }
      })
      .then(() => dispatch(fetchSuccess(POST_MOORING_BOUY_UTILIZATION)))
      .catch(() => dispatch(fetchError(POST_MOORING_BOUY_UTILIZATION)))
  }
}
export const POST_RESERVATION_STATUS_NOTIFICATION= 'POST_RESERVATION_STATUS_NOTIFICATION'
export const postReservationStatusNotification = (user_email) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_RESERVATION_STATUS_NOTIFICATION))
    services
      .postReservationStatusNotification(user_email)
      .then((response) => {       
          dispatch({
            type: POST_RESERVATION_STATUS_NOTIFICATION,
            payload: response,
          })
      })
      .then(() => dispatch(fetchSuccess(POST_RESERVATION_STATUS_NOTIFICATION)))
      .catch(() => dispatch(fetchError(POST_RESERVATION_STATUS_NOTIFICATION)))
  }
}
