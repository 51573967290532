import * as mainPageActions from '../actions/actionMain'

const defaultState = {
  searchData: {
    data: '',
  },
  mainPage: {
    data: {},
    locationData: [],
    showVessel: true,
    showStandbyVessel: true,
    showOtherVessel: true,
    showPlatform: true,
    showSupplyBase: true,
    showWorkBarge: true,
    showMooring: true,
    showDrilling: true,
    showWorkBoat: true,
    showWindSpeed: false,
    showWindDirection: false,
    showWaveHeight: false,
    showTemperatures: false,
    showRadar: false,
    showTropical: false,
    showSeaTemp: false,
    showLightning: false,
    showForecastPrecip: false,
    region: 'All',
    location: 'All',
    job: 'All',
    vessel: 'All',
    searchInput: '',
  },
}

const REDUCERS = {
  [mainPageActions.SET_FILTER_DATA]: (state, { payload }) => {
    return {
      ...state,
      mainPage: {
        ...state.mainPage,
        data: payload,
      },
    }
  },
  [mainPageActions.SET_LOCATION_DATA]: (state, { payload }) => {
    return {
      ...state,
      mainPage: {
        ...state.mainPage,
        locationData: payload,
      },
    }
  },
  [mainPageActions.GET_WORKBOAT_VESSELS]: (state, { payload }) => {
    return {
      ...state,
      mainPage: {
        ...state.mainPage,
        workboatVessels: payload,
      },
    }
  },
  // [mainPageActions.POST_SEARCH_DATA]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     searchData: {
  //       ...state.searchData,
  //       data: payload,
  //     },
  //   };
  // },
  [mainPageActions.SET_RESET_MAIN_PAGE]: (state, { payload }) => {
    return {
      ...state,
      mainPage: {
        ...state.mainPage,
        data: {},
        
      },
    }
  },

}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const MAIN_PAGE_REDUCER = 'mainPage'
