import * as ReleaseNoteActions from '../../actions/common/actionReleaseNote'

const defaultState = {
  releaseNote: {
    data: '',
  },
}

const REDUCERS = {
  [ReleaseNoteActions.GET_RELEASE_NOTE]: (state, { payload }) => {
    return {
      ...state,
      releaseNote: {
        ...state.releaseNote,
        data: payload,
      },
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const RELEASE_NOTE_REDUCER = 'commonReleaseNote'
