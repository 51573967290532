import React, { useEffect } from 'react';
import { Modal, Image } from "semantic-ui-react";
import "./style.scss";
import FetchStateWrapper from "../../components/fetch-state-wrapper";
import { useStateWithPaths, useActions } from "../../hooks/useConnect";
import { ONBOARDING_STATUS_REDUCER } from "../../reducers/common/onBoardingStatusReducer";
import * as OnboardingStatusActions from "../../actions/common/actionOnboardingStatus";
import TICK_CIRCLE from "../../assets/images/tick-circle.svg";
import CLOSE_CIRCLE from "../../assets/images/close-circle.svg";
import CLOSE from '../../assets/images/x-black.svg'

const OnBoardingStatus = ({ open, onClose }) => {
  const mapping = {
    "regions": "Regions",
    "projects": "Projects",
    "locations": "Locations",
    "vesselsContract": "Vessel & Contracts",
    "vesselsCargo": "Vessel Cargo"
  };


  const [commonOnboardingStatus] = useStateWithPaths([`${ONBOARDING_STATUS_REDUCER}`]);
  const onBoardingStatusData = commonOnboardingStatus?.onBoardingStatus?.data;

  const { getOnboardingStatus } = useActions({
    getOnboardingStatus: OnboardingStatusActions.getOnboardingStatus,
  });

  useEffect(() => {
    getOnboardingStatus();
  }, []);

  return (
    <>
    <FetchStateWrapper id={OnboardingStatusActions.GET_ONBOARDING_STATUS} />

    <Modal
      className="onboarding-status"
      onClose={onClose}
      open={open}
      size="tiny"
    >
      <Modal.Content className="onboarding-content no-padding">
        <div className="header">
          <div className="header-text">Onboarding Status</div>
          <Image className='header-icon' src={CLOSE} size="mini" onClick={onClose} />
        </div>
        <div className="content">
          <div className="info">
            <p className="info-text">
              Please ensure your data is complete in the Tenant Admin Portal for full feature access.
            </p>
          </div>
          <div className="status">
            <div className="status-wrapper">
            {
              onBoardingStatusData && Object.entries(onBoardingStatusData).map(([key, value]) => (
                <div className="status-item" key={key}>
                  <div className="text" style={{ color: value ? 'green' : 'red' }}>{mapping[key]}</div>
                  <Image className={`complete-icon`} src={value ? TICK_CIRCLE : CLOSE_CIRCLE} color={value ? 'green' : 'red'} size="mini" />
                </div>
              ))
            }
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
    </>
  );

};

export default OnBoardingStatus;