import * as LandingPageActions from "../actions/actionLanding";
import * as services from "../services/serviceReservation";

import { fetchError, fetchStart, fetchSuccess } from "./common/actionFetch";

export const SET_SHOW_CONFIRM_DEMAND_MODAL = "SET_SHOW_CONFIRM_DEMAND_MODAL";
export const setShowConfirmDemandModal = (showConfirmDemandModal) => {
  return (dispatch) => {
    dispatch({
      type: SET_SHOW_CONFIRM_DEMAND_MODAL,
      payload: showConfirmDemandModal,
    });
  };
};
export const SET_IS_SHOW_IAP_PLANNER_MODAL = "SET_IS_SHOW_IAP_PLANNER_MODAL";
export const setIsShowIAPPlannerModal = (isShowIAPPlannerModal) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SHOW_IAP_PLANNER_MODAL,
      payload: isShowIAPPlannerModal,
    });
  };
};
export const RESET_RESERVATION_PAGE = "RESET_RESERVATION_PAGE";
export const resetReservationPage = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_RESERVATION_PAGE,
      payload: [],
    });
  };
};

export const RESET_RESERVATION_VIEW_PAGE = "RESET_RESERVATION_VIEW_PAGE";
export const resetReservationViewPage = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_RESERVATION_VIEW_PAGE,
      payload: [],
    });
  };
};

export const RESET_RESERVATION_DATA = "RESET_RESERVATION_DATA";
export const resetReservationData = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_RESERVATION_DATA,
      payload: [],
    });
  };
};

export const RESET_RESERVATION_LIST = "RESET_RESERVATION_LIST";
export const resetReservationList = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_RESERVATION_LIST,
      payload: [],
    });
  };
};

export const SET_IS_EDIT = "SET_IS_EDIT";
export const setIsEdit = (isEdit) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDIT,
      payload: isEdit,
    });
  };
};

export const GET_PROJECT_INPUT_DEMAND_DROPDOWN = "GET_PROJECT_INPUT_DEMAND_DROPDOWN";
export const getProjectInputDemandDropdown = (type) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_PROJECT_INPUT_DEMAND_DROPDOWN));
    services
      .getInputDemandDropdown(type, "project_name")
      .then((response) =>
        dispatch({
          type: GET_PROJECT_INPUT_DEMAND_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PROJECT_INPUT_DEMAND_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_PROJECT_INPUT_DEMAND_DROPDOWN)));
  };
};

export const GET_DISCIPLINE_INPUT_DEMAND_DROPDOWN = "GET_DISCIPLINE_INPUT_DEMAND_DROPDOWN";
export const getDisciplineInputDemandDropdown = (project_name) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DISCIPLINE_INPUT_DEMAND_DROPDOWN));
    services
      .getDisciplineByProjectDropdown(project_name)
      .then((response) =>
        dispatch({
          type: GET_DISCIPLINE_INPUT_DEMAND_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DISCIPLINE_INPUT_DEMAND_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_DISCIPLINE_INPUT_DEMAND_DROPDOWN)));
  };
};

export const GET_LOCATION_INPUT_DEMAND_DROPDOWN = "GET_LOCATION_INPUT_DEMAND_DROPDOWN";
export const getLocationInputDemandDropdown = (type) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_INPUT_DEMAND_DROPDOWN));
    services
      .getInputDemandDropdown(type, "location_name")
      .then((response) =>
        dispatch({
          type: GET_LOCATION_INPUT_DEMAND_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_LOCATION_INPUT_DEMAND_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_LOCATION_INPUT_DEMAND_DROPDOWN)));
  };
};

export const GET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN = "GET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN";
export const getDemandTypeSpecialRequestDropdown = (type) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN));
    services
      .getDemandTypeSpecialRequestDropdown(type)
      .then((response) =>
        dispatch({
          type: GET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN)));
  };
};

export const GET_CARGO_SPECIAL_REQUEST_DROPDOWN = "GET_CARGO_SPECIAL_REQUEST_DROPDOWN";
export const getCargoSpecialRequestDropdown = (type, demandType) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_CARGO_SPECIAL_REQUEST_DROPDOWN));
    services
      .getCargoSpecialRequestDropdown(type, demandType)
      .then((response) =>
        dispatch({
          type: GET_CARGO_SPECIAL_REQUEST_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_CARGO_SPECIAL_REQUEST_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_CARGO_SPECIAL_REQUEST_DROPDOWN)));
  };
};

export const GET_CARGO_UOM_DROPDOWN = "GET_CARGO_UOM_DROPDOWN";
export const getCargoUomDropdown = (type, cargoName) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_CARGO_UOM_DROPDOWN));
    services
      .getCargoUomDropdown(type, cargoName)
      .then((response) =>
        dispatch({
          type: GET_CARGO_UOM_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_CARGO_UOM_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_CARGO_UOM_DROPDOWN)));
  };
};

export const GET_VIEW_IAP_PLANNER_DROPDOWN = "GET_VIEW_IAP_PLANNER_DROPDOWN";
export const getViewIapPlannerDropdown = (userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VIEW_IAP_PLANNER_DROPDOWN));
    services
      .getViewIapPlannerDropdown(userEmail)
      .then((response) =>
        dispatch({
          type: GET_VIEW_IAP_PLANNER_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VIEW_IAP_PLANNER_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_VIEW_IAP_PLANNER_DROPDOWN)));
  };
};

export const POST_VIEW_MATCHING_VOYAGES = "POST_VIEW_MATCHING_VOYAGES";
export const postViewMatchingVoyages = (
  type,
  projectName,
  discipline,
  startLocation,
  endLocation,
  departureDate,
  arrivalDate
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_MATCHING_VOYAGES));
    services
      .postViewMatchingVoyages(
        type,
        projectName,
        discipline,
        startLocation,
        endLocation,
        departureDate,
        arrivalDate
      )
      .then((response) =>
        dispatch({
          type: POST_VIEW_MATCHING_VOYAGES,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_MATCHING_VOYAGES)))
      .catch(() => dispatch(fetchError(POST_VIEW_MATCHING_VOYAGES)));
  };
};

export const POST_SAVE_SPECIAL_REQUEST = "POST_SAVE_SPECIAL_REQUEST";
export const postSaveSpecialRequest = (confirmSpecialRequestData) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_SPECIAL_REQUEST));
    services
      .postSaveSpecialRequest(confirmSpecialRequestData)
      .then((response) =>
        dispatch({
          type: POST_SAVE_SPECIAL_REQUEST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_SPECIAL_REQUEST)))
      .catch(() => dispatch(fetchError(POST_SAVE_SPECIAL_REQUEST)));
  };
};

export const POST_SAVE_RESERVATION = "POST_SAVE_RESERVATION";
export const postSaveReservation = (confirmReservationData) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_RESERVATION));
    services
      .postSaveReservation(confirmReservationData)
      .then((response) => {
        dispatch({
          type: POST_SAVE_RESERVATION,
          payload: response,
        });
        //setShowConfirmDemandModal(true)
        dispatch({
          type: SET_NEW_RESERVATION_PAGE,
          payload: "submittedPage",
        });
      })
      .then(() => dispatch(fetchSuccess(POST_SAVE_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_SAVE_RESERVATION)));
  };
};

export const POST_UPDATE_RESERVATION = "POST_UPDATE_RESERVATION";
export const postUpdateReservation = (updatedReservation) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPDATE_RESERVATION));
    services
      .postUpdateReservation(updatedReservation)
      .then((response) => {
        dispatch({
          type: POST_UPDATE_RESERVATION,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(POST_UPDATE_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_UPDATE_RESERVATION)));
  };
};

export const RESET_UPDATE_RESERVATION_RESPONSE = "RESET_UPDATE_RESERVATION_RESPONSE";
export const resetUpdateReservationResponse = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_UPDATE_RESERVATION_RESPONSE,
      payload: [],
    });
  };
};

export const POST_SAVE_RESERVATION_APPROVER = "POST_SAVE_RESERVATION_APPROVER";
export const postSaveReservationApprover = (type, reservationId, userEmail, iapEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_RESERVATION_APPROVER));
    services
      .postSaveReservationApprover(type, reservationId, userEmail, iapEmail)
      .then((response) =>
        dispatch({
          type: POST_SAVE_RESERVATION_APPROVER,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_RESERVATION_APPROVER)))
      .catch(() => dispatch(fetchError(POST_SAVE_RESERVATION_APPROVER)));
  };
};

export const POST_VIEW_RESERVATION_LIST = "POST_VIEW_RESERVATION_LIST";
export const postViewReservationList = (
  type,
  userEmail,
  status = "",
  pagination = 1,
  filterOptions
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_RESERVATION_LIST));
    services
      .postViewReservationList(type, userEmail, status, pagination, filterOptions)
      .then((response) =>
        dispatch({
          type: POST_VIEW_RESERVATION_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_RESERVATION_LIST)))
      .catch(() => dispatch(fetchError(POST_VIEW_RESERVATION_LIST)));
  };
};

export const POST_VIEW_ALL_RESERVATION_LIST = "POST_VIEW_ALL_RESERVATION_LIST";
export const postViewAllReservationList = (type, userEmail, status) => {
  const filterOptions = { is_download: true };
  if (status === 'Inactive') {
    filterOptions.inactive_tab_status = 'All';
  }
  return (dispatch) => {
    // reset datas
    dispatch({
      type: POST_VIEW_ALL_RESERVATION_LIST,
      payload: null,
    });
    dispatch(fetchStart(POST_VIEW_ALL_RESERVATION_LIST));
    services
      .postViewReservationList(type, userEmail, status, undefined, filterOptions)
      .then((response) =>
        dispatch({
          type: POST_VIEW_ALL_RESERVATION_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_ALL_RESERVATION_LIST)))
      .catch(() => dispatch(fetchError(POST_VIEW_ALL_RESERVATION_LIST)));
  };
};

export const POST_VIEW_ORIGINAL_RESERVATION = "POST_VIEW_ORIGINAL_RESERVATION";
export const postViewOriginalReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_ORIGINAL_RESERVATION));
    services
      .postViewOriginalReservation(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_VIEW_ORIGINAL_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_ORIGINAL_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_VIEW_ORIGINAL_RESERVATION)));
  };
};

export const POST_TRIGGER_VIEWED_RESERVATION = "POST_TRIGGER_VIEWED_RESERVATION";
export const postTriggerViewedReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_TRIGGER_VIEWED_RESERVATION));
    services
      .postTriggerViewedReservation(type, reservationId, voyageId, userEmail)
      .then((response) => {
        dispatch({
          type: POST_TRIGGER_VIEWED_RESERVATION,
          payload: response,
        });
        LandingPageActions.postReservationStatusNotification(userEmail);
      })
      .then(() => dispatch(fetchSuccess(POST_TRIGGER_VIEWED_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_TRIGGER_VIEWED_RESERVATION)));
  };
};

export const POST_DOWNLOAD_RESERVATION_DETAILS = "POST_DOWNLOAD_RESERVATION_DETAILS";
export const postDownloadReservationDetails = (type, reservationId, userEmail, voyageId) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DOWNLOAD_RESERVATION_DETAILS));
    services
      .postDownloadReservationDetails(type, reservationId, userEmail, voyageId)
      .then((response) =>
        dispatch({
          type: POST_DOWNLOAD_RESERVATION_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DOWNLOAD_RESERVATION_DETAILS)))
      .catch(() => dispatch(fetchError(POST_DOWNLOAD_RESERVATION_DETAILS)));
  };
};

export const POST_DELETE_RESERVATION = "POST_DELETE_RESERVATION";
export const postDeleteReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DELETE_RESERVATION));
    services
      .postDeleteReservation(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_DELETE_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DELETE_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_DELETE_RESERVATION)));
  };
};

export const POST_DELETE_SPECIAL_RESERVATION = "POST_DELETE_SPECIAL_RESERVATION";
export const postDeleteSpecialReservation = (type, selectedReservationId, user_email) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DELETE_SPECIAL_RESERVATION));
    services
      .postDeleteSpecialReservation(type, selectedReservationId, user_email)
      .then((response) =>
        dispatch({
          type: POST_DELETE_SPECIAL_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DELETE_SPECIAL_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_DELETE_SPECIAL_RESERVATION)));
  };
};

export const POST_EDIT_RESERVATION = "POST_EDIT_RESERVATION";
export const postEditReservation = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EDIT_RESERVATION));
    services
      .postEditReservation(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_EDIT_RESERVATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EDIT_RESERVATION)))
      .catch(() => dispatch(fetchError(POST_EDIT_RESERVATION)));
  };
};

export const POST_VIEW_FINALIZED_ITINERARY = "POST_VIEW_FINALIZED_ITINERARY";
export const postViewFinalizedItinerary = (
  type,
  reservationId,
  tripId,
  userEmail,
  pagination,
  sortOption
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_FINALIZED_ITINERARY));
    services
      .postViewFinalizedItinerary(type, reservationId, tripId, userEmail, pagination, sortOption)
      .then((response) =>
        dispatch({
          type: POST_VIEW_FINALIZED_ITINERARY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_FINALIZED_ITINERARY)))
      .catch(() => dispatch(fetchError(POST_VIEW_FINALIZED_ITINERARY)));
  };
};

export const POST_UPDATE_DELIVERED_STATUS = "POST_UPDATE_DELIVERED_STATUS";
export const postUpdateDeliveredStatus = (
  type,
  reservationId,
  voyageId,
  bookingId,
  materialId,
  cargoType,
  arrayUpdated,
  value
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPDATE_DELIVERED_STATUS));
    services
      .postUpdateDeliveredStatus(
        type,
        reservationId,
        voyageId,
        bookingId,
        materialId,
        cargoType,
        value
      )
      .then((response) =>
        dispatch({
          type: POST_UPDATE_DELIVERED_STATUS,
          payload: { response: response, arrayUpdated: arrayUpdated },
        })
      )
      .then(() => dispatch(fetchSuccess(POST_UPDATE_DELIVERED_STATUS)))
      .catch(() => dispatch(fetchError(POST_UPDATE_DELIVERED_STATUS)));
  };
};

export const POST_UPDATE_APPROVAL_STATUS = "POST_UPDATE_APPROVAL_STATUS";
export const postUpdateApprovalStatus = (mode, type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPDATE_APPROVAL_STATUS));
    services
      .postUpdateApprovalStatus(mode, type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_UPDATE_APPROVAL_STATUS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_UPDATE_APPROVAL_STATUS)))
      .catch(() => dispatch(fetchError(POST_UPDATE_APPROVAL_STATUS)));
  };
};

export const POST_TRACK_VOYAGE_STATUS = "POST_TRACK_VOYAGE_STATUS";
export const postTrackVoyageStatus = (type, reservationId, voyageId, userEmail) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_TRACK_VOYAGE_STATUS));
    services
      .postTrackVoyageStatus(type, reservationId, voyageId, userEmail)
      .then((response) =>
        dispatch({
          type: POST_TRACK_VOYAGE_STATUS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_TRACK_VOYAGE_STATUS)))
      .catch(() => dispatch(fetchError(POST_TRACK_VOYAGE_STATUS)));
  };
};

export const POST_SAVE_BOOKING_DETAILS = "POST_SAVE_BOOKING_DETAILS";
export const postSaveBookingDetails = (requestBody) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_BOOKING_DETAILS));
    services
      .postSaveBookingDetails(requestBody)
      .then((response) =>
        dispatch({
          type: POST_SAVE_BOOKING_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_BOOKING_DETAILS)))
      .catch(() => dispatch(fetchError(POST_SAVE_BOOKING_DETAILS)));
  };
};

export const POST_VIEW_VOYAGE_LIST = "POST_VIEW_VOYAGE_LIST";
export const postViewVoyageList = () => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_VOYAGE_LIST));
    services
      .postViewVoyageList()
      .then((response) =>
        dispatch({
          type: POST_VIEW_VOYAGE_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_VOYAGE_LIST)))
      .catch(() => dispatch(fetchError(POST_VIEW_VOYAGE_LIST)));
  };
};

export const POST_VIEW_VOYAGE_DETAILS = "POST_VIEW_VOYAGE_DETAILS";
export const postViewVoyageDetails = (vesselName, tripId) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VIEW_VOYAGE_DETAILS));
    services
      .postViewVoyageDetails(vesselName, tripId)
      .then((response) =>
        dispatch({
          type: POST_VIEW_VOYAGE_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VIEW_VOYAGE_DETAILS)))
      .catch(() => dispatch(fetchError(POST_VIEW_VOYAGE_DETAILS)));
  };
};

export const POST_VALIDATE_RESERVED_CAPACITY = "POST_VALIDATE_RESERVED_CAPACITY";
export const validateReservedCapacity = (
  confirmReservationData,
  handleSuccess = null,
  handleError = null
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VALIDATE_RESERVED_CAPACITY));
    services
      .validateReservedCapacity(confirmReservationData)
      .then((response) => {
        dispatch({
          type: POST_VALIDATE_RESERVED_CAPACITY,
          payload: response,
        });
        if (response?.is_valid) {
          handleSuccess && handleSuccess();
        } else {
          handleError && handleError();
        }
      })
      .then(() => dispatch(fetchSuccess(POST_VALIDATE_RESERVED_CAPACITY)))
      .catch((error) => {
        dispatch(fetchError(POST_VALIDATE_RESERVED_CAPACITY));
      });
  };
};

export const HANDLE_RESET_VALIDATE_RESERVED_CAPACITY = "HANDLE_RESET_VALIDATE_RESERVED_CAPACITY";
export const resetReservedCapacity = () => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_RESET_VALIDATE_RESERVED_CAPACITY,
      payload: {},
    });
  };
};

export const SET_NEW_RESERVATION_PAGE = "SET_NEW_RESERVATION_PAGE";
export const setNewReservationPage = (page) => {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_RESERVATION_PAGE,
      payload: page,
    });
  };
};

export const SET_IS_GO_BACK_EDIT = "SET_IS_GO_BACK_EDIT";
export const setIsGoBackEdit = (isGoBackEdit) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_GO_BACK_EDIT,
      payload: isGoBackEdit,
    });
  };
};

export const SET_MODAL_RESERVATION_VIEW = "SET_MODAL_RESERVATION_VIEW";
export const setModalReservationView = (modalData) => {
  return (dispatch) => {
    dispatch({
      type: SET_MODAL_RESERVATION_VIEW,
      payload: modalData,
    });
  };
};

export const validateReservedEdit = (confirmReservationData, reservationID) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VALIDATE_RESERVED_CAPACITY));
    services
      .validateReservedCapacity(confirmReservationData)
      .then((response) => {
        dispatch({
          type: POST_VALIDATE_RESERVED_CAPACITY,
          payload: { ...response, reservationID },
        });
      })
      .then(() => dispatch(fetchSuccess(POST_VALIDATE_RESERVED_CAPACITY)))
      .catch((error) => dispatch(fetchError(POST_VALIDATE_RESERVED_CAPACITY)));
  };
};

export const SAVE_HUB_RESERVATION_ITEM_ON_PAGE = "SAVE_HUB_RESERVATION_ITEM_ON_PAGE";
export const savingHubReservationItemOnPage = (hubData) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_HUB_RESERVATION_ITEM_ON_PAGE,
      payload: hubData,
    });
  };
};

export const RESET_IS_SAVE_RESERVATION_HUB_NOTIFY = "RESET_IS_SAVE_RESERVATION_HUB_NOTIFY";
export const resetsSaveReservationHubNotify = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_IS_SAVE_RESERVATION_HUB_NOTIFY,
      payload: { isSaveReservationHubNotify: false },
    });
  };
};

export const UPDATE_HUB_RESERVATION_ITEM_ON_PAGE = "UPDATE_HUB_RESERVATION_ITEM_ON_PAGE";
export const updatingHubReservationItemOnPage = (hubData) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_HUB_RESERVATION_ITEM_ON_PAGE,
      payload: hubData,
    });
  };
};

export const RESET_IS_HUB_RESERVATION_ITEM_ON_PAGE = "RESET_IS_HUB_RESERVATION_ITEM_ON_PAGE";
export const resetIsHubReservationItemOnPage = (id) => {
  return (dispatch) => {
    dispatch({
      type: RESET_IS_HUB_RESERVATION_ITEM_ON_PAGE,
      payload: { [id]: false },
    });
  };
};

export const GET_VIEW_RESERVATION_LOCATION_DROPDOWN = "GET_VIEW_RESERVATION_LOCATION_DROPDOWN";
export const getViewReservationLocationDropdown = (locationName) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VIEW_RESERVATION_LOCATION_DROPDOWN));
    services
      .getViewReservationDropdown(locationName)
      .then((response) =>
        dispatch({
          type: GET_VIEW_RESERVATION_LOCATION_DROPDOWN,
          payload: { response, locationName },
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VIEW_RESERVATION_LOCATION_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_VIEW_RESERVATION_LOCATION_DROPDOWN)));
  };
};

export const GET_DROPDOWN_BY_VOYAGEID = "GET_DROPDOWN_BY_VOYAGEID";
export const getDropdownByVoyageID = (fieldName, voyageID, divisionName) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DROPDOWN_BY_VOYAGEID));
    services
      .getViewReservationDropdown(fieldName, voyageID, divisionName)
      .then((response) =>
        dispatch({
          type: GET_DROPDOWN_BY_VOYAGEID,
          payload: { response, fieldName },
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DROPDOWN_BY_VOYAGEID)))
      .catch(() => dispatch(fetchError(GET_DROPDOWN_BY_VOYAGEID)));
  };
};

export const RESET_SEARCH_AND_FILTER = "RESET_SEARCH_AND_FILTER";
export const resetSearchAndFilter = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SEARCH_AND_FILTER,
    });
  };
};

export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const setFilterOptions = (filterOptions) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILTER_OPTIONS,
      payload: filterOptions,
    });
  };
};

export const SET_STATUS_RESERVATION_VIEW_PAGE = "SET_STATUS_RESERVATION_VIEW_PAGE";
export const setStatusReservationViewPage = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: SET_STATUS_RESERVATION_VIEW_PAGE,
      payload: { [key]: value },
    });
  };
};

export const SET_IS_GO_THROUGH_VOYAGE_PAGE = "SET_IS_GO_THROUGH_VOYAGE_PAGE";
export const setIsGoThroughVoyagePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_GO_THROUGH_VOYAGE_PAGE,
      payload: value,
    });
  };
};

export const SET_SORT_BY_DEPARTURE_DATE = "SET_SORT_BY_DEPARTURE_DATE";
export const setSortByDepartureDate = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_SORT_BY_DEPARTURE_DATE,
      payload: value,
    });
  };
};

export const SET_PERIOD_CALENDAR_FILTER = "SET_PERIOD_CALENDAR_FILTER";
export const setPeriodCalendarFilter = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_PERIOD_CALENDAR_FILTER,
      payload: value,
    });
  };
};

export const SET_IS_SHOW_ALL_CALENDAR_FILTER = "SET_IS_SHOW_ALL_CALENDAR_FILTER";
export const setIsShowAllCalendarFilter = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SHOW_ALL_CALENDAR_FILTER,
      payload: value,
    });
  };
};
