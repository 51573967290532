import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";

const CONTROLLER_NAME = "/fms";

export const getFuelCappingSectionsData = (queries) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/fuelCappingSectionsData`, { params: queries })
  );
};

export const updateFuelCappingOffshoreOperation = (body) => {
  return LAMBDA_API_INSTANCE().then((response) => {
    const bodyParams = { ...body };
    return response.post(`${CONTROLLER_NAME}/fuelCappingOffshoreOperation`, bodyParams);
  });
};

export const updateFuelCappingBufferSection = (body) => {
  return LAMBDA_API_INSTANCE().then((response) => {
    const bodyParams = { ...body };
    return response.post(`${CONTROLLER_NAME}/fuelCappingBufferSection`, bodyParams);
  });
};

export const getExternalSupplyRunData = (queries) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/fuelCappingExternalSupplyRun`, { params: queries })
  );
};

export const updateExternalSupplyRunData = (body) => {
  return LAMBDA_API_INSTANCE().then((response) => {
    return response.post(`${CONTROLLER_NAME}/fuelCappingExternalSupplyRun`, body);
  });
};

export const getAllExternalSupplyRunTypes = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/externalSupplyRunTypes`)
  );
};

export const calculateFuelCappingSummary = (body) => {
  return LAMBDA_API_INSTANCE().then((response) => {
    return response.post(`${CONTROLLER_NAME}/fuelCappingSummary`, body);
  });
}

export const getFuelCappingSummary = (queries) => {
  return LAMBDA_API_INSTANCE().then((response) => {
    return response.get(`${CONTROLLER_NAME}/fuelCappingSummary`, { params: queries });
  });
}

export const getFuelCappingSummaryList = (queries) => {
  return LAMBDA_API_INSTANCE().then((response) => {
    return response.get(`${CONTROLLER_NAME}/fuelCappingSummaryList`, { params: queries });
  });
}
