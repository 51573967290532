import * as dashboardActions from '../actions/actionDashboard'

const defaultState = {
  dashboardPage: {
    powerBIConfiguration: {},
  }
}

const REDUCERS = {
  [dashboardActions.POST_POWERBI_DASHBOARD_INFO]: (state, { payload }) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        powerBIConfiguration: payload,
      }
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const DASHBOARD_PAGE_REDUCER = 'dashboardPage'
