import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { ReactSVG } from "react-svg";

import ArrowDown from '../../../assets/images/arrow-dropdown-down.svg';

const CustomDropdown = ({ placeholder, value, setValue, loading , options, classProperty, style = {}}) => {
    return (
      <Dropdown
        placeholder={placeholder}
        value={value}
        selection
        search
        options={options}
        onChange={(e, data) => setValue(data.value)}
        loading={loading}
        disabled={loading}
        icon={
            loading ?
            <Icon name="spinner" loading style={{ marginRight: "12px" }} /> :
            <ReactSVG src={ ArrowDown} style={{ textAlign: "right", marginTop: "-15px" }}/>
        }
        style={{
          height: "46px",
          padding: "12px 16px",
          borderRadius: "8px",
          border: "1px solid #D9D9E3",
          fontWeight: 400,
          fontSize: "14px",
          gap: "12px",
          color:"#242433",
          textTransform: "none",
          ...style 
        }}
        className={classProperty}
      />
        
    );
  };

  export default CustomDropdown;