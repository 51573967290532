import * as services from '../services/serviceMooringBuoyDetail'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'
import swal from 'sweetalert'

export const GET_EQUIPMENT_TASKS = 'GET_EQUIPMENT_TASKS'
export const getEquipmentTasksData = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_EQUIPMENT_TASKS))
    services.getEquipmentTasksData()
      .then((response) => {
        dispatch({
          type: GET_EQUIPMENT_TASKS,
          payload: response.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_EQUIPMENT_TASKS)))
      .catch(() => dispatch(fetchError(GET_EQUIPMENT_TASKS)))
  }
}

export const GET_MAINTENANCE_LOG = 'GET_MAINTENANCE_LOG'
export const getMaintenanceLogList = (mooring_buoy, page_number = 1, page_size = 10) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_MAINTENANCE_LOG))
    services.getMaintenanceLogList(mooring_buoy, page_number, page_size)
      .then((response) => {
        dispatch({
          type: GET_MAINTENANCE_LOG,
          payload: response.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_MAINTENANCE_LOG)))
      .catch(() => dispatch(fetchError(GET_MAINTENANCE_LOG)))
  }
}

export const ADD_UPDATE_MAINTENANCE_LOG = 'ADD_UPDATE_MAINTENANCE_LOG'
export const addUpdateMaintenanceLog = (formData, mooringBuoyName, getMaintenanceLogList, setOpenEditContract) => {
  return (dispatch) => {
    dispatch(fetchStart(ADD_UPDATE_MAINTENANCE_LOG))
    services.createUpdateMaintenanceTask(formData)
      .then((response) => {
        dispatch({
          type: ADD_UPDATE_MAINTENANCE_LOG,
          payload: response
        })
        getMaintenanceLogList && getMaintenanceLogList(mooringBuoyName)
        setOpenEditContract && setOpenEditContract(false)
      })
      .then(() => dispatch(fetchSuccess(ADD_UPDATE_MAINTENANCE_LOG)))
      .catch(() => dispatch(fetchError(ADD_UPDATE_MAINTENANCE_LOG)))
  }
}

export const DEL_MAINTENANCE_LOG = 'DEL_MAINTENANCE_LOG'
export const deleteMaintenanceTask = (maintenance_task_id, maintenance_id, mooringBuoyName, getMaintenanceLogList) => {
  return (dispatch) => {
    dispatch(fetchStart(DEL_MAINTENANCE_LOG))
    services.deleteMaintenanceTask(maintenance_task_id, maintenance_id)
      .then((response) => {
        dispatch({
          type: DEL_MAINTENANCE_LOG,
          payload: response.data
        })
        getMaintenanceLogList && getMaintenanceLogList(mooringBuoyName)
      })
      .then(() => dispatch(fetchSuccess(DEL_MAINTENANCE_LOG)))
      .catch(() => dispatch(fetchError(DEL_MAINTENANCE_LOG)))
  }
}

export const CHANGE_INDEX_TAB_LOG = 'CHANGE_INDEX_TAB_LOG'
export const changeIndexTabLog = (index) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_INDEX_TAB_LOG,
      payload: index
    })
  }
}

// Action Location Log
export const GET_REGION_LOCATION_DROPDOWNS = "GET_REGION_LOCATION_DROPDOWNS"
export const getRegionLocationDropdowns = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_REGION_LOCATION_DROPDOWNS))
    services
      .getRegionLocationDropdown()
      .then((response) => {
        dispatch({
          type: GET_REGION_LOCATION_DROPDOWNS,
          payload: response,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_REGION_LOCATION_DROPDOWNS)))
      .catch(() => dispatch(fetchError(GET_REGION_LOCATION_DROPDOWNS)))
  }
}

export const GET_LOCATION_LOG_LIST = "GET_LOCATION_LOG_LIST"
export const getLocationLogList = (
  mooring_buoy,
  page_number = 1,
  page_size = 10
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_LOCATION_LOG_LIST));
    services
      .getLocationLogList(mooring_buoy, page_number, page_size)
      .then((response) => {
        dispatch({
          type: GET_LOCATION_LOG_LIST,
          payload: response.data,
        });
      })
      .then(() => dispatch(fetchSuccess(GET_LOCATION_LOG_LIST)))
      .catch(() => dispatch(fetchError(GET_LOCATION_LOG_LIST)))
  }
}

export const CREATE_UPDATE_LOCATION_LOG = "CREATE_UPDATE_LOCATION_LOG"
export const createUpdateLocationLog = (payloadObj, setOpenModalLocation, getLocationLogList, getMaintenanceLogList) => {
  return (dispatch) => {
    dispatch(fetchStart(CREATE_UPDATE_LOCATION_LOG));
    services
      .createUpdateLocationLog(payloadObj)
      .then((response) => {
        if (response.is_valid) {
          dispatch({
            type: CREATE_UPDATE_LOCATION_LOG,
            payload: response,
          })
          getLocationLogList && getLocationLogList(payloadObj.mooring_buoy_name)
          getMaintenanceLogList(payloadObj.mooring_buoy_name)
          setOpenModalLocation && setOpenModalLocation(false)
        } else {
          swal({
            icon: 'error',
            title: 'Save Unsuccessful',
            text: response.message,
          })
        }

      })
      .then(() => dispatch(fetchSuccess(CREATE_UPDATE_LOCATION_LOG)))
      .catch(() => dispatch(fetchError(CREATE_UPDATE_LOCATION_LOG)))
  }
}