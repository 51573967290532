export const SC = "AWS-CWP_groups_sc_" + process.env.REACT_APP_TOKEN_ENV_FOR_USER_GROUP;
export const TN = "AWS-CWP_groups_tn_" + process.env.REACT_APP_TOKEN_ENV_FOR_USER_GROUP;
export const ENTS = "AWS-CWP_groups_ents_" + process.env.REACT_APP_TOKEN_ENV_FOR_USER_GROUP;

export const USER_PERSONA_LIST = [
  {
    title: "TN",
    description: "TN",
    value: TN,
  },
  {
    title: "ENTS",
    description: "ENTS",
    value: ENTS,
  },
  {
    title: "SC",
    description: "SC",
    value: SC,
  },
];

export const PERMISSIONS = {
  setting: ["olv-admin-access"],
  vesselManagement: ["olv-admin-access", "olv-vessel-management-page-access"],
  track: ["olv-admin-access", "exec-all-user-page-access", "exec-main-page-access"],
  explore: ["olv-admin-access", "exec-all-user-page-access", "exec-explore-page-access"],
  mainExplore: [
    "olv-admin-access",
    "exec-all-user-page-access",
    "exec-main-page-access",
    "exec-explore-page-access",
  ],
  kpi: ["olv-admin-access", "exec-all-user-page-access", "exec-kpi-page-access"],
  plainning: ["olv-admin-access", "exec-all-user-page-access", "exec-plan-page-access"],
  speed: ["olv-admin-access", "exec-all-user-page-access", "exec-speed-page-access"],
  deleteVesselSchedule: [
    "olv-admin-access",
    "exec-all-user-page-access",
    "exec-vessel-schedule-page-access",
  ],
  dashboard: ["olv-admin-access", "exec-all-user-page-access", "exec-dashboard-page-access"],
  mooringBuoyManagement: [
    "olv-admin-access",
    "exec-all-user-page-access",
    "exec-dashboard-page-access",
  ],
  demand: [
    "olv-admin-access",
    "demand-all-user-page-access",
    "demand-enable-save-reservation",
    "demand-reservation-page-access",
    "demand-reservation-en-route-widget",
    "demand-leadership-dashboard-widget",
  ],
  reservation: [
    "olv-admin-access",
    "demand-all-user-page-access",
    "demand-reservation-page-access",
  ],
  support: ["olv-admin-access", "demand-all-user-page-access"],
  iapApproval: ["demand-all-user-page-access", "demand-approval-page-access"],
  optimiseCreate: ["olv-admin-access", "opt-all-user-page-access", "opt-cvs-page-access"],
  optimiseUpload: ["olv-admin-access", "opt-all-user-page-access", "opt-upload-page-access"],
  optimiseForecast: ["olv-admin-access", "opt-all-user-page-access"],
  optimisePlanner: ["olv-admin-access", "opt-all-user-page-access"],
  vesselSchedule: ["olv-admin-access", "opt-all-user-page-access", "opt-vvs-page-access"],
  editSchedule: ["olv-admin-access", "exec-all-user-page-access", "exec-enable-edit-schedule"],
  fleetPerformanceWidget: [
    "olv-admin-access",
    "exec-all-user-page-access",
    "exec-fleet-performance-widget",
  ],
  mooringBuoyUtilizationWidget: [
    "olv-admin-access",
    "exec-all-user-page-access",
    "exec-mooring-buoy-utilization-widget",
  ],
  reservationEnRouteWidget: [
    "olv-admin-access",
    "demand-all-user-page-access",
    "demand-reservation-en-route-widget",
  ],
  leadershipDashboardWidget: [
    "olv-admin-access",
    "demand-all-user-page-access",
    "demand-leadership-dashboard-widget",
  ],
  shipmentEnRouteWidget: [
    "olv-admin-access",
    "exec-all-user-page-access",
    "exec-shipment-en-route-widget",
  ],
  fleetSummaryWidget: [
    "olv-admin-access",
    "exec-all-user-page-access",
    "exec-fleet-summary-widget",
  ],
  vodTester: [
    "vod-vessel-listing-page",
    "vod-vessel-registration-page",
    "vod-reservation-page-access",
    "vod-booking-page-access",
    "vod-track-trace-page-access",
    "vod-voyage-planning-widget",
    "vod-action-required-widget",
    "vod-notification-widget",
    "vod-leaderboard-widget",
  ],
  vodAdmin: ["vod-approval-page-access", "vod-admin-email-verification"],
  editProfile: ["olv-admin-access"],
  systemAlertView: ["system-alert-full-access", "system-alert-view-access"],
  systemAlertCreateEdit: ["system-alert-full-access", "system-alert-create-&-edit-access"],
  systemAlertDelete: ["system-alert-full-access", "system-alert-delete-access"],
  dataManagement: ["data-management-volume-full-access", "data-management-standard-daily-average-full-access", "data-management-fuel-capping-full-access", "data-management-volume-view-access", "data-management-standard-daily-average-view-access", "data-management-fuel-capping-view-access"],
  dataManagementVolumeView: ["data-management-volume-full-access", "data-management-volume-view-access"],
  dataManagementVolumeCreate: ["data-management-volume-full-access", "data-management-volume-create-access"],
  dataManagementVolumeEdit: ["data-management-volume-full-access", "data-management-volume-edit-access"],
  dataManagementStandardDailyView: ["data-management-standard-daily-average-full-access", "data-management-standard-daily-average-view-access"],
  dataManagementStandardDailyEdit: ["data-management-standard-daily-average-full-access", "data-management-standard-daily-average-edit-access"],
  dataManagementFuelCappingView: ["data-management-fuel-capping-full-access", "data-management-fuel-capping-view-access"],
  dataManagementFuelCappingCreate: ["data-management-fuel-capping-full-access", "data-management-fuel-capping-create-access"],
  dataManagementFuelCappingEdit: ["data-management-fuel-capping-full-access", "data-management-fuel-capping-edit-access"],
  fmsDashboardView: ["fms-dashboard-view-access"]
};
// vodAdmin
// vodApprovalPage

export const DIVISION_CODE = {
  PCSB: 'PCSB',
  VESTIGO: 'Vestigo'
}

export const MATERIAL_PLANNER_ROLE = "Material Planner / Dispatch";

export const IAP_PLANNER_ROLE = "IAP Planner";

export const BOOKING_FOCAL_ROLE = "Booking Focal";

export const ROLES = {
  newUiConfirmedReservation: ['Material Planner / Dispatch', 'Business Users', 'IAP Focal', 'Booking Focal']
};

export const UNIT_SYSTEM = {
  METRIC: 'Metric',
  IMPERIAL: 'Imperial'
}

export const UNIT = {
  GAL: 'gal',
  KL: 'kL',
  M2: 'm2',
  M: 'M',
  FT2: 'ft2',
  FT: 'FT',
  TON: 'ton',
  MT: 'Mt',
  NM: 'nm',
  KN: 'kn',
  LB: 'lb',
  KG: 'kg',
  C: 'C',
  F: 'F',
  KMH: 'KM/H',
  MPH: 'MPH',
  INCH: 'INCH',
  MM: 'MM',
  ms: 'm/s',
  fts: 'ft/s',
  knots: 'knots',
  ft: 'ft',
  mm: 'mm',
  in: 'in',
  m: 'm',
  ft2: 'ft²',
  m2: 'm²',
  m3: 'm³',
  kl: 'kl',
  sqft: 'sqft',
  KL2: 'KL'
}

export const UNIT_CONVERT = {
  M_TO_FT : '3.281',
  M2_TO_FT2: '10.76391',
  KL_TO_GAL: '264.172052',
}

export const LAYER_TYPE = {
  METRIC: 'b-metric',
  IMPERIAL: 'b'
}