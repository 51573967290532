import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'

// Components
import {
  Divider,
} from 'semantic-ui-react'

import ActiveLog from './activeLog/view'
import ReasonMethod from './activeLog/method'

// Assets
import WARNING_ICON from '../../../../assets/images/warning-circle.svg'
import BACK_ICON from '../../../../assets/images/arrow-left.svg'
import CustomSmallPopup from '../../../custom-small-popup'


const ReportIncidenceSideBar = (props) => {
    const {
      setShowReportIncidence,
      incidentReasons,
      incidentReasonsDescription,
      selectedNotification,
      setIsOpenModal,
      setFilterTripIDtoExplorePage,
      activityLogs,
      reflectActiveLogs,
      isMobile,
      showReportIncidence,
    } = props
  
    // eslint-disable-next-line
    const [selectedReasonList, setSelectedReasonList] = useState([]) // eslint-disable-next-line
    const [selectedReason, setSelectedReason] = useState(null) // eslint-disable-next-line
    const [reasonComment, setReasonComment] = useState(null) // eslint-disable-next-line
    const [isReasonExisted, setIsReasonExisted] = useState(false)

    const handleIsOpen = (value) => {
      setShowReportIncidence(value);
      setIsOpenModal(value);
    }
  
    useEffect(() => {
      const isExisted = selectedReasonList.some((item) => _.isEqual(item.sub_reason, selectedReason))
      setIsReasonExisted(isExisted)
      console.log('-- isMobile --', isMobile);
    }, [selectedReason, selectedReasonList])
    return (isMobile ? 
      <CustomSmallPopup
        title = {'Report Incident'}
        isOpen={showReportIncidence}
        setIsOpen={handleIsOpen}
        styles={{
          // container: {
          //   borderRadius: '10px 10px 0px 0px',
          //   background: 'var(--queen-blue-25, #EBF0F5)',
          // },
          title: {
            color: 'var(--queen-blue-300-main, #4B7093)',
            fontFamily: 'Inter',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px',
          }
        }}
      >
        <div className='report-incident-container'>
          <div className={`row-1`} style={{ display: 'flex', gap: '10px', paddingTop: '20px' }}>
            <ReactSVG src={WARNING_ICON} />
            <Link to={'/main-explore'} onClick={setFilterTripIDtoExplorePage}>
              <div className={`sub-title`} style={{ 
                color: 'var(--duke-blue-p-300-main, #0000A1)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '22px',
               }}>
                {selectedNotification.vessel_name + ` | ` + selectedNotification.location}
              </div>
            </Link>
          </div>
          <div className='row-2' style={{ paddingTop: '12px' }}>
            <div className={`secondary-sub-title`} style={{
              color: 'var(--independence-grey-dark-n-300-form-labels, #5C5C7A)',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px',
            }}>
              {`Voyage: ${selectedNotification.trip_id}`}{' '}
            </div>
          </div>
          <div className='row-3' style={{ paddingTop: '18px' }}>
            <div className={`details-container`} style={{
              borderRadius: '10px',
              background: 'var(--alert-warning-y-50, #FDF1CE)',
              padding: '16px',
            }}>
              <div className={`sidebar-details`} style={{
                color: 'var(--alert-warning-y-500, #C79500)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '22px',
              }}>
                {selectedNotification.reason} at {selectedNotification.location}, sequence{' '}
                {selectedNotification.seq} for Voyage ID:
                {selectedNotification.trip_id}
              </div>
            </div>
          </div>
          <div className='row-3'>
            <ActiveLog data={activityLogs} reflectActiveLogs={reflectActiveLogs} isMobile={isMobile}></ActiveLog>
          </div>
          <div className='row-4'>
            <div className="clear-float"></div>
            <ReasonMethod
              incidentReasons={incidentReasons}
              incidentReasonsDescription={incidentReasonsDescription}
              selectedNotification={selectedNotification}
              reflectActiveLogs={reflectActiveLogs}
              isMobile={isMobile}
              handleIsOpen={handleIsOpen}
            ></ReasonMethod>
          </div>
        </div>
      </CustomSmallPopup>
    : 
    <div className={`notification-dimmer`}>
      <div className={`notification-list`}>
        <div className={`notification-heading flex`}>
          <div className={`flex`}>
            <ReactSVG
              src={BACK_ICON}
              className={`close-icon`}
              onClick={() => {
                setShowReportIncidence(false)
                setIsOpenModal(false)
              }}
            />
            <div className={`sidebar-label`}>Report Incidence / Schedule Adherence Reason</div>
          </div>
        </div>
        <Divider />
        <div className={`pad-x-1`}>
          <div className={`flex`}>
            <ReactSVG src={WARNING_ICON} />
            <Link to={'/main-explore'} onClick={setFilterTripIDtoExplorePage}>
              <div className={`sidebar-sub-title`}>
                {selectedNotification.vessel_name + ` | ` + selectedNotification.location}
              </div>
            </Link>
          </div>
          <div className={`sidebar-secondary-sub-title mar-top-0_5`}>
            {`Voyage: ${selectedNotification.trip_id}`}{' '}
          </div>
        </div>
        <Divider />
        <div className={`pad-x-1 pad-y-0_5`}>
          <div className={`sidebar-details-container`}>
            <div className={`sidebar-details `}>
              {selectedNotification.reason} at {selectedNotification.location}, sequence{' '}
              {selectedNotification.seq} for Voyage ID:
              {selectedNotification.trip_id}
            </div>
          </div>
          <ActiveLog data={activityLogs} reflectActiveLogs={reflectActiveLogs}></ActiveLog>
          <div className="clear-float"></div>
          <ReasonMethod
            incidentReasons={incidentReasons}
            incidentReasonsDescription={incidentReasonsDescription}
            selectedNotification={selectedNotification}
            reflectActiveLogs={reflectActiveLogs}
          ></ReasonMethod>
        </div>
      </div>
  </div>)
}

export default ReportIncidenceSideBar