import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment';
import _ from 'lodash';

export const getVoyageScheduleFormatData = (data) => {
  const bData = cloneDeep(data);
  const startDateKey = 'start_date';
  const endDateKey = 'end_date';
  const vesselDetailKey = 'vessel_detail';
  const plannedArrivalDateKey = 'planned_arrival_date';
  const plannedDepartureDateKey = 'planned_departure_date';
  
  // validate
  if (
    !bData ||
    !bData[startDateKey] ||
    !bData[endDateKey] ||
    bData[vesselDetailKey].length === 0
  ) {
    return data;
  }

  bData[startDateKey] = moment(bData[startDateKey]).utc().format();
  bData[endDateKey] = moment(bData[endDateKey]).utc().format();

  for (const ind in bData[vesselDetailKey]) {
    bData[vesselDetailKey][ind][plannedArrivalDateKey] =
      bData[vesselDetailKey][ind][plannedArrivalDateKey] ?
        moment(bData[vesselDetailKey][ind] [plannedArrivalDateKey]).utc().format() : null;
    bData[vesselDetailKey][ind][plannedDepartureDateKey] =
      bData[vesselDetailKey][ind][plannedDepartureDateKey] ?
        moment(bData[vesselDetailKey][ind] [plannedDepartureDateKey]).utc().format() : null;
  }

  return bData;
}

export const getPayloadVoyageChart = (data) => {
  let payload = cloneDeep(data.data);
  const vesselTypeKey = 'vessel_type';
  const startDateKey = 'start_date';
  const endDateKey = 'end_date';
  const vesselDetailKey = 'vessel_detail';
  const startDateRawKey = 'start_date_raw'
  const endDateRawKey = 'end_date_raw'
  // validate
  if (
    !payload ||
    !payload?.length ||
    !payload[0][vesselTypeKey]?.length ||
    !payload[0][vesselTypeKey][0][vesselDetailKey]?.length
  ) {
    return data;
  }

  for (const ind in payload) {
    for (const inde in payload[ind][vesselTypeKey]) {
      // add 8 hours because the DB return Malay time
      payload[ind][vesselTypeKey][inde][startDateRawKey] = moment(
        payload[ind][vesselTypeKey][inde][startDateKey]
      );
      payload[ind][vesselTypeKey][inde][startDateKey] = moment(
        payload[ind][vesselTypeKey][inde][startDateKey]
      ).format("YYYY-MM-DD");
      
      payload[ind][vesselTypeKey][inde][endDateRawKey] = moment(
        payload[ind][vesselTypeKey][inde][endDateKey]
      );
      payload[ind][vesselTypeKey][inde][endDateKey] = moment(
        payload[ind][vesselTypeKey][inde][endDateKey]
      ).format("YYYY-MM-DD");
      
      for (const index in payload[ind][vesselTypeKey][inde][vesselDetailKey]) {
        payload[ind][vesselTypeKey][inde][vesselDetailKey][index][startDateRawKey] = moment(
          payload[ind][vesselTypeKey][inde][vesselDetailKey][index][startDateKey]
        );
        payload[ind][vesselTypeKey][inde][vesselDetailKey][index][startDateKey] = moment(
          payload[ind][vesselTypeKey][inde][vesselDetailKey][index][startDateKey]
        ).format("YYYY-MM-DD");
        
        payload[ind][vesselTypeKey][inde][vesselDetailKey][index][endDateKey] = moment(
          payload[ind][vesselTypeKey][inde][vesselDetailKey][index][endDateKey]
        ).format("YYYY-MM-DD");
      }
    }
  }
  const {weather_alert, weather_alert_count} = getPayloadVoyageChartWeatherAlert(data);
  payload = { ...data, data: payload, weather_alert, weather_alert_count };
  return payload;
}

const getPayloadVoyageChartWeatherAlert = (data) => {
  let payload = cloneDeep(data.weather_alert);
  let weather_alert = [];
  let weather_alert_count = 0;

  if (
    payload &&
    payload?.length > 0
  ) {
    _.forEach(payload, (value) => {

      let alert_by_vessel_count = 0;
      let weatherObj = {
        vessel_name: _.get(value, 'vessel_name'),
        vessel_id: _.get(value, 'vessel_id'),
        alert: []
      }

      const dateFormatedAlerts = _.map(value.alert,  alertItem => {
        const alert_start_date = moment(alertItem?.location_start_date).format('YYYY-MM-DD');
        delete alert?.location_start_date
        return {
          ...alertItem,
          alert_start_date
        }
      })

      const groupedAlerts = _.groupBy(dateFormatedAlerts, 'alert_start_date');

      const uniqAlertsByDate = _.mapValues(groupedAlerts, (group) => {
        alert_by_vessel_count++;
        const uniqueAlerts = _.uniqBy(group, 'alert_message');
        const locations = _.uniq(_.flatMap(group, 'alert_location'));

        return uniqueAlerts.map((alert) => ({
            alert_type: alert.alert_type,
            alert_start_date: alert.alert_start_date,
            alert_location: locations,
            alert_message: alert.alert_message,
        }));
      });

    
      weather_alert_count += alert_by_vessel_count;
      weatherObj.alert = uniqAlertsByDate;
      weather_alert.push(weatherObj)
    })
  }
  return {weather_alert, weather_alert_count};
}

export const formatDownloadScheduleDMinus3 = (data) => {
  const payload = cloneDeep(data);
 // validate
  if (
    !payload ||
    !payload?.length
  ) {
    return data;
  }
  return payload?.map(item => ({...item, end_date: moment(item?.end_date).format('YYYY-MM-DD HH:mm:ss'), start_date: moment(item?.start_date).format('YYYY-MM-DD HH:mm:ss')}))
}

export const getformatVesselSchedule = (data) => {
  const maxSeq = data.length;
  const result = data.reduce((arr, item, index) => {
    if (index === maxSeq - 1) return arr;
    const nextSeq = index < maxSeq ? data[index + 1] : null;
    const deptDate = moment(item?.planned_departure_date).utc().format();
    const arrivalDate = moment(nextSeq?.planned_arrival_date).utc().format();
    const object = {
      seq: index + 1,
      start_location: item?.location,
      destination: nextSeq?.location,
      tar_dept_date: deptDate,
      tar_arrival_date: arrivalDate,
      eta_dept_date: deptDate,
      eta_arrival_date: arrivalDate,
    }
    return arr.concat(object)
  }, [])

  return result;
}

export const uniqArrayValues = (arr) => [...new Set(arr)];

export const getSameDayVoyages = (data) => {
  const result = data?.data
    ?.map((item) => {
      let groups = [];
      let grouppedIds = [];

      item.vessel_type?.forEach((el, idx) => {
        if (el.end_date === item.vessel_type[idx + 1]?.start_date) {
          grouppedIds.push(el.voyage_id, item.vessel_type[idx + 1]?.voyage_id);
        } else if (
          el.end_date !== item.vessel_type[idx + 1]?.start_date &&
          grouppedIds.length > 0
        ) {
          //add current grouppedIds into Groups
          groups.push(uniqArrayValues(grouppedIds));
          //reset
          grouppedIds = [];
        }
      });

      if (groups.length > 0) {
        return {
          vesselName: item.vessel_name,
          vesselId: item.vessel_id,
          overlapVoyageIds: groups,
        };
      }
    })
    .filter(Boolean);

  return result;
};
     