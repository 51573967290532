import _ from 'lodash'
import moment from 'moment'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

const CONTROLLER_NAME = '/optimise'

const objectToQueryString = (obj) => {
  const queryString = new URLSearchParams(obj).toString();
  return queryString ? `?${queryString}` : '';
}

export const getVesselSchedule = (regionCode, period) => {
  let isEmpty = _.isEmpty(regionCode) && _.isEmpty(period)
  const startDate = moment(_.map(period, 'startDate')[0]).format('YYYY-MM-DD')
  const endDate = moment(_.map(period, 'endDate')[0]).format('YYYY-MM-DD')

  const getRegion = `${CONTROLLER_NAME}/vesselSchedule`
  const getData = `${CONTROLLER_NAME}/vesselSchedule?region_code=${regionCode}&start_date=${startDate}&end_date=${endDate}`

  return LAMBDA_API_INSTANCE().then(response => response.get(isEmpty ? getRegion : getData))
}

export const getVesselScheduleByRegionPeriodSupplyBaseAPI = (regionCode, supplyBase, period) => {
  let isEmpty = _.isEmpty(regionCode) && _.isEmpty(period) && _.isEmpty(supplyBase)
  const startDate = moment(_.map(period, 'startDate')[0]).format('YYYY-MM-DD')
  const endDate = moment(_.map(period, 'endDate')[0]).format('YYYY-MM-DD')

  const getRegion = `${CONTROLLER_NAME}/vesselSchedule`
  const getData = `${CONTROLLER_NAME}/vesselSchedule?region_code=${regionCode}&supply_base=${supplyBase}&start_date=${startDate}&end_date=${endDate}`

  return LAMBDA_API_INSTANCE().then(response => response.get(isEmpty ? getRegion : getData))
}

export const postVesselSchedule = (regionCode, period, jobType, supplyBase = null) => {
  const getStartDate = _.reduce(_.map(period, item => item.startDate))
  const getEndDate = _.reduce(_.map(period, item => item.endDate))
  const bodyParams = {
    region_code: regionCode,
    start_date: moment(getStartDate).format('YYYY-MM-DD'),
    end_date: moment(getEndDate).format('YYYY-MM-DD'),
    job_type: jobType,
    ...(supplyBase && { supply_base: supplyBase })
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselSchedule`, bodyParams))
}

export const getScenarioModelling = (regionCode, period, jobType, supplyBase = null) => {
  const getStartDate = _.reduce(_.map(period, item => item.startDate))
  const getEndDate = _.reduce(_.map(period, item => item.endDate))
  const startDate = moment(getStartDate).format('YYYY-MM-DD')
  const endDate = moment(getEndDate).format('YYYY-MM-DD')

  const queryString = objectToQueryString({
    region_code: regionCode,
    job_type: jobType,
    start_date: startDate,
    end_date: endDate,
    ...(supplyBase && { supply_base: supplyBase })
  });

  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/scenarioModelling${queryString}`))
}

export const putScenarioModelling = (regionCode, period, jobType, scenarioType, supplyBase = null) => {
  const getStartDate = _.reduce(_.map(period, item => item.startDate))
  const getEndDate = _.reduce(_.map(period, item => item.endDate))
  const bodyParams = {
    region_code: regionCode,
    start_date: moment(getStartDate).format('YYYY-MM-DD'),
    end_date: moment(getEndDate).format('YYYY-MM-DD'),
    job_type: jobType,
    scenario_type: scenarioType,
    ...(supplyBase && { supply_base: supplyBase })
  }
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/scenarioModelling`, bodyParams))
}

export const deleteScenarioModelling = (regionCode, period, jobType, supplyBase = null) => {
  const getStartDate = _.reduce(_.map(period, item => item.startDate))
  const getEndDate = _.reduce(_.map(period, item => item.endDate))
  const startDate = moment(getStartDate).format('YYYY-MM-DD')
  const endDate = moment(getEndDate).format('YYYY-MM-DD')

  const queryString = objectToQueryString({
    region_code: regionCode,
    job_type: jobType,
    start_date: startDate,
    end_date: endDate,
    ...(supplyBase && { supply_base: supplyBase })
  }
  );
  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_NAME}/scenarioModelling${queryString}`))
}

export const getUserRegionDropdown = () => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/dropdown?category=user_region`))
}

export const getDropdown = (category, additionalParam = []) => {
  const extraParam = _.isEmpty(additionalParam) ? '' : _.join(additionalParam, '')
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/dropdown?category=${category}${extraParam}`))
}

export const getDropdownD30OptionalVessels = (category, additionalParam = []) => {
  let bodyParams = { ...additionalParam, category: category }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/dropdown`, bodyParams))
}

export const getSchedulePlannerChart = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  // period
  startDate,
  endDate,
  supplyBase = null
) => {

  const queryString = objectToQueryString({
    material_region_code: materialRegionCode,
    people_region_code: peopleRegionCode,
    marine_region_code: marineRegionCode,
    start_date: startDate,
    end_date: endDate,
    ...(supplyBase && { supply_base: supplyBase })
  }
  );
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/schedulePlannerChart${queryString}`
  ))
}

export const getConfirmSchedule = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  // period
  startDate,
  endDate,
  supplyBase = null
) => {

  const queryString = objectToQueryString({
    material_region_code: materialRegionCode,
    people_region_code: peopleRegionCode,
    marine_region_code: marineRegionCode,
    start_date: startDate,
    end_date: endDate,
    ...(supplyBase && { supply_base: supplyBase })
  }
  );

  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/confirmSchedule${queryString}`
  ))
}

export const getScheduleComparison = (
  kpiType,
  regionCode,
  startDate,
  endDate
) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/scheduleComparison?kpi_type=${kpiType}&region_code=${regionCode}&start_date=${startDate}&end_date=${endDate}`
  ))
}

export const putConfirmSchedule = (
  startDate,
  endDate,
  regionCode,
  supplyBase = null
) => {
  const bodyParams = {
    start_date: startDate,
    end_date: endDate,
    region_code: regionCode,
    ...(supplyBase && { supply_base: supplyBase })
  }
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/confirmSchedule`, bodyParams))
}

export const getSchedulePlannerChartDetail = (vesselId, voyageId, jobType, scheduleHdrId) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/schedulePlannerChartDetail?vessel_id=${vesselId}&voyage_id=${voyageId}&job_type=${jobType}&schedule_hdr_id=${scheduleHdrId}`
  ))
}

export const getScheduleOverviewFooter = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  // period
  startDate,
  endDate,
  isPublished,
  supplyBase = null
) => {
  const queryString = objectToQueryString({
    material_region_code: materialRegionCode,
    people_region_code: peopleRegionCode,
    marine_region_code: marineRegionCode,
    start_date: startDate,
    end_date: endDate,
    is_published: isPublished,
    ...(supplyBase && { supply_base: supplyBase })
  }
  );

  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/scheduleOverviewFooter${queryString}`
  ))
}

export const postExecuteModel = (regionCode, period, jobType, scenarioId, supplyBase = null, business_units, vessel_job_types, vessels) => {
  const getStartDate = _.reduce(_.map(period, item => item.startDate))
  const getEndDate = _.reduce(_.map(period, item => item.endDate))
  const bodyParams = {
    region_code: regionCode,
    start_date: moment(getStartDate).format('YYYY-MM-DD'),
    end_date: moment(getEndDate).format('YYYY-MM-DD'),
    job_type: jobType,
    scenario_id: scenarioId,
    ...(supplyBase && { supply_base: supplyBase }),
    business_units,
    vessel_job_types,
    vessels
  }
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/executeModel`, bodyParams))
}

export const getSchedulePlannerList = (
  // period,
  startDate,
  endDate,
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode
) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/schedulePlannerList?start_date=${startDate}&end_date=${endDate}&material_region_code=${materialRegionCode}&people_region_code=${peopleRegionCode}&marine_region_code=${marineRegionCode}`
  ))
}

export const getVesselScheduleChart = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  startDate,
  endDate,
  vesselId = null,
) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/vesselScheduleChart?material_region_code=${materialRegionCode}&people_region_code=${peopleRegionCode}&marine_region_code=${marineRegionCode}&start_date=${startDate}&end_date=${endDate}&vessel_id=${vesselId}`
  ))
}

export const getVesselScheduleSidebar = (voyageId, scheduleHdrId, isImperial = false) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/vesselScheduleSidebar?voyage_id=${voyageId}${scheduleHdrId ? `&schedule_hdr_id=${scheduleHdrId}` : ''}&isImperial=${isImperial}`))
}

export const getVesselScheduleFooter = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  period
) => {
  const getStartDate = _.reduce(_.map(period, item => item.startDate))
  const getEndDate = _.reduce(_.map(period, item => item.endDate))
  const startDate = moment(getStartDate).format('YYYY-MM-DD')
  const endDate = moment(getEndDate).format('YYYY-MM-DD')

  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/vesselScheduleFooter?start_date=${startDate}&end_date=${endDate}&material_region_code=${materialRegionCode}&people_region_code=${peopleRegionCode}&marine_region_code=${marineRegionCode}`
  ))
}

export const putScheduleMaintenance = (maintenanceList) => {
  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/scheduleMaintenance`, maintenanceList))
}

export const postBookVesselSchedule = (
  regionCode,
  startDate,
  endDate,
  marineStartDate,
  locationName,
  vesselId,
  loadRequirement,
  jobType
) => {
  const bodyParams = {
    region_code: regionCode,
    start_date: startDate,
    end_date: endDate,
    marine_start_date: marineStartDate,
    location_name: locationName,
    vessel_id: vesselId,
    load_requirement: loadRequirement,
    job_type: jobType,
  }

  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselSchedule`, bodyParams))
}

export const postBookMarineService = (inputParams = {}) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselSchedule`, inputParams))
}

export const postOptimiseBookVesselSchedule = (
  regionCode,
  startDate,
  endDate,
  marineStartDate,
  marineEndDate,
  locationName,
  vesselId,
  loadRequirement,
  marineType,
  jobType
) => {
  const bodyParams = {
    region_code: regionCode,
    start_date: startDate,
    end_date: endDate,
    marine_start_date: marineStartDate,
    marine_end_date: marineEndDate,
    location_name: locationName,
    vessel_ids: vesselId,
    load_requirement: loadRequirement,
    marine_type: marineType,
    job_type: jobType,
  }

  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselSchedule`, bodyParams))
}

export const postOptimiseBookRovingSchedule = (
  regionCode,
  startDate,
  endDate,
  marineStartDate,
  marineEndDate,
  locationName,
  vesselId,
  serviceTypeId,
  marineType,
  jobType
) => {
  const bodyParams = {
    region_code: regionCode,
    start_date: startDate,
    end_date: endDate,
    marine_start_date: marineStartDate,
    marine_end_date: marineEndDate,
    location_name: locationName,
    vessel_ids: vesselId,
    service_type_id: serviceTypeId,
    marine_type: marineType,
    job_type: jobType,
  }

  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselSchedule`, bodyParams))
}

export const postUploadDataFiles = (uploadFile) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/files`, uploadFile))
}

export const getUploadDataFiles = (category, fileName) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/files?category=${category}&file_name=${fileName}`
  ))
}

export const getUploadDataFilesList = (filterCategory, filterStartDate, filterEndDate) => {
  const startDate = moment(_.get(...filterStartDate, 'startDate')).format('YYYY-MM-DD')
  const endDate = moment(_.get(...filterEndDate, 'startDate')).format('YYYY-MM-DD')

  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/fileList?category=${filterCategory}&start_date=${startDate}&end_date=${endDate}`
  ))
}

export const getTemplateBinaryData = () => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/generateDataTemplate`,
    {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'responseType': "arraybuffer"
    }
  ))
}

export const putVesselSchedule = (list) => {
  const bodyParams = list

  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/vesselSchedule`, bodyParams))
}

export const putVesselScheduleSidebar = (list) => {

  return LAMBDA_API_INSTANCE().then(response => response.put(`${CONTROLLER_NAME}/vesselScheduleSidebar`, list))
}

export const postCustomScenarioModelling = (
  voyageDetailsObject
) => {
  const bodyParams = voyageDetailsObject

  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/customScenarioModeling`, bodyParams))
}

export const deleteVoyage = (voyageId, jobType, scheduleHdrId) => {
  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_NAME}/schedulePlannerChartDetail?voyage_id=${voyageId}&job_type=${jobType}&schedule_hdr_id=${scheduleHdrId}`))
}

export const getVesselCapacity = (
  vesselId,
  JobType,
  isImperial = false
) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/vesselCapacity?vessel_id=${vesselId}&job_type=${JobType}&isImperial=${isImperial}`
  ))
}

export const getVesselContractPeriod = (vesselName) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/vesselScheduleContractValidation?vessel_name=${vesselName}`
  ))
}

export const postVesselScheduleSidebar = (object) => {

  return LAMBDA_API_INSTANCE().then(response => response.post(`${CONTROLLER_NAME}/vesselScheduleSidebar`, object))
}

export const deletePublishedVoyage = (voyageId, deleteReason) => {
  const bodyParams = {
    data: {
      voyage_id: voyageId,
      delete_reason: deleteReason,
    }
  }
  return LAMBDA_API_INSTANCE().then(response => response.delete(`${CONTROLLER_NAME}/vesselScheduleSidebar`, bodyParams))
}

export const getVoyageScheduleSeqPriority = (
  tripId
) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/voyageScheduleSeqPriority?trip_id=${tripId}`
  ))
}

export const postVoyageScheduleSeqPriority = (
  array
) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/voyageScheduleSeqPriority`, array
  ))
}

export const putVoyageScheduleSeqPriority = (
  array
) => {
  return LAMBDA_API_INSTANCE().then(response => response.put(
    `${CONTROLLER_NAME}/voyageScheduleSeqPriority`, array
  ))
}

export const postExecuteDMinus3 = (payload) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/executeDMinus3`, payload
  ))
}

export const putScheduleStatusDMinus3 = (
  array
) => {
  return LAMBDA_API_INSTANCE().then(response => response.put(
    `${CONTROLLER_NAME}/scheduleStatusDMinus3`, array
  ))
}

export const getScheduleStatusDMinus3 = (
  object
) => {
  const params = Object.entries(object).map(([key, value]) => `${value ? `${key}=${value}` : ''}`).join('&');

  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/scheduleStatusDMinus3?${params}`
  ))
}

export const getDownloadScheduleDMinus3 = (
  tripId
) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/downloadScheduleDMinus3?trip_id=${tripId}`
  ))
}

export const comparePLTScheduleAPI = (voyageID) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/comparePLTSchedule?voyage_id=${voyageID}`))
}

export const comparePLTScheduleShowDetailAPI = (voyageID) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(`${CONTROLLER_NAME}/comparePLTSchedule?voyage_id=${voyageID}&show_detail_compare=true`))
}

export const postPLTIntegration = (body) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/pltIntegration`, body
  ))
}

export const postDemandForecast = (body) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/demandForecast`, body
  ))
}

export const postVoyageActivity = (body) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/voyageActivity`, body
  ))
}

export const postFetchDemandData = (body) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/fetchDemandData`, body
  ))
}

export const getFetchDemandData = (query) => {
  return LAMBDA_API_INSTANCE().then(response => response.get(
    `${CONTROLLER_NAME}/fetchDemandData`, { params: query }
  ))
}

export const postVesselScheduleWeatherData = (body) => {
  return LAMBDA_API_INSTANCE().then(response => response.post(
    `${CONTROLLER_NAME}/vesselScheduleWeatherData`, body
  ))
}