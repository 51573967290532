import _ from "lodash";
import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";

const CONTROLLER_NAME = "/supply";

export const getLeaderboard = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/supplyLeaderboard`)
  );
};

// ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ---------- DROPDOWN ----------
export const getDropdown = (page_name, dropdown_category, objName, objValue) => {
  var bodyParams = `page_name=${page_name}`;
  if (!_.isEmpty(dropdown_category)) bodyParams += `&dropdown_category=${dropdown_category}`;
  if (!_.isEmpty(objName)) bodyParams += `&${objName}=${objValue}`;

  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/dropdown?${bodyParams}`)
  );
};

// ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ---------- SELLER VESSE LIST ----------
export const getMyVessels = () => {
  return LAMBDA_API_INSTANCE().then((response) => response.get(`${CONTROLLER_NAME}/myVessels`));
};

export const getLeaseVessel = (registrationID, leaseType) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/leaseVessel?registration_id=${registrationID}&lease_type=${leaseType}`
    )
  );
};

export const deleteLeaseVessel = (registration_id, lease_type) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.delete(
      `${CONTROLLER_NAME}/leaseVessel?registration_id=${registration_id}&lease_type=${lease_type}`
    )
  );
};

export const putLeaseVessel = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.put(`${CONTROLLER_NAME}/leaseVessel`, bodyParams)
  );
};

export const postLeaseVessel = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/leaseVessel`, bodyParams)
  );
};

// ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ---------- SELLER VESSE REGISTRATION ----------
export const getVesselRegistration = (registrationID, isDraft) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/vesselRegistration?registration_id=${registrationID}&is_draft=${isDraft}`
    )
  );
};

export const postVesselRegistration = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/vesselRegistration`, bodyParams)
  );
};

export const putVesselRegistration = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.put(`${CONTROLLER_NAME}/vesselRegistration`, bodyParams)
  );
};

export const deleteVesselRegistration = (isDraft, registrationID, originalRegistrationID) => {
  var bodyParams = `is_draft=${isDraft}&registration_id=${registrationID}`;
  if (!_.isEmpty(originalRegistrationID))
    bodyParams += `&original_registration_id=${originalRegistrationID}`;

  return LAMBDA_API_INSTANCE().then((response) =>
    response.delete(`${CONTROLLER_NAME}/vesselRegistration?${bodyParams}`)
  );
};

// ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ---------- BUYER ----------
export const getBuyerSearchVessel = (
  lease_type,
  project_name,
  vessel_type,
  discipline,
  base_seat_capacity,
  port_of_anchorage,
  start_date,
  end_date
) => {
  const bodyParamsSharing = {
    lease_type,
    project_name,
    vessel_type,
    discipline,
    base_seat_capacity,
  };

  const bodyParamsChartering = {
    lease_type,
    port_of_anchorage,
    vessel_type,
  };

  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(
      `${CONTROLLER_NAME}/triggerMatchingAlgorithm`,
      _.isEqual(lease_type, "voyage-sharing") ? bodyParamsSharing : bodyParamsChartering
    )
  );
};

export const putBuyerSearchVessel = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.put(`${CONTROLLER_NAME}/searchReservation`, bodyParams)
  );
};

export const postBuyerSearchVessel = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/searchReservation`, bodyParams)
  );
};

export const deleteBuyerSearchVessel = (lease_type) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.delete(`${CONTROLLER_NAME}/searchReservation?lease_type=${lease_type}`)
  );
};

export const putBuyerReserveVessel = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.put(`${CONTROLLER_NAME}/submitReservation`, bodyParams)
  );
};

export const postBuyerReserveVessel = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/submitReservation`, bodyParams)
  );
};

// ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ---------- BOOKINGS ----------
export const getMyBookings = () => {
  return LAMBDA_API_INSTANCE().then((response) => response.get(`${CONTROLLER_NAME}/myBookings`));
};

export const getMyBookingsDetails = (bookingID, leaseType) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/myBookings?booking_id=${bookingID}&lease_type=${leaseType}`)
  );
};

export const deleteMyBookingsDetails = (bookingID, leaseType) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.delete(`${CONTROLLER_NAME}/myBookings?booking_id=${bookingID}&lease_type=${leaseType}`)
  );
};

// ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ---------- APPROVAL REGISTRATION ----------
export const getApprovalRegistration = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/sellerRegistration`)
  );
};

export const getApprovalRegistrationDetails = (registrationID) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/sellerRegistration?registration_id=${registrationID}`)
  );
};

export const postApprovalRegistration = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/sellerRegistration`, bodyParams)
  );
};

// ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ---------- LANDING WIDGET ----------
export const getActionRequired = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/actionRequired`)
  );
};

export const getNotifications = () => {
  return LAMBDA_API_INSTANCE().then((response) => response.get(`${CONTROLLER_NAME}/notifications`));
};

export const getVoyagePlanning = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/voyagePlanning`)
  );
};

// ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ---------- SELLER BOOKING ----------
export const getMySellerBookings = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/mySellerBookings`)
  );
};

export const getVODSellerBookingSummary = (tripId) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(`${CONTROLLER_NAME}/voyageSummary?trip_id=${tripId}`)
  );
};

export const postBuyerReservation = (reservation_id, mode, lease_type) => {
  const bodyParams = {
    reservation_id,
    mode,
    lease_type,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/buyerReservation`, bodyParams)
  );
};

export const postConfirmVoyageCompletion = (bodyParams) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/confirmVoyageCompletion`, bodyParams)
  );
};

export const getScheduleVarianceApi = (reservationId, tripId) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/scheduleVariance?buyer_reservation_id=${reservationId}&trip_id=${tripId}`
    )
  );
};

// ------------ Location ------------------

export const getLocationPageDropdownApi = (dropdownCategory) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/dropdown?page_name=Location-Management&dropdown_category=${dropdownCategory}`
    )
  );
};

export const getLocationListApi = (query, offset, limit) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.get(
      `${CONTROLLER_NAME}/myLocations?search_query=${query}&offset=${offset}&limit=${limit}`
    )
  );
};

export const getLocationDetailApi = (id) => {
    return LAMBDA_API_INSTANCE().then((response) =>
        response.get(
            `${CONTROLLER_NAME}/myLocations?location_id=${id}`
        )
    );
};

export const getLocationFileDownloadApi = (id) => {
    return LAMBDA_API_INSTANCE().then((response) =>
        response.get(
            `${CONTROLLER_NAME}/downloadLocationFile?location_file_id=${id}`
        )
    );
};

export const postLocationApi = (bodyParams) => {
    return LAMBDA_API_INSTANCE().then((response) =>
        response.post(`${CONTROLLER_NAME}/myLocations`, bodyParams)
    );
};

export const putLocationApi = (bodyParams) => {
    return LAMBDA_API_INSTANCE().then((response) =>
        response.put(`${CONTROLLER_NAME}/myLocations`, bodyParams)
    );
};

export const deleteLocationApi = (locationId) => {
    return LAMBDA_API_INSTANCE().then((response) =>
        response.delete(`${CONTROLLER_NAME}/myLocations?location_id=${locationId}`)
    );
};


