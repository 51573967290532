import * as services from '../services/serviceVesselDetail'
import { fetchError, fetchStart, fetchSuccess } from './common/actionFetch'


export const GET_VESSEL_MAINTENANCE_LOG = 'GET_VESSEL_MAINTENANCE_LOG'
export const getMaintenanceLogList = (vessel_id) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_MAINTENANCE_LOG))
    services.getMaintenanceLogList(vessel_id)
      .then((response) => {
        dispatch({
          type: GET_VESSEL_MAINTENANCE_LOG,
          payload: response?.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_VESSEL_MAINTENANCE_LOG)))
      .catch(() => dispatch(fetchError(GET_VESSEL_MAINTENANCE_LOG)))
  }
}


export const GET_TYPE_AND_LOCATION_DROPDOWN = 'GET_TYPE_AND_LOCATION_DROPDOWN'
export const getTypeAndLocationDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_TYPE_AND_LOCATION_DROPDOWN))
    services.getTypeAndLocationDropdown()
      .then((response) => {
        dispatch({
          type: GET_TYPE_AND_LOCATION_DROPDOWN,
          payload: response
        })
      })
      .then(() => dispatch(fetchSuccess(GET_TYPE_AND_LOCATION_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_TYPE_AND_LOCATION_DROPDOWN)))
  }
}

export const GET_VESSEL_FILTER_DROPDOWN = 'GET_VESSEL_FILTER_DROPDOWN'
export const getFilterDropdown = (vessel_id) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_FILTER_DROPDOWN))
    services.getFilterDropdown(vessel_id)
      .then((response) => {
        dispatch({
          type: GET_VESSEL_FILTER_DROPDOWN,
          payload: response?.data
        })
      })
      .then(() => dispatch(fetchSuccess(GET_VESSEL_FILTER_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_VESSEL_FILTER_DROPDOWN)))
  }
}

export const DEL_VESSEL_MAINTENANCE_TASK = 'DEL_VESSEL_MAINTENANCE_TASK'
export const deleteMaintenanceTask = (maintenance_task_id, maintenance_id, vessel_id, getMaintenanceLogList) => {
  return (dispatch) => {
    dispatch(fetchStart(DEL_VESSEL_MAINTENANCE_TASK))
    services.deleteMaintenanceTask(maintenance_task_id, maintenance_id)
      .then((response) => {
        dispatch({
          type: DEL_VESSEL_MAINTENANCE_TASK,
          payload: response
        })
        getMaintenanceLogList && getMaintenanceLogList(vessel_id)
      })
      .then(() => dispatch(fetchSuccess(DEL_VESSEL_MAINTENANCE_TASK)))
      .catch(() => dispatch(fetchError(DEL_VESSEL_MAINTENANCE_TASK)))
  }
}


export const ADD_UPDATE_VESSEL_MAINTENANCE_LOG = 'ADD_UPDATE_VESSEL_MAINTENANCE_LOG'
export const addUpdateMaintenanceLog = (payloadObj, vessel_id, getMaintenanceLogList, setOpenModalMaintenance, getFilterDropdown) => {
  return (dispatch) => {
    dispatch(fetchStart(ADD_UPDATE_VESSEL_MAINTENANCE_LOG))
    services.createUpdateMaintenanceLog(payloadObj)
      .then((response) => {
        dispatch({
          type: ADD_UPDATE_VESSEL_MAINTENANCE_LOG,
          payload: response
        })
        getFilterDropdown && getFilterDropdown(vessel_id)
        getMaintenanceLogList && getMaintenanceLogList(vessel_id)
        setOpenModalMaintenance && setOpenModalMaintenance({ action: '', open: false })
      })
      .then(() => dispatch(fetchSuccess(ADD_UPDATE_VESSEL_MAINTENANCE_LOG)))
      .catch(() => dispatch(fetchError(ADD_UPDATE_VESSEL_MAINTENANCE_LOG)))
  }
}