import * as vesselDetailActions from '../actions/actionVesselDetail'

const defaultState = {
  vesselDetail: {
    maintenanceLogList: [],
    typesAndLoctions: {
      typeDropdowns: [],
      locationDropdowns: [],
      statusDropdowns: [],
    },
    filterDropdown: {
      maintenanceIdDropdown: [],
      maintenanceTypeDropdown: [],
    },
    actionResponse: { is_valid: false, message: '', data: {} },
  },
}

const REDUCERS = {
  [vesselDetailActions.GET_VESSEL_MAINTENANCE_LOG]: (state, { payload }) => {
    return {
      ...state,
      vesselDetail: {
        ...state.vesselDetail,
        maintenanceLogList: payload,
      },
    }
  },
  [vesselDetailActions.GET_TYPE_AND_LOCATION_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      vesselDetail: {
        ...state.vesselDetail,
        typesAndLoctions: {
          typeDropdowns: payload.maintenance_types || [],
          locationDropdowns: payload.maintenance_locations || [],
          statusDropdowns: payload.maintenance_status || [],
        },
      },
    }
  },
  [vesselDetailActions.GET_VESSEL_FILTER_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      vesselDetail: {
        ...state.vesselDetail,
        filterDropdown: {
          maintenanceIdDropdown: payload.maintenance_ids || [],
          maintenanceTypesDropdown: payload.maintenance_types || [],
        },
      },
    }
  },
  [vesselDetailActions.ADD_UPDATE_VESSEL_MAINTENANCE_LOG]: (state, { payload }) => {
    return {
      ...state,
      vesselDetail: {
        ...state.vesselDetail,
        actionResponse: payload,
      },
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const VESSEL_DETAIL_REDUCER = 'vesselDetail'
