import * as bookingPageActions from '../actions/actionBooking'

const defaultState = {
  bookingPage: {
    module: 'search',
    finalised: 'finalised',
    deckUnit: 'm2',
    tripId: [],
    savings: [],
    isEdit: false,
    materialMatchingVoyages: [],
    passengerMatchingVoyages: [],
    updatedPassengerMatchingVoyages: [],
    updatedMaterialMatchingVoyages: [],
    saveReservation: [],
    editPassengerReservationData: [],
    editMaterialReservationData: [],
    reservationListData: [],
    passengerFinalizedItineraryData: [],
    materialFinalizedItineraryData: [],
    confirmMaterialData: [],
    confirmEditClicked: false,
    cargoFields: [
      {
        demandType: '',
        cargoType: '',
        approximateVolume: '',
        uom: '',
        cargoTypeOption: [],
      },
    ],
    page: 'home',
    projectName: '',
    iapPlanner: '',
    discipline: '',
    type: 'Material',
    demandType: 'Payload Under Deck',
    startLocation: 'PDJT',
    endLocation: '',
    incomingPassenger: '',
    outgoingPassenger: '',
    specialEddPeriod: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
    specialLddPeriod: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
    reservationList: {
      originalVoyage: [],
    },
    dropdown: {
      iapDropdown: [],
    },
    tabActive: 0,
  },
  projectDropdown: [],
  disciplineDropdown: [],
  locationDropdown: [],
  demandTypeDropdown: [],
  cargoTypeDropdown: [],
  cargoUomDropdown: [],
  materialSpecialRequestData: {},
  trackVoyage: {},
}

const REDUCERS = {
  [bookingPageActions.SET_PAGE]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        page: payload,
      },
    }
  },
  [bookingPageActions.SET_MODULE]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        module: payload,
      },
    }
  },
  [bookingPageActions.SET_BOOKING_TYPE]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        type: payload,
      },
    }
  },
  [bookingPageActions.SET_EDIT_BUTTON]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        confirmEditClicked: payload,
      },
    }
  },
  [bookingPageActions.SET_IS_EDIT]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        isEdit: payload,
      },
    }
  },
  [bookingPageActions.SET_MATERIAL_SPECIAL_REQUEST_DATA]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        materialSpecialRequestData: payload,
      },
    }
  },
  [bookingPageActions.POST_VIEW_MATERIAL_MATCHING_VOYAGES]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        materialMatchingVoyages: payload,
      },
    }
  },
  [bookingPageActions.POST_VIEW_PASSENGER_MATCHING_VOYAGES]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        passengerMatchingVoyages: payload,
      },
    }
  },
  [bookingPageActions.POST_SAVE_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        saveReservation: payload,
      },
    }
  },
  [bookingPageActions.POST_VIEW_RESERVATION_LIST]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        reservationListData: payload,
      },
    }
  },
  [bookingPageActions.POST_EDIT_PASSENGER_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        editPassengerReservationData: payload,
      },
    }
  },
  [bookingPageActions.POST_EDIT_MATERIAL_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        editMaterialReservationData: payload,
      },
    }
  },
  [bookingPageActions.POST_VIEW_PASSENGER_FINALIZED_ITINERARY]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        passengerFinalizedItineraryData: payload,
      },
    }
  },
  [bookingPageActions.POST_VIEW_MATERIAL_FINALIZED_ITINERARY]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        materialFinalizedItineraryData: payload,
      },
    }
  },
  [bookingPageActions.POST_SAVE_SPECIAL_REQUEST]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        saveSpecialRequest: payload,
      },
    }
  },
  [bookingPageActions.POST_DELETE_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        deleteReservationAction: payload,
      },
    }
  },
  [bookingPageActions.UPDATED_PASSENGER_VOYAGE]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        updatedPassengerMatchingVoyages: payload,
      },
    }
  },
  [bookingPageActions.UPDATED_MATERIAL_VOYAGE]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        updatedMaterialMatchingVoyages: payload,
      },
    }
  },
  [bookingPageActions.POST_UPDATE_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        updateReservationAction: payload,
      },
    }
  },
  [bookingPageActions.SET_INCOMING_PASSENGER]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        incomingPassenger: payload,
      },
    }
  },
  [bookingPageActions.SET_OUTGOING_PASSENGER]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        outgoingPassenger: payload,
      },
    }
  },
  [bookingPageActions.RESET_BOOKING_PAGE]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: defaultState.bookingPage,
    }
  },
  [bookingPageActions.SET_PROJECT_INPUT_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        projectDropdown: payload,
      },
    }
  },
  [bookingPageActions.SET_DISCIPLINE_INPUT_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        disciplineDropdown: payload,
      },
    }
  },
  [bookingPageActions.SET_LOCATION_INPUT_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        locationDropdown: payload,
      },
    }
  },
  [bookingPageActions.SET_DEMAND_TYPE_SPECIAL_REQUEST_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        demandTypeDropdown: payload,
      },
    }
  },
  [bookingPageActions.SET_CARGO_SPECIAL_REQUEST_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        cargoTypeDropdown: payload,
      },
    }
  },
  [bookingPageActions.SET_CARGO_UOM_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        cargoUomDropdown: payload,
      },
    }
  },
  [bookingPageActions.SET_CONFIRM_MATERIAL]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        confirmMaterialData: payload,
      },
    }
  },
  [bookingPageActions.POST_VIEW_ORIGINAL_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        reservationList: {
          ...state.bookingPage.reservationList,
          originalVoyage: payload,
        },
      },
    }
  },
  [bookingPageActions.POST_TRIGGER_VIEWED_RESERVATION]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        triggerViewedReservationAction: payload,
      },
    }
  },
  [bookingPageActions.SET_IAP_PLANNER_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        dropdown: {
          ...state.bookingPage.dropdown,
          iapDropdown: payload,
        },
      },
    }
  },
  [bookingPageActions.POST_UPDATE_APPROVAL_STATUS]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        updateApprovalStatusAction: payload,
      },
    }
  },
  [bookingPageActions.POST_SAVE_RESERVATION_APPROVER]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        saveReservationApproverAction: payload,
      },
    }
  },
  [bookingPageActions.POST_DOWNLOAD_RESERVATION_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        downloadReservationDetails: payload,
      },
    }
  },
  [bookingPageActions.POST_TRACK_VOYAGE_STATUS]: (state, { payload }) => {
    return {
      ...state,
      trackVoyage: payload,
    }
  },
  [bookingPageActions.SET_ACTION_BOOKING_PAGE]: (state, { payload }) => {
    return {
      ...state,
      bookingPage: {
        ...state.bookingPage,
        ...payload,
      }
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const BOOKING_PAGE_REDUCER = 'bookingPage'
