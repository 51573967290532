import * as services from '../services/serviceDeleteVessel'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

export const POST_VESSEL_SCHEDULE_DATA = 'POST_VESSEL_SCHEDULE_DATA'
export const setVesselScheduleData = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_SCHEDULE_DATA))
    services
      .postVesselScheduleData(filterObject)
      .then((response) =>
        dispatch({
          type: POST_VESSEL_SCHEDULE_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VESSEL_SCHEDULE_DATA)))
      .catch(() => dispatch(fetchError(POST_VESSEL_SCHEDULE_DATA)))
  }
}

export const POST_DELETE_VESSEL_SCHEDULE = 'POST_DELETE_VESSEL_SCHEDULE'
export const setDeleteVesselSchedule = (filterObject) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DELETE_VESSEL_SCHEDULE))
    services
      .postDeleteVesselSchedule(filterObject)
      .then((response) =>
        dispatch({
          type: POST_DELETE_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DELETE_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(POST_DELETE_VESSEL_SCHEDULE)))
  }
}
