import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";

const CONTROLLER_NAME = "/demand";

export const getInputDemandDropdown = (type, dropdown_category) => {
  const bodyParams = {
    type,
    dropdown_category,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/inputDemandDropdown`, bodyParams)
  );
};

export const getDemandTypeSpecialRequestDropdown = (type) => {
  const bodyParams = {
    type: type,
    dropdown_category: "demand_type",
    project_name: null,
    discipline_name: null,
    demand_type: null,
    cargo_name: null,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/inputSpecialRequestDropdown`, bodyParams)
  );
};

export const getCargoSpecialRequestDropdown = (type, demandType) => {
  const bodyParams = {
    type: type,
    dropdown_category: "cargo_name",
    project_name: null,
    discipline_name: null,
    demand_type: demandType,
    cargo_name: null,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/inputSpecialRequestDropdown`, bodyParams)
  );
};

export const getCargoUomDropdown = (type, cargoName) => {
  const bodyParams = {
    type: type,
    dropdown_category: "cargo_uom",
    demand_type: null,
    cargo_name: cargoName,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/inputSpecialRequestDropdown`, bodyParams)
  );
};

export const getViewIapPlannerDropdown = (userEmail) => {
  const bodyParams = {
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewIAPPlannerDropdown`, bodyParams)
  );
};

export const postViewMatchingVoyages = (
  type,
  projectName,
  discipline,
  startLocation,
  endLocation,
  departureDate,
  arrivalDate
) => {
  const bodyParams = {
    type: type,
    project_name: projectName,
    discipline_name: discipline,
    start_location: startLocation,
    end_location: endLocation,
    departure_date: departureDate,
    arrival_date: arrivalDate,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewMatchingVoyages`, bodyParams)
  );
};

export const postSaveSpecialRequest = (confirmSpecialRequestData) => {
  const bodyParams = confirmSpecialRequestData;
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/saveSpecialRequest`, bodyParams)
  );
};

export const postSaveReservation = (confirmReservationData) => {
  const bodyParams = confirmReservationData;
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/saveReservation`, bodyParams)
  );
};

export const validateReservedCapacity = (confirmReservationData) => {
  const bodyParams = confirmReservationData;

  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/validateReservedCapacity`, bodyParams)
  );
};

export const postUpdateReservation = (updatedReservation) => {
  const bodyParams = updatedReservation;
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/updateReservation`, bodyParams)
  );
};

export const postSaveReservationApprover = (type, reservationId, userEmail, iapEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    user_email: userEmail,
    iap_email: iapEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/saveReservationApprover`, bodyParams)
  );
};

export const postViewReservationList = (type, userEmail, status, pagination, filterOptions) => {
  const bodyParams = {
    type: type,
    user_email: userEmail,
    status: status,
    pagination: pagination,
    filter_options: filterOptions,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewReservationList`, bodyParams)
  );
};

export const postViewOriginalReservation = (type, reservationId, voyageId, userEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewOriginalReservation`, bodyParams)
  );
};

export const postTriggerViewedReservation = (type, reservationId, voyageId, userEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/triggerViewedReservation`, bodyParams)
  );
};

export const postDownloadReservationDetails = (type, reservationId, userEmail, voyageId) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    user_email: userEmail,
    voyage_id: voyageId,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/downloadReservationDetails`, bodyParams)
  );
};

export const postDeleteReservation = (type, reservationId, voyageId, userEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/deleteReservation`, bodyParams)
  );
};

export const postDeleteSpecialReservation = (type, request_id, user_email) => {
  const bodyParams = {
    type,
    request_id,
    user_email,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/deleteSpecialReservation`, bodyParams)
  );
};

export const postEditReservation = (type, reservationId, voyageId, userEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/editReservation`, bodyParams)
  );
};

export const postViewFinalizedItinerary = (
  type,
  reservationId,
  voyageId,
  userEmail,
  pagination,
  sortOption
) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
    pagination: pagination,
    sort_option: {
      upperdeck: {
        sort_column: sortOption?.upperdeck.sortColumn,
        sort_by: sortOption?.upperdeck.sortBy,
      },
      underdeck: {
        sort_column: sortOption?.underdeck.sortColumn,
        sort_by: sortOption?.underdeck.sortBy,
      },
    },
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewFinalizedItinerary`, bodyParams)
  );
};

export const postUpdateDeliveredStatus = (
  type,
  reservationId,
  tripId,
  bookingId,
  materialId,
  cargoType,
  value
) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    trip_id: tripId,
    booking_id: bookingId,
    material_id: materialId,
    cargo_type: cargoType,
    value: value,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/updateDeliveredStatus`, bodyParams)
  );
};

export const postUpdateApprovalStatus = (mode, type, reservationId, voyageId, userEmail) => {
  const bodyParams = {
    mode: mode,
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/updateApprovalStatus`, bodyParams)
  );
};

export const postTrackVoyageStatus = (type, reservationId, voyageId, userEmail) => {
  const bodyParams = {
    type: type,
    reservation_id: reservationId,
    voyage_id: voyageId,
    user_email: userEmail,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/trackVoyageStatus`, bodyParams)
  );
};

export const postSaveBookingDetails = (requestBody) => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/saveBookingDetails`, requestBody)
  );
};

export const postViewVoyageList = () => {
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewVoyageList`)
  );
};

export const postViewVoyageDetails = (vesselName, tripId) => {
  const bodyParams = {
    vessel_name: vesselName,
    trip_id: tripId,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewVoyageDetails`, bodyParams)
  );
};

export const getDisciplineByProjectDropdown = (projectName) => {
  const bodyParams = {
    project_name: projectName,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/disciplineByProjectDropdown`, bodyParams)
  );
};

export const getViewReservationDropdown = (category, voyage_id, division_name) => {
  const bodyParams = {
    dropdown_category: category,
    voyage_id: voyage_id,
    division_name,
  };
  return LAMBDA_API_INSTANCE().then((response) =>
    response.post(`${CONTROLLER_NAME}/viewReservationDropdown`, bodyParams)
  );
};
