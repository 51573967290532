import * as OnboardingStatusActions from '../../actions/common/actionOnboardingStatus'

const defaultState = {
  onBoardingStatus: {
    data: '',
  },
}

const REDUCERS = {
  [OnboardingStatusActions.GET_ONBOARDING_STATUS]: (state, { payload }) => {
    return {
      ...state,
      onBoardingStatus: {
        ...state.onBoardingStatus,
        data: payload,
      },
    }
  },
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const ONBOARDING_STATUS_REDUCER = 'commonOnboardingStatus'
