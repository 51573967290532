import * as systemAlertActions from '../actions/actionSystemAlertPage';

const defaultState = {
  systemAlertPage: {
    systemAlertTypes: [],
    vessels: [],
    systemAlerts: {},
    emailOptions: [],
  },
};

const REDUCERS = {
  [systemAlertActions.GET_SYSTEM_ALERTS_TYPES]: (state, { payload }) => {
    return {
      ...state,
      systemAlertPage: {
        ...state.systemAlertPage,
        systemAlertTypes: payload,
      },
    };
  },

  [systemAlertActions.GET_VESSELS]: (state, { payload }) => {
    return {
      ...state,
      systemAlertPage: {
        ...state.systemAlertPage,
        vessels: payload,
      },
    };
  },

  [systemAlertActions.GET_SYSTEM_ALERTS]: (state, { payload }) => {
    return {
      ...state,
      systemAlertPage: {
        ...state.systemAlertPage,
        systemAlerts: payload,
      },
    };
  },

  [systemAlertActions.GET_EMAIL_OPTIONS]: (state, { payload }) => {
    return {
      ...state,
      systemAlertPage: {
        ...state.systemAlertPage,
        emailOptions: payload,
      },
    };
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const SYSTEM_ALERT_PAGE_REDUCER = 'systemAlertPageReducer';
